import { TextFragmentType } from './TextFragmentType'
import { Component } from './component'

export class TextFragment {
  type
  text
  component

  constructor(
    type = TextFragmentType.NULL,
    text = '',
    component = '',
  ) {
    this.type = type
    if(type === TextFragmentType.TEXT ) this.text = text
    this.component = component
  }

  static NULL = new TextFragment()
}
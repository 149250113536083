import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import * as PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Type } from '../../../store/constants/type'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  conventionItem: {
    paddingTop: 14,
    paddingBottom: 10,
    borderBottom: `1px solid #dadde7`,
  },
}))
const componentHeaders = ['Nom', 'Label', 'Type', 'Description']
export const ComponentTable = ({ components, listType }) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    conventionCollective: { idcc },
    theme: { nom: themeName },
  } = useSelector(state => state.conventionModule)
  const handleClick = nom => history.push(`/detail-convention/${idcc}/theme/${themeName}/${listType}/${nom}`)
  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {componentHeaders.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {components.map(({ nom, label, type, description }) => (
              <TableRow
                hover
                key={nom}
                data-test={listType + '_row_' + nom}
                className={listType + '_row'}
                onClick={() => listType === 'input' && type !== Type.DATE && handleClick(nom)}
              >
                <TableCell data-test={`${listType}_nom`}>{nom}</TableCell>
                <TableCell data-test={`${listType}_label`}>{label}</TableCell>
                <TableCell data-test={`${listType}_type`}>{type.name.toLowerCase()}</TableCell>
                <TableCell data-test={`${listType}_description`}>{description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

ComponentTable.propTypes = {
  components: PropTypes.array.isRequired,
  listType: PropTypes.string.isRequired,
}

import { makeStyles } from '@material-ui/core'
import { getThemes, updateTheme } from '../../store/actions/theme-action'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Theme } from '../../store/constants/theme'
import { DataCellButton } from '../tableur/tab/table/row/cell/data-cell-button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Type } from '../../store/constants/type'
import { isList } from '../../store/constants/type-helper'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  container: { maxHeight: '100%', overflowX: 'hidden' },
  selector: { margin: 0, height: 31 },
}))
const setInputType = theme => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(updateTheme(theme)))
    .then(() => dispatch(getThemes(true)))
    .catch(e => console.log(e))
}

function isReferentialUnit(components, input) {
  return components.find(comp => comp.nom === input.unit.nom).type === Type.REFERENCE
}

function isReferenceOrWithUnitRef(input, components) {
  return input.type === Type.REFERENCE
    || (isList(input.type) && isReferentialUnit(components, input))
}

export function SelectButton({ theme }) {
  const [inputType, setinputType] = useState('')
  const [isButton, setIsButton] = useState(true)
  const components = useSelector(state => state.componentModule.components)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = ({ target: { value } }) => {
    const copy = Theme.clone(theme)
    copy.inputType = value
    dispatch(setInputType(copy))
    setIsButton(true)
  }

  useEffect(() => {
    setinputType(theme.inputType || '')
  }, [theme])

  const handleClick = () => setIsButton(!isButton)
  let componentsFiltred = [...theme.inputs, ...theme.inputs.flatMap(i => [...i.enfants])]
    .map(input => components.find(comp => comp.nom === input.nom))
    .filter(input => isReferenceOrWithUnitRef(input, components),
    )
  return isButton ? <DataCellButton data-test={`inputType_${theme.nom}_button`} onClick={handleClick}>
    {inputType}
  </DataCellButton> : <FormControl className={classes.selector}>
    <InputLabel id='input_type_label'>Type</InputLabel>
    <Select
      labelId='input_type_label'
      data-test='select_input_type'
      value={inputType}
      onChange={handleChange}
      open
      onClick={handleClick}
    >
      <MenuItem data-test={'select_input_type_aucun'}>
        <i>aucun</i>
      </MenuItem>
      {componentsFiltred.map(({ nom: inputName }) =>
        <MenuItem key={inputName} value={inputName} data-test={'select_input_type_' + inputName}>
          {inputName}
        </MenuItem>,
      )}
    </Select>
  </FormControl>
}

import { Type } from '../../../store/constants/type'
import { Operateur } from './Operateur'
import { TypeFormField } from '../../../store/constants/TypeFormField'

export function getPossibleComparators(element) {
  return () => {
    if (element.type === Type.DATE || element.type === Type.NUMERIC) {
      return Operateur.COMPARATORS
    } else if (element.type === Type.LIST || element.type === Type.LIST_EXCLUSION || element.type === Type.TYPE_THEME) {
      return Operateur.COMPARATORS_LIST
    } else {
      return Operateur.COMPARATORS_REF
    }
  }
}

export function getSameTypeComps(allComp, element) {
  return () =>
    allComp.filter(
      c =>
        c.type === element.type &&
        (!(element.type === Type.LIST || element.type === Type.LIST_EXCLUSION) ||
          allComp.find(comp => comp.nom === c.unit.nom).type === Type.REFERENCE)
    )
}

export function getPossibleOperators(element) {
  return () =>
    element.type === Type.REFERENCE ||
    element.type === Type.TEXT ||
    element.type === Type.LIST ||
    element.type === Type.LIST_EXCLUSION
      ? Operateur.COMPARATORS_DEFAULT
      : Operateur.OPERATORS
}

export function getPossibleTargetValues(formFields, element, referentielName) {
  return () =>
    formFields.length
      ? formFields
          .filter(f => {
            return (
              ((f.typeForm.name === element.type.name ||
                (element.type.name == Type.REFERENCE.name && f.typeForm.name == Type.LIST.name) ||
                (element.type.name == Type.LIST.name && f.typeForm.name == Type.LIST_EXCLUSION.name)) &&
                (element.type.name === Type.TYPE_THEME.name || element.component) &&
                (!referentielName || f.referentielName === referentielName)) ||
              (!!f.workflowField &&
                f.workflowField.typeForm.name === element.type.name &&
                (!referentielName || f.workflowField.referentielName === referentielName))
            )
          })

          .map(f => f.field)
      : []
}

export function isNotCompleteWorkflow(element) {
  return element.typeForm === TypeFormField.WORKFLOW && (!element.workflowRef || !element.workflowField)
}

export function getOperatorAndValue(targetOperator, targetValue, selectedTargetValue) {
  const value = targetOperator
    ? targetValue || selectedTargetValue
      ? targetValue
        ? targetValue
        : selectedTargetValue
      : 'vide'
    : ''
  return `${Operateur.getOperateurFromString(targetOperator).label}${value ? ' ' + value : ''}`
}

export function getNewValue({ value, operation, refField, operationUnit }) {
  if (operation === '=') return getOperatorAndValue(operation, value, refField)
  else if (operation)
    return (
      refField +
      ' ' +
      operation +
      ' ' +
      value +
      (operationUnit ? ' ' + (operationUnit.label ? operationUnit.label : operationUnit) : '')
    )
  return ''
}

export function getWFInputValue(tmpRefElement) {
  return tmpRefElement.component
    ? tmpRefElement.component.nom
      ? tmpRefElement.component.nom
      : tmpRefElement.component
    : ''
}

export function setWFComp(value, element, referentiels, setReferentielValues, setReferentielName) {
  if (value && element.type === Type.REFERENCE) {
    const found = referentiels.find(r => r.nom === value.referentiel)
    if (found) {
      setReferentielValues(found.valeurs)
      setReferentielName(value.referentiel)
    }
  } else {
    setReferentielValues([])
    setReferentielName('')
  }
}

import { Statut } from './historique'

export class Data {
  id
  inputs
  outputs
  statut
  historique
  editable
  constructor(id = 0, inputs = {}, outputs = {}, statut = Statut.NULL, historique = "",editable=true) {
    this.id = id
    this.inputs = inputs
    this.outputs = outputs
    this.statut = statut
    this.historique = historique
    this.editable=editable
  }
  static NULL = new Data()
}

import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TableCell } from '@material-ui/core'
import { Type } from '../../store/constants/type'
import InputText from '../common/input-text'
import CellActions from '../common/table/editable/renderer/cell-actions'

export default function AddListValues({
  component,
  rowValue,
  dataTestPrefix,
  dataTestKey,
  onCancel,
  onSave,
  onChange,
  onEditReferentiel,
  childrenReferentiel,
  save,
}) {
  const [rowData, setRowData] = useState(rowValue)
  const [updateAvailable, setUpdateAvailable] = useState(true)
  const unit = component.unit

  const dataTest = dataTestPrefix + '_edit_'

  const handleInputText = (name, e, value) => {
    const val = { ...rowData }
    val[name] = value
    setRowData(val)
    onChange && onChange(val)
  }

  const handleCancel = () => {
    setRowData(rowValue)
    setUpdateAvailable(false)
    onCancel && onCancel()
  }

  const handleSave = () => {
    setUpdateAvailable(false)
    if (onSave) {
      if (!onSave(rowData)) {
        setUpdateAvailable(true)
      }
    }
  }

  const handleSelectChange = event => {
    const value = event.target.value
    if (value.find(val => val === 'modifierReferentiel')) {
      onEditReferentiel && onEditReferentiel(unit)
    } else {
      const val = { ...rowData }
      val.valeur = value
      setRowData(val)
      onChange && onChange(val)
    }
  }

  return (
    <>
      <TableCell key="0">
        <InputText
          onChange={(e, value) => handleInputText('description', e, value)}
          placeholder={'description'}
          initialValue={rowValue.description}
          dataTest={dataTest + 'description'}
          dataKey={dataTestKey}
        />
      </TableCell>
      {unit.type === Type.NUMERIC && (
        <TableCell key="1">
          <InputText
            onChange={(e, value) => handleInputText('valeur', e, value.split(';'))}
            placeholder={unit.nom}
            initialValue={rowValue.valeur.join(';')}
            dataTest={dataTest + 'valeur'}
            dataKey={dataTestKey}
            pattern="^([0-9]*(\.)?[0-9]*)(;[0-9]*(\.)?[0-9]*)*$"
          />
        </TableCell>
      )}
      {unit.type === Type.TEXT && (
        <TableCell key="1">
          <InputText
            onChange={(e, value) => handleInputText('valeur', e, value.split(';'))}
            placeholder={unit.nom}
            initialValue={rowValue.valeur.join(';')}
            dataTest={dataTest + 'valeur'}
            dataKey={dataTestKey}
          />
        </TableCell>
      )}
      {unit.type === Type.REFERENCE && (
        <TableCell key="1">
          <FormControl>
            <InputLabel id={'component-select-label-' + unit.nom}>{unit.nom}</InputLabel>
            <Select
              id={dataTest + unit.nom}
              multiple={true}
              name={unit.nom}
              value={rowData.valeur.filter(val => !!val)}
              renderValue={value =>
                value
                  .map(v => v + ' - ' + childrenReferentiel[unit.nom].values.find(r => r.code === v).libelle)
                  .join(', ')
              }
              labelId={'component-select-label-' + dataTest + unit.nom}
              onChange={handleSelectChange}
            >
              {childrenReferentiel[unit.nom].values.map(option => (
                <MenuItem value={option.code} key={option.code}>
                  <Checkbox checked={rowData.valeur.indexOf(option.code) > -1} />
                  <ListItemText primary={`${option.code} - ${option.libelle}`} />
                </MenuItem>
              ))}
              <MenuItem value="modifierReferentiel">
                <em>Modifier le référentiel</em>
              </MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      )}

      <CellActions
        key="2"
        dataTestKey={dataTestKey}
        dataTestPrefix={dataTestPrefix}
        save={updateAvailable && save}
        cancel={true}
        edit={false}
        remove={false}
        onSave={() => handleSave()}
        onCancel={() => handleCancel()}
      />
    </>
  )
}
AddListValues.propTypes = {
  component: PropTypes.any.isRequired,
  rowValue: PropTypes.object.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  dataTestKey: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditReferentiel: PropTypes.func,
  childrenReferentiel: PropTypes.any.isRequired,
  save: PropTypes.bool.isRequired,
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import { Button, Checkbox, Grid, TextField } from '@material-ui/core'
import { ContainerTitle } from '../common/container-title'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CancelButton } from '../common/cancel-button'
import { Theme } from '../../store/constants/theme'
import ConventionCollective from '../../store/constants/convention-collective'
import { getExcelFile } from '../../store/actions/tableur-action'

export const ExportPopOver = ({ setAnchorElExportForm, anchorElExportForm, openExportForm }) => {

  const [selectedConventions, setSelectedConventions] = useState([])
  const [selectedThemes, setSelectedThemes] = useState([])
  const [withChildren, setWithChilren] = useState(false)
  const [withDefault, setWithDefault] = useState(false)
  const [ccOnly, setCcOnly] = useState(false)
  const testmode = useSelector(state => state.user.testmode)

  const conventions = useSelector(state => state.conventionModule.conventionsCollectives)
  const themes = useSelector(state => state.themeModule.themes)
  const convention = useSelector(state => state.conventionModule.conventionCollective)
  const theme = useSelector(state => state.conventionModule.theme)

  const dispatch = useDispatch()

  useEffect(() => {
    const themeParent = theme !== Theme.NULL ? themes.find(t => t.idTheme === theme.themeParentId) : Theme.NULL
    setSelectedThemes(themeParent !== Theme.NULL && convention.idcc !== 'all'
      ? [themeParent.idTheme + " - " + themeParent.nom]
      : [])
    setSelectedConventions(convention !== ConventionCollective.NULL && convention.idcc !== 'all'
      ? [convention.idcc +" - "+convention.titre]
      : [])
  }, [convention, theme])

  function getIdccList() {
    return selectedConventions.map(cc => cc.split(' - ')[0]).map(cc => 'idcc=' + cc).join('&')
  }

  function getThemesList() {
    return selectedThemes.map(cc => cc.split(' - ')[1]).map(theme => 'themeName=' + theme).join('&')
  }


  const handleExportData = () => {
    setAnchorElExportForm(null)
    const themesList = getThemesList()
    const idccList = getIdccList()
    const children = 'withChildren='+ (withChildren ? 'true' : 'false' )
    const defaultV = 'withDefault='+ (withDefault ? 'true' : 'false' )
    const withCCOnly = 'useCCThemes='+ (ccOnly ? 'true' : 'false' )
    const exportUrl = `/${testmode ? 'testmode' : 'api'}/export?${idccList}&${themesList}&${children}&${withCCOnly}&${defaultV}`
    const message = 'Aucune données à exporter pour les conventions ['
          + idccList
          + '] dans les thèmes [' + themesList + ']'
    dispatch(getExcelFile(exportUrl, message))
  }

  function handleValuesChangeConventions(values) {
    if(values.length <= 50) setSelectedConventions(values)
  }

  function handleValuesChangeThemes(values) {
    setSelectedThemes(values)
  }

  function handleChangeWithChildren() {
    setWithChilren(!withChildren)
  }

  function handleChangeWithDefault() {
    setWithDefault(!withDefault)
  }

  function handleChangeWithCCOnly() {
    setCcOnly(!ccOnly)
  }

  return <Popover
    anchorEl={anchorElExportForm}
    open={openExportForm}
    anchorReference='anchorPosition'
    anchorPosition={{ top: 0, left: 0 }}

    style={{
      top: 0,
      left: 0,
      width: 600,
      margin: 'auto',
      position: 'relative',
      marginTop: 160,
      minHeight: 600,
      maxHeight: 800
    }}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
  >
    <Paper style={{}} elevation={1}>
      <Grid container style={{ width: 530, padding: 15 }} direction='column'>
        <Grid>
          <ContainerTitle variant='h5' title='Export de données conventionnelles' />
        </Grid>
        <Grid item xs={11} >
          <Checkbox  id="whith_children_box"
                     value={withChildren}
                     color={'primary'}
                     onChange={handleChangeWithChildren}
                     style={{right: 12, marginRight: -10}}/>
          <span style={{verticalAlign: 'middle'}}>Exporter toutes les conventions associées</span>
        </Grid>
        <Grid item xs={11} >
          <Checkbox  id="whith_default_box"
                     value={withDefault}
                     color={'primary'}
                     onChange={handleChangeWithDefault}
                     style={{right: 12, marginRight: -10}}/>
          <span style={{verticalAlign: 'middle'}}>Exporter avec les valeurs par défaut</span>
        </Grid>
        <Grid item xs={11}>
          <Autocomplete multiple
                        renderOption={(option) => (
                          <span role='option' key={option}>{option}</span>
                        )}
                        options={conventions.map(cc => cc.idcc + ' - ' + cc.titre)}
                        getOptionLabel={val => val}
                        onChange={(e, value) => handleValuesChangeConventions(value)}
                        renderInput={(params) => <TextField {...params} label={'Selectionnez les conventions'} />}
                        value={selectedConventions} />
        </Grid>
        <Grid item xs={11}>
          <Autocomplete multiple
                        renderOption={(option) => (
                          <span role='option' key={option}>{option}</span>
                        )}
                        options={themes.filter(t => t.idTheme).map(t => t.idTheme + ' - ' + t.nom)}
                        getOptionLabel={val => val}
                        onChange={(e, value) => handleValuesChangeThemes(value)}
                        renderInput={(params) => <TextField {...params} label={'Selectionnez les thèmes'} />}
                        value={selectedThemes} />
        </Grid>
      </Grid>
      <Grid container direction='row' justify='flex-end' style={{ padding: 15 }}>
        <Grid item>
          <Button
            variant='contained'
            size='medium'
            color='primary'
            onClick={handleExportData}
            style={{ marginRight: 10 }}
            disabled={!selectedThemes.length || !selectedConventions.length}
          >
            EXPORTER
          </Button>
        </Grid>
        <Grid item>
          <CancelButton
            variant='contained'
            size='medium'
            color='primary'
            onClick={() => setAnchorElExportForm(null)}
          >
            ANNULER
          </CancelButton>
        </Grid>
      </Grid>
    </Paper>
  </Popover>
}
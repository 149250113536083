import withStyles from '@material-ui/core/styles/withStyles'
import TableRow from '@material-ui/core/TableRow'

export const AlertRow = withStyles(() => {
  return {
    root: {
      backgroundColor: '#ffcaca',
    },
  }
})(TableRow)

import React from 'react'
import { useSelector } from 'react-redux'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { StatutItem } from '../../historiques/statut-item'
import { Statut } from '../../../store/constants/historique'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  conventionItem: {
    paddingTop: 14,
    paddingBottom: 10,
    borderBottom: `1px solid #dadde7`,
  },
}))

const themeHeaders = ['ID Thème', 'Nom', 'Description', 'Statut']

export const CCThemeList = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    themes,
    conventionCollective: { idcc },
  } = useSelector(state => state.conventionModule)
  const handleClick = async nom => {
    history.push(`/detail-convention/${idcc}/theme/${nom}`)
  }
  return (
    <>
      {themes ? (
        <Paper>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {themeHeaders.map(header => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {themes.map(({ description, idTheme, nom, statut }) => (
                  <TableRow
                    hover
                    key={nom}
                    data-test={'theme_row_' + nom}
                    onClick={() => handleClick(nom)}
                    className="theme_row"
                  >
                    <TableCell data-test={'theme_id'}>{idTheme}</TableCell>
                    <TableCell data-test={'theme_nom'}>{nom}</TableCell>
                    <TableCell data-test={'theme_description'}>{description}</TableCell>
                    <TableCell data-test={'theme_statut'}>
                      <StatutItem statut={statut}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <div>Aucun thème ajouté...</div>
      )}
    </>
  )
}

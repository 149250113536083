import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import * as PropTypes from 'prop-types'
import React from 'react'
import { EditSaveButton } from './edit-save-button'
import ConventionCollective from '../../../store/constants/convention-collective'
import { CCField } from '../../../store/constants/active-cc-field'
import { useCCForm, useCCList } from './hooks'

export const IdccList = ({
  idcc,
  idccListInput,
  onChange,
  isLabel = false,
  onSave,
  label,
  ccField,
}) => {
  const [setEditable, isActive, disabled] = useCCForm(ccField, isLabel)
  const idccList = useCCList(idccListInput)
  return (
    <>
      {disabled ? (
        <>
          <Grid item xs={10}>
            <TextField
              data-test={ccField.name + '_label'}
              label={label}
              className={'TextField'}
              value={idcc}
              margin="normal"
              fullWidth
              disabled
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={10}>
          <TextField
            id={ccField.name}
            label={label}
            select
            data-test={ccField.name}
            className={'TextField'}
            value={idcc}
            onChange={onChange}
            margin="normal"
            fullWidth
          >
            {idccList.map(option => (
              <MenuItem
                key={'key_' + option.idcc}
                value={option.idcc}
                data-test={'option_' + option.idcc}
              >
                {option === ConventionCollective.NULL
                  ? 'Aucune Convention'
                  : `${option.idcc} - ${option.titre}`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      {isLabel && (
        <Grid item xs={2}>
          <EditSaveButton
            editable={isActive}
            setEditable={setEditable}
            dataTest={ccField.name}
            onSave={onSave}
          />
        </Grid>
      )}
    </>
  )
}

IdccList.propTypes = {
  idcc: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLabel: PropTypes.bool,
  idccListInput: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSave: PropTypes.func,
  label: PropTypes.string.isRequired,
  ccField: PropTypes.instanceOf(CCField).isRequired,
}

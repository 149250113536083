import { getPropByString } from '../../Components/tableur/tab/table/row/cell/tableur-cell-helper'
import { createSelector } from 'reselect'
import { contains } from '../../Components/tableur/tab/table/header/tableur-cell-filter'
import { Statut } from '../constants/historique'
import { ModelInstanceWorkflow } from '../constants/ModelInstanceWorkFlow'

const getDataItem = state => {
  return state.tableurModule.data
}
const getFilterItem = state => state.tableurModule.filter
const getDoublonMode = state => state.tableurModule.doublonMode
const getWorkflow = state => {
  return state.workflowModule ? state.workflowModule.workflow : ModelInstanceWorkflow.NULL
}

export const getComputedData = createSelector(
  getDataItem,
  getFilterItem,
  getDoublonMode,
  getWorkflow,
  (data, filter, doublonMode,workflow) => {
    if ((!filter.size && !doublonMode) && workflow === ModelInstanceWorkflow.NULL ) return data
    return data.filter(d => {
      if (doublonMode && d.statut !== Statut.EN_DOUBLON) return false
      if(workflow !== ModelInstanceWorkflow.NULL && !d.editable) return false
      if(!filter.size) return true
      const entries = filter.entries()
      let entry = entries.next()
      while (!entry.done) {
        const [key, values] = entry.value
        if (values && !contains(values, getPropByString(d, key))) return false
        entry = entries.next()
      }
      return true
    })
  }
)

function addUseColumns(d, result, parent) {
    Object.keys(d).forEach(k => {
      let prefix = parent ? parent + '.' : ''
      result.add(prefix + k)
      if(typeof d[k] === 'object' && !Array.isArray(d[k]) && d[k] !== null) addUseColumns(d[k], result, prefix + k)
    })
}

export const getUsedColumns = createSelector(
  getDataItem,
  (data) => {
    const result = new Set()

    data.forEach(d => {
      addUseColumns(d.inputs, result, '')
      addUseColumns(d.outputs, result, '')
    })

    return result;
  }
)

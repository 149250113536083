import React, { useEffect } from 'react'

import { Grid, makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { useParams } from 'react-router'

import LinearProgress from '@material-ui/core/LinearProgress'
import { ContainerTitle } from '../common/container-title'
import TextField from '@material-ui/core/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { loadHistoriqueDetail, publishCCHistorique } from '../../store/actions/historique-action'
import { HistoriqueDetail } from '../../store/constants/historique-detail'
import { Historique, Statut } from '../../store/constants/historique'
import { StatutItem } from './statut-item'
import { AddButton } from '../common/add-button'
import { showConfirmation } from '../../store/actions/alert-action'
import BoButton from '../../Components/common/bo-button'
import { getVersions } from '../../store/actions/version-action'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

export const PUBLICATION_MESSAGE =
  'Attention, vous allez publier les données de convention collective, voulez-vous continuer ?'

function versionsSelector() {
  return state => {
    const vs = state.versionModule.versions.sort((v1, v2) => (v1.id == v2.id ? 0 : v1.id > v2.id ? -1 : 1))
    if (vs.length > 0) return [vs[0]]
    else return []
  }
}

export const HistoriqueDetailPage = () => {
  const classes = useStyles()
  const { idcc, nom } = useParams()

  const loader = useSelector(state => state.loader)
  const testmode = useSelector(state => state.user.testmode)
  const dispatch = useDispatch()
  const historiqueDetail = useSelector(state => state.conventionModule.historiqueDetail)
  const historique = useSelector(state => {
    const find = state.conventionModule.historiques.find(h => h.id === state.conventionModule.historiqueDetail.id)
    return find ? find : Historique.NULL
  })
  const versions = useSelector(versionsSelector(historique.datePublication))
  useEffect(() => {
    if (idcc) {
      dispatch(loadHistoriqueDetail(idcc))
      dispatch(getVersions())
    }
  }, [])

  const handleClick = () => {
    dispatch(
      showConfirmation([PUBLICATION_MESSAGE], () => {
        dispatch(publishCCHistorique(idcc))
      })
    )
  }

  return (
    <>
      {historiqueDetail !== HistoriqueDetail.NULL && historique !== Historique.NULL && (
        <main>
          <Grid container spacing={2}>
            <Grid item xs={6} />
            <Grid item xs={3} container justify="flex-end" />
          </Grid>
          <br />
          <br />
          {loader ? (
            <LinearProgress />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <Paper className={classes.contentRoot} elevation={1}>
                    <ContainerTitle classes={classes} title="Détail historique" />
                    <form className={classes.container}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <TextField
                            data-test={'historique_nom'}
                            label={'Nom'}
                            className={'textField'}
                            value={nom}
                            margin="normal"
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <StatutItem statut={historique.statut} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <TextField
                            data-test={'historique_description'}
                            label={'Description'}
                            className={'textField'}
                            value={historique.description}
                            margin="normal"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <TextField
                            data-test={'historique_themes'}
                            label={'Thèmes'}
                            className={'textField'}
                            value={historiqueDetail.themes.join(', ')}
                            margin="normal"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <TextField
                            data-test={'historique_creations'}
                            label={'Créations'}
                            className={'textField'}
                            value={historiqueDetail.creations}
                            margin="normal"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <TextField
                            data-test={'historique_modifications'}
                            label={'Modifications'}
                            className={'textField'}
                            value={historiqueDetail.modifications}
                            margin="normal"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <TextField
                            data-test={'historique_suppressions'}
                            label={'Suppressions'}
                            className={'textField'}
                            value={historiqueDetail.suppressions}
                            margin="normal"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          {versions.length && (
                            <BoButton
                              title={`Prévisualiser le Json`}
                              url={version =>
                                `/${
                                  testmode ? 'testmode' : 'api'
                                }/bo/${version}/conventions-collectives/${idcc}/historiques/${historiqueDetail.id}/json`
                              }
                              dataTest="JSON"
                              fab={false}
                              placement={'top'}
                              versions={versions}
                              nextVersion={false}
                            />
                          )}
                          {/*<Button data-test="JSON" onClick={handleClick}>JSON</Button>*/}
                        </Grid>
                      </Grid>
                      {historique.statut === Statut.PUBLIE || (
                        <Grid container justify="flex-end">
                          <Grid item xs={2}>
                            <AddButton
                              data-test="Publier"
                              disabled={historique.statut !== Statut.VALIDE}
                              onClick={handleClick}
                            >
                              Publier
                            </AddButton>
                          </Grid>
                        </Grid>
                      )}
                    </form>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          )}
        </main>
      )}
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { withTable } from '../common/table/editable/editable-table'
import Header from '../common/table/editable/renderer/header'
import { withAlert, withValuesSplitter } from '../common/table/editable/editable-row'
import { withActions, withCells } from '../common/table/editable/editable-row-content'
import Action from '../common/table/editable/action'
import ActionsBar from '../common/table/editable/actions-bar'
import * as PropTypes from 'prop-types'
import { getKey } from '../components/helper'
import TableRow from '@material-ui/core/TableRow'
import { withPropsMapping } from '../common/tools/api-react'
import _ from 'lodash'

export const NULL_NUMBER = -1

export function createDuplicateAlert(key) {
  return { rowKey: key, code: 'duplicate', label: 'La ligne est un doublon' }
}

export function ActionForm({
  children,
  childIndex,
  buttonName,
  buttonDataTest,
  rowsEditMode,
  actionEditMode,
  multipleEdit = false,
  onEdit,
  disabled=false,
}) {
  return (
    <Action
      key={childIndex}
      buttonName={buttonName}
      buttonDataTest={buttonDataTest}
      edit={!disabled && (multipleEdit || (rowsEditMode.length === 0 && actionEditMode === NULL_NUMBER))}
      onEdit={() => onEdit && onEdit(childIndex)}
      showButton={actionEditMode === NULL_NUMBER}
      showForm={actionEditMode === childIndex}
    >
      {children}
    </Action>
  )
}
ActionForm.propTypes = {
  childIndex: PropTypes.number.isRequired,
  buttonName: PropTypes.string.isRequired,
  buttonDataTest: PropTypes.string.isRequired,
  rowsEditMode: PropTypes.array.isRequired,
  actionEditMode: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  forceEdit: PropTypes.bool,
  disabled: PropTypes.bool,
}

export function withEditableRow(RowForm, CellsRenderer) {
  let RowComponent = withAlert(withActions(RowForm, withCells(CellsRenderer)))
  let Component = ({
    rowKey,
    rowValue,
    actionEditMode,
    dataTestPrefix,
    rowsEditMode = [],
    alertsOn,
    onEdit,
    onDelete,
    onChange,
    onSave,
    onSelect,
    onCancel,
    edit = true,
    multipleEdit = false,
    remove = true,
    save,
    selectable,
    ...props
  }) => {
    return (
      <RowComponent
        key={rowKey}
        dataTestPrefix={dataTestPrefix}
        dataTestKey={rowKey}
        rowKey={rowKey}
        rowValue={rowValue}
        showForm={rowsEditMode.indexOf(rowKey) >= 0}
        save={save}
        edit={edit && (multipleEdit || (actionEditMode === NULL_NUMBER && rowsEditMode.length === 0))}
        remove={remove && actionEditMode === NULL_NUMBER && rowsEditMode.length === 0}
        onChange={value => {
          onChange && onChange(rowKey, value)
        }}
        onSave={value => {
          onSave && onSave(value, rowKey)
        }}
        onCancel={() => {
          onCancel && onCancel(rowKey)
        }}
        onDelete={() => {
          onDelete && onDelete(rowKey)
        }}
        onEdit={() => {
          onEdit && onEdit(rowKey)
        }}
        alert={!!alertsOn.find(alert => alert.rowKey === rowKey)}
        selectable={selectable && rowsEditMode.indexOf(rowKey) < 0 && actionEditMode === NULL_NUMBER}
        onSelect={selected => {
          onSelect && onSelect(rowKey, rowValue, selected)
        }}
        {...props}
      ></RowComponent>
    )
  }

  Component.propTypes = {
    dataTestPrefix: PropTypes.string.isRequired,
    rowKey: PropTypes.string.isRequired,
    rowValue: PropTypes.any.isRequired,
    actionEditMode: PropTypes.number,
    rowsEditMode: PropTypes.array,
    alertsOn: PropTypes.array.isRequired,
    save: PropTypes.bool.isRequired,
    multipleEdit: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  return Component
}

function withActionsForm(actions) {
  let Component = ({
    onEdit,
    values,
    onChange,
    onCancel,
    onSave,
    save,
    rowsEditMode,
    actionEditMode,
    multipleEdit = false,
    ...props
  }) => {
    return (
      <ActionsBar showForm={actionEditMode !== NULL_NUMBER}>
        {actions.map((ActionComponent, index) => {
          return (
            <ActionComponent
              key={index}
              childIndex={index}
              onEdit={() => onEdit && onEdit(index)}
              onChange={onChange}
              onCancel={onCancel}
              onSave={onSave}
              save={save}
              rowsEditMode={rowsEditMode}
              values={values}
              actionEditMode={actionEditMode}
              multipleEdit={multipleEdit}
              {...props}
            />
          )
        })}
      </ActionsBar>
    )
  }

  Component.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    save: PropTypes.bool.isRequired,
    multipleEdit: PropTypes.bool,
    rowsEditMode: PropTypes.array.isRequired,
    actionEditMode: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
  }
  return Component
}

export function withDefaultHeader(propName) {
  return props => {
    let label = props[propName]
    if (_.isFunction(label)) {
      return <Header>{label()}</Header>
    } else {
      return <Header>{label}</Header>
    }
  }
}

export function withDefaultHeaderLabel(label) {
  return () => {
    return <Header>{label}</Header>
  }
}

export function withHeaders(headers) {
  let Component = props => {
    return (
      <TableRow>
        {headers.map((HeaderComponent, index) => {
          return <HeaderComponent key={index} childIndex={index} {...props} />
        })}
      </TableRow>
    )
  }

  Component.propTypes = {}
  return Component
}

export function withFormTable(actions, headers, RowComponent) {
  const EditableTable = withTable(
    withPropsMapping(withActionsForm(actions), {
      actionOnChange: 'onChange',
      actionEditMode: 'actionEditMode',
      actionOnEdit: 'onEdit',
      actionOnCancel: 'onCancel',
    }),
    headers,
    withPropsMapping(withValuesSplitter(RowComponent), {
      rowOnEdit: 'onEdit',
      rowOnChange: 'onChange',
      rowOnDelete: 'onDelete',
      rowOnCancel: 'onCancel',
    })
  )

  const checkUnicityStable = (valuesMap, isValueEqual, onCheckUnicity, rowValue, excludeRowKey) => {
    let values = Array.from(valuesMap)
    let val = values
      .filter(item => !excludeRowKey || item[0] !== excludeRowKey)
      .find(item => (isValueEqual && isValueEqual(item[1], rowValue)) || _.isEqual(item[1], rowValue))
    if (val) {
      return val[0]
    } else {
      if (onCheckUnicity) {
        let itemChanged = values.find(item => item[0] === excludeRowKey)
        return onCheckUnicity(rowValue, itemChanged)
      }
      return null
    }
  }

  const validateStable = (
    validateRowValue,
    setAlertsOn,
    setEnableSave,
    valuesMap,
    isValueEqual,
    onCheckUnicity,
    rowValue,
    excludeRowKey
  ) => {
    let result = true
    let alerts = []
    if (validateRowValue(rowValue)) {
      let otherKey = checkUnicityStable(valuesMap, isValueEqual, onCheckUnicity, rowValue, excludeRowKey)
      if (_.isString(otherKey)) {
        alerts.push(createDuplicateAlert(otherKey))
        result = false
      } else if (_.isBoolean(otherKey)) {
        result = otherKey
      } else {
        result = !otherKey
      }
    } else {
      result = false
    }
    setAlertsOn(alerts)
    setEnableSave(result)
    return result
  }

  let Component = ({
    onSave,
    onDelete,
    valuesMap,
    validateRowValue,
    dataTestPrefix,
    actionEditKey,
    rowsEditKey = [],
    onActionEdit,
    onRowEdit,
    onRowCancel,
    onActionCancel,
    onSelect,
    onCheckUnicity,
    onRowChange,
    isValueEqual,
    alertsOnValue = [],
    multipleEdit = false,
    createKey = getKey,
    comparator,
    ...props
  }) => {
    const [alertsOn, setAlertsOn] = useState([])
    const [enableSave, setEnableSave] = useState(true)
    const [rowsEditMode, setRowsEditMode] = useState([])
    const [actionEditMode, setActionEditMode] = useState(NULL_NUMBER)
    const [lastAlertsOnValue, setLastAlertsOnValue] = useState(null)

    const validate = (rowValue, excludeRowKey) => {
      return validateStable(
        validateRowValue,
        setAlertsOn,
        setEnableSave,
        valuesMap,
        isValueEqual,
        onCheckUnicity,
        rowValue,
        excludeRowKey
      )
    }

    useEffect(() => {
      if (!_.isUndefined(actionEditKey)) {
        if (actionEditKey === NULL_NUMBER) {
          if (actionEditMode !== NULL_NUMBER) {
            setAlertsOn([])
            setEnableSave(false)
            setActionEditMode(NULL_NUMBER)
          }
        } else if (actionEditMode === NULL_NUMBER) {
          setEnableSave(false)
          setActionEditMode(actionEditKey)
        }
      }
    }, [actionEditKey, actionEditMode, onActionEdit, onActionCancel])

    useEffect(() => {
      rowsEditKey.forEach(rowEditKey => {
        if (rowsEditMode.indexOf(rowEditKey) < 0) {
          setEnableSave(true)
          setRowsEditMode([...rowsEditMode, rowEditKey])
        }
      })
      if (multipleEdit) {
        let newRowsEditMode = [...rowsEditMode]
        let updated = false
        rowsEditMode.forEach(rowEditMode => {
          if (rowsEditKey.indexOf(rowEditMode) < 0) {
            newRowsEditMode = newRowsEditMode.filter(val => val !== rowEditMode)
            updated = true
          }
        })
        if (updated) setRowsEditMode(newRowsEditMode)
      }
      rowsEditKey.forEach(key =>
        validateStable(
          validateRowValue,
          setAlertsOn,
          setEnableSave,
          valuesMap,
          isValueEqual,
          onCheckUnicity,
          valuesMap.get(key),
          key
        )
      )
    }, [rowsEditKey, rowsEditMode, multipleEdit, validateRowValue, valuesMap, isValueEqual, onCheckUnicity])

    useEffect(() => {
      let vals = Array.from(valuesMap)
      let findValue = rowValue => {
        return vals.find(item => (isValueEqual && isValueEqual(item[1], rowValue)) || _.isEqual(item[1], rowValue))
      }
      if (lastAlertsOnValue) {
        if (_.isEqual(lastAlertsOnValue, alertsOnValue)) return
        else {
          let newAlertsOnValue = alertsOnValue
            .filter(valNew => !lastAlertsOnValue.find(valOld => valNew.code === valOld.code))
            .map(val => findValue(val))
          let removedAlertsOnValue = lastAlertsOnValue
            .filter(valOld => !alertsOnValue.find(valNew => valNew.code === valOld.code))
            .map(val => findValue(val))
          if (newAlertsOnValue.length > 0 || removedAlertsOnValue.length > 0) {
            let newAlertsOn = [...alertsOn]
            newAlertsOn = newAlertsOn.filter(
              currentAlert =>
                !removedAlertsOnValue.find(removedAlertValue => removedAlertValue[0] === currentAlert.rowKey)
            )
            newAlertsOnValue.forEach(newAlertOnValue => {
              newAlertsOn.push(createDuplicateAlert(newAlertOnValue[0]))
            })
            setAlertsOn(newAlertsOn)
          }
          setLastAlertsOnValue(alertsOnValue)
        }
      } else {
        alertsOnValue.forEach(rowValue => {
          let itemFound = findValue(rowValue)
          if (itemFound && !alertsOn.find(alert => alert.rowKey === itemFound[0])) {
            let newAlerts = [...alertsOn]
            newAlerts.push(createDuplicateAlert(itemFound[0]))
            setAlertsOn(newAlerts)
          }
        })
        setLastAlertsOnValue(alertsOnValue)
      }
    }, [alertsOnValue, lastAlertsOnValue, valuesMap, alertsOn, isValueEqual])

    let vals = Array.from(valuesMap)
    let comp = comparator
    if (!comp) {
      comp = (val1, val2) => {
        if (!val1 || !val1[0]) {
          if (!val2 || !val2[0]) {
            return 0
          } else {
            return -1
          }
        } else if (!val1 || !val1[0]) {
          return 1
        } else {
          if (_.isString(val1[0]) && _.isString(val2[0])) {
            let v1 = parseFloat(val1[0])
            let v2 = parseFloat(val2[0])
            if (_.isNumber(v1) && !_.isNaN(v1) && _.isNumber(v2) && !_.isNaN(v2)) {
              if (v1 < v2) {
                return -1
              } else if (v1 === v2) {
                return 0
              } else {
                return 1
              }
            } else {
              return val1[0].localeCompare(val2[0])
            }
          } else {
            return 0
          }
        }
      }
    }
    vals = vals.sort(comp)
    const values = vals

    const handleSave = (rowValue, rowKey) => {
      let result = false
      if (validate(rowValue, rowKey)) {
        if (_.isArray(rowValue) && !rowKey) {
          let tempValues = new Map(valuesMap)
          let datas = rowValue.map(val => {
            let v = {
              key: createKey(tempValues),
              value: val,
            }
            tempValues.set(v.key, v.value)
            return v
          })
          onSave(datas)
        } else {
          let data = {
            key: rowKey ? rowKey : createKey(values),
            value: rowValue,
          }
          onSave(data)
        }
        result = true
      }
      if (result) {
        setActionEditMode(NULL_NUMBER)
        setRowsEditMode(rowsEditMode.filter(val => val !== rowKey))
      }
      return result
    }

    const handleActionEdit = index => {
      if (!onActionEdit || onActionEdit(index)) {
        setEnableSave(false)
        setActionEditMode(index)
      }
    }

    const handleActionChange = rowValue => {
      return validate(rowValue)
    }

    const handleActionCancel = () => {
      setAlertsOn([])
      setEnableSave(false)
      setActionEditMode(NULL_NUMBER)
      if (onActionCancel) {
        onActionCancel()
      }
    }

    const handleRowChange = (rowKey, rowValue) => {
      validate(rowValue, rowKey)

      if (onRowChange) {
        onRowChange(rowKey, rowValue)
      }
    }

    const handleRowCancel = rowKey => {
      setAlertsOn([])
      setEnableSave(false)
      setRowsEditMode(rowsEditMode.filter(val => val !== rowKey))
      if (onRowCancel) {
        onRowCancel(rowKey)
      }
    }

    const handleRowDelete = rowKey => {
      if (onDelete) {
        onDelete(rowKey)
      }
    }

    const handleRowEdit = rowKey => {
      setEnableSave(true)
      if (onRowEdit) {
        onRowEdit(rowKey)
      }
      if (!multipleEdit) {
        setRowsEditMode([...rowsEditMode, rowKey])
      }
    }
    // const { isFocusValueFree } = useSelector(state => state.conventionModule)
    return (
      <Grid item xs={12}>
        <EditableTable
          dataTestPrefix={dataTestPrefix}
          values={values}
          alertsOn={alertsOn}
          onSave={handleSave}
          save={enableSave}
          actionEditMode={actionEditMode}
          rowsEditMode={rowsEditMode}
          actionOnEdit={handleActionEdit}
          actionOnChange={handleActionChange}
          actionOnCancel={handleActionCancel}
          actionIgnoreIfNotFound={true}
          rowOnEdit={handleRowEdit}
          rowOnChange={handleRowChange}
          rowOnDelete={handleRowDelete}
          rowOnCancel={handleRowCancel}
          onSelect={onSelect}
          multipleEdit={multipleEdit}
          {...props}
        ></EditableTable>
      </Grid>
    )
  }

  Component.propTypes = {
    valuesMap: PropTypes.any.isRequired,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    validateRowValue: PropTypes.func.isRequired,
    dataTestPrefix: PropTypes.string.isRequired,
    createKey: PropTypes.func,
    onRowChange: PropTypes.func,
    rowsEditKey: PropTypes.array,
    onRowEdit: PropTypes.func,
  }
  return Component
}

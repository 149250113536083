import Typography from '@material-ui/core/Typography'
import * as PropTypes from 'prop-types'
import React from 'react'
import Grid from '@material-ui/core/Grid'

export const ContainerTitle = ({ title, variant = 'h6' }) => (
  <Grid item xs={11}>
    <Typography data-test={title} variant={variant}>
      {title}
    </Typography>
  </Grid>
)

ContainerTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

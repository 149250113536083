import React from 'react'
import { AppBar, Grid, Tab, Tabs, useTheme } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import { setTab } from '../../store/actions/tab-action'
import { useDispatch, useSelector } from 'react-redux'

export const TabPanel = ({ children, titles, disabled = false, isDisabled, onChange }) => {
  const dispatch = useDispatch()
  const index = useSelector(state => state.activeTabs > titles.length - 1 ? 0 : state.activeTabs)
  const handleChange = (event, newValue) => {
    dispatch(setTab(newValue))
    onChange && onChange(newValue)
  }
  const handleChangeIndex = idx => {
    if (idx < titles.length) dispatch(setTab(idx))
  }
  const theme = useTheme()
  return (
    <Grid item style={{ width: '100%' }}>
      <AppBar position="static" color="default">
        <Tabs
          value={index}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on">
          {titles.map(t => {
            if(t.icon && t.label) {
             return (
                <Tab key={t.label} label={t.label} icon={t.icon} id={`tab_${t.label}`} data-test={`tab_${t.label}`}
                     disabled={isDisabled ? isDisabled(t) : disabled}/>
              )
            }else{
              return (
                 <Tab key={t} label={t} id={`tab_${t}`} data-test={`tab_${t.label ? t.label : t}`}
                      disabled={isDisabled ? isDisabled(t) : disabled}/>
               )
            }
          })
          }
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        {children}
      </SwipeableViews>
    </Grid>
  )
}

import * as PropTypes from 'prop-types'
import React from 'react'
import { DataHeaderCell } from './data-header-cell'


export const DataHeaderRow = ({ component, role, idx,classes }) => {
  return <DataHeaderCell  classes={classes} key={component.nom + '_' + idx} component={component} role={role} title={component.label}/>
}

DataHeaderRow.propTypes = {
  className: PropTypes.any,
}

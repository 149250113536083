import React from 'react'
import * as PropTypes from 'prop-types'
import { Panel } from '../../common/panel'
import { useChildrenTheme } from '../fields/hooks'
import { useSelector } from 'react-redux'
import { patchCCThemes } from '../../../store/actions/convention-collective-action'
import { CCThemeList } from './cc-theme-list'
import { AddItemContainer } from './add-item-container'
import { useItemContainer } from '../../common/hooks/item-container-hooks'
import { SelectThemeCC } from './select-theme-cc'
import { useTheme } from '@material-ui/core'
import {CcTheme} from '../../../store/constants/cc-theme'

export function CcThemeTab() {
  const theme = useTheme()
  const { themes: ccThemes, conventionCollective: cc } = useSelector(state => state.conventionModule)
  const themes = useChildrenTheme(ccThemes.map(t => t.nom))
  const [selectedThemes, handleSelection, handleClickOnSave] = useItemContainer(
    selectedThemes => {
      return themes.filter(t => selectedThemes.includes(t.nom))
    },
    (dispatch, themes) => dispatch(patchCCThemes(cc.idcc, themes.map(t=>new CcTheme(t.idTheme, t.nom, t.description, t.themeParent, t.themeParentId, cc.idcc))))
  )
  return (
    <Panel value={0} index={0} dir={theme.direction}>
      <AddItemContainer onSave={handleClickOnSave} addDataTest="ajouter un thème" saveDataTest="ajouter ce thème">
        <SelectThemeCC selectedThemes={selectedThemes} onChange={handleSelection} themes={themes} />
      </AddItemContainer>
      <CCThemeList />
    </Panel>
  )
}

CcThemeTab.propTypes = {
  value: PropTypes.number,
  theme: PropTypes.any,
}

import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { DropzoneDialog } from 'material-ui-dropzone'
import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import getPayeService from '../../api/envService'
import { IMPORT_DATA_ENDPOINT } from '../../api/endpoints'

export function ImportStructure() {
  const [open, setOpen] = useState(false)
  const [state, setState] = useState({addOnly:true})
  const handleClick = () => setOpen(true)
  const handleSave = files => {
    if (files.length === 1) {
      setOpen(false)
      if(state.addOnly){
        getPayeService().patchFile(IMPORT_DATA_ENDPOINT, files[0], 'dataFile')
      }else{
        getPayeService().postFile(IMPORT_DATA_ENDPOINT, files[0], 'dataFile')
      }

    }
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return <Grid container>
    <Grid item xs={2}>
      <Typography data-test={'Import Structure'} variant='h5' style={{ lineHeight: 1.8 }}>
        Import Structure
      </Typography>
    </Grid>
    <Grid xs={3}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.addOnly}
              disabled
              onChange={handleChange}
              name='addOnly'
              color='primary'
              data-test='Mise à jour uniquement'
            />
          }
          label='Mise à jour (Uniquement ajout)'
        />
      </FormGroup>
    </Grid>
    <Grid xs={6}>
      <FormGroup>
        <Button variant='contained' color='primary' onClick={handleClick} data-test='choose-file'>
          choisir fichier structure
        </Button>
        <DropzoneDialog
          showAlerts={false}
          open={open}
          onSave={handleSave}
          dropzoneClass='drop-zone'
          acceptedFiles={[
            'application/vnd.ms-excel',
            'application/octet-stream',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            '.xlsx',
          ]}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={() => setOpen(false)}
          filesLimit={1}
          submitButtonText='Importer'
          cancelButtonText='Annuler'
        />
      </FormGroup>

    </Grid>
  </Grid>
}

ImportStructure.propTypes = {
  state: PropTypes.shape({ addOnly: PropTypes.bool }),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
}
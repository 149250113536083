import { Grid,ListSubheader } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import _ from "lodash"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 450,
  },
}))
const getIdPrefix = id => (id ? id + ' - ' : '')

export const SelectThemeCC = ({ onChange, themes, selectedThemes }) => {
  const classes = useStyles()
  const allThemes=useSelector(({ themeModule: { themes } })=>{return themes})
  const compareIdTheme = (v1,v2)=>{
    if(_.isFinite(_.toNumber(v1)) && _.isFinite(_.toNumber(v2))){
      v1=_.toNumber(v1)
      v2=_.toNumber(v2)
    }
  
  
    if (v1 < v2) {
      return -1
    }
    if (v1 > v2) {
      return 1
    }
    if(v1 === v2){
      return 0
    }
  }


  var themesParent=[]
  var themesList=[...themes]
  themesList.forEach((theme)=>{
    if(theme.idTheme && !themes.find(t=>t.themeParent === theme.nom)){
        let son=_.clone(theme)
        son.themeParent=theme.nom
        themesList.push(son)
    }
  })
  themesList.forEach((theme)=>{
    if(theme.themeParent){
      if(themesParent.indexOf(theme.themeParent) < 0)
        themesParent[theme.themeParent]=allThemes.find(t=>t.nom === theme.themeParent)
    }
  })
  Object.keys(themesParent).forEach(key=>{
    const parent=themesParent[key]
    if(!themesList.find(t=>t.nom === parent.nom)){
      themesList.push(parent)
    }
  })

  return (
    <Grid item xs={4}>
      <FormControl className={classes.formControl}>
        <InputLabel id="mutiple-checkbox-label">Sélectionnez</InputLabel>
        <Select
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          multiple
          value={selectedThemes}
          onChange={onChange}
          input={<Input />}
          renderValue={selected => selected.join(', ')}
          data-test={'thèmes_list'}
        >
          {
            themesList.sort(
            (t1,t2)=>{
              if(t1.themeParent && t2.themeParent){
                let compareParent = compareIdTheme(themesParent[t1.themeParent].idTheme,themesParent[t2.themeParent].idTheme)
                if(compareParent === 0){
                  return compareIdTheme(t1.nom,t2.nom)
                }else{
                  return compareParent
                }
              }else if(t1.themeParent){
                const comp=compareIdTheme(themesParent[t1.themeParent].idTheme,t2.idTheme)
                if(comp===0){
                  return 1
                }else{
                  return comp
                }
              }else if(t2.themeParent){
                const comp= compareIdTheme(t1.idTheme,themesParent[t2.themeParent].idTheme)
                if(comp===0){
                  return -1
                }else{
                  return comp
                }
              }else{
                return compareIdTheme(t1.idTheme,t2.idTheme)
              }
             
            }).map(({ idTheme, nom, themeParent }) => {
            const idNom = getIdPrefix(idTheme) + nom
            if(themesParent[nom] && !themeParent){
              return (<ListSubheader key={`option_theme_${idNom}`} disableSticky={true}>{idNom}</ListSubheader>)
            }
            else {
              return (
                <MenuItem
                  key={`option_theme_${nom}`}
                  value={nom}
                  className={'thèmes_list'}
                  data-test={'option_thème_' + idNom}
                >
                  <Checkbox checked={selectedThemes.indexOf(nom) > -1} />
                  <ListItemText primary={nom}/>
                </MenuItem>
              )
              }
          })}
        </Select>
      </FormControl>
    </Grid>
  )
}

SelectThemeCC.propTypes = {
  selectedThemes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  themes: PropTypes.any.isRequired,
}

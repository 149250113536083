export const IN_OUT_TYPES = [
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'nombre',
    label: 'Nombre',
  },
  {
    value: 'group',
    label: 'Groupe',
  },
  {
    value: 'referentiel',
    label: 'Référentiel',
  },
  {
    value: 'liste',
    label: 'Liste',
  },
]

export const composantFilter = item => {
  return (
    item.type !== 'group' &&
    item.type !== 'liste' &&
    item.type !== 'referentiel'
  )
}

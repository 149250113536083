import { combineReducers } from 'redux'

import user from './user-reducer'
import loginState from './login-state-reducer'
import conventionModule from './convention-collective-reducer'
import alerts from './alert-reducer'
import loader from './loader-reducer'
import themeModule from './themes-reducer'
import activeTabs from './active-tabs-reducer'
import tableurModule from './tableur-reducer'
import referentiels from './referentiels-reducer'
import componentModule from './components-reducer'
import versionModule from './versions-reducer'
import workflowModule from './workflow-reducer'
import accessModule from './access-reducer'
import transformationRuleModule from './transformation-rule-reducer'
import taskModule from './task-reducer'
import settingsModule from './api-mode-reducer'

const payeReducer = (globalState, action) =>
  combineReducers({
    settingsModule,
    versionModule,
    conventionModule,
    referentiels,
    alerts: (state, action) => alerts(state, action, globalState),
    loader,
    loginState,
    componentModule,
    themeModule,
    workflowModule,
    tableurModule,
    accessModule,
    user,
    activeTabs,
    transformationRuleModule,
    taskModule,
  })(globalState, action)

const rootReducer = (globalState, action) => {
  if (action.type === 'RESET_STATE') {
    globalState = undefined
  }
  return payeReducer(globalState, action)
}

export default rootReducer

export default class Referentiel {
  id
  nom
  name
  description
  valeurs

  constructor(nom, description, valeurs = []) {
    this.id = nom
    this.nom = nom
    this.name=nom
    this.description = description
    this.valeurs = valeurs
    this._lastValeurs = this.valeurs
  }

  value(id) {
    return this._values[id]
  }

  get values() {
    if (!this._values || this._lastValeurs !== this.valeurs) {
      this.lastValeurs = this._values = this.valeurs
        .sort((val1, val2) => val1.code.localeCompare(val2.code))
        .map((val, index) => ({
          id: `${index}`,
          code: val.code,
          libelle: val.libelle,
          text: val.text,
          defaultValue: val.defaultValue,
        }))
      this._lastValeurs = this.valeurs
    }
    return this._values
  }

  static NULL = new Referentiel('', '', [])
}

export class ReferentielValeur {
  code
  libelle
  text
  defaultValue
  constructor(code = '', libelle = '', text = '', defaultValue = false) {
    this.code = code
    this.libelle = libelle
    this.text = text
    this.defaultValue = defaultValue
  }

  static NULL = new ReferentielValeur()
}

import React, { useEffect, useMemo, useState } from 'react'
import * as PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  Grid,
  makeStyles,
  FormControlLabel,
  Typography,
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import _ from 'lodash'
import Divider from '@material-ui/core/Divider'
import { DragIndicator } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { updateBlocks } from '../../store/actions/workflow-action'

const useStyles = makeStyles(atheme => ({
  title: {
    marginBottom: atheme.spacing(2),
  },
  listeStyle: {
    backgroundColor: 'white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      cursor: 'pointer',
    },
  },
  listeStyleSelected: {
    backgroundColor: '#daf5f3',
    '&:hover': {
      backgroundColor: '#c5dbd9',
      cursor: 'pointer',
    },
  },
}))

export const SeparableTab = () => {

  const blocks = useSelector( state => state.workflowModule.blocks)
  const isModelisateur = useSelector(state => state.user.user.role === 'MODELISATEUR')
  const dispatch = useDispatch()
  const classes = useStyles()

  function dragCC(e, index, value) {
    e.dataTransfer.setData('index', index)
    e.dataTransfer.setData('value', value)
  }

  function allowDrop(e) {
    e.preventDefault()
  }

  function onDrop(e, newIndex) {
    let index = e.dataTransfer.getData('index')
    let value = e.dataTransfer.getData('value')
    const newList = blocks.sortedPartNames
    newList.splice(Number.parseInt(index), 1)
    newList.splice(newIndex, 0, value)
    dispatch(updateBlocks({...blocks, sortedPartNames: newList}))
  }

  return (
    <Grid item xs = {12}>
    <Grid container justify="center">
    <Grid item xs={4}>
      <Paper
        style={{ marginTop: 20, backgroundColor: 'rgb(245, 245, 245)' }}
      >
        <Box p={1} width="98%">
          <Grid container direction="column" justify="center" spacing={1}>
            <Grid item>
              <Box className={classes.title}>
                <Typography className={classes.title} variant="h6" id="tableTitle">
                  Liste des séparateurs
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper style={{ marginTop: 5 }} onDragOver={e => allowDrop(e)}>
        {blocks.sortedPartNames.map((value, index) => (
          <div key={value.key}>
            {isModelisateur
              ? <ListItem
              draggable
              className={classes.listeStyle}
              key={value}
              onDragStart={e => dragCC(e, index, value)}
              onDragOver={e => allowDrop(e)}
              onDropCapture={e => onDrop(e, index)}
            >
              <ListItemIcon>
                <DragIndicator />
              </ListItemIcon>
              <ListItemText primary={(index + 1) + '  -  ' + value} />
            </ListItem>
              :
              <ListItem
                className={classes.listeStyle}
                key={value}
              >
                <ListItemIcon>
                  <DragIndicator />
                </ListItemIcon>
                <ListItemText primary={(index + 1) + '  -  ' + value} />
              </ListItem>
              }

            <Divider style={{ marginTop: 1, marginBottom: 1 }} />
          </div>
        ))}
      </Paper>
    </Grid>

    </Grid>
    </Grid>
  )
}

SeparableTab.propTypes = {
  tabName: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}


import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import { AlertRow } from '../../alert-row'
import lightGreen from '@material-ui/core/colors/lightGreen';


const useStyles = makeStyles((theme) => ({
  root: {
  },
  tr: {
    '&.Mui-selected': {
      background: lightGreen[100]+' !important',
    },
  }
}))

export function withValuesSplitter(RowComponent){
    let Component=({values,...props})=>{
      return values.map(row=>{
        return (
            <RowComponent
                  key={row[0]}  
                  rowKey={row[0]}
                  rowValue={row[1]}    
                  {...props}
            >
  
           </RowComponent>
       )
     })
    }
    
  
    return Component
  }

export function withAlert(RowContent){
    let Component=({alert,selectable,onSelect,...props})=> {
    const classes = useStyles()
    const [selected,setSelected]=useState(false)
    const handleSelect = () => {
          if(selectable){
            setSelected(!selected)
            if(onSelect)
              onSelect(!selected)
          }
    }

    useEffect(()=>{
      if(!selectable){
        setSelected(false)
      }
    },[selectable])

    return alert ? 
            (<AlertRow onClick={()=>handleSelect()} hover={selected} className={classes.tr} data-row={props.rowKey}>
                <RowContent {...props}/>
             </AlertRow>) : 
             (<TableRow  onClick={()=>handleSelect()} selected={selected} className={classes.tr} data-row={props.rowKey}>
                <RowContent {...props}/>
             </TableRow>)
    }
    Component.propTypes = {
        alert:PropTypes.bool.isRequired,
    }
    return Component
}

import React from 'react'

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
  Box,
  makeStyles,
  Popper as MuiPopper } from '@material-ui/core'
import { closeConfirmation } from '../../store/actions/alert-action'
import Confirmation from './Confirmation'
import * as PropTypes from 'prop-types'



const useStyles = makeStyles(theme => ({
  content: {
    marginTop : '10px',
    maxWidth : '800px',
    maxHeight : '600px',
    overflow : 'scroll'
  },
}))

const Confirmations = ({ timeout, locateRef, querySelector }) => {
  const classes = useStyles()
  const [isShow, setShow] = useState(false)
  const [dom, setDom] = useState(null)
  useEffect(() => {
    if (locateRef.current) {
      let domElmt = locateRef.current.querySelector(querySelector)
      if (domElmt) {
        setShow(true)
        setDom(domElmt)
      }
    }
  }, [locateRef, querySelector])

  const onCloseConfirmation = index => {
    dispatch(closeConfirmation(index))
  }

  const confirmations = useSelector(state => state.alerts.confirmations)

  const dispatch = useDispatch()

  return (
    isShow &&
    confirmations && (
      <MuiPopper
        name="alerts"
        anchorEl={dom}
        placement="top-end"
        open={confirmations.length > 0}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: false,
          },
        }}
      >
        {confirmations.map(confirmation => {
          return (
            <Box key={confirmation.key} className={classes.content}>
              <Confirmation
                timeout={timeout}
                onClose={() => onCloseConfirmation(confirmation.key)}
                messages={confirmation.messages}
                onSave={confirmation.onSave}
                confirmMessage={confirmation.confirmMessage}
              />
            </Box>
          )
        })}
      </MuiPopper>
    )
  )
}

Confirmations.propTypes = {
  timeout: PropTypes.number,
  locateRef: PropTypes.any.isRequired,
  querySelector: PropTypes.string.isRequired,
}

export default Confirmations

import { TextParamType } from './TextParamType'

export class TextParam {
  component
  type
  dispositionOption
  themesOption
  parent


  constructor(
    component = '',
    type = TextParamType.NULL,
    dispositionOption = [],
    themesOption = [],
    parent= ''
  ) {
    this.dispositionOption = dispositionOption ? dispositionOption : []
    this.themesOption = themesOption ? themesOption : []
    this.component = component
    this.type = type
    this.parent = parent
  }

  static NULL = new TextParam()
}
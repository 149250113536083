export class TextBlockType {
  name
  label

  constructor(name, label) {
    this.name = name
    this.label = label ? label : name
  }

  static NULL = new TextBlockType('null')
  static SIMPLE = new TextBlockType('simple')
  static GROUP = new TextBlockType('group')
  static LIST = new TextBlockType('list')
  static ALL = [TextBlockType.NULL, TextBlockType.SIMPLE, TextBlockType.GROUP, TextBlockType.LIST]
  static fromStr = str => str ? TextBlockType.ALL.find(f => f.name.toUpperCase()  === str.toUpperCase()) : str
}
export class Operateur {
  value
  label

  constructor(value, label = null) {
    this.value = value
    this.label = label ? label : value
  }

  static NULL = new Operateur('', 'vide')
  static EGALE = new Operateur('=')
  static ANY = new Operateur('any', 'parmis')
  static ALL = new Operateur('all', 'toute')
  static NONE = new Operateur('none', 'aucune')
  static MOINS = new Operateur('-')
  static PLUS = new Operateur('+')
  static INF = new Operateur('<')
  static SUP = new Operateur('>')

  static ALL = [
    Operateur.NULL,
    Operateur.EGALE,
    Operateur.ANY,
    Operateur.ALL,
    Operateur.NONE,
    Operateur.MOINS,
    Operateur.PLUS,
    Operateur.INF,
    Operateur.SUP,
  ]

  static OPERATORS = [Operateur.NULL, Operateur.EGALE, Operateur.MOINS, Operateur.PLUS]
  static COMPARATORS = [Operateur.NULL, Operateur.EGALE, Operateur.INF, Operateur.SUP]
  static COMPARATORS_DEFAULT = [Operateur.NULL, Operateur.EGALE]
  static COMPARATORS_REF = [Operateur.NULL, Operateur.EGALE, Operateur.NONE]
  static COMPARATORS_LIST = [Operateur.NULL, Operateur.EGALE]
  static getOperateurFromString = (string = '') => {
    const toCompare = string.value ? string.value : string
    const operateur = Operateur.ALL.find(r => r.value.toUpperCase() === toCompare.toUpperCase())
    if (operateur) return operateur
    throw new Error('Le type ' + string + ' est inconnu')
  }
}

import { Grid, makeStyles } from '@material-ui/core'

import { useParams } from 'react-router'
import React, { useEffect, useState } from 'react'
import { ModelWorkflow, TypeModele } from '../../../store/constants/ModelWorkflow'
import { ModelFormElements } from './form-element/modelFormElements'
import { ModelInfos } from '../modelInfos'
import { ModelRefElement } from './ref-element/modelRefElements'
import { ModelNewElement } from './new-element/modelNewElements'
import { ModelDoublonElements } from './doublon-element/modelDoublonElements'
import { useDispatch, useSelector } from 'react-redux'
import { AddItemButtons } from '../../conventions-collectives/themes/add-item-buttons'
import getPayeService from '../../../api/envService'
import { MODELE_WORKFLOW_ENDPOINT } from '../../../api/endpoints'
import { setLoader } from '../../../store/actions/loader-action'
import { useHistory } from 'react-router-dom'
import { TextualisationTextElements } from './textualisation-element/blocks/textualisationTextElements'
import { TextualisationParamElements } from './textualisation-element/params/textualisationParamsElements'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  popper: {
    ...theme.mixins.gutters(),
    minWidth: 380,
    maxWidth: 600,
    margin: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  container: { maxWidth: '80%', overflowX: 'hidden', margin: 'auto', marginTop: 16 },
  container2: { maxWidth: '80%', overflowX: 'hidden', marginTop: 16 },
  formControl: {
    margin: 0,
    marginTop: 16,
    width: '100%',
  },
  area: {
    '&:focus-visible': {
      border: '#3f51b5 solid',
    },
  }
}))

function changeTypes(ff) {
  ff.typeForm = ff.typeForm.name
  if (ff.workflowField && ff.workflowField.typeForm) ff.workflowField.typeForm = ff.workflowField.typeForm.name
  return ff
}

function getRefModel(ff) {
  ff.type = ff.type.name
  if (ff.newValue && ff.newValue.operationUnit) ff.newValue.operationUnit = ff.newValue.operationUnit.label
  return ff
}

function getNewValue(ff) {
  ff.type = ff.type.name
  if (ff.newValue && ff.newValue.operationUnit) ff.newValue.operationUnit = ff.newValue.operationUnit.label
  return ff
}

const updateModel = tmpModel => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() =>
      getPayeService().put(
        MODELE_WORKFLOW_ENDPOINT,
        {
          ...tmpModel,
          type: tmpModel.type.name,
          formFields: tmpModel.formFields.map(ff => changeTypes(ff)),
          doublons: tmpModel.doublons.map(ff => getRefModel(ff)),
          refFields: tmpModel.refFields.map(ff => getRefModel(ff)),
          newValues: tmpModel.newValues.map(ff => getRefModel(ff)),
        },
        tmpModel.nom
      )
    )
    .catch(e => console.log(e))
    .finally(() => dispatch(setLoader(false)))

const createModel = tmpModel => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() =>
      getPayeService().post(MODELE_WORKFLOW_ENDPOINT, {
        ...tmpModel,
        type: tmpModel.type.name,
        formFields: tmpModel.formFields.map(ff => changeTypes(ff)),
        doublons: tmpModel.doublons.map(ff => getRefModel(ff)),
        refFields: tmpModel.refFields.map(ff => getRefModel(ff)),
        newValues: tmpModel.newValues.map(ff => getRefModel(ff)),
      })
    )
    .catch(e => console.log(e))
    .finally(() => dispatch(setLoader(false)))

export const WorkflowModelDetail = () => {
  const history = useHistory()

  const { nom } = useParams()
  const classes = useStyles()
  const [tmpModel, setTmpModel] = useState(new ModelWorkflow())
  const user = useSelector(state => state.user.user)
  const models = useSelector(state => state.workflowModule.models.sort((a, b) => a.nom.localeCompare(b.nom)))
  const dispatch = useDispatch()

  function handleChange(value, nom) {
    const newTmpModel = { ...tmpModel }
    newTmpModel[nom] = value
    setTmpModel(newTmpModel)
  }

  useEffect(() => {
    if (nom) setTmpModel(models.find(m => m.nom === nom))
  }, [nom])

  function handleSaveModel() {
    if (nom) dispatch(updateModel(tmpModel)).then(() => history.push('/workflows'))
    else dispatch(createModel(tmpModel)).then(() => history.push('/workflows'))
  }

  function handleCancelModel() {
    history.push('/workflows')
  }

  const enable = tmpModel && tmpModel.nom && tmpModel.type && user.role && user.role.toUpperCase() === 'MODELISATEUR'
  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={6} />
        <Grid item xs={3} />
      </Grid>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ModelInfos classes={classes} disableName={!!nom} tmpModel={tmpModel} handleChange={handleChange} />
        </Grid>
        {tmpModel.type !== TypeModele.NULL && (
          <>
            <ModelFormElements classes={classes} tmpModel={tmpModel} setTmpModel={setTmpModel} />
            {tmpModel.type !== TypeModele.CREATE && <ModelRefElement classes={classes} tmpModel={tmpModel} setTmpModel={setTmpModel} />}
            {tmpModel.type === TypeModele.UPDATE && (
              <ModelDoublonElements classes={classes} tmpModel={tmpModel} setTmpModel={setTmpModel} />
            )}
            {tmpModel.type !== TypeModele.CREATE && <ModelNewElement classes={classes} tmpModel={tmpModel} setTmpModel={setTmpModel} />}
            <TextualisationParamElements classes={classes} tmpModel={tmpModel} setTmpModel={setTmpModel} />
          </>
        )}
      </Grid>
      <Grid container direction="row" justify="flex-end">
        <AddItemButtons
          onClick={() => {}}
          onSave={handleSaveModel}
          onCancel={handleCancelModel}
          showList={true}
          addDataTest="AJOUT MODEL"
          cancelDataTest={'ANNULER MODEL'}
          saveDataTest={'AJOUTER MODEL'}
          isSaveDisabled={!enable}
        />
      </Grid>
    </main>
  )
}

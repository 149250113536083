import React,{useEffect,useState} from 'react'
import {ActionForm,withEditableRow,withDefaultHeader,withDefaultHeaderLabel,withFormTable,withHeaders} from '../Table/form-table'
import RowEditNumeric from '../common/table/editable/renderer/row-edit-numeric'
import * as PropTypes from 'prop-types'
import {StringFloatConverter} from '../common/table/editable/converter/primitive-converter'
import CellStringRenderer from '../common/table/editable/renderer/cell-string-renderer'
import { getKey } from '../components/helper'
import _ from "lodash"

function ActionNumericEditor(props){
  return (
    <RowEditNumeric
      dataTestPrefix="form"
      dataTestKey="button"
      initialValue={null}
      onChange={props.onChange}
      onSave={props.onSave}
      save={props.save}
      onCancel={props.onCancel}
    />)
}

ActionNumericEditor.propTypes = {
}

function RowNumericEditor(props){
  return (
    <RowEditNumeric
          dataTestPrefix="component"
          dataTestKey={props.rowKey}
          initialValue={props.rowValue}
          onChange={props.onChange}
          onSave={props.onSave}
          save={props.save}
          onCancel={props.onCancel}
    />)
}

RowNumericEditor.propTypes = {
}

var converter=StringFloatConverter()

function CellRenderer({rowKey,rowValue}){
  return <CellStringRenderer 
    align="left"  
    dataTestKey={rowKey} 
    dataTestPrefix="component"
    mapper={converter.from}
    rowValue={rowValue}/>
}
CellRenderer.propTypes = {
  dataTestKey:PropTypes.string,
  dataTestPrefix:PropTypes.string,
  rowValue:PropTypes.any
}

function ActionNouveau(props){
  return (<ActionForm buttonName="Nouveau" buttonDataTest="Nouvelle valeur" {...props}>
            <ActionNumericEditor {...props}/>
          </ActionForm>)
}

let FormTable=withFormTable(
  [ActionNouveau],
  withHeaders([withDefaultHeader("header"),withDefaultHeaderLabel("actions")]),
  withEditableRow(RowNumericEditor,[CellRenderer])
)
export default function ComponentNumericValues({onSave,onDelete,values,component}) {
  const [valuesMap,setValuesMap] = useState(new Map([]))
  const [rowsEditKey,setRowsEditKey] = useState([])

  useEffect(()=>{
    if(rowsEditKey.length === 0) setValuesMap(values)
  },[values,rowsEditKey])

      const validateRowValue = (value)=>{
        if(value){
          if(_.isNumber(value) && !_.isNaN(value)){
              return true;
          }else{
              return false;
          }
        }else{
            return false
        }
      }

      const handleDelete = (key)=>{
        if(onDelete){
          onDelete(key)
        }
      }

      const handleRowCancel = ()=>{
        setRowsEditKey([])
      }

      const handleSave = (datas)=>{
        setRowsEditKey([])
        if(onSave) {
          onSave(datas)
        }
      }

      const handleActionEdit = ()=>{
        var newValuesMap = new Map(valuesMap)
        var key=getKey(newValuesMap)
        newValuesMap.set(key,"")
        setValuesMap(newValuesMap)
        setRowsEditKey([...rowsEditKey,key])
        return false
      }
      return (
      <FormTable 
        onActionEdit={handleActionEdit}
        onSave={handleSave} 
        onDelete={handleDelete} 
        onRowCancel={handleRowCancel}
        valuesMap={valuesMap} 
        rowsEditKey={rowsEditKey}
        validateRowValue={validateRowValue}
        dataTestPrefix="component"
        header={component.nom}>
      </FormTable>)
  }

  ComponentNumericValues.propTypes = {
    onSave:PropTypes.func.isRequired,
    onDelete:PropTypes.func.isRequired,
    values:PropTypes.any.isRequired,
    component:PropTypes.any.isRequired
}
import PropTypes from 'prop-types'
import React,{useEffect} from 'react'

import FilterListIcon from '@material-ui/icons/FilterList'
import IconButton from '@material-ui/core/IconButton'
import { lighten, makeStyles } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {Input} from '@material-ui/core/'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import  {Subject} from 'rxjs'
import {debounceTime} from 'rxjs/operators'

import { IN_OUT_TYPES } from '../../lib/constantes'
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox'
import { Refresh } from '@material-ui/icons'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  rootInline: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display:"inline flex"
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}))

const searchFactory = () => {
  const search$ = new Subject()
  return {
    subscribe: runSearch => search$.asObservable().pipe(
        debounceTime(400)
      ).subscribe(runSearch),
    search: text=>{
      search$.next(text)
    }
  }
}

const EnhancedTableToolbar = ({ title, onFilter, onSearch, initSearchText = '',inline=false, refresh}) => {
  const classes = useToolbarStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [filter, setFilter] = React.useState('')
  const [searchText,setSearchText] = React.useState('')
  const [search] = React.useState(searchFactory())

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = newFilter => {
    setFilter(newFilter)
    setAnchorEl(null)
    onFilter(newFilter)
  }

  const handleSearch = text => {
    setSearchText(text)
    search.search(text)
  }

  useEffect(()=>{
    handleSearch(initSearchText)
  },[initSearchText])

  useEffect(()=>{
    const subscription=search.subscribe((text)=>onSearch && onSearch(text))
    return ()=>subscription.unsubscribe()
  },[])

  return (
    <Toolbar className={inline ? classes.rootInline : classes.root}>
      {title && (<Typography className={classes.title} variant="h6" id="tableTitle">
        {title}
      </Typography>)}
      { refresh && <IconButton aria-label="refrech" data-test="Refresh" onClick={refresh} style={{marginRight: 10}}>
        <Refresh />
      </IconButton>
      }
      <Input type="text" value={searchText} placeholder="Rechercher ..." data-test="searchText" onChange={(event)=>{handleSearch(event.target.value)}}/>
      {onFilter && (<><Tooltip title="Filter list">
        <IconButton
          aria-label="filter list"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FilterListIcon />
          <Typography>{filter.label}</Typography>
        </IconButton>
      </Tooltip>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          handleClose(filter)
        }}
      >
        <MenuItem
          selected={'' === filter}
          onClick={() => {
            handleClose('')
          }}
        >
          Tous
        </MenuItem>
        {IN_OUT_TYPES.map((item, index) => (
          <MenuItem
            key={index}
            selected={item.value === filter.value}
            onClick={() => {
              handleClose(item)
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu></>)}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  initSearchText: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func.isRequired
}

export default EnhancedTableToolbar

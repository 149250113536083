import React from 'react'
import * as PropTypes from 'prop-types'
import Edit from '@material-ui/icons/Edit'
import Save from '@material-ui/icons/Save'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

export const EditSaveButton = ({ editable, setEditable, dataTest, onSave }) => {
  const classes = useStyles()
  const handleClick = e => {
    e.preventDefault()
    if (editable) {
      onSave()
    }
    setEditable()
  }

  return (
    <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleClick}>
      {editable ? <Save data-test={'save_' + dataTest} /> : <Edit data-test={'edit_' + dataTest} />}
    </IconButton>
  )
}

EditSaveButton.propTypes = {
  editable: PropTypes.bool.isRequired,
  setEditable: PropTypes.func.isRequired,
  dataTest: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
}

import React from 'react'
import { blue, grey } from '@material-ui/core/colors'
import { WorkflowStatut } from '../../../store/constants/ModelInstanceWorkFlow'
import amber from '@material-ui/core/colors/amber'
import green from '@material-ui/core/colors/green'

function getStatutColor(statut) {
  switch (statut) {
    case WorkflowStatut.EN_COURS:
      return amber[300]
    case WorkflowStatut.PUBLIE:
      return green[300]
    case WorkflowStatut.VALIDE:
      return blue[300]
    default:
      return grey[300]
  }
}

export const StatutItem = ({ statut }) => {
  const style = {
    border: '7px double',
    textAlign: 'center',
    borderRadius: '10px',
    color: getStatutColor(statut),
    padding: '3px 0px'
  }
  const statutLabel = statut.label
  return <>{
    statut && statut !== WorkflowStatut.NULL && <div data-test="workflow_statut" style={style}>{statutLabel}</div>
  }</>
}
import { Grid, TextField } from '@material-ui/core'
import { Item } from './textualisationTextElements'
import CancelIcon from '@material-ui/icons/Cancel'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { MoreVert } from '@material-ui/icons'
import { hasIndex } from './block-textualisation-popper-content'

export const iconStyle = {
  color: '#9e9e9e',
  cursor: 'pointer',
  verticalAlign: 'middle',
  display: 'inline-block',
  height: 21,
  '&:hover': {
    color: '#616060',
  },
}

export const deleteStyle = {
  ...iconStyle,
  marginLeft: 10,
}

const menuStyle = {
  ...iconStyle,
  marginLeft: -7,
  marginRight: 10,
}

export function TextCard({
                           idx,
                           childIdx,
                           grandChildIdx,
                           list,
                           setList,
                           children,
                           edit,
                           isGroup = false,
                           isEditable = false,
                           fragment,
                         }) {
  const [onEdition, setOnEdition] = useState(false)
  const [tmpValue, setTmpValue] = useState('')

  useEffect(() => {
    if (fragment) setTmpValue(fragment.text)
  }, [fragment])

  function dragFragement(e) {
    e.dataTransfer.setData('index', idx)
    e.dataTransfer.setData('childIndex', childIdx)
    e.dataTransfer.setData('grandChildIdx', grandChildIdx)
  }

  function allowDrop(e) {
    const oldIndex = Number.parseInt(e.dataTransfer.getData('index'))
    if (oldIndex === idx || oldIndex === idx - 1) return
    e.preventDefault()
  }

  function onDrop(e) {
    const oldIndex = Number.parseInt(e.dataTransfer.getData('index'))
    const oldChildIndex = Number.parseInt(e.dataTransfer.getData('childIndex'))
    const oldGrandChildIndex = Number.parseInt(e.dataTransfer.getData('grandChildIdx'))
    let newList = list
    let obj = list[oldIndex]
    if (hasIndex(oldChildIndex)) {
      if (!hasIndex(childIdx)) return
      if (oldIndex === idx) {
        let children1 = obj.children
        const child = children1[oldChildIndex]
        if (hasIndex(oldGrandChildIndex )) {
          if (!hasIndex(grandChildIdx)) return
          if (childIdx === oldChildIndex) {
            let grandChildren1 = child.children
            const grandChild = grandChildren1[oldGrandChildIndex]
            grandChildren1.splice(oldGrandChildIndex, 1)
            grandChildren1.splice(grandChildIdx, 0, grandChild)
            child.children = [...grandChildren1]
          }
        }
        children1.splice(oldChildIndex, 1)
        children1.splice(childIdx, 0, child)
        obj.children = [...children1]
      }
    }
    newList.splice(oldIndex, 1)
    newList.splice(idx, 0, obj)
    setList([...newList])


  }

  function removeFragment() {
    if (hasIndex(grandChildIdx)) list[idx].children[childIdx].children.splice(grandChildIdx, 1)
    else if (hasIndex(childIdx)) list[idx].children.splice(childIdx, 1)
    else list.splice(idx, 1)
    setList([...list])
  }

  function updateText() {
    let obj = { ...list[idx], text: tmpValue }
    list.splice(idx, 1, obj)
    setList([...list])
    setOnEdition(false)
  }

  function handleChange(e) {
    setTmpValue(e.target.value)
  }

  function handlekeyPress(e) {
    if (e.key === 'Enter') updateText()
  }

  return <Grid
    onDoubleClick={() => isEditable && setOnEdition(true)}
    onDragOver={allowDrop}
    onDropCapture={onDrop}>

    <Item style={isGroup ? { backgroundColor: '#ccd4ff80' } : {}}>
      <Grid container direction='row' alignItems='center'>
        {
          onEdition
            ? <TextField id='standard-basic' label='text' variant='standard' value={tmpValue} onChange={handleChange}
                         onKeyPress={handlekeyPress} />
            : <>
              <Grid draggable onDragStart={dragFragement}><span style={menuStyle} onClick={edit}><MoreVert
                fontSize='small' /></span></Grid>
              <Grid>{children}</Grid>
            </>
        }
        <Grid><span style={deleteStyle} onClick={removeFragment}><CancelIcon fontSize='small' /></span></Grid>
      </Grid>
    </Item>
  </Grid>
}

TextCard.propTypes = {
  idx: PropTypes.number,
  childIdx: PropTypes.number,
  grandChildIdx: PropTypes.number,
  list: PropTypes.array,
  setList: PropTypes.func,
  edit: PropTypes.func,
  text: PropTypes.string,
  children: PropTypes.any,
  isGroup: PropTypes.bool,
  isEditable: PropTypes.bool,
  fragment: PropTypes.any,
}
import React from 'react'
import * as PropTypes from 'prop-types'
import { TextField, makeStyles, Box, FormControl, Checkbox } from '@material-ui/core'
import { TypeFormField } from '../../../../store/constants/TypeFormField'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((atheme) => ({
  root: {
    flexGrow: 1,
  },
  conventionItem: {
    paddingTop: 14,
    paddingBottom: 10,
    borderBottom: `1px solid #dadde7`,
  },
  formControl: {
    margin: atheme.spacing(1),
    maxWidth: "100%",
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *' : {
      width:'80%',
      marginRight: atheme.spacing(1)
    }
  },
}))

export function getFormatedDate(strDate) {
  if (!strDate) return strDate
  else {
    const targetDate = strDate.split('/')
    if (targetDate.length !== 3) return strDate
    return targetDate[2] + '-' + targetDate[1] + '-' + targetDate[0]
  }
}

export function getDateToSave(strDate) {
  if (!strDate) return strDate
  else {
    const targetDate = strDate.split('-')
    if (targetDate.length !== 3) return strDate
    return targetDate[2] + '/' + targetDate[1] + '/' + targetDate[0]
  }
}

function CCWorkflowFormSimple({
  dataTest,nom,value,onValueChange,type,disabled=false, ignore, changeIgnore, required= false,
}) {
  const classes = useStyles()

  function handleChange({ target: { value } }) {
    onValueChange && onValueChange((type === TypeFormField.DATE && getDateToSave(value)) || value)
  }

  const state=(type === TypeFormField.DATE && getFormatedDate(value)) || value || ""

  return (
    <Box>
      <Grid container container direction='row' style={{ width: '100%' }} alignItems='center' spacing={1}>
        <Grid item xs={1}>
          <Checkbox disabled={required} style={{margin: 0, padding: 0}} checked={!ignore} onClick={() => changeIgnore(!ignore)} size='small' color='primary' />
        </Grid>
        <Grid item xs={10}>
          <FormControl className={classes.formControl}>
            {type === TypeFormField.DATE && (
              <TextField
                id={nom}
                label={nom}
                data-test={dataTest + '_edit'}
                type="date"
                value={state}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                disabled={disabled || ignore}
              />
            )}
            {type === TypeFormField.NUMERIC && (
              <TextField
                data-test={dataTest + '_edit'}
                type={'number'}
                value={state}
                onChange={(e)=>e.target.value && handleChange(e)}
                label={nom}
                onKeyDown={evt => ['ArrowLeft','ArrowRight','Backspace','Delete','0','1','2','3','4','5','6','7','8','9','+','-','.'].includes(evt.key) || evt.preventDefault()}
                disabled={disabled || ignore}
              />
            )}
            {type === TypeFormField.TEXT && (
              <TextField
                data-test={dataTest + '_edit'}
                type={'text'}
                value={state}
                onChange={handleChange}
                label={nom}
                disabled={disabled || ignore}
              />
            )}
          </FormControl>

        </Grid>

      </Grid>
    </Box>
  )
}

CCWorkflowFormSimple.propTypes = {
  dataTest:PropTypes.string.isRequired,
  nom:PropTypes.string.isRequired,
  type:PropTypes.object.isRequired,
  onValueChange:PropTypes.func
}

export default CCWorkflowFormSimple

import _ from 'lodash'
import { isGroup, isList, isListExclusion, isReferentiel } from '../../../../../store/constants/type-helper'
import { getComputedData } from '../../../../../store/selectors/tableur-selectors'
import { getPropByString } from '../row/cell/tableur-cell-helper'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo } from 'react'
import { addTableurFilter } from '../../../../../store/actions/tableur-action'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export function contains(list, value) {
  return !value ? list.includes(value) : !!list.find(e => _.isEqual(e, value))
}

function hasDefaultValue(type, component) {
  return (
    (isReferentiel(type) && component.defaultValue) ||
    (component.unit && isReferentiel(component.unit.type) && component.unit.defaultValue)
  )
}

function printValue(v, type, component, parent) {
  if(parent && parent.defaultValues[component.nom]) return 'valeur par défaut (' + parent.defaultValues[component.nom] + ')'
  if ((!v && v !== 0) || (isList(type) && v.length === 0))
    return hasDefaultValue(type, component)
      ? 'valeur par défaut (' + (component.defaultValue ? component.defaultValue : component.unit.defaultValue) + ')'
      : 'vide'
  if (isGroup(type) || isListExclusion(type)) return JSON.stringify(v).replaceAll('"', '')
  if (isList(type)) return v.length === 0 ? '[]' : Array.isArray(v) ? v.join(', ') : v.key
  return v
}

export function allValuesSelector(componentKey) {
  return state => {
    const data = getComputedData(state)
    const v = []
    data.forEach(d => {
      const propByString = getPropByString(d, componentKey)
      if (!contains(v, propByString)) v.push(propByString)
    })
    return [...v]
  }
}

export const EMPTY_LIST = []

export const TableurCellFilter = ({
  component,
  componentKey,
  type,
  setShowFilter,
  tmpFilter,
  setTmpFilter,
  setAnchorEl,
  anchorEl,
  parent
}) => {
  const tableurFilter = useSelector(state => state.tableurModule.filter.get(componentKey) || EMPTY_LIST)
  const allValues = useSelector(allValuesSelector(componentKey))
  const listOfValue = useMemo(() => (allValues.length >= tableurFilter.length ? allValues : tableurFilter), [
    allValues,
    tableurFilter,
  ])

  const dispatch = useDispatch()
  const handleClose = () => {
    setAnchorEl(null)
    setShowFilter(false)
    dispatch(addTableurFilter(componentKey, tmpFilter))
  }
  useEffect(() => {
    setTmpFilter(tableurFilter)
    if (!tableurFilter.length) setShowFilter(false)
  }, [tableurFilter])

  const onChangeFilter = (checked, value) => {
    setTmpFilter(checked ? [...tmpFilter, value] : tmpFilter.filter(f => !_.isEqual(f, value)))
  }
  return (
    <>
      {
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {listOfValue.map((v, idx) => (
            <MenuItem key={idx}>
              <input
                type="checkbox"
                value={v}
                style={{ marginRight: 3 }}
                checked={contains(tmpFilter, v)}
                onChange={e => onChangeFilter(e.target.checked, v)}
              />
              {printValue(v, type, component, parent)}
            </MenuItem>
          ))}
        </Menu>
      }
    </>
  )
}

import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TableCell } from '@material-ui/core'
import { Type } from '../../store/constants/type'
import InputText from '../common/input-text'
import CellActions from '../common/table/editable/renderer/cell-actions'

export default function AddListExclusionValues({
  component,
  rowValue,
  dataTestPrefix,
  dataTestKey,
  onCancel,
  onSave,
  onChange,
  onEditReferentiel,
  childrenReferentiel,
  save,
}) {
  const [rowData, setRowData] = useState(rowValue)
  const [updateAvailable, setUpdateAvailable] = useState(true)
  const unit = component.unit

  const dataTest = dataTestPrefix + '_edit_'

  const handleInputText = (name, e, value) => {
    const val = { ...rowData }
    val[name] = value
    setRowData(val)
    onChange && onChange(val)
  }

  const handleCancel = () => {
    setRowData(rowValue)
    setUpdateAvailable(false)
    onCancel && onCancel()
  }

  const handleSave = () => {
    setUpdateAvailable(false)
    if (onSave) {
      if (!onSave(rowData)) {
        setUpdateAvailable(true)
      }
    }
  }

  const handleSelectChange = (event, column) => {
    const value = event.target.value
    if (value.find(val => val === 'modifierReferentiel')) {
      onEditReferentiel && onEditReferentiel(unit)
    } else {
      const val = { ...rowData }
      val[column] = value
      setRowData(val)
      onChange && onChange(val)
    }
  }

  return (
    <>
      <TableCell key="0">
        <InputText
          onChange={(e, value) => handleInputText('description', e, value)}
          placeholder={'description'}
          initialValue={rowValue.description}
          dataTest={dataTest + 'description'}
          dataKey={dataTestKey}
        />
      </TableCell>
      {unit.type === Type.NUMERIC &&
        ['inclues', 'exclues'].map((field, index) => (
          <TableCell key={index + 1}>
            <InputText
              onChange={(e, value) => handleInputText(field, e, value.split(';'))}
              placeholder={unit.nom}
              initialValue={rowValue[field].join(';')}
              dataTest={dataTest + field}
              dataKey={dataTestKey}
              pattern="^([0-9]*(\.)?[0-9]*)(;[0-9]*(\.)?[0-9]*)*$"
            />
          </TableCell>
        ))}
      {unit.type === Type.TEXT &&
        ['inclues', 'exclues'].map((field, index) => (
          <TableCell key={index + 1}>
            <InputText
              onChange={(e, value) => handleInputText(field, e, value.split(';'))}
              placeholder={unit.nom}
              initialValue={rowValue[field].join(';')}
              dataTest={dataTest + field}
              dataKey={dataTestKey}
            />
          </TableCell>
        ))}
      {unit.type === Type.REFERENCE &&
        ['inclues', 'exclues'].map((field, index) => (
          <TableCell key={index + 1}>
            <FormControl>
              <InputLabel id={'component-select-label-' + field}>{unit.nom}</InputLabel>
              <Select
                id={dataTest + field}
                multiple={true}
                name={field}
                value={rowData[field].filter(val => !!val)}
                renderValue={value =>
                  value
                    .map(v => v + ' - ' + childrenReferentiel[unit.nom].values.find(r => r.code === v).libelle)
                    .join(', ')
                }
                labelId={'component-select-label-' + dataTest + field}
                onChange={e => handleSelectChange(e, field)}
              >
                {childrenReferentiel[unit.nom].values
                  .filter(option=>{
                    return rowData[field === 'inclues' ? 'exclues':'inclues'].indexOf(option.code) < 0
                  })
                  .map(option => (
                  <MenuItem value={option.code} key={option.code}>
                    <Checkbox checked={rowData[field].indexOf(option.code) > -1} />
                    <ListItemText primary={`${option.code} - ${option.libelle}`} />
                  </MenuItem>
                ))}
                <MenuItem value="modifierReferentiel">
                  <em>Modifier le référentiel</em>
                </MenuItem>
              </Select>
            </FormControl>
          </TableCell>
        ))}
      <CellActions
        key="3"
        dataTestKey={dataTestKey}
        dataTestPrefix={dataTestPrefix}
        save={updateAvailable && save}
        cancel={true}
        edit={false}
        remove={false}
        onSave={() => handleSave()}
        onCancel={() => handleCancel()}
      />
    </>
  )
}
AddListExclusionValues.propTypes = {
  component: PropTypes.any.isRequired,
  rowValue: PropTypes.object.isRequired,
  dataTestPrefix: PropTypes.string.isRequired,
  dataTestKey: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditReferentiel: PropTypes.func,
  childrenReferentiel: PropTypes.any.isRequired,
  save: PropTypes.bool.isRequired,
}

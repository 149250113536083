import React from 'react'

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { closeAlert } from '../../store/actions/alert-action'
import {
  Box,
  makeStyles,
  Popper as MuiPopper
} from '@material-ui/core'
import Alert from './Alert'
import * as PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  content: {
    marginTop : '10px',
    maxWidth : '800px',
    maxHeight : '600px',
    overflow : 'scroll'
  },
}))

const Alerts = ({ timeout, locateRef, querySelector }) => {
  const classes = useStyles()
  const [dom, setDom] = useState(null)
  useEffect(() => {
    if (locateRef.current) {
      let domElmt = locateRef.current.querySelector(querySelector)
      if (domElmt) {
        setDom(domElmt)
      }
    }
  }, [locateRef, querySelector])
  const alerts = useSelector(state => state.alerts.list)
  const dispatch = useDispatch()
  const onCloseAlert = index => {
    dispatch(closeAlert(index))
  }
  return (
    alerts && (
      <MuiPopper
        name="alerts"
        anchorEl={dom}
        placement="top-end"
        open={alerts.length > 0}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: false,
          },
        }}
      >
        {alerts.map(alert => (
          <Box key={alert.key} className={classes.content}>
            <Alert timeout={timeout} severity={alert.severity} onClose={() => onCloseAlert(alert.key)}>
              {alert.message}
            </Alert>
          </Box>
        ))}
      </MuiPopper>
    )
  )
}

Alerts.propTypes = {
  timeout: PropTypes.number,
  locateRef: PropTypes.any.isRequired,
  querySelector: PropTypes.string.isRequired,
}

export default Alerts

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ActionForm, withEditableRow, withFormTable } from '../Table/form-table'
import AddGroupValues, { flatChildren, getChildValue } from './add-group-values'
import { getKey } from './helper'
import { Grid } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import CellStringRenderer from '../common/table/editable/renderer/cell-string-renderer'
import { Type } from '../../store/constants/type'
import { getCCChildReferentielById } from '../../store/actions/referentiel-action'
import { stashPopinContext } from '../../store/actions/values-action'
import { dispatchActions } from '../../store/actions/common'
import { patchReferentielValues } from '../../store/actions/referentiel-action'
import { setLoader } from '../../store/actions/loader-action'
import { GroupValuesHeaders } from './component-group-headers'
import _ from 'lodash'

function ActionNouveau(props) {
  return <ActionForm buttonName="Nouveau" buttonDataTest="Nouvelle valeur" {...props} />
}

export const stringifyChildren = (component, childrenReferentiel, valueToBeMapped) => {
  const children = flatChildren(component)
  let result = ''
  children.forEach(enfant => {
    if (result.length > 0) {
      result = result + ' - '
    }
    result = result + enfant.nom + ':' + stringifyChildValue(enfant, childrenReferentiel, valueToBeMapped)
  })
  return result
}

export const stringifyChildValue = (enfant, childrenReferentiel, valueToBeMapped) => {
  if (enfant.type === Type.REFERENCE) {
    let value = getChildValue(enfant, valueToBeMapped)
    if (value) {
      let refVal = childrenReferentiel[enfant.nom].values.find(val => val.code === value)
      return refVal.code + ' - ' + refVal.libelle
    } else {
      return ''
    }
  } else {
    return getChildValue(enfant, valueToBeMapped)
  }
}

function ChildrenGroupCell({ component, rowKey, rowValue, childrenReferentiel }) {
  const children = flatChildren(component)
  return children.map((enfant, index) => {
    let mapper = valueToBeMapped => {
      return stringifyChildValue(enfant, childrenReferentiel, valueToBeMapped)
    }
    return (
      <CellStringRenderer
        key={index + 1}
        align="left"
        dataTestKey={index + '_' + rowKey}
        dataTestPrefix="component"
        mapper={mapper}
        rowValue={rowValue}
      />
    )
  })
}

ChildrenGroupCell.propTypes = {
  component: PropTypes.object.isRequired,
  rowKey: PropTypes.string.isRequired,
  rowValue: PropTypes.object.isRequired,
  childrenReferentiel: PropTypes.any.isRequired,
}

let FormTable = withFormTable([ActionNouveau], GroupValuesHeaders, withEditableRow(AddGroupValues, [ChildrenGroupCell]))

export default function ComponentGroupValues({
  component,
  values,
  onSave,
  onDelete,
  idcc,
  themeName,
  role,
  popinAnchorEl,
}) {
  const childrenComponent = useSelector(state => state.conventionModule.childrenComponent)
  const childrenReferentiel = useSelector(state => state.conventionModule.childrenReferentiel)
  const popinContext = useSelector(state => state.conventionModule.popinContext)
  const [valuesMap, setValuesMap] = useState(new Map([]))
  const [rowsEditKey, setRowsEditKey] = useState([])
  const [popInChildComponent, setPopInChildComponent] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    const isStash = popinContext.componentNom === component.nom
    if (isStash) {
      setValuesMap(new Map(popinContext.valuesMap))
      setRowsEditKey(popinContext.rowsEditKey)
      if (popinContext.childComponent) {
        const stack = [popinContext.childComponent.nom]
        let parent = popinContext.childComponent.parent
        while (parent) {
          stack.push(parent.nom)
          parent = parent.parent
        }
        let child = component
        while (stack.length > 0 && child) {
          let childNom = stack.pop()
          child = component.enfants && component.enfants.find(enfant => enfant.nom === childNom)
        }
        if (child) setPopInChildComponent(child)
      } else {
        setPopInChildComponent(null)
        dispatch(stashPopinContext(''))
      }
    } else {
      if (rowsEditKey.length === 0) setValuesMap(values)
    }
  }, [values, popinContext, component, dispatch, rowsEditKey])

  const handleClosePopinChild = () => {
    dispatchActions(dispatch, stashPopinContext(component.nom, { childComponent: null, valuesMap, rowsEditKey }))
  }

  const handleSavePopinChild = datas => {
    dispatchActions(
      dispatch,
      setLoader(true),
      datas ? patchReferentielValues(datas.referentielId, datas.refValuesMap) : undefined,
      getCCChildReferentielById(popInChildComponent),
      setLoader(false)
    )
  }

  const handleEditReferentiel = childComponent => {
    dispatch(stashPopinContext(component.nom, { childComponent, valuesMap, rowsEditKey }))
  }

  const handleRowEdit = rowKey => {
    setRowsEditKey([...rowsEditKey, rowKey])
  }

  const handleActionEdit = () => {
    var newValuesMap = new Map(valuesMap)
    var key = getKey(newValuesMap)
    newValuesMap.set(key, {})
    setValuesMap(newValuesMap)
    setRowsEditKey([...rowsEditKey, key])
    return false
  }

  const handleRowChange = (rowKey, rowValue) => {
    if (rowsEditKey.indexOf(rowKey) >= 0) {
      var newValuesMap = new Map(valuesMap)
      newValuesMap.set(rowKey, rowValue)
      setValuesMap(newValuesMap)
    }
  }

  const handleRowSave = datas => {
    dispatch(stashPopinContext(''))
    if (onSave) {
      onSave(datas)
    }
  }

  const handleRowCancel = rowKey => {
    dispatch(stashPopinContext(''))
    if (rowsEditKey.indexOf(rowKey) >= 0) {
      var newValuesMap = new Map(valuesMap)
      newValuesMap.delete(rowKey)
      setValuesMap(newValuesMap)
      let newRowsEditKey = [...rowsEditKey]
      _.remove(newRowsEditKey, val => val === rowKey)
      setRowsEditKey(newRowsEditKey)
    }
  }

  const handleRowDelete = rowKey => {
    dispatch(stashPopinContext(''))
    if (onDelete) {
      onDelete(rowKey)
    }
  }

  return (
    <Grid container direction="column" justify="center" alignItems="stretch">
      <Grid item style={{ width: '100%' }}>
        <FormTable
          valuesMap={valuesMap}
          dataTestPrefix="component"
          component={component}
          validateRowValue={() => true}
          childrenReferentiel={childrenReferentiel}
          childrenComponent={childrenComponent}
          onActionEdit={handleActionEdit}
          rowsEditKey={rowsEditKey}
          multipleEdit={false}
          onDelete={handleRowDelete}
          onRowEdit={handleRowEdit}
          onRowCancel={handleRowCancel}
          onRowChange={handleRowChange}
          onSave={handleRowSave}
          idcc={idcc}
          themeName={themeName}
          role={role}
          onSavePopinChild={handleSavePopinChild}
          onClosePopinChild={handleClosePopinChild}
          popinChild={popInChildComponent}
          onEditReferentiel={handleEditReferentiel}
          popinAnchorEl={popinAnchorEl}
        />
      </Grid>
    </Grid>
  )
}

ComponentGroupValues.propTypes = {
  component: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  values: PropTypes.object.isRequired,
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Grid, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCCById,
  getCCHistoriques,
  getCCThemes,
  setActiveCCField,
  updateConvention,
} from '../../store/actions/convention-collective-action'
import Paper from '@material-ui/core/Paper'
import { IdccInpput } from './fields/idcc-input'
import { CheckPersonnalise } from './fields/check-personnalise'
import { TitreInput } from './fields/titre-input'
import { IdccList } from './fields/idcc-list'

import './fields/style.css'
import LinearProgress from '@material-ui/core/LinearProgress'
import { CCField } from '../../store/constants/active-cc-field'
import { getThemes } from '../../store/actions/theme-action'
import ConventionCollective from '../../store/constants/convention-collective'
import { ContainerTitle } from '../common/container-title'
import { CCTabs } from './cc-tabs'
import { setTab } from '../../store/actions/tab-action'
import { AttachedCCInpput } from './fields/attached-cc-input'
import { getCCWorkflows, getModels } from '../../store/actions/workflow-action'
import { StatutItem } from '../historiques/statut-item'
import { getAllReferentiels } from '../../store/actions/referentiel-action'
import { loadComponents } from '../../store/actions/component-action'
import { IdccDate } from './fields/cc-date'
import { Statut } from '../../store/constants/historique'
import { setLoader } from '../../store/actions/loader-action'

const IDCC_RATTACHEMENT = CCField.IDCC_RATTACHEMENT
const IDCC_NATIONAL = CCField.IDCC_NATIONAL
const DATE_FERMETURE = CCField.DATE_FERMETURE

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

export const ConventionDetail = () => {
  const classes = useStyles()

  const [idccRattachements, setIdccRattachmentList] = useState([])
  const [idccNationaux, setIdccNationaux] = useState([])
  const { activeCCField } = useSelector(state => state.conventionModule)
  const dispatch = useDispatch()
  const { id } = useParams()

  const { conventionsCollectives } = useSelector(state => state.conventionModule)
  const loader = useSelector(state => state.loader)
  const handleChange = prop => ({ target: { value } }) => setState({ ...state, [prop]: value })

  const onChangePersonnalise = () =>
    setState({
      ...state,
      personnalise: !state.personnalise,
    })

  useEffect(() => {
    setIdccRattachmentList(conventionsCollectives.filter(c => !c.idccRattachement))
    setIdccNationaux(conventionsCollectives.filter(c => !c.idccNational))
  }, [conventionsCollectives])

  const { idcc, titre, idccNational, idccRattachement, personnalise, ccRegionales, ccRattaches, statut, dateFermeture, ouvert } = useSelector(
    state => state.conventionModule.conventionCollective
  )

  const [state, setState] = useState({
    idcc,
    titre,
    idccNational,
    idccRattachement,
    personnalise,
    ccRegionales,
    ccRattaches,
    dateFermeture
  })

  useEffect(() => {
    Promise.resolve()
      .then(() => dispatch(setLoader(true)))
      .then(() => {
        dispatch(getCCById(id))
        dispatch(getThemes())
        dispatch(getCCThemes(id))
        dispatch(getCCHistoriques(id))
        dispatch(getAllReferentiels())
        dispatch(loadComponents(true))
        //dispatch(initWorkflowsConfiguration())
        dispatch(getModels())
        dispatch(getCCWorkflows(id))
        dispatch(setTab(0))
      })
      .finally(() => dispatch(setLoader(false)))
    return () => dispatch(setActiveCCField())
  }, [id, dispatch])

  useEffect(() => {
    setState({
      idcc,
      titre,
      idccNational,
      idccRattachement,
      personnalise,
      dateFermeture
    })
  }, [idcc, titre, idccNational, idccRattachement, personnalise, activeCCField, dateFermeture])

  const handleSave = () => {
    dispatch(updateConvention(state))
  }

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={6} />
        <Grid item xs={3} container justify="flex-end" />
      </Grid>
      <br />
      <br />
      {loader ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Paper className={classes.contentRoot} elevation={1}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <ContainerTitle classes={classes} title="Convention détail" />
                  </Grid>
                  <Grid item xs={2}>
                    <StatutItem statut={statut} isClosed={!ouvert}/>
                  </Grid>
                </Grid>
                <form className={classes.container}>
                  <Grid container spacing={2}>
                    <IdccInpput className={'textField'} idcc={state.idcc} onChange={handleChange} isLabel={true} />
                    <CheckPersonnalise
                      className={'textField'}
                      personnalise={state.personnalise}
                      onChange={onChangePersonnalise}
                      disable={true}
                    />
                    <TitreInput titre={state.titre} onChange={handleChange} isLabel={true} onSave={handleSave} />
                    {ccRegionales.length > 0 ? (
                      <AttachedCCInpput label={'Aucun idcc national'} dataTest={'idccNational'} />
                    ) : (
                      <IdccList
                        idcc={state.idccNational || ConventionCollective.NULL.idcc}
                        ccField={IDCC_NATIONAL}
                        onChange={handleChange(IDCC_NATIONAL.name)}
                        idccListInput={idccNationaux}
                        isLabel={true}
                        onSave={handleSave}
                        dataTest="idccNational"
                        label="IDCC National"
                      />
                    )}
                    {ccRattaches.length > 0 ? (
                      <AttachedCCInpput label={'Aucun idcc rattachement'} dataTest={'idccRattachement'} />
                    ) : (
                      <IdccList
                        idcc={state.idccRattachement || ConventionCollective.NULL.idcc}
                        ccField={IDCC_RATTACHEMENT}
                        onChange={handleChange(IDCC_RATTACHEMENT.name)}
                        idccListInput={idccRattachements}
                        isLabel={true}
                        onSave={handleSave}
                        dataTest="idccRattachement"
                        label="IDCC Rattachement"
                      />
                    )}
                    {(statut === Statut.PUBLIE || !ouvert) && <IdccDate
                      ccDate={state.dateFermeture}
                      ccField={DATE_FERMETURE}
                      onChange={handleChange(DATE_FERMETURE.name)}
                      idccListInput={idccRattachements}
                      isLabel={true}
                      onSave={handleSave}
                      dataTest="dateFermeture"
                      label="Date de fermeture"
                    />}
                  </Grid>
                </form>
              </Paper>
              <br />
            </div>
            <CCTabs />
          </Grid>
        </Grid>
      )}
    </main>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { setSelectMode } from '../../../../store/actions/tableur-action'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import * as PropTypes from 'prop-types'
import React from 'react'

export const SelectButton = () => {

  const dispatch = useDispatch()
  const editMode = useSelector(state => state.tableurModule.editMode)
  const selectMode = useSelector(state => state.tableurModule.selectMode)
  const handlSelectMode = () => dispatch(setSelectMode(true))

  return <Grid item>
    <>
      {!selectMode && !editMode && (
        <IconButton aria-label="select mode" data-test="select_lines_icon" onClick={handlSelectMode}>
          <PlaylistAddCheckIcon/>
        </IconButton>
      )}
    </>
  </Grid>
}

SelectButton.propTypes = {
  selectMode: PropTypes.any,
  editMode: PropTypes.any,
  onClick: PropTypes.any,
}
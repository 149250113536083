export class ModelTextualisation {
  parameters
  blocks

  constructor(
    parameters =  [],
    blocks = [],
  ) {
    this.parameters = parameters
    this.blocks = blocks
  }

  static NULL = new ModelTextualisation()
}


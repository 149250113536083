export default class Doublon {
  type
  idcc
  source
  target

  constructor(type, idcc, source, target) {
    this.idcc = idcc
    this.type = type
    this.source = source
    this.target = target
  }

  getMessage() {
    return 'ID ' + this.source + this.type.message + this.target + ' (CC ' + this.idcc + ')'
  }
}

export class TypeDoublon {
  name
  message

  constructor(name, message) {
    this.name = name
    this.message = message
  }

  static INPUTS = new TypeDoublon('INPUTS', " est en doublon d'inputs avec ID ")
  static BORNES = new TypeDoublon('BORNES', ' est en doublon de bornes avec ID ')
  static REFERENTIEL = new TypeDoublon('REFERENTIEL', ' est en doublon de référence avec ID ')
  static NULL = new TypeDoublon('NULL', ' est en doublon null avec ID ')
  static ALL = [TypeDoublon.INPUTS, TypeDoublon.BORNES, TypeDoublon.REFERENTIEL, TypeDoublon.NULL]

  static fromStr(str) {
    return TypeDoublon.ALL.find(t => t.name === str)
  }
}

import * as PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { InputLabel, ListSubheader, MenuItem, Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import ConventionCollective from '../../store/constants/convention-collective'
import Icon from '@material-ui/core/Icon'
import { DEFAULT_COLOR, getStatutColor } from '../historiques/statut-item'
import { WorkflowStatut } from '../../store/constants/ModelInstanceWorkFlow'


function getThemeGroup(themes, CCTheme) {
  return themes.filter(t => !!t.idTheme).map(t => ({
    nom: t.nom,
    id: t.idTheme,
    children: themes.filter(child => child.themeParentId === t.idTheme).map(t1 => ({
      ...t1,
      statut: CCTheme.find(t2 => t2.nom === t1.nom) ? CCTheme.find(t2 => t2.nom === t1.nom).statut : t1.statut,
    })),
  }))
}

function ThemeIcon({ theme, workflows }) {
  const color = useMemo(() => theme ? getStatutColor(theme.statut) : DEFAULT_COLOR, [theme])
  let icon = color === DEFAULT_COLOR ? '' : workflows.find(w => w[theme.nom.toUpperCase()] === WorkflowStatut.EN_COURS) ? 'account_tree' : 'circle'
  return <Icon style={{
    marginRight: icon === 'circle' ? 18 : 8,
    fontSize: icon === 'circle' ? 15 : 25,
    color: icon === 'circle' ? color : '',
  }}>{icon}</Icon>
}

ThemeIcon.propTypes = { theme: PropTypes.any }


function ThemeSelectElement({ tGroup, onChange, workflow }) {
  return
}

ThemeSelectElement.propTypes = {
  tGroup: PropTypes.any,
  onChange: PropTypes.func,
  workflow: PropTypes.any,
}
export const SelectThemeData = ({ onChange, theme, convention, onFocusOut }) => {
  const [open, setOpen] = useState(false)
  let themes = useSelector(state => state.themeModule.themes)
  let ccTheme = useSelector(state => state.conventionModule.themes)
  const workflow = useSelector(state => state.workflowModule.workflows.filter(w => w.idcc === convention.idcc).map(w => w.statut))
  const themesParentGroup = useMemo(() => getThemeGroup(themes, ccTheme), [themes, ccTheme])
  const handleClose = () => {
    onFocusOut()
    setOpen(false)
  }
  const handleOpen = () => setOpen(true)

  useEffect(() => {
    setOpen(!!theme)
  }, [theme])

  const label = useMemo(() => theme ? theme : 'Selectionnez un Thème', [theme])

  return <FormControl variant='outlined' sx={{ m: 1, minWidth: 120 }} style={{ marginTop: 10 }} size='small'>
    <InputLabel id='choose_theme_select'>{label}</InputLabel>
    <Select label={label}
            labelId='choose_theme_select'
            variant='outlined'
            style={{ width: 300 }}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            disabled={!convention || convention === ConventionCollective.NULL}>
      {
        themesParentGroup.map(tGroup => <div key={tGroup.id}>
            <ListSubheader disableSticky>{tGroup.id} - {tGroup.nom}</ListSubheader>
            <li>
              {
                tGroup.children.map(t => <ul key={t.nom} onClick={() => onChange(t.nom, tGroup)}>
                  <MenuItem key={t.nom}>
                    <ThemeIcon theme={t} workflows={workflow} />{t.nom}
                  </MenuItem>
                </ul>)
              }
            </li>
          </div>,
        )
      }
    </Select>
  </FormControl>
}

SelectThemeData.propTypes = {
  onChange: PropTypes.func,
  theme: PropTypes.string,
}

import React from 'react'
import * as PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

export default function ActionsBar({children,showForm}){ 
    return (
        <Grid item xs={12}>        
            <Grid container justify={showForm ? "center" : "flex-end"} space={1} direction={showForm ? "column" : "row"} alignItems={showForm ? "stretch": "center"}>
                 {children}
            </Grid>
      </Grid>
    )
}

ActionsBar.propTypes = {
    showForm:PropTypes.bool.isRequired
}
import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, Checkbox, Collapse, FormControlLabel, Grid, makeStyles, Paper, TextField } from '@material-ui/core'
import { ContainerTitle } from '../../common/container-title'
import CcWorkflowFormList from './inputs/cc-workflow-form-list'
import CcWorkflowFormInstance from './inputs/cc-workflow-form-instance'
import CcWorkflowFormListExclusion from './inputs/cc-workflow-form-list-exclusion'
import { Type } from '../../../store/constants/type'
import { ModelInstanceWorkflow } from '../../../store/constants/ModelInstanceWorkFlow'
import CCWorkflowFormField from './cc-workflow-formfield'
import _ from 'lodash'
import { TypeModele } from '../../../store/constants/ModelWorkflow'

const useStyles = makeStyles(atheme => ({
  contentRoot: {
    ...atheme.mixins.gutters(),
    paddingTop: atheme.spacing(2),
    paddingBottom: atheme.spacing(2),
  },
  content: {
    padding: atheme.spacing(3),
  },
}))

function getReferenceValues(state, theme, emptyValue = []) {
  return state.themesInputType.find(ti => ti.theme === theme.nom)
    ? state.themesInputType.find(ti => ti.theme === theme.nom).value
    : emptyValue
}

function getReferenceIgnore(state, theme) {
  return state.themesInputType.find(ti => ti.theme === theme.nom)
    ? state.themesInputType.find(ti => ti.theme === theme.nom).ignore
    : false
}

function getFieldIgnore(state, field) {
  return state.fieldsInputType.find(ti => ti.field === field)
    ? state.fieldsInputType.find(ti => ti.field === field).ignore
    : false
}

function CcWorkflowForm({ model, idcc, instanceWF = ModelInstanceWorkflow.NULL, onChange }) {
  const { themes: ccThemes, conventionCollective: cc } = useSelector(state => state.conventionModule)
  const { user } = useSelector(state => state.user)
  const components = useSelector(state => state.componentModule.components)
  const [oldInstance, setOldInstance] = useState(null)
  const withWorkflowRef = model.formFields.filter(field => !!field.workflowRef).length > 0
  const workflowRefForms = model.formFields
    .filter(field => !!field.workflowRef)
    .map(field => {
      const result = { ...field.workflowField }
      result.field = field.field
      return result
    })
  const workflowRefNames = _.uniq(model.formFields.filter(field => !!field.workflowRef).map(field => field.workflowRef))

  const state = {
    nom: instanceWF.nom,
    description: instanceWF.description,
    referenceAccord: instanceWF.referenceAccord,
    source: instanceWF.source,
    themeSelected: instanceWF.themes,
    themesInputType: instanceWF.themesInputType,
    fieldsInputType: instanceWF.fieldsInputType,
    workflowRef: instanceWF.workflowRef ? instanceWF.workflowRef : {},
    parameters: instanceWF.parameters
  }

  const setState = newState => {
    const newInstanceWF = new ModelInstanceWorkflow(
      newState.nom,
      newState.description,
      newState.referenceAccord,
      null,
      newState.themeSelected,
      newState.themesInputType,
      newState.fieldsInputType,
      idcc,
      model.nom,
      new Date(),
      newState.workflowRef,
      user.username,
      newState.source,
      newState.parameters
    )
    onChange && onChange(newInstanceWF)
  }
  const classes = useStyles()

  const getComponent = nom => {
    let result = components.find(comp => comp.nom === nom)
    return result
  }

  const handleChange = (field, value, pattern) => {
    if (!pattern || value.match(pattern)) {
      const newState = { ...state }
      newState[field] = value
      setState(newState)
    }
  }

  const handleWorkflowInstanceChange = (workflowRefName, instance) => {
    let currentState = { ...state }
    if (!instance) {
      if (oldInstance) {
        oldInstance.themes
          .map(themeName => ccThemes.find(t => t.nom === themeName))
          .forEach(theme => handleSelectTheme(false, theme, currentState))
        model.formFields
          .filter(field => field.workflowRef === oldInstance.modelWorkflow)
          .forEach(formField => {
            handleFieldValueChange(formField.field, null, currentState)
          })
        delete currentState.workflowRef[workflowRefName]
        setState(currentState)
        setOldInstance(null)
        return
      }
    }
    currentState.workflowRef[workflowRefName] = instance.nom
    instance.themes
      .map(themeName => ccThemes.find(t => t.nom === themeName))
      .forEach(theme => {
        handleSelectTheme(true, theme, currentState)
        if (theme.inputType) {
          const inputThemedValue = instance.themesInputType.find(f => f.theme === theme.nom)
          handleThemeValueChange(theme.nom, inputThemedValue.value, currentState)
        }
      })

    model.formFields
      .filter(field => field.workflowRef === instance.modelWorkflow)
      .forEach(form => {
        const fieldValue = instance.fieldsInputType.find(f => f.field === form.workflowField.field)
        handleFieldValueChange(form.field, fieldValue ? fieldValue.value : null, currentState)
      })
    setState(currentState)
    setOldInstance(instance)
  }

  const handleFieldValueChange = (field, value, currentState) => {
    handleValueChange(field, value, currentState, 'fieldsInputType', 'field')
  }

  const handleThemeValueChange = (field, value, currentState) => {
    handleValueChange(field, value, currentState, 'themesInputType', 'theme')
  }

  const handleValueChange = (field, value, currentState, parentFieldName, fieldName) => {
    const newState = currentState || { ...state }
    const newValue = newState[parentFieldName].filter(it => it[fieldName] !== field)
    if (value) newValue.push({ [fieldName]: field, value })
    newState[parentFieldName] = newValue
    !currentState && setState(newState)
  }

  const handleChangeIgnoreTheme = (field, ignore, parentFieldName, fieldName) => {
    const newState = { ...state }
    const newValue = newState[parentFieldName].filter(it => it[fieldName] !== field)
    if (ignore) newValue.push({ [fieldName]: field, ignore })
    newState[parentFieldName] = newValue
    setState(newState)
  }

  const handleSelectTheme = (checked, theme, currentState) => {
    if (!checked) {
      if (state.themeSelected.indexOf(theme.nom) >= 0) {
        const newState = currentState || { ...state }
        newState.themeSelected = [...newState.themeSelected]
        newState.themeSelected.splice(newState.themeSelected.indexOf(theme.nom), 1)
        newState.themesInputType = newState.themesInputType.filter(ti => ti.theme !== theme.nom)
        !currentState && setState(newState)
      }
    } else {
      if (state.themeSelected.indexOf(theme.nom) < 0) {
        const newState = currentState || { ...state }
        newState.themeSelected = [...newState.themeSelected]
        newState.themeSelected.push(theme.nom)
        if (theme.inputType) {
          let component = getComponent(theme.inputType)
          if (component.type === Type.REFERENCE) {
            newState.themesInputType.push({ theme: theme.nom, value: [] })
          } else if (component.type === Type.LIST) {
            newState.themesInputType.push({ theme: theme.nom, value: [] })
          } else if (component.type === Type.LIST_EXCLUSION) {
            newState.themesInputType.push({ theme: theme.nom, value: { inclus: [], exclus: [] } })
          }
        }
        !currentState && setState(newState)
      }
    }
  }
  return (
    <Box>
      <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
        <Grid item style={{ width: '90%' }}>
          <Paper>
            <Box className={classes.content}>
              <ContainerTitle classes={classes} title="Nouvelle tâche de workflow" />
              {!!instanceWF.id && (
                <TextField
                  data-test={'nom'}
                  value={state.nom}
                  onChange={e => handleChange('nom', e.target.value, /^[0-9a-zA-Z_-]*$/)}
                  fullWidth
                  disabled={!!instanceWF.id}
                  label="Nom"
                />
              )}
              <TextField
                data-test={'description'}
                value={state.description}
                onChange={e => handleChange('description', e.target.value)}
                fullWidth
                label="Description"
                disabled={!!instanceWF.id}
              />
              <TextField
                data-test={'reference_accord'}
                value={state.referenceAccord}
                onChange={e => handleChange('referenceAccord', e.target.value)}
                fullWidth
                label="Référence accord"
                disabled={!!instanceWF.id}
              />
              <TextField
                data-test={'source_accord'}
                value={state.source}
                onChange={e => handleChange('source', e.target.value)}
                fullWidth
                label="Source"
                disabled={!!instanceWF.id}
              />
              <Box>
                {workflowRefNames &&
                  workflowRefNames.map(workflowRefName => (
                    <CcWorkflowFormInstance
                      key={workflowRefName}
                      dataTest={`formField_input_${workflowRefName}`}
                      workflowRef={workflowRefName}
                      idcc={idcc}
                      onValueChange={value => handleWorkflowInstanceChange(workflowRefName, value)}
                    />
                  ))}
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Grid container direction="row" justify="space-evenly" alignItems="stretch">
            <Grid item style={{ width: '45%' }}>
              <Paper>
                <Box className={classes.content}>
                  <ContainerTitle classes={classes} title="Thèmes" />
                  <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                    {ccThemes &&
                      ccThemes.map(theme => (
                        <Grid item key={theme.nom}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.themeSelected.indexOf(theme.nom) >= 0}
                                onChange={event => handleSelectTheme(event.target.checked, theme)}
                                disabled={withWorkflowRef}
                              />
                            }
                            label={theme.nom}
                            data-test={`theme_${theme.nom}`}
                          />
                          {theme.inputType && model.type !== TypeModele.CREATE && (
                            <Collapse in={state.themeSelected.indexOf(theme.nom) >= 0}>
                              {getComponent(theme.inputType).type === Type.REFERENCE && (
                                <CcWorkflowFormList
                                  dataTest={`themeInput_${theme.nom}_${theme.inputType}`}
                                  nom={theme.inputType}
                                  referentielName={getComponent(theme.inputType).referentiel}
                                  referenceValues={getReferenceValues(state, theme)}
                                  onValueChange={values => handleThemeValueChange(theme.nom, values)}
                                  changeIgnore={ignore =>
                                    handleChangeIgnoreTheme(theme.nom, ignore, 'themesInputType', 'theme')
                                  }
                                  ignore={getReferenceIgnore(state, theme)}
                                  disabled={withWorkflowRef}
                                />
                              )}
                              {getComponent(theme.inputType).type === Type.LIST && (
                                <CcWorkflowFormList
                                  dataTest={`themeInput_${theme.nom}_${theme.inputType}`}
                                  nom={theme.inputType}
                                  referentielName={getComponent(getComponent(theme.inputType).unit.nom).referentiel}
                                  referenceValues={getReferenceValues(state, theme)}
                                  onValueChange={values => handleThemeValueChange(theme.nom, values)}
                                  changeIgnore={ignore =>
                                    handleChangeIgnoreTheme(theme.nom, ignore, 'themesInputType', 'theme')
                                  }
                                  ignore={getReferenceIgnore(state, theme)}
                                  disabled={withWorkflowRef}
                                />
                              )}
                              {getComponent(theme.inputType).type === Type.LIST_EXCLUSION && (
                                <CcWorkflowFormListExclusion
                                  dataTest={`themeInput_${theme.nom}_${theme.inputType}`}
                                  nom={theme.inputType}
                                  referentielName={getComponent(getComponent(theme.inputType).unit.nom).referentiel}
                                  referenceValues={getReferenceValues(state, theme, { inclus: [], exclus: [] })}
                                  onValueChange={values => handleThemeValueChange(theme.nom, values)}
                                  changeIgnore={ignore =>
                                    handleChangeIgnoreTheme(theme.nom, ignore, 'themesInputType', 'theme')
                                  }
                                  ignore={getReferenceIgnore(state, theme)}
                                  disabled={withWorkflowRef}
                                />
                              )}
                            </Collapse>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item style={{ width: '45%', height: '100%' }}>
              <Paper>
                <Box className={classes.content}>
                  <ContainerTitle classes={classes} title="Formulaire" />
                  <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                    {workflowRefForms &&
                      workflowRefForms.map(formField => (
                        <CCWorkflowFormField
                          key={`workflowRef_${formField.field}`}
                          formField={formField}
                          fieldsInputType={state.fieldsInputType}
                          changeIgnore={ignore =>
                            handleChangeIgnoreTheme(formField.field, ignore, 'fieldsInputType', 'field')
                          }
                          ignore={getFieldIgnore(state, formField.field)}
                          required={formField.required}
                          onFieldValueChange={handleFieldValueChange}
                          disabled={true}
                        />
                      ))}
                    {model.formFields &&
                      model.formFields.map(formField => (
                        <CCWorkflowFormField
                          key={`form_${formField.field}`}
                          formField={formField}
                          fieldsInputType={state.fieldsInputType}
                          changeIgnore={ignore =>
                            handleChangeIgnoreTheme(formField.field, ignore, 'fieldsInputType', 'field')
                          }
                          ignore={!formField.require && getFieldIgnore(state, formField.field)}
                          required={formField.required}
                          onFieldValueChange={handleFieldValueChange}
                        />
                      ))}
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

CcWorkflowForm.propTypes = {
  model: PropTypes.object.isRequired,
  instanceWF: PropTypes.object,
  onChange: PropTypes.func,
  idcc: PropTypes.string.isRequired,
}

export default CcWorkflowForm

import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import { Button, Checkbox, Fade, Grid } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import _ from "lodash"

function isPublication(messages) {
  return messages.includes('publier les données')
}

export default function Confirmation({ onClose, onSave, messages,confirmMessage }) {
  const newMessages=messages.map(m=>{
    if(_.isString(m)){
      if(isPublication(messages)){
        return {
          message:m,
          withCheckBox: false
        }
      }else{
        return {
          message: m,
          withCheckBox: true
        }
      }
    }else{
      return m
    }
  })
  const [open] = React.useState(true)
  const [isClose, setClose] = React.useState(false)
  const [disabled, setDisabled] = React.useState(newMessages.length > 0)
  const [checked, setChecked] = React.useState(newMessages.map((mes) => !mes.withCheckBox))
  const close = () => {
    if (!isClose) {
      setClose(true)
      onClose()
    }
  }
  const onTransitionEndClose = () => {
    if (!open) {
      close()
    }
  }

  const handleSave = () => {
    close()
    onSave && onSave()
  }

  const clickCheckBox = index => {
    let check = [...checked]
    check[index] = !check[index]
    setChecked(check)
    let value = true
    check.forEach(v => (value = value && v))
    setDisabled(!value)
  }

  return (
    <Fade in={open}>
      <MuiAlert
        style={{ MaxWidth: 'sm' }}
        onTransitionEnd={onTransitionEndClose}
        severity="warning"
        onClose={close}
        role="confirmation"
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            {newMessages.map(({message,withCheckBox}, index) => {
              return (
                <Grid container direction="row" justify="flex-start" alignItems="center" key={index}>
                  <>
                    {withCheckBox && (
                      <Grid item>
                        <Checkbox
                          onChange={() => clickCheckBox(index)}
                          value={checked[index]}
                          data-test={`confirmation_check_${index}`}
                        />
                      </Grid>
                    )}
                    <Grid item key={index} data-test={index} name="confirmation_message">
                      <div style={{ maxWidth: '400px' }}>{message}</div>
                    </Grid>
                  </>
                </Grid>
              )
            })}
          </Grid>
          <Grid item>

          <>
            {(_.isString(messages[0]) && isPublication(messages[0])) ? (
              <Button type="button" color="primary" onClick={handleSave} data-test="oui" disabled={disabled}>
                OUI
              </Button>
            ) : (
              <Button
                type="button"
                color="primary"
                onClick={handleSave}
                disabled={disabled}
                data-test="saveSuppression"
              >
                {confirmMessage}
              </Button>

            )}

            <Button
                type="button"
                color="primary"
                onClick={close}
                disabled={false}
                data-test="cancel"
              >
               Annuler
              </Button>
          </>
          </Grid>
        </Grid>
      </MuiAlert>
    </Fade>
  )
}

Confirmation.propTypes = {
  timeout: PropTypes.number,
  messages: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

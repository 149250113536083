import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles, TablePagination } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import EnhancedTableHead from '../Table/EnhancedTableHead'
import EnhancedTableToolbar from '../Table/EnhancedTableToolbar'
import { getSorting, stableSort } from '../Table/TableUtils'
import { showAlert, showConfirmation } from '../../store/actions/alert-action'
import _ from 'lodash'
import { StatutItem } from '../historiques/statut-item'
import { Statut } from '../../store/constants/historique'
import { loadPublishAllCC, publishMulti, setAllCCHistoriquesDetails } from '../../store/actions/historique-action'
import Grid from '@material-ui/core/Grid'
import { CancelButton } from '../common/cancel-button'
import { ValidationButton } from '../common/validation-button'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  conventionItem: {
    paddingTop: 14,
    paddingBottom: 10,
    borderBottom: `1px solid #dadde7`,
  },
}))

const headCells = [
  {
    id: 'idcc',
    numeric: false,
    disablePadding: false,
    label: 'Idcc',
  },
  {
    id: 'titre',
    numeric: false,
    disablePadding: false,
    label: 'Titre',
  },
  {
    id: 'idccNational',
    numeric: false,
    disablePadding: false,
    label: 'Idcc National',
  },
  {
    id: 'idccRattachement',
    numeric: false,
    disablePadding: false,
    label: 'Idcc de rattachement',
  },
  {
    id: 'statut',
    numeric: false,
    disablePadding: false,
    label: 'Statut',
  },
  {
    id: 'personnalise',
    disablePadding: false,
    boolean: true,
    label: 'Personnalisé',
  },
]

const publishHeadCells = [
  {
    id: 'publier',
    disablePadding: false,
    boolean: true,
    label: 'publier',
  },
  ...headCells,
  {
    id: 'themes',
    disablePadding: false,
    boolean: true,
    label: 'themes',
  },
  {
    id: 'description',
    disablePadding: false,
    boolean: true,
    label: 'description',
  },
]

function getThemes(historiquesDetails, idcc) {
  const detail = historiquesDetails.get(idcc)
  return detail ? detail.themes.join(',') : ''
}

function getDescription(historiquesDetails, idcc) {
  const detail = historiquesDetails.get(idcc)
  return detail ? detail.description : ''
}

export const ConventionTable = ({
  conventions,
  title,
  onDelete,
  onEdit,
  publishMode,
  setPublishMode,
}) => {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('idcc')
  const [search, setSearch] = useState('')
  const [items, setItems] = useState(conventions)
  const [publishList, setPublishList] = useState(items.map(i => i.idcc))
  const historiquesDetails = useSelector(state => state.conventionModule.historiquesDetails)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const history = useHistory()
  const dispatch = useDispatch()

  const handleRequestSort = (event, property) => {
    if (orderBy === property) {
      setOrder(order === 'desc' ? 'asc' : 'desc')
    } else {
      setOrder('asc')
      setOrderBy(property)
    }
  }

  function handleItemClick(event, idcc) {
    history.push(`/detail-convention/${idcc}`)
  }

  function handleDeleteClick(event, idcc) {
    event.stopPropagation()
    onDelete(idcc)
  }

  function handleSearch(searchValue) {
    setSearch(searchValue)
    setPage(0)
  }

  function format(value) {
    if (value) {
      let result = value.toLowerCase()
      result = result.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      result = _.replace(result, '_', '')
      return result
    }
    return value
  }

  function filter(items) {
    return items.filter(item => {
      if (search) {
        return (
          format(item.idcc).includes(format(search)) ||
          format(item.titre).includes(format(search)) ||
          format(item.idccNational).includes(format(search)) ||
          format(item.idccRattachement).includes(format(search))
        )
      } else {
        return true
      }
    })
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filter(items).length - page * rowsPerPage)

  useEffect(() => {
    if (search && filter(items).length === 0) {
      dispatch(showAlert('error', 'aucune convention collective ne correspond à votre recherche'))
    }
  }, [items, search])

  useEffect(() => {
    setItems(conventions)
    if (publishMode) dispatch(loadPublishAllCC())
    if (publishMode) setPublishList(conventions.map(c => c.idcc))
  }, [publishMode])

  function handleCheckboxPublier(idcc) {
    setPublishList(publishList.includes(idcc) ? publishList.filter(p => p !== idcc) : [...publishList, idcc])
  }

  const handlePublishButton = list =>
    dispatch(
      showConfirmation(
        [
          'Attention, vous allez publier les données des conventions collectives ' +
            list.join(', ') +
            ', voulez-vous continuer ?',
        ],
        () =>
          Promise.resolve()
            .then(() => dispatch(publishMulti(publishList)))
            .then(() => setPublishMode(false))
      )
    )

  const onCancel = () => {
    dispatch(setAllCCHistoriquesDetails(new Map()))
    setPublishMode(false)
  }

  let sortedItems = stableSort(filter(items), getSorting(order, orderBy))
  return (
    <>
      {publishMode && (
        <Grid container justify="flex-end">
          <Grid>
            <ValidationButton
              style={{ marginBottom: 10, marginRight: 10 }}
              onClick={() => handlePublishButton(publishList)}
              data-test="publier"
            >
              {'PUBLIER'}
            </ValidationButton>
          </Grid>
          <Grid>
            <CancelButton style={{ marginBottom: 10 }} onClick={onCancel} data-test="ANNULER">
              {'ANNULER'}
            </CancelButton>
          </Grid>
        </Grid>
      )}
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          title={title}
          onSearch={searchValue => {
            handleSearch(searchValue)
          }}
        />
        <TableContainer>
          <Table className={classes.table} data-test="convention-table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={publishMode ? publishHeadCells : headCells}
            />
            <TableBody>
              {sortedItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                ({ id, idcc, idccNational, idccRattachement, personnalise, statut, titre, ouvert }, index) => (
                  <TableRow
                    hover
                    key={`row_${idcc}`}
                    data-test={`row_${idcc}`}
                    data-index={index}
                    onClick={event => publishMode || handleItemClick(event, idcc)}
                  >
                    {publishMode && (
                      <TableCell data-test={`publier_${idcc}`}>
                        <Checkbox
                          onClick={() => handleCheckboxPublier(idcc)}
                          className={classes.textField}
                          checked={publishList.includes(idcc)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </TableCell>
                    )}
                    <TableCell data-test={`idcc_${idcc}`}>{idcc}</TableCell>
                    <TableCell data-test={`titre_${idcc}`}>{titre}</TableCell>
                    <TableCell data-test={`idccNational_${idcc}`}>{idccNational}</TableCell>
                    <TableCell data-test={`idccRattachement_${idcc}`}>{idccRattachement}</TableCell>
                    <TableCell data-test={`statut_${idcc}`}>
                      <StatutItem statut={statut} isClosed={!ouvert}/>
                    </TableCell>
                    <TableCell data-test={`personnalise_${idcc}`}>
                      <Checkbox
                        disabled
                        className={classes.textField}
                        checked={personnalise}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </TableCell>
                    {publishMode && (
                      <>
                        <TableCell data-test={`themes_${idcc}`}>{getThemes(historiquesDetails, idcc)}</TableCell>
                        <TableCell data-test={`description_${idcc}`}>
                          {getDescription(historiquesDetails, idcc)}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <>
                        {publishMode || (
                          <>
                            {typeof onDelete === 'function' && (
                              <IconButton
                                className={classes.button}
                                aria-label="Delete"
                                data-test={`Delete_${idcc}`}
                                onClick={e => handleDeleteClick(e, idcc)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </>
                        )}
                      </>
                    </TableCell>
                  </TableRow>
                )
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelDisplayedRows={({count, page}) => "page "+(page + 1)+" sur "+(Math.ceil(count/rowsPerPage))}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortedItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage=""
          style={{backgroundColor: '#f5f5f5'}}
        />
      </Paper>
    </>
  )
}

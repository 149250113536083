import React,{useState} from 'react'
import * as PropTypes from 'prop-types'
import _ from 'lodash'

export default function InputNumeric({dataTest,dataKey,initialValue,onChange,placeholder=""}) {
    
    const patternRegexp=/^[0-9]*(\.)?[0-9]*$/
    const [inputValue,setInputValue] = useState(_.isNumber(initialValue) ? ""+initialValue : "")
    const [inputOldValue,setInputOldValue] = useState(_.isNumber(initialValue) ? ""+initialValue : "")
    
    const checkPattern = (value)=>{
        return value.match(patternRegexp)
    }

    const handleChange = (e) => {
        let input=e.target;
        if (checkPattern(input.value)) {
            setInputValue(input.value)
            if(onChange){
                onChange(e,input.value)
            }
        }
    }

    const filterInput = (e) => {
        let input=e.target;
        if (checkPattern(input.value)) {
            setInputOldValue(input.value);
            input.oldSelectionStart = input.selectionStart;
            input.oldSelectionEnd = input.selectionEnd;
          } else  {
            setInputValue(inputOldValue);
            input.setSelectionRange(input.oldSelectionStart, input.oldSelectionEnd);
            e.preventDefault()
          } 
    }
    return (
        <input 
            data-key={dataKey}
            data-test={dataTest}
            type="text" 
            value={inputValue}
            onInput={filterInput}
            onKeyDown={filterInput} 
            onKeyUp={filterInput}
            onMouseDown={filterInput}
            onMouseUp={filterInput}
            onSelect={filterInput}
            onContextMenu={filterInput}
            onDrop={filterInput}
            onChange={handleChange}
            placeholder={placeholder}/>
    )
}

InputNumeric.propTypes =  {
    placeholder:PropTypes.string,
    dataTestPrefix:PropTypes.string,
    initialValue:PropTypes.number,
    dataTestValue:PropTypes.string,
    dataKey:PropTypes.string,
    onChange:PropTypes.func
}
export class Version {
  nom
  datePublication
  statut
  id
  description=''
  constructor(nom, datePublication,statut,id) {
    this.nom = nom
    this.datePublication = datePublication
    this.statut = statut
    this.id=id
  }

  static NULL = new Version('','','','')
}



export class Type {
  name
  label

  constructor(name, label) {
    this.name = name
    this.label = label
  }

  static TEXT = new Type('TEXT', 'text')
  static NUMERIC = new Type('NUMERIC', 'numeric')
  static REFERENCE = new Type('REFERENCE', 'reference')
  static GROUP = new Type('GROUP', 'group')
  static GROUP_DIVISIBLE = new Type('GROUP_DIVISIBLE', 'group-divisible')
  static LIST = new Type('LIST', 'liste')
  static LIST_EXCLUSION = new Type('LIST_EXCLUSION', 'liste-exclusion')
  static DATE = new Type('DATE', 'date')
  static TYPE_THEME = new Type('TYPE_THEME', 'typeTheme')
  static NULL = new Type('', '')
}

export const ALL_TYPES = [
  Type.TEXT,
  Type.NUMERIC,
  Type.REFERENCE,
  Type.GROUP,
  Type.LIST,
  Type.LIST_EXCLUSION,
  Type.DATE,
  Type.TYPE_THEME,
  Type.NULL
]

export const ALL_TYPES_NO_TYPE_THEME = [
  Type.TEXT,
  Type.NUMERIC,
  Type.REFERENCE,
  Type.GROUP,
  Type.LIST,
  Type.LIST_EXCLUSION,
  Type.DATE,
  Type.NULL
]
export const getTypeFromString = (string = '') => {
  const toCompare = string.name ? string.name : string
  const type = ALL_TYPES.find(r => r.name === toCompare.toUpperCase())
  if (type) return type
  throw new Error('Le type ' + string + ' est inconnu')
}

export const getTypeFromLabel = (string = '') => {
  const toCompare = string.label ? string.label : string
  const type = ALL_TYPES.find(r => r.label === toCompare)
  if (type) return type
  throw new Error('Le type ' + string + ' est inconnu')
}

import React, { useEffect, useMemo, useState } from 'react'
import { Grid, TableBody, TableHead, TableRow } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../../../common/container-title'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Checkbox from '@material-ui/core/Checkbox'
import * as PropTypes from 'prop-types'
import { ModelFormField } from '../../../../store/constants/ModelFormField'
import { AddItemButtons } from '../../../conventions-collectives/themes/add-item-buttons'
import { TypeFormField } from '../../../../store/constants/TypeFormField'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import Link from '@material-ui/core/Link'
import { ModelFormPopperContent } from './model-form-popper-content'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

ModelFormPopperContent.propTypes = {
  className: PropTypes.any,
  tmpModel: PropTypes.any,
  onChange: PropTypes.func,
  callbackfn: PropTypes.func,
  onChange1: PropTypes.func,
  onChange2: PropTypes.func,
  onChange3: PropTypes.func,
}

function isNotCompleteWorkflow(tmpFormElement) {
  return (
    (tmpFormElement.typeForm === TypeFormField.WORKFLOW &&
      (!tmpFormElement.workflowRef || !tmpFormElement.workflowField)) ||
    (tmpFormElement.typeForm === TypeFormField.REFERENCE && !tmpFormElement.referentielName) ||
    (tmpFormElement.typeForm === TypeFormField.LIST && !tmpFormElement.referentielName) ||
    (tmpFormElement.typeForm === TypeFormField.LIST_EXCLUSION && !tmpFormElement.referentielName)
  )
}

export function ModelFormElements({ classes, tmpModel, setTmpModel }) {
  const [anchorEl, setAnchorEl] = useState()
  const [idxElement, setIdxElement] = useState(0)
  const [tmpFormElement, setTmpFormElement] = useState(ModelFormField.NULL)
  const [openPanel, setOpenPanel] = useState(false)

  useEffect(() => {
    if (!tmpModel.formFields || !tmpModel.formFields.length) {
      setOpenPanel(true)
    }
  }, [])
  const handleChange = (value, field) => {
    const newFormElement = { ...tmpFormElement }
    newFormElement[field] = value
    setTmpFormElement(newFormElement)
  }
  const handlePopoverOpen = (event, model, idx) => {
    setAnchorEl(event.currentTarget)
    setTmpFormElement(model ? { ...model } : ModelFormField.NULL)
    setIdxElement(idx)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'popin_Ajout_element_formulaire' : undefined

  function handleSave() {
    const newformFields = { ...tmpFormElement }
    let formFields = [...tmpModel.formFields]
    if (idxElement) formFields.splice(idxElement - 1, 1, newformFields)
    else formFields = [...tmpModel.formFields, newformFields]
    setTmpModel({ ...tmpModel, formFields })
    setAnchorEl(null)
    setIdxElement(0)
  }

  function handleCancel() {
    setAnchorEl(null)
  }

  const isSaveDisable = useMemo(
    () =>
      tmpFormElement === ModelFormField.NULL ||
      tmpFormElement.typeForm === TypeFormField.NULL ||
      isNotCompleteWorkflow(tmpFormElement),
    [tmpFormElement],
  )

  function deleteField(field) {
    setTmpModel({ ...tmpModel, formFields: tmpModel.formFields.filter(ff => ff.field !== field) })
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.contentRoot} elevation={1} data-test={'modele_table_Elements du formulaire'}>
        <Grid container direction='row' style={{ cursor: 'pointer' }} onClick={() => setOpenPanel(!openPanel)}  >

              {
                openPanel
                  ? <KeyboardArrowUp  fontSize='large'/>
                  : <KeyboardArrowDown fontSize='large' />
              }
              <ContainerTitle classes={classes} title='Elements du formulaire' />
        </Grid>
        {
          openPanel &&
          <>
            <Grid container direction='row' justify='flex-end'>
              <Grid item style={{ marginBottom: 16 }}>
                <Button data-test='Ajouter un element de formulaire'
                        color='primary'
                        variant='contained'
                        size='medium'
                        className={classes.margin}
                        onClick={handlePopoverOpen}>
                  NOUVEAU
                </Button>
              </Grid>
            </Grid>
            {!!tmpModel.formFields.length && (
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Champ</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Obligatoire</TableCell>
                    <TableCell>Referentiel</TableCell>
                    <TableCell>Workflow de Reference</TableCell>
                    <TableCell>Champ du Workflow</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tmpModel.formFields.map((model, idx) => {
                    const { field, required, typeForm, referentielName, workflowField, workflowRef } = model
                    return (
                      <TableRow key={field}>
                        <TableCell data-test={`formulaire_champ_${idx}`}>
                          <Link href='#' onClick={e => handlePopoverOpen(e, model, idx + 1)}>
                            {field}
                          </Link>
                        </TableCell>
                        <TableCell data-test={`formulaire_type_${idx}`}>{typeForm.description}</TableCell>
                        <TableCell>
                          <Checkbox
                            data-test={`formulaire_obligatoire_${idx}`}
                            value={required}
                            checked={required}
                            disabled
                          />
                        </TableCell>
                        <TableCell data-test={`formulaire_liste_ref_${idx}`}>{referentielName}</TableCell>
                        <TableCell data-test={`formulaire_workflow_ref_${idx}`}>{workflowRef}</TableCell>
                        <TableCell data-test={`formulaire_workflow_champ_${idx}`}>
                          {workflowField ? workflowField.field : ''}
                        </TableCell>
                        <TableCell>
                          <Link href='#' onClick={() => deleteField(field)}>
                            X
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            )}
          </>
        }
      </Paper>
      <Popover
        anchorEl={anchorEl}
        id={id}
        open={open}
        anchorReference='anchorPosition'
        anchorPosition={{ top: 270, left: 660 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Paper className={classes.popper} elevation={1}>
          <ContainerTitle variant='h5' classes={classes} title='Création élément formulaire' />
          <ModelFormPopperContent
            className={classes.formControl}
            handleChange={handleChange}
            tmpFormElement={tmpFormElement}
          />
          <Grid container direction='row' justify='flex-end'>
            <AddItemButtons
              onClick={() => {
              }}
              onSave={handleSave}
              onCancel={handleCancel}
              showList={true}
              addDataTest={''}
              cancelDataTest={'ANNULER ELEMENT FORMULAIRE'}
              saveDataTest={'AJOUTER ELEMENT FORMULAIRE'}
              isSaveDisabled={isSaveDisable}
            />
          </Grid>
        </Paper>
      </Popover>
    </Grid>
  )
}

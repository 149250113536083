import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'

import EnhancedTableBody from '../Table/EnhancedTableBody'
import EnhancedTableHead from '../Table/EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}))

const EnhancedTable = props => {
  const classes = useStyles()
  const { headCells, items, title, onDelete, onEdit, dataTest } = props
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('nom')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [filterItems, setFilterItems] = useState(items)

  useEffect(() => {
    setFilterItems(items)
  }, [setFilterItems, items])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilter = filter => {
    if (filter) {
      setFilterItems(items.filter(item => item.type === filter.value))
    } else {
      setFilterItems(items)
    }
  }

  return (
    <Paper className={classes.root}>
      <EnhancedTableToolbar
        title={title}
        headCells={headCells}
        onFilter={handleFilter}
      />
      <TableContainer>
        <Table className={classes.table} data-test={dataTest}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <EnhancedTableBody
            items={filterItems}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            page={page}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filterItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

EnhancedTable.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
    })
  ).isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default EnhancedTable

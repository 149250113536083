import React from 'react'
import { Statut } from '../../store/constants/historique'
import amber from '@material-ui/core/colors/amber'
import { blue, grey } from '@material-ui/core/colors'
import green from '@material-ui/core/colors/green'
import purple from '@material-ui/core/colors/purple'

export const DEFAULT_COLOR = grey[300]
export const EN_COURS_COLOR = amber[300]

export function getStatutColor(statut, isClosed) {
  if(isClosed) return "black"
  switch (statut) {
    case Statut.MODIFICATION_EN_COURS:
      return EN_COURS_COLOR
    case Statut.CREATION_EN_COURS:
      return purple[300]
    case Statut.VALIDE:
      return blue[300]
    case Statut.PUBLIE:
      return green[300]
    default:
      return DEFAULT_COLOR
  }
}

const FERME = "fermé".toUpperCase()

export const StatutItem = ({ statut, isClosed }) => {
  const style = {
    border: '7px double',
    textAlign: 'center',
    borderRadius: '10px',
    color: getStatutColor(statut, isClosed),
    padding: '3px 0px'
  }
  const statutUp = isClosed ? FERME : statut.label
  return <>{
    statut && statut !== Statut.NULL && <div data-test="historique_statut" style={style}>{statutUp}</div>
  }</>
}
export default class User {
  _email

  constructor(email) {
    this._email = email
  }

  get email() {
    return this._email
  }

  static NULL = new User('')
}

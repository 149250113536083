export class Adjuster {
  height1
  initialHeight1
  setHeight1
  height2
  initialHeight2
  setHeight2
  height3
  initialHeight3
  setHeight3
  height = {}
  static rowAdjuster = new Map()

  constructor(height, setHeight) {
    this.height1 = height
    this.setHeight1 = setHeight
    this.initialHeight1 = height
  }

  clearAdjuster() {
    this.height1 = this.initialHeight1
    this.height2 = this.initialHeight2
    this.height3 = this.initialHeight3
    this.setHeight1(this.initialHeight1)
    this.setHeight2(this.initialHeight2)
    this.setHeight3(this.initialHeight3)
  }

  static hasAllLoaded(){
    return this.rowAdjuster.size === 3
  }
}

import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Checkbox, Grid } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Component } from '../../../../../store/constants/component'
import Box from '@material-ui/core/Box'
import { Type } from '../../../../../store/constants/type'
import { FragmentTextPanel } from './fragment-text-panel'
import { AddItemButtons } from '../../../../conventions-collectives/themes/add-item-buttons'
import { isList } from '../../../../../store/constants/type-helper'
import { TextFragmentType } from '../../../../../store/constants/TextFragmentType'
import { TextBlock } from '../../../../../store/constants/TextBlock'
import { TextFragment } from '../../../../../store/constants/TextFragment'
import { TextBlockType } from '../../../../../store/constants/TextBlockType'
import { ContainerTitle } from '../../../../common/container-title'
import { AddCircle, RemoveCircle } from '@material-ui/icons'
import { ConditionalText } from '../../../../../store/constants/ConditionalText'
import { CompValues } from '../../../../../store/constants/CompValues'
import { NEW_CONDITION } from './cards-menu'
import { CancelButton } from '../../../../common/cancel-button'


const iconStyle = {
  color: '#9e9e9e',
  cursor: 'pointer',
  verticalAlign: 'middle',
  display: 'inline-block',
  height: 21,
  '&:hover': {
    color: '#616060',
  },
}

const addStyle = {
  ...iconStyle,
  color: '#3f51b5',
  '&:hover': {
    color: '#1a2f80',
  },
}

const deleteStyle = {
  ...iconStyle,
  marginLeft: 10,
}

export function hasIndex(index) {
  return index || index === 0
}

function splitAndGetParent(parent) {
  let split = parent.split('.')
  return split[split.length - 1]
}

export function BlockTextualisationPopperContent({
                                                   className,
                                                   handleChange,
                                                   formFields,
                                                   setTmpBlock,
                                                   parameters,
                                                   parent,
                                                   tmpBlocks,
                                                   index,
                                                   handleCancel,
                                                   handleSave,
                                                   childIndex,
                                                   grandChildIndex,
                                                   condition
                                                 }) {
  const storeComps = useSelector(state => state.componentModule.components)
  const [fragments, setfragments] = useState([])
  const [tmpOnlyUniq, setTmpOnlyUniq] = useState(false)
  const [tmpSeparable, setTmpSeparable] = useState(false)
  const [pluralFragments, setPluralFragments] = useState([])
  const [tmpConditional, setTmpConditional] = useState(ConditionalText.NULL)
  const [conditional, setConditional] = useState(ConditionalText.NULL)
  const [newComp, setNewComp] = useState(Component.NULL)
  const [newAutoComp, setAutoComp] = useState('')
  const [newtext, setNewText] = useState('')
  const allComp = useMemo(() => storeComps.filter( c => parameters && !parameters.find(p => p.component === c.nom) ), [parameters])
  const allCompChildren = useMemo(() => parent ? allComp.find(c => c.nom === splitAndGetParent(parent)).enfants : [], [allComp, parent])
  const allCompCFiltered = useMemo(() => fragments.length === 0 ? allComp : allComp.filter(c => c.type !== Type.GROUP), [fragments.length, allComp])
  const allCHildrenFiltered = useMemo(() => fragments.length === 0 ? allCompChildren : allCompChildren.filter(c => c.type !== Type.GROUP), [fragments.length, allCompChildren])


  useEffect(() => {
    if (hasIndex(index) && tmpBlocks.length) {
      if(hasIndex(childIndex) && parent){
        if(parent.split('.').length > 1 ){
          if(hasIndex(grandChildIndex)) {
            setTmpOnlyUniq(tmpBlocks[index].children[childIndex].children[grandChildIndex].uniqValueOnly)
            setTmpSeparable(tmpBlocks[index].children[childIndex].children[grandChildIndex].separable)
            if(condition || condition === 0){
              setfragments(condition === NEW_CONDITION ? [] : tmpBlocks[index].children[childIndex].children[grandChildIndex].conditionalTexts[condition].block.fragments)
              setPluralFragments(condition === NEW_CONDITION ? [] : tmpBlocks[index].children[childIndex].children[grandChildIndex].conditionalTexts[condition].block.pluralFragments)
            } else {
              setfragments(tmpBlocks[index].children[childIndex].children[grandChildIndex].fragments)
              setPluralFragments(tmpBlocks[index].children[childIndex].children[grandChildIndex].pluralFragments)
            }
            setConditional(tmpBlocks[index].children[childIndex].children[grandChildIndex].conditionalTexts)
            if((condition || condition === 0) && condition !== NEW_CONDITION) setTmpConditional(tmpBlocks[index].children[childIndex].children[grandChildIndex].conditionalTexts[condition])
          }
        } else {
          setTmpSeparable(tmpBlocks[index].children[childIndex].separable)
          setTmpOnlyUniq(tmpBlocks[index].children[childIndex].uniqValueOnly)
          if(condition || condition === 0){
            setfragments(condition === NEW_CONDITION ? [] : tmpBlocks[index].children[childIndex].conditionalTexts[condition].block.fragments)
            setPluralFragments(condition === NEW_CONDITION ? [] : tmpBlocks[index].children[childIndex].conditionalTexts[condition].block.pluralFragments)
          } else {
            setfragments(tmpBlocks[index].children[childIndex].fragments)
            setPluralFragments(tmpBlocks[index].children[childIndex].pluralFragments)
          }
          setConditional(tmpBlocks[index].children[childIndex].conditionalTexts)
          if((condition || condition === 0) && condition !== NEW_CONDITION) setTmpConditional(tmpBlocks[index].children[childIndex].conditionalTexts[condition])
        }
      } else if(!parent){
        setTmpSeparable(tmpBlocks[index].separable)
        setTmpOnlyUniq(tmpBlocks[index].uniqValueOnly)
        if(condition || condition === 0){
          setfragments(condition === NEW_CONDITION ? [] : tmpBlocks[index].conditionalTexts[condition].block.fragments)
          setPluralFragments(condition === NEW_CONDITION ? [] :tmpBlocks[index].conditionalTexts[condition].block.pluralFragments)
        } else {
          setfragments(tmpBlocks[index].fragments)
          setPluralFragments(tmpBlocks[index].pluralFragments)
        }
        setConditional(tmpBlocks[index].conditionalTexts)
        if((condition || condition === 0) && condition !== NEW_CONDITION) setTmpConditional(tmpBlocks[index].conditionalTexts[condition])
      }
    }
  }, [index, tmpBlocks, parent, childIndex, condition])

  const addText = () => {
    const res = new TextFragment(TextFragmentType.TEXT, newtext)
    setfragments([...fragments, res])
    if (pluralFragments.length) setPluralFragments([...pluralFragments, res])
    setNewText('')
  }

  const addComp = () => {
    const res = [...fragments, new TextFragment(newComp.type === Type.GROUP ? TextFragmentType.GROUP
      : isList(newComp.type)
        ? TextFragmentType.LIST : TextFragmentType.COMPONENT, '', newComp.nom)]
    setfragments(res)
    if (isList(newComp.type)) setPluralFragments([...res])
    else if (pluralFragments.length) setPluralFragments([])
    setNewComp(Component.NULL)
    setAutoComp('')
  }

  const handleChangeComp = (e, value) => {
    setAutoComp(value ? value.nom : '')
    setNewComp(value ? value : Component.NULL)
  }

  function getType() {
    if (!fragments.length) return TextBlockType.NULL
    else if (fragments[0].type === TextFragmentType.GROUP) return TextBlockType.GROUP
    else if (fragments.filter( f => f.type ===TextFragmentType.LIST).length) return TextBlockType.LIST
    else return TextBlockType.SIMPLE
  }

  function updateChild(tmpBlock, childIndex, conditionalTexts) {
    const children = tmpBlock.children
    if(!condition && condition !== 0) children.splice(childIndex, 1, new TextBlock(fragments, getType(), pluralFragments, tmpOnlyUniq, false, [], conditionalTexts))
    else children.splice(childIndex, 1, { ...tmpBlock.children[childIndex], conditionalTexts })
    return [...children]
  }

  function innerHandleSave(deleteCurrent) {
    let conditionalTexts
    if(condition === NEW_CONDITION) {
      conditionalTexts = [...conditional, { ...tmpConditional,  block: new TextBlock(fragments, getType(), pluralFragments, tmpOnlyUniq)}]
    }
    else if(condition || condition === 0) {
      conditionalTexts = [...conditional]
      if(deleteCurrent)conditionalTexts.splice(condition, 1)
      else conditionalTexts.splice(condition, 1, { ...tmpConditional,  block: new TextBlock(fragments, getType(), pluralFragments, tmpOnlyUniq)})
    } else conditionalTexts = conditional

  if (hasIndex(index)) {
      if (parent) {
        if (parent.split('.').length > 1) {
          let tmpChildren = tmpBlocks[index].children
          let tmpChildBlock = tmpChildren[childIndex]
          tmpChildBlock.children = hasIndex(grandChildIndex) ? updateChild(tmpChildBlock, grandChildIndex, conditionalTexts) : [...tmpChildBlock.children, new TextBlock(fragments, getType(), pluralFragments, tmpOnlyUniq)]
          tmpChildren.splice(childIndex, 1, tmpChildBlock)
          let tmpBlock = { ...tmpBlocks[index], children: [...tmpChildren], conditionalTexts }
          handleSave({
            ...tmpBlock
          }, index)
        } else {
          let tmpBlock = tmpBlocks[index]
          let children = hasIndex(childIndex) ? updateChild(tmpBlock, childIndex, conditionalTexts) : [...tmpBlock.children, new TextBlock(fragments, getType(), pluralFragments, tmpOnlyUniq)]
          handleSave({
            ...tmpBlock,
            children: children
          }, index)
        }
      } else {
        if(condition || condition === 0) handleSave({...tmpBlocks[index], conditionalTexts}, index)
        else if (fragments === tmpBlocks[index].fragments) {
          handleSave(new TextBlock(fragments, getType(), pluralFragments, tmpOnlyUniq, tmpSeparable, tmpBlocks[index].children, conditionalTexts), index)
        } else {
          handleSave(new TextBlock(fragments, getType(), pluralFragments, tmpOnlyUniq, tmpSeparable, [], conditionalTexts), index)
        }

      }
    } else {
      handleSave(new TextBlock(fragments, getType(), pluralFragments, tmpOnlyUniq, tmpSeparable))
    }
  }

  const isListBlock = useMemo(() => fragments.filter(f => f.type === TextFragmentType.LIST).length, [fragments])

  function setCompValueComp(e, idx) {
    let cps = [...tmpConditional.compValues]
    cps[idx] = {...cps[idx], component: e.target.value}
    return cps
  }
  function setCompValueValue(e, idx) {
    let cps = [...tmpConditional.compValues]
    cps[idx] = {...cps[idx], value : e.target.value}
    return cps
  }
  function setCompValueShouldRemove(idx) {
    let cps = [...tmpConditional.compValues]
    cps[idx] = {...cps[idx], shouldRemove : !cps[idx].shouldRemove}
    return cps
  }

  function addConditionComValue(){
    let cps = [...tmpConditional.compValues]
    cps.push(CompValues.NULL)
    setTmpConditional({...tmpConditional, compValues: cps})
  }

  function deleteCompValue(idx){
    let cps = [...tmpConditional.compValues]
    cps.splice(idx, 1)
    setTmpConditional({...tmpConditional, compValues: cps})
  }
  return <>
    <Grid container direction='row' style={{ marginTop: 15, marginBottom: 15 }} alignContent='center'>
      <Grid item xs={10}>
        <TextField label='Fragment de text'
                   variant='outlined'
                   rows={3}
                   value={newtext}
                   disabled={!!fragments.length && fragments[0].type === TextFragmentType.GROUP}
                   onChange={e => setNewText(e.target.value)}
                   style={{ width: '100%' }} />
      </Grid>
      <Grid item xs={2}>
        <Button color='primary'
                variant='contained'
                style={{ height: 55, width: 93 }}
                onClick={addText}
                disabled={!newtext.length || (!!fragments.length && fragments[0].type === TextFragmentType.GROUP)}
                size='medium'>Ajouter</Button>
      </Grid>
    </Grid>
    <Grid container direction='row' alignContent='center' style={{ marginBottom: 30 }}>
      <Grid item xs={10}>
        <Autocomplete
          data-test='ref_composant'
          options={parent ? [] : allCompCFiltered}
          getOptionLabel={c => c.nom}
          style={{ width: '100%' }}
          onChange={handleChangeComp}
          inputValue={parent ? parent : newAutoComp}
          disabled={!!fragments.length
          && !!fragments.filter(f => [TextFragmentType.GROUP, TextFragmentType.COMPONENT].includes(f.type)).length
          }
          renderInput={params =>
            <TextField {...params}
                       value={params.inputProps.value}
                       label='Fragment de composant'
                       variant='outlined'
                       onChange={e => setAutoComp(e.target.value)} />}
        />
      </Grid>
      <Grid item xs={2}>
        <Button color='primary'
                variant='contained'
                style={{ height: 55, width: 93 }}
                onClick={addComp}
                disabled={!!parent || newComp === Component.NULL}
                size='medium'>Ajouter</Button>
      </Grid>
    </Grid>
    {parent &&
    <Grid container direction='row' alignContent='center' style={{ marginBottom: 30 }}>
      <Grid item xs={10}>
        <Autocomplete
          data-test='ref_composant'
          options={allCHildrenFiltered}
          getOptionLabel={c => c.nom}
          style={{ width: '100%' }}
          onChange={handleChangeComp}
          inputValue={newAutoComp}
          disabled={
            fragments.length
            && !!fragments.filter(f => [TextFragmentType.GROUP, TextFragmentType.COMPONENT].includes(f.type)).length
          }
          renderInput={params =>
            <TextField {...params}
                       value={params.inputProps.value}
                       label='Fragment de composant'
                       variant='outlined'
                       onChange={e => setAutoComp(e.target.value)} />}
        />
      </Grid>
      <Grid item xs={2}>
        <Button color='primary'
                variant='contained'
                style={{ height: 55, width: 93 }}
                onClick={addComp}
                disabled={newComp === Component.NULL}
                size='medium'>Ajouter</Button>
      </Grid>
    </Grid>
    }
    <Grid>
      <Checkbox checked={tmpOnlyUniq} disabled={!!condition || condition === 0} onClick={() => setTmpOnlyUniq(!tmpOnlyUniq)} />
      valeur unique seulement
    </Grid>
    {!!parent || <Grid>
      <Checkbox checked={tmpSeparable} disabled={!!condition || condition === 0} onClick={() => setTmpSeparable(!tmpSeparable)} />
      composant séparateur
    </Grid>}
    <Grid item xs={12}>
      <Box display='grid' gap={2}>
        <FragmentTextPanel fragments={fragments} setFragments={setfragments} />
      </Box>
    </Grid>
    {
      !!isListBlock &&
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <Box display='grid' gap={2}>
          <FragmentTextPanel fragments={pluralFragments} setFragments={setPluralFragments} isPlural />
        </Box>
      </Grid>
    }
    {
      (condition || condition === 0 )  && <Grid container style={{ marginTop: 20, marginBottom: 10}}>
        <Grid item xs={6}>
          <Grid container direction="row">
            <Grid item xs={10}><ContainerTitle title='Conditions du block' /></Grid>
            <Grid item xs={2}><span style={addStyle} onClick={() => addConditionComValue()}><AddCircle fontSize='small' /></span></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
            <TextField label='Nom de la condition'
                       variant='outlined'
                       rows={3}
                       value={tmpConditional.name}
                       onChange={e => setTmpConditional({...tmpConditional, name : e.target.value})}
                       style={{ width: '100%', marginBottom: 10, marginTop: 10 }} />
        </Grid>
        <Grid container direction="row"  spacing={4}>
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={2}>
            <Grid container alignItems="stretch" alignContent="stretch">
              Vider
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container alignItems="stretch" alignContent="stretch">
              Ignorer
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row"  spacing={4}>
          <Grid item xs={8}>
            <TextField label='Valeur du composant'
                       variant='outlined'
                       rows={3}
                       disabled={!tmpConditional.readCurrentValue}
                       value={tmpConditional.currentValue}
                       onChange={e => setTmpConditional({ ...tmpConditional, currentValue : e.target.value })}
                       style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={2}>
            <Grid container alignItems="stretch" alignContent="stretch">
              <Checkbox checked={tmpConditional.shouldRemove} onClick={() => setTmpConditional({ ...tmpConditional, shouldRemove: !tmpConditional.shouldRemove })} />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container alignItems="stretch" alignContent="stretch">
              <Checkbox checked={!tmpConditional.readCurrentValue} onClick={() => setTmpConditional({ ...tmpConditional, readCurrentValue: !tmpConditional.readCurrentValue,  currentValue: ''})} />
            </Grid>
          </Grid>
        </Grid>
        {tmpConditional.compValues.map((compValue, idx) =>
            <Grid container direction="row"  spacing={4} style={{marginTop: 10}} key={idx}>
            <Grid item xs={4}>
              <TextField label='composant'
                         variant='outlined'
                         rows={3}
                         value={tmpConditional.compValues[idx].component}
                         onChange={e => setTmpConditional({...tmpConditional, compValues: setCompValueComp(e, idx) })}
                         style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={4}>
              <TextField label='Valeur'
                         variant='outlined'
                         rows={3}
                         value={tmpConditional.compValues[idx].value}
                         onChange={e => setTmpConditional({...tmpConditional, compValues: setCompValueValue(e, idx) })}
                         style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={2}>
              <Grid container alignItems="stretch" alignContent="stretch">
                <Checkbox hei checked={tmpConditional.compValues[idx].shouldRemove} onClick={() => setTmpConditional({...tmpConditional, compValues: setCompValueShouldRemove(idx)})} />
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container alignItems="stretch" alignContent="stretch">
                <RemoveCircle style={deleteStyle} onClick={() => deleteCompValue(idx)}/>
              </Grid>
            </Grid>
          </Grid>)
        }
      </Grid>



    }
    {  (condition || condition === 0 ) && condition !== NEW_CONDITION
      ? <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid xs={6}>
            <CancelButton
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => innerHandleSave(true)}
              //className={classes.margin}
            >
              SUPPRIMER
            </CancelButton>
          </Grid>
          <Grid xs={6}>
            <Grid container direction="row" justifyContent="flex-end">
              <AddItemButtons
                onSave={() => innerHandleSave()}
                onCancel={handleCancel}
                showList={true}
                addDataTest=''
                cancelDataTest={'ANNULER'}
                saveDataTest={'AJOUTER'}
                isSaveDisabled={!fragments.length}
              />
            </Grid>
          </Grid>

        </Grid>
      : <AddItemButtons
      onSave={innerHandleSave}
      onCancel={handleCancel}
      showList={true}
      addDataTest=''
      cancelDataTest={'ANNULER'}
      saveDataTest={'AJOUTER'}
      isSaveDisabled={!fragments.length || ((condition || condition === 0) && !tmpConditional.name)}
    />}
  </>
}


/***
 *
 *
 *

 fragment.type === TextFragmentType.GROUP && fragment.children.length
 ? <Paper style={{ backgroundColor: '#ccd4ff80' }}>
 <Grid container direction='row'>
 <span style={{ color: '#e91e63', textAlign: 'center', margin: 5, }}>{[fragment.blocks]}</span>
 {fragment.children.map((f, j) =>
                        <Grid key={j}>
                          <Item>
                        <span
                          style={{ color: f.type === TextFragmentType.TEXT ? '#9fa8da' : '#e91e63' }}>{[f.blocks]}</span>
                          </Item>
                        </Grid>,
                      )}
 </Grid>
 </Paper>
 :
 *
 */
export class TransformationRule {
  id
  type
  component
  component2
  creationDate
  oldRef
  newRef
  newVersion
  oldVersion
  correspondances
  otherComponents
  themes
  isTarget
  newRefvaluesParent
  oldRefvaluesParent

  constructor(
    id,
    type = TransformationType.NULL,
    creationDate,
    component = '',
    component2 = '',
    oldRef = '',
    newRef = '',
    newVersion = '',
    oldVersion = '',
    correspondances = [],
    otherComponents = [],
    themes = [],
    isTarget= false,
    newRefvaluesParent = new Map(),
    oldRefvaluesParent = new Map()
  ) {
    this.id = id
    this.type = type
    this.component = component
    this.component2 = component2
    this.creationDate = creationDate
    this.oldRef = oldRef
    this.newRef = newRef
    this.newVersion = newVersion
    this.oldVersion = oldVersion
    this.correspondances = correspondances
    this.otherComponents = otherComponents
    this.themes = themes
    this.isTarget = isTarget
    this.newRefvaluesParent = newRefvaluesParent
    this.oldRefvaluesParent = oldRefvaluesParent
  }
}

export class TransformationType {
  name
  description

  constructor(name, description) {
    this.name = name
    this.description = description
  }

  static CHANGEMENT_REF_ASSOCIE = new TransformationType('CHANGEMENT_REF_ASSOCIE', 'Changement du référentiel associé')
  static SPLIT_REFERENTIEL = new TransformationType('SPLIT_REFERENTIEL', 'split du référentiel en deux')
  static NULL = new TransformationType('', '')
  static ALL = [
    TransformationType.NULL,
    TransformationType.CHANGEMENT_REF_ASSOCIE,
    TransformationType.SPLIT_REFERENTIEL,
  ]
  static fromStr = str => (str ? this.ALL.find(t => t.name.toUpperCase() === str) : str)
}

export class RefValueTransformation {
  oldRefvalueCode
  newRefvalueCode

  constructor(oldRefvalueCode, newRefvalueCode) {
    this.oldRefvalueCode = oldRefvalueCode
    this.newRefvalueCode = newRefvalueCode
  }

  addValue(oldRefvalue, comp) {
    const s = comp+':'
    if(this.oldRefvalueCode.includes(s)) this.oldRefvalueCode = this.oldRefvalueCode.split(';').filter(v => !v.startsWith(s)).join(';')
    this.oldRefvalueCode = this.oldRefvalueCode.length ? this.oldRefvalueCode+';'+s+oldRefvalue :  s+oldRefvalue
  }
}

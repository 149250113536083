import { useSelector } from 'react-redux'

export const useInputsTheme = () => {
  const {
    inputs,
    outputs,
  } = useSelector(state => state.conventionModule)
  const {
    allInputs,
    allOutputs,
  } = useSelector(state => ({
    allInputs : state.themeModule.inputs.filter(f => !inputs.map(i => i.nom).includes(f.nom)),
    allOutputs : state.themeModule.outputs.filter(f => !outputs.map(i => i.nom).includes(f.nom)),
  }) )
  return [inputs, outputs, allInputs, allOutputs]
}

import { payeService as payeServiceApi } from './paye-service-api'
import { payeService as payeServiceDB } from './paye-service-db'
import store from '../store/store'
import { showAlert } from '../store/actions/alert-action'
import { MESSAGES_ENDPOINTS, setFileNameIfNeeded } from './messages-endpoints'
import { HELPER_ENDPOINTS } from './endpoints'
import {
  handleError,
  selectStatus,
  showAndReturnHandledError,
  showAndReturnStandardError,
  showAndReturnUnknownError,
} from './handler-helper'

export const isApiConfig = ()=>{
  const result= localStorage.getItem("isApiConfig") !== "false"
  return result
}

const handler = mode => {
  return {
    get: function(obj, serviceName) {
      if (!(serviceName in obj)) {
        return async () => {
          store.dispatch(showAlert('error', 'Fonctionnalité ' + serviceName + ' non supportée en mode ' + mode))
          return Promise.reject('Not supported')
        }
      }
      return async (...args) => {
        let argsCopy = []
        let endpoint = {}
        args.forEach(function(arg) {
          if (arg && arg.endpoint) {
            endpoint = { ...MESSAGES_ENDPOINTS, ...HELPER_ENDPOINTS, ...arg }
            argsCopy.push(arg.endpoint)
          } else {
            argsCopy.push(arg)
          }
        })
        let defaultStatus = MESSAGES_ENDPOINTS.http.status
        let methodStatus = getMethodStatus(endpoint, serviceName)
        return obj[serviceName]
          .apply(obj, argsCopy)
          .then(response => {
            if (!response) {
              return null
            }
            const status = selectStatus(methodStatus, response, defaultStatus)
            if (status && status['' + response.status] && status['' + response.status].message) {
              let httpHandler = status['' + response.status]
              store.dispatch(
                showAlert(
                  httpHandler.severity || 'success',
                  setFileNameIfNeeded(httpHandler, status, response, endpoint, args)
                )
              )
            }
            return response.data
          })
          .catch(error => {
            console.log(error)
            if(error.status === 403 && error.error && error.error.errorType)
              return Promise.reject(error.error)
            if(error.status === 400){
              return Promise.reject(error.error)
            }
            if(error.status === '000'){
              return Promise.reject(error.error)
            }
            if(error.status === 'DOUBLONS'){
              return Promise.reject(error)
            }
            if (!error.status) {
              console.trace()
              return showAndReturnUnknownError(error)
            }
            const status = selectStatus(methodStatus, error, defaultStatus)
            let handledError = handleError(status, error, endpoint)
            return handledError
              ? showAndReturnHandledError(handledError, endpoint, error)
              : showAndReturnStandardError(error)
          })
      }
    },
  }
}

export const methodService = {
  get: 'GET',
  getChildren: 'GET',
  getById: 'GET_UNIQ',
  post: 'POST',
  postChildren: 'POST',
  put: 'PUT',
  deleteById: 'DELETE',
  deleteChild: 'DELETE',
  clear: 'DELETE',
  patch: 'PATCH',
  patchChild: 'PATCH',
  getGrandchildren: 'GET',
  getGrandchild: 'GET_UNIQ',
  getGrandFather: 'GET',
  postFile: 'POST_FILE',
  patchFile: 'PATCH_FILE'
}

const API_PROXI = new Proxy(payeServiceApi, handler('connecté'))
const DB_PROXY = new Proxy(payeServiceDB, handler('déconnecté'))

export default function getPayeService() {
  return isApiConfig() ? API_PROXI : DB_PROXY
}

export function setMethodStatus(endpoint,prop,status){
  if(!endpoint.http){
    endpoint.http={method:{}}
  }
  if(!endpoint.http.method){
    endpoint.http.method={}
  }
  if(!endpoint.http.method[methodService[prop]]){
    endpoint.http.method[methodService[prop]]={
      status:status
    }
  }else{
    endpoint.http.method[methodService[prop]].status=status
  }
}

export function getMethodStatus(endpoint, prop) {
  return (
    endpoint.http &&
    endpoint.http.method &&
    endpoint.http.method[methodService[prop]] &&
    endpoint.http.method[methodService[prop]].status
  )
}

import { setLoader } from './loader-action'
import getPayeService from '../../api/envService'
import { SETTINGS_ENDPOINT } from '../../api/endpoints'
import { SET_API_MODE } from '../reducers/api-mode-reducer'
import { showAlert } from './alert-action'

const API_MODE = 'api-mode'

function setApiMode(apiModeValue) {
  return { type: SET_API_MODE, value: apiModeValue }
}

function toApiMode(response) {
  return response.value === 'ENABLE'
}

export const getApiMode = () => dispatch => {
  dispatch(setLoader(true))
  return getPayeService()
    .getById(SETTINGS_ENDPOINT.endpoint, API_MODE)
    .then(response => toApiMode(response))
    .then(apiMode => dispatch(setApiMode(apiMode)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const updateApiMode = mode => dispatch => {
  dispatch(setLoader(true))
  return getPayeService()
    .put(SETTINGS_ENDPOINT.endpoint, { mode }, API_MODE)
    .then(() => dispatch(getApiMode()))
    .then(() => dispatch(showAlert('success', 'Les services conventions collectives ont été ' + (mode ? 'activées' : 'désactivées'))))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

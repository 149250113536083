import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import * as PropTypes from 'prop-types'
import React from 'react'

export function CheckPersonnalise({
  classeName,
  personnalise,
  onChange,
  disabled = false,
}) {
  return (
    <Grid item xs={2}>
      <FormControlLabel
        control={
          <Checkbox
            data-test="personnalise_checkbox"
            className={classeName}
            value={personnalise}
            color="primary"
            onChange={onChange}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={disabled}
            checked={personnalise}
          />
        }
        label="Personnalisé"
      />
    </Grid>
  )
}

CheckPersonnalise.propTypes = {
  className: PropTypes.string,
  personnalise: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

import React,{useEffect,useRef} from 'react'
import { makeStyles } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import { Grid } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import grey from '@material-ui/core/colors/grey';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    container : {
      maxHeight : 440,
      '& thead tr' : {
        height: "4.1em",
        '& th': {
          top: "calc(var(--nbLine,0)*4.1em)",
          position:"sticky",
          background:grey[200]
        }
      }
    },
    conventionItem: {
      paddingTop: 14,
      paddingBottom: 10,
      borderBottom: `1px solid #dadde7`,
    }
  }))

export function withTable(ActionsBar,HeadersComponent,RowsComponent) {

  let Component=(props) => {
    const tbodyEl=useRef(null)
    const containerEl=useRef(null)
    useEffect(()=>{
      if(tbodyEl.current && props.rowsEditMode && props.rowsEditMode.length > 0){
        let line=tbodyEl.current.querySelector(`[data-row="${props.rowsEditMode[0]}"]`)
        let size=0
        containerEl.current.querySelectorAll(`thead tr`).forEach(item=>{
          size=size+item.offsetHeight 
        })
        line.scrollIntoView(true)
        if((line.offsetTop-containerEl.current.scrollTop) < size){
         containerEl.current.scrollBy(0,-size)
        }
      }
    },[props.rowsEditMode])

    const classes = useStyles()
    return (
      <Grid container direction="column" justify="flex-start" alignItems="stretch">
        <ActionsBar {...props}/>
        <Grid item xs={12}>
          <Paper>
              <TableContainer className={classes.container} ref={containerEl}>
                <Table className={classes.table}>
                  <TableHead>
                    <HeadersComponent {...props}/>
                  </TableHead>
                  <TableBody ref={tbodyEl}>
                        <RowsComponent {...props}/>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
    )
  }
  Component.propTypes = {
  }

  return Component

}

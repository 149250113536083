import React from 'react'

export function TableurCellSimpleList({ values, prefix = '' }) {

  return <>
    {values.map((v, idx) =>
      <div key={v} data-test={`${prefix}_${idx}`}>
        {v}
      </div>)
    }
  </>
}

import { isReferentiel, isSimpleType } from '../../../../../../../store/constants/type-helper'
import { TableurCellReferentiel } from '../Referentiel/tableur-cell-referentiel'
import * as PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import { useStyles } from '../../../../../../Inputs/Inputs'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { getDataIdFromrState } from '../../action-row'

function getAllParent(data, split, i) {
  if (i === split.length) return data
  return getAllParent(data[split[i]], split, i + 1)
}

function getParentValue(data, role, component) {
  const split = component.parentPath.split('.')
  return getAllParent(data[role], split, 0)
}

function getOtherListValues(storedTmpData, role, component, otherExclude, data) {
  const split = component.parentPath.split('.')
  return storedTmpData
    ? storedTmpData[otherExclude]
    : data && data[role] && hasAllNeedParents(data[role], split) && getAllParent(data[role], split, 0)[otherExclude]
      ? getAllParent(data[role], split, 0)[otherExclude]
      : []
}

function hasAllNeedParents(data, split, i = 0) {
  if (i === split.length) return true
  const splitElement = split[i]
  return data[splitElement] && hasAllNeedParents(data[splitElement], split, i + 1)
}

function hasLineData(lineData, role, component) {
  const split = component.parentPath.split('.')
  return (
    lineData && lineData[role] && hasAllNeedParents(lineData[role], split) && getAllParent(lineData[role], split, 0)
  )
}

function dataSelector(line) {
  return state => {
    const dataId = getDataIdFromrState(state, line)
    return state.tableurModule.data.find(d => dataId === d.id + '_' + d.idCC)
  }
}

function tmpDataSelector(line, role, component) {
  return state => {
    const dataId = getDataIdFromrState(state, line)
    const lineData = state.tableurModule.tmpData.get(dataId)
    if (hasLineData(lineData, role, component)) return getParentValue(lineData, role, component)
  }
}

function referentielSelector(component) {
  return state => state.tableurModule.referentiels.find(r => r.nom === component.referentiel)
}

export function setRefListToolTip(tooltipContent, values, referentielValues, setWithTooltip) {
  if (tooltipContent && values && (values.length > 5 || referentielValues.filter(rv => rv.libelle.length >= 30).length)) {
    tooltipContent(<div>{referentielValues.map(v => <div key={v.code}>`{v.code} - {v.libelle}`</div>)}</div>)
    setWithTooltip(true)
  }
}

export function TableurCellUnitOfListExclusion({
                                                 compValue,
                                                 component,
                                                 editDataTest,
                                                 line,
                                                 cellEditMode,
                                                 lineEditMode,
                                                 setTmpData,
                                                 role,
                                                 tooltipContent,
                                               }) {
  const classes = useStyles()
  const [tmpValues, setTmpValues] = useState(compValue ? compValue : [])
  const exclude = component.exclude ? 'exclus' : 'inclus'
  const otherExclude = !component.exclude ? 'exclus' : 'inclus'
  const storedTmpData = useSelector(tmpDataSelector(line, role, component), shallowEqual)
  const data = useSelector(dataSelector(line), _.isEqual)
  const referentiel = useSelector(referentielSelector(component))
  const [referentielValues, setReferentielValues] = useState([])
  const [otherListNotEmpty, setOtherListNotEmpty] = useState(false)
  const [withTooltip, setWithTooltip] = useState(false)


  function handleChange({ target: { value } }) {
    setTmpValues(value)
    setTmpData(component.nom, value, component)
  }

  useEffect(() => {
    let newVar = compValue ? compValue : []
    setTmpValues(newVar)
  }, [compValue, cellEditMode])

  useEffect(() => {
    if (component.type && isReferentiel(component.type) && referentiel) {
      const otherListValues = getOtherListValues(storedTmpData, role, component, otherExclude, data)
      let res = []
      if (!otherListValues || (!otherListValues.length && referentiel)) res = referentiel.valeurs
      else
        referentiel.valeurs.forEach(v => {
          if (!otherListValues.includes(v.code)) res.push(v)
        })
      setReferentielValues(res)
      setOtherListNotEmpty(otherListValues ? otherListValues.length : true)
    }
  }, [storedTmpData, referentiel, exclude])

  const tmpExcpludesOrIncludes = Array.isArray(tmpValues) ? tmpValues : tmpValues[exclude] || []

  useEffect(() => {
    const hasDataForTooltip = referentielValues && tmpExcpludesOrIncludes && referentielValues.length && tmpExcpludesOrIncludes.length
    if (hasDataForTooltip && tooltipContent && tmpExcpludesOrIncludes && (tmpExcpludesOrIncludes.length > 5 || referentielValues.filter(rv => tmpExcpludesOrIncludes.includes(rv.code) && rv.libelle.length > 30).length)) {
      tooltipContent(<>
        {
          referentielValues
            .filter(rv => tmpExcpludesOrIncludes.includes(rv.code))
            .map(v => <div key={v.code}>{v.code} - {v.libelle}</div>)
        }
      </>)
      setWithTooltip(true)
    } else if (withTooltip) {
      setWithTooltip(false)
      tooltipContent && tooltipContent()
    }
  }, [tmpExcpludesOrIncludes, referentielValues])

  return (
    <div>
      {(cellEditMode || lineEditMode) && (
        <>
          {tmpExcpludesOrIncludes && Array.isArray(tmpExcpludesOrIncludes) && (
            <Select
              multiple
              disabled={otherListNotEmpty}
              displayEmpty
              label={component.label}
              input={<Input />}
              value={tmpExcpludesOrIncludes}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
              data-test={`${editDataTest}_edit`}
            >
              {isReferentiel(component.type)
                ? referentielValues.map(value => (
                  <MenuItem key={value.code} value={value.code}>
                    <Checkbox checked={!!tmpExcpludesOrIncludes.find(v => value.code === v)} />
                    <ListItemText primary={value.code + ' - ' + value.libelle} />
                  </MenuItem>
                ))
                : tmpExcpludesOrIncludes.map(value => (
                  <MenuItem key={value} value={value}>
                    <Checkbox checked={true} />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
            </Select>
          )}
        </>
      )}
      {!lineEditMode && !cellEditMode && (
        <>
          <div>
            {isReferentiel(component.type) &&
            tmpExcpludesOrIncludes &&
            tmpExcpludesOrIncludes.map((v, idx) => (
              <div key={v}>
                {
                  withTooltip && idx > 3
                    ? idx === 4 ? '...' : ''
                    : <TableurCellReferentiel code={v}
                                              nom={component.referentiel}
                                              component={component}
                                              line={line} />
                }
              </div>
            ))}
            {isSimpleType(component.type) &&
            tmpExcpludesOrIncludes &&
            tmpExcpludesOrIncludes.map(v => <div key={v}>{v}</div>)}
          </div>
        </>
      )}
    </div>
  )
}

TableurCellUnitOfListExclusion.propTypes = {
  compValue: PropTypes.any,
  component: PropTypes.any,
}

import React, { useEffect, useState } from 'react'
import { Type } from '../../../../../../store/constants/type'
import TextField from '@material-ui/core/TextField'
import { isDate, isText } from '../../../../../../store/constants/type-helper'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useSelector } from 'react-redux'

export function getFormatedDate(strDate) {
  if (!strDate) return strDate
  else {
    const targetDate = strDate.split('/')
    if (targetDate.length !== 3) return strDate
    return targetDate[2] + '-' + targetDate[1] + '-' + targetDate[0]
  }
}

export function getDateToSave(strDate) {
  if (!strDate) return strDate
  else {
    const targetDate = strDate.split('-')
    if (targetDate.length !== 3) return strDate
    return targetDate[2] + '/' + targetDate[1] + '/' + targetDate[0]
  }
}

export function getTrunkedTextValue(tmpValue) {
  return exceedMaxChar(tmpValue) ? tmpValue.substring(0, 31) + '...' : tmpValue
}

export function exceedMaxChar(text) {
  return text && text.length > 30
}

export function SimpleCellType({
  component,
  compValue,
  tooltipContent,
  cellEditMode,
  lineEditMode,
  editValue,
  editDataTest,
  setTmpData,
  role,
}) {
  const [tmpValue, setTmpValue] = useState(cellEditMode && !lineEditMode ? editValue : compValue)
  const legalOutputs = useSelector(state => state.conventionModule.legalOutputs)

  useEffect(() => {
    setTmpValue(cellEditMode ? editValue : compValue)
  }, [editValue, compValue, cellEditMode])

  useEffect(() => {
    if (lineEditMode) setTmpValue(compValue)
  }, [lineEditMode])

  useEffect(() => {
    {
      tooltipContent(component.type.name === Type.TEXT.name && exceedMaxChar(compValue) ? <div>{compValue}</div> : '')
    }
  }, [compValue])

  function handleChange({ target: { value } }) {
    const toSave = isDate(component.type) ? getDateToSave(value) : value
    setTmpValue(toSave)
    setTmpData(component.nom, toSave, component)
  }

  function handleClickLegal() {
    setTmpValue('#LEGAL')
    setTmpData(component.nom, '#LEGAL', component)
  }

  const tmpDate = component.type === Type.DATE ? getFormatedDate(tmpValue) : ''
  function getBtnLegal() {
    return role === 'outputs' &&
      legalOutputs.find(comp => comp.nom === component.nom || comp.nom === component.parentPath) ? (
      <MoreVertIcon fontSize={'small'} onClick={handleClickLegal} data-test="LEGAL" />
    ) : (
      <></>
    )
  }

  return (
    <>
      {!cellEditMode ? (
        isText(component.type) ? (
          getTrunkedTextValue(tmpValue)
        ) : (
          tmpValue
        )
      ) : component.type.name === Type.DATE.name ? (
        <>
          {
            <TextField
              label={component.label}
              data-test={editDataTest + '_edit'}
              disabled={!lineEditMode}
              type="date"
              value={tmpDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          }
        </>
      ) : (
        <>
          {component.type.name === Type.NUMERIC.name && tmpValue !== '#LEGAL' ? (
            <TextField
              data-test={editDataTest + '_edit'}
              type={'number'}
              value={tmpValue}
              onChange={handleChange}
              disabled={!lineEditMode}
              label={component.label}
              onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              InputProps={{
                startAdornment: getBtnLegal(),
              }}
            />
          ) : (
            <TextField
              data-test={editDataTest + '_edit'}
              type={'text'}
              value={tmpValue}
              disabled={!lineEditMode}
              onChange={handleChange}
              label={component.label}
              InputProps={{
                startAdornment: getBtnLegal(),
              }}
            />
          )}
        </>
      )}
    </>
  )
}

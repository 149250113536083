
import React from 'react'
import * as PropTypes from 'prop-types'
import { makeStyles,TableRow } from '@material-ui/core'
import {Type} from '../../store/constants/type'
import Header from '../common/table/editable/renderer/header'
import ReferenceEdit from './component-reference-edit'
import _ from "lodash"

  const flat = (array)=>{
    let result=0
    array.forEach(val=>result=result+val)
    return result
  }
  const calcLevel = (comp)=>{
    let subGroups=comp.enfants.filter(child=>child.type===Type.GROUP)
    return subGroups.length+flat(subGroups.map(group=>calcLevel(group)))
  }


  const useStyles = makeStyles((theme) => ({
    nowrap : {
      "white-space" : "nowrap"
    }
  }))


function GroupHeader({lineComponent,childrenReferentiel,popinChild,onSavePopinChild,onClosePopinChild,nbLevel,popinAnchorEl}){
    const classes=useStyles()

    return lineComponent.enfants ? lineComponent.enfants.map((enfant,index)=>{
      const rowSpan=enfant.type === Type.GROUP ? 1 : (nbLevel+1) 
      const colSpan=enfant.type === Type.GROUP ? enfant.enfants.length+1 : 1
      return (enfant.type===Type.REFERENCE && 
          (<Header key={index} rowSpan={rowSpan} colSpan={colSpan}>
              <span className={classes.nowrap}>
                  {enfant.nom}
              </span>
              <div>
                    <ReferenceEdit 
                      component={enfant} 
                      referentielId={childrenReferentiel[enfant.nom].id}
                      refValues={childrenReferentiel[enfant.nom].values} 
                      open={_.isEqual(popinChild,enfant)}
                      onSave={onSavePopinChild}
                      onClose={onClosePopinChild}
                      popinAnchorEl={popinAnchorEl}/>
                  </div>
            </Header>
            )
        ) 
        || (<Header key={index}  rowSpan={rowSpan} colSpan={colSpan}>{enfant.nom}</Header>
          )
      }
    ) : (<></>)
}
GroupHeader.propTypes = {
  lineComponent:PropTypes.any.isRequired,
  childrenReferentiel:PropTypes.any.isRequired,
  popinChild:PropTypes.any,
  nbLevel:PropTypes.number.isRequired,
  onClosePopinChild:PropTypes.func.isRequired,
  popinAnchorEl:PropTypes.any.isRequired
}

export function GroupValuesHeaders({component,childrenReferentiel,popinChild,onSavePopinChild,onClosePopinChild,popinAnchorEl}){
  let nbTotalLevel=calcLevel(component)
  let rows=[]
  let components=[]
  for(let level=0;level<=nbTotalLevel;level++){
    let nbLevel=nbTotalLevel-level
    if(level===0){
      rows.push((<TableRow key={level} style={{"--nbLine":level}}>
          <Header key={"description"} rowSpan={nbLevel+1}>description</Header>
          <GroupHeader 
            childrenReferentiel={childrenReferentiel}
            lineComponent={component}
            popinChild={popinChild}
            onClosePopinChild={onClosePopinChild}
            onSavePopinChild={onSavePopinChild}
            nbLevel={nbLevel}
            popinAnchorEl={popinAnchorEl}
          />
          <Header key={"actions"} rowSpan={nbLevel+1}>actions</Header>
        </TableRow>))
        let newComponents=[]
        component.enfants.filter(enfant=>enfant.type===Type.GROUP).forEach(enfant=>{
          newComponents.push(enfant)
        })
        components=newComponents
    }else{
      let newComponents=[]
      components.forEach(comp=>{
        comp.enfants.filter(enfant=>enfant.type===Type.GROUP).forEach(enfant=>{
          newComponents.push(enfant)
        })
      })
      rows.push((<TableRow key={level}  style={{"--nbLine":level}}>{
        components.map((comp,index)=>{
          return (<React.Fragment key={index}>
            <Header key={index} rowSpan={nbLevel+1}>description</Header>
            <GroupHeader 
            childrenReferentiel={childrenReferentiel}
            lineComponent={comp}
            popinChild={popinChild}
            onClosePopinChild={onClosePopinChild}
            onSavePopinChild={onSavePopinChild}
            popinAnchorEl={popinAnchorEl}
            nbLevel={nbLevel}
            key={index+1}
          /></React.Fragment>)
        })
      }</TableRow>))
      components=newComponents
    }

  }
  return rows
}

GroupValuesHeaders.propTypes = {
    component:PropTypes.any.isRequired,
    childrenReferentiel:PropTypes.any.isRequired,
    popinChild:PropTypes.any,
    onClosePopinChild:PropTypes.func.isRequired,
    popinAnchorEl:PropTypes.any.isRequired
}
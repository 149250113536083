import { TextBlockType } from './TextBlockType'

export class TextBlock {
  type
  fragments
  pluralFragments
  children
  uniqValueOnly
  separable
  conditionalTexts

  constructor(
    fragments = [],
    type = TextBlockType.GROUP,
    pluralFragments = [],
    uniqValueOnly = false,
    separable = false,
    children = [],
    conditionalTexts = []
  ) {
    this.fragments = fragments
    this.type = type
    this.pluralFragments = pluralFragments
    this.children = children
    this.separable = separable
    this.uniqValueOnly = uniqValueOnly
    this.conditionalTexts = conditionalTexts
  }

  static NULL = new TextBlock()
}
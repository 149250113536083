import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import * as PropTypes from 'prop-types'
import React from 'react'

export const AttachedCCInpput = ({ dataTest, label }) => (
  <>
    <Grid item xs={10}>
      <TextField
        data-test={`${dataTest}_label`}
        label={label}
        className={'textField'}
        margin="normal"
        inputProps={{ maxLength: 4 }}
        fullWidth
        disabled={true}
      />
    </Grid>
  </>
)

AttachedCCInpput.propTypes = {
  dataTest: PropTypes.string,
  label: PropTypes.string,
}

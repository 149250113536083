import makeStyles from '@material-ui/core/styles/makeStyles'
import { Grid } from '@material-ui/core'
import { ValidationButton } from '../../common/validation-button'
import { CancelButton } from '../../common/cancel-button'
import Button from '@material-ui/core/Button'
import * as PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  top: {},
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

export const AddItemButtons = ({
  onClick,
  onSave,
  onCancel,
  showList,
  saveDataTest,
  addDataTest,
  cancelDataTest,
  isDisabled,
  isSaveDisabled,
}) => {
  const classes = useStyles()
  return (
    <>
      {showList ? (
        <>
          <Grid>
            <ValidationButton
              data-test={saveDataTest}
              variant="contained"
              size="medium"
              color="primary"
              onClick={onSave}
              className={classes.margin}
              disabled={isSaveDisabled}
            >
              ENREGISTRER
            </ValidationButton>
          </Grid>
          <Grid>
            <CancelButton
              data-test={cancelDataTest}
              variant="contained"
              size="medium"
              color="primary"
              onClick={onCancel ? onCancel : onClick}
              className={classes.margin}
            >
              ANNULER
            </CancelButton>
          </Grid>
        </>
      ) : (
        <Grid>
          <Button
            data-test={addDataTest}
            variant="contained"
            size="medium"
            color="primary"
            onClick={onClick}
            className={classes.margin}
            disabled={isDisabled}
          >
            NOUVEAU
          </Button>
        </Grid>
      )}
    </>
  )
}

AddItemButtons.propTypes = {
  showList: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  saveDataTest: PropTypes.string.isRequired,
  addDataTest: PropTypes.string.isRequired,
  cancelDataTest: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
}

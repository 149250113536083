export class CCField {
  name
  constructor(name) {
    this._name = name
  }

  get name() {
    return this._name
  }

  static NULL = new CCField('NO_VALUE')
  static TITRE = new CCField('titre')
  static IDCC_NATIONAL = new CCField('idccNational')
  static IDCC_RATTACHEMENT = new CCField('idccRattachement')
  static DATE_FERMETURE = new CCField('dateFermeture')
}

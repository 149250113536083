import User from '../constants/user'
import {
  LOGIN_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
} from '../reducers/login-state-reducer'
import { setUser } from './user-action'
import getPayeService from '../../api/envService'
import {LOGIN_ENDPOINT,USER_ENDPOINT,LOGOUT_ENDPOINT} from '../../api/endpoints'

/*
export const logIn = (email, password) => dispatch =>
  dispatch({
    type: LOGIN_START,
    meta: {
      offline: {
        effect: {
          json: { email, password },
          method: 'POST',
          url: '/auth',
        },
        commit: {
          type: LOGIN_SUCCESS,
          meta: {
            then: () => dispatch(setUser(new User(email))),
          },
        },
        rollback: { type: LOGIN_FAILURE },
      },
    },
  })
*/

const failure = () => dispatch => {
  Promise.resolve()
    .then(() => dispatch(logInFailure()))
    .then(() => dispatch(logInFailure()))
}

const success = (user) => dispatch => {
  Promise.resolve()
    .then(() => dispatch(logInSuccess()))
    .then(() => dispatch(setUser(user)))
}

export const logInWithToken = () => dispatch => {
  return Promise.resolve()
    .then(()=>getPayeService().get(USER_ENDPOINT))
    .then((user)=>dispatch(success(user)),()=>dispatch(logInStart()))
}

export const logIn = (email, password) => dispatch => {
  const form = new FormData();
  form.set("username",email)
  form.set("password",password)
  Promise.resolve()
    .then(() => dispatch(logInStart()))
    .then(() => getPayeService().post(LOGIN_ENDPOINT,form,{'Content-Type': 'multipart/form-data' }))
    .then(() => dispatch(logInWithToken()), ()=>dispatch(failure()))
}

export function logInFailure() {
  return { type: LOGIN_FAILURE }
}

export function logInStart() {
  return { type: LOGIN_START }
}

export function logInSuccess() {
  return { type: LOGIN_SUCCESS }
}

export const logOut = () => dispatch=>{
  Promise.resolve()
    .then(()=>getPayeService().get(LOGOUT_ENDPOINT))
    .then(()=>dispatch(setUser()))
    .then(() => dispatch(logOutStart()))
}

export function logOutStart() {
  return { type: LOGOUT }
}

export class ConditionalText {
  name
  currentValue
  readCurrentValue
  compValues
  shouldRemove
  block
  constructor(name='', currentValue = '',  readCurrentValue = true, compValues = [], shouldRemove = false, block) {
    this.name = name
    this.currentValue = currentValue
    this.readCurrentValue = readCurrentValue
    this.compValues = compValues
    this.shouldRemove = shouldRemove
    this.block = block
  }

  static NULL = new ConditionalText()
}
export const START_LOADER = 'paye-bo/loader/START_LOADER'
export const STOP_LOADER = 'paye-bo/loader/STOP_LOADER'

let LOADER_INCR = 0

export default function loader(state = false, action) {
  switch (action.type) {
    case START_LOADER:
      LOADER_INCR++
      break
    case STOP_LOADER:
      LOADER_INCR--
      break
    default:
      return LOADER_INCR > 0
  }
  return LOADER_INCR > 0
}

export const MESSAGES_ENDPOINTS = {
  http: {
    status: {
      '400': {
        severity: 'error',
        message: () => 'La requête est erronnée',
      },
      '401': {
        severity: 'error',
        message: () => 'Vous devez être connecté pour effectuer cette requête',
      },
      '403': {
        severity: 'error',
        message: () => "Vous n'êtes pas autorisé à accéder à ce service",
      },
      '404': {
        severity: 'error',
        message: () => 'Ressource indisponible',
      },
      '410': {
        severity: 'error',
        message: endpoint => {
          return (
            'Ce service pour ' + endpoint.helper.d(endpoint, true) + ' ' + endpoint.pluriel + " n'est plus disponible"
          )
        },
      },
    },
    method: {
      GET_UNIQ: {
        name: 'getUniq',
        status: {
          '204': {
            //no content
            severity: 'warning',
            message: endpoint => {
              return (
                'Aucun' +
                endpoint.helper.e(endpoint, false) +
                ' ' +
                endpoint.singulier +
                " n'a été trouvé" +
                endpoint.helper.e(endpoint, false)
              )
            },
          },
          '404': {
            //not found
            severity: 'error',
            message: endpoint => {
              return (
                endpoint.helper.d(endpoint, false) +
                ' ' +
                endpoint.singulier +
                " n'a pas été trouvé" +
                endpoint.helper.e(endpoint, false)
              )
            },
          },
        },
      },
      GET: {
        name: 'get',
      },
      POST: {
        name: 'post',
        status: {
          '201': {
            //created
            message: endpoint => {
              return (
                endpoint.helper.d(endpoint, false) +
                ' ' +
                endpoint.singulier +
                ' a été créé' +
                endpoint.helper.e(endpoint, false)
              )
            },
          },
          '200': {
            //created
            message: endpoint => {
              return (
                endpoint.helper.d(endpoint, false) +
                ' ' +
                endpoint.singulier +
                ' a été créé' +
                endpoint.helper.e(endpoint, false)
              )
            },
          },
          '204': {
            //no content
            message: endpoint => {
              return (
                'La modification concernant ' +
                endpoint.helper.d(endpoint, false) +
                ' ' +
                endpoint.singulier +
                ' a été effectuée'
              )
            },
          },
        },
      },
      PUT: {
        name: 'put',
        status: {
          '200': {
            //ok
            message: endpoint => {
              return (
                'La modification concernant ' +
                endpoint.helper.d(endpoint, false) +
                ' ' +
                endpoint.singulier +
                ' a été effectuée'
              )
            },
          },
          '204': {
            //no content
            message: endpoint => {
              return (
                'La modification concernant ' +
                endpoint.helper.d(endpoint, false) +
                ' ' +
                endpoint.singulier +
                ' a été effectuée'
              )
            },
          },
        },
      },
      PATCH: {
        name: 'patch',
        status: {
          '200': {
            //ok
            message: endpoint => {
              return (
                'La mise à jour de ' +
                endpoint.helper.d(endpoint, false) +
                ' ' +
                endpoint.singulier +
                ' a été effectuée'
              )
              .replace('de le','du')
            },
          },
        },
      },
      DELETE: {
        name: 'clear',
        status: {
          '200': {
            //ok
            message: endpoint => {
              return (
                'Les données concernant ' +
                endpoint.helper.d(endpoint, true) +
                ' ' +
                endpoint.pluriel +
                ' ont été supprimées'
              )
            },
          },
          '204': {
            //no content
            message: endpoint => {
              return (
                'Les données concernant ' +
                endpoint.helper.d(endpoint, true) +
                ' ' +
                endpoint.pluriel +
                ' ont été supprimées'
              )
            },
          },
          '404': {
            //no content
            message: endpoint => {
              return (
                'Echec de suppression de ' +
                endpoint.helper.d(endpoint, false) +
                ' ' +
                endpoint.singulier +
                ' car introuvable'
              )
              .replace('de le','du')
            },
          },
        },
      },
      POST_FILE: {
        name: 'POST_FILE',
        status: {
          '201': {
            //ok
            message: () => {
              return `##FILE_NAME## a été importé`
            },
          },
        },
      },
      PATCH_FILE: {
        name: 'PATCH_FILE',
        status: {
          '201': {
            //ok
            message: () => {
              return `##FILE_NAME## a été importé`
            },
          },
        },
      },
    },
  },
}

export function setFileNameIfNeeded(httpHandler, status, response, endpoint, args) {
  const m = httpHandler.message(endpoint)
  return m.includes('##FILE_NAME##') ? m.replace('##FILE_NAME##', args[1].name) : m
}

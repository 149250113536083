import React, { useState } from 'react'
import { TextCard } from './textCard'
import * as PropTypes from 'prop-types'
import { CardsMenu } from './cards-menu'
import { TextFragmentType } from '../../../../../store/constants/TextFragmentType'


function addSpace(idx, block) {
  return idx === block.fragments.length ? '' : ' '
}

export function SimpleCard({ block, childIdx, grandChildIdx, edit, idx, setList, blocks, handleTextPopoverOpen }) {
  const [anchorElMenu, setAnchorElMenu] = useState()

  function handleOpenMenu(e) {
    setAnchorElMenu(e.target)
    //return edit(e, block.fragments[0].component)
  }

  function innerHandleTextPopoverOpen(event, idx, parent, idx1, idx2, condition) {
    setAnchorElMenu(null)
    handleTextPopoverOpen(event, idx, parent, childIdx, grandChildIdx, condition)
  }

  return <>
    <TextCard idx={idx}
              childIdx={childIdx}
              grandChildIdx={grandChildIdx}
              edit={handleOpenMenu}
              setList={setList}
              list={blocks}>
      {
        block.fragments.map((fragment, j) => <span key={j}>
          {
            fragment.type === TextFragmentType.TEXT
              ? <span style={{ color: '#9fa8da' }}>{fragment.text + addSpace(j, block)}</span>
              : <span style={{ color: '#e91e63' }}>[{fragment.component}]{addSpace(j, block)}</span>
          }
        </span>)
      }
    </TextCard>

    {
      anchorElMenu
      && <CardsMenu anchorEl={() => anchorElMenu}
                    idx={idx}
                    onClose={() => setAnchorElMenu(null)}
                    handleTextPopoverOpen={innerHandleTextPopoverOpen}
                    block={block} />
    }
  </>
}

SimpleCard.propTypes = {
  idx: PropTypes.number,
  childIdx: PropTypes.any,
  grandChildIdx: PropTypes.any,
  edit: PropTypes.func,
  setList: PropTypes.func,
  blocks: PropTypes.array,
  block: PropTypes.any,
  prop6: PropTypes.func,
  handleTextPopoverOpen: PropTypes.func,
}
export const SET_ALERT = 'paye-bo/alert/SET_ALERT'
export const SET_CONFIRMATION = 'paye-bo/alert/SET_CONFIRMATION'
export const UNSET_CONFIRMATION = 'paye-bo/alert/UNSET_CONFIRMATION'
export const UNSET_ALERT = 'paye-bo/alert/UNSET_ALERT'

function setAlert(state, action) {
  return {
    ...state,
    counter: state.counter + 1,
    confirmations: [...(state.confirmations)],
    list: [...(state.list), { ...action.value, key: state.counter }],
  }
}

function setConfirmation(state, action) {
  return {
    ...state,
    counter: state.counter + 1,
    confirmations: [...(state.confirmations),{ ...action.value, key: state.counter }],
    list: [...(state.list)],
  }
}

function pushConfirmationCue(action, state,confirmation) {
  return { ...state, cue: [...state.cue, {action,confirmation:confirmation}] }
}

function setConfirmOrPushInCue(state, action,confirmation) {
  if ((state.list.length + state.confirmations.length) > 10) return pushConfirmationCue(action, state,confirmation)
  else if(confirmation){
    return setConfirmation(state,action)
  }{
    return setAlert(state, action)
  }
}

function decrConfirmAndShowFromCueIfNeeded(state, action,confirmation) {
  let newState=state
  if(state.cue.length){
    const popCue=state.cue.shift()
    if(popCue.confirmation) newState = setConfirmation(state, popCue.action)
    else newState = setAlert(state, popCue.action)
  }
  if(confirmation){
    return {
      ...state,
      counter: newState.counter,
      confirmations: newState.confirmations.filter(a => a.key !== action.key),
      list: [...(newState.list)],
    }
  }else{
    return {
      ...state,
      counter: newState.counter,
      confirmations: [...(newState.confirmations)],
      list: newState.list.filter(a => a.key !== action.key),
    }
  }
}

export default function alerts(state = { counter: 0, list: [], confirmations: [], cue: [] }, action, globalState) {
  if (globalState && globalState.loginState === 'success') {
    switch (action.type) {
      case SET_ALERT:
        return setConfirmOrPushInCue(state, action,false)
      case UNSET_ALERT:
        return decrConfirmAndShowFromCueIfNeeded(state, action,false)
      case SET_CONFIRMATION:
        return setConfirmOrPushInCue(state, action,true)
      case UNSET_CONFIRMATION:
        return decrConfirmAndShowFromCueIfNeeded(state, action,true)
      default :
        return state
    }
  } else {
    return state
  }

}
import Access from '../constants/access'

export const SET_CLIENTS = 'paye-bo/access/SET_CLIENTS'
export const SET_CLIENT = 'paye-bo/access/SET_CLIENT'

const initialState = {
  clients: [],
  client:Access.NULL
}

export default function accessModule(state = initialState, action) {
    switch (action.type) {
      case SET_CLIENTS :
        return { ...state, clients: action.value }
      case SET_CLIENT :
          return { ...state, client: action.value }
    default:
        return state
    }
}
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

export const AddButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#3f51b5',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
}))(Button)

export class Role {
  name
  label
  constructor(name, label) {
    this.name = name
    this.label = label
  }

  static INPUT = new Role('INPUT', 'INPUT')
  static OUTPUT = new Role('OUTPUT', 'OUTPUT')
  static INPUT_OUTPUT = new Role('INPUT_OUTPUT', 'INPUT/OUTPUT')
}

export const ALL_ROLES = [Role.INPUT, Role.OUTPUT, Role.INPUT_OUTPUT]

export const getRoleFromString = (string = '') => {
  const toCompare = string.name ? string.name : string
  const role = [Role.INPUT, Role.OUTPUT, Role.INPUT_OUTPUT].find(r => r.name === toCompare.toUpperCase())
  if (role) return role
  else throw new Error('Le role ' + string + ' est inconnu')
}

export const getRoleFromLabel = (string = '') => {
  const toCompare = string.label ? string.label : string
  const role = [Role.INPUT, Role.OUTPUT, Role.INPUT_OUTPUT].find(r => r.label === toCompare)
  if (role) return role
  else throw new Error('Le role ' + string + ' est inconnu')
}

import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../common/container-title'
import { Grid, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import { TypeModele } from '../../store/constants/ModelWorkflow'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import React from 'react'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  container: { maxWidth: '80%', overflowX: 'hidden', margin: 'auto', marginTop: 16 },
  container2: { maxWidth: '80%', overflowX: 'hidden', marginTop: 16 },
  formControl: {
    margin: 0,
    marginTop: 8,
    width: '100%',
  },
}))

export const ModelInfos = ({ classes, handleChange, tmpModel, disableName }) =>
  <Paper className={classes.contentRoot} elevation={1}>
    <ContainerTitle variant="h5" classes={classes} title="Création de modèle de workflow"/>
    <Grid item xs={8}>
      <TextField data-test={'modele_nom'}
                 label={'Nom du modèle'}
                 value={tmpModel.nom}
                 className={'TextField'}
                 margin="normal"
                 fullWidth
                 disabled={disableName}
                 onChange={e => handleChange(e.target.value, 'nom')}/>
      <TextField data-test={'modele_description'}
                 label={'Description'}
                 value={tmpModel.description}
                 className={'TextField'}
                 margin="normal"
                 fullWidth
                 onChange={e => handleChange(e.target.value, 'description')}/>
      <form className={classes.formControl}>
        <InputLabel id="typeLabel">
          Type
        </InputLabel>
        <Select labelId="typeLabel"
                label="Type"
                data-test="modele_type"
                style={{ width: '100%' }}
                value={tmpModel.type ? tmpModel.type.name : ''}
                onChange={e => handleChange(TypeModele.fromStr(e.target.value), 'type')}>
          {TypeModele.ALL.map((type) =>
            <MenuItem data-test={'modele_type_' + type.name} key={type.name} value={type.name ? type.name : null}>
              {type.name ? type.description : 'aucun'}
            </MenuItem>,
          )}
        </Select>
        {
          tmpModel.type !== TypeModele.CREATE && <Grid container direction="row" style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                tmpModel.type !== TypeModele.CREATE && <Checkbox
                  onClick={() => handleChange(!tmpModel.clearOutput, 'clearOutput')}
                  data-test="modele_suppressionOutput"
                  value={tmpModel.clearOutput}
                  checked={tmpModel.clearOutput}
                  color="primary"/>
              }
              label="Suppression Outputs"
            />


          </Grid>
        }
      </form>
    </Grid>
  </Paper>
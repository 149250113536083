import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import Paper from '@material-ui/core/Paper'
import { makeStyles, TablePagination } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { setHistoriqueId } from '../../../store/actions/historique-action'
import { StatutItem } from '../../historiques/statut-item'
import { getWFNumber } from '../workflow/cc-workflow-list'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  conventionItem: {
    paddingTop: 14,
    paddingBottom: 10,
    borderBottom: `1px solid #dadde7`,
  },
}))

const headers = ["historique","description","date de publication dans l'API","statut"]

const setHistoriqueIdAndPush = (history, idcc, id, nom) => dispatch => {
  return Promise.resolve()
    .then( () => dispatch(setHistoriqueId(id)))
    .then(() => history.push(`/detail-convention/${idcc}/historiques/${nom}`))
}

export const CCHistoriqueList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles()
  const history = useHistory()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const {
    historiques,
    conventionCollective: { idcc },
  } = useSelector(state => state.conventionModule)
  const dispatch = useDispatch()
  const handleClick = (id, nom) => {
    dispatch(setHistoriqueIdAndPush(history, idcc, id, nom))
  }
  return (
    <>
      {historiques ? (
        <Paper>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {headers.map(header => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {historiques
                  .sort((a, b) =>  getWFNumber(b.nom) - getWFNumber(a.nom) )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(({ id,nom,statut,description,datePublication }) => (
                  <TableRow
                    hover
                    key={nom}
                    data-test={'historique_row_' + nom}
                    onClick={() => handleClick(id, nom)}
                    className="historique_row">
                    <TableCell data-test={'historique_nom'}>{nom}</TableCell>
                    <TableCell data-test={'historique_description'}>{description}</TableCell>
                    <TableCell data-test={'historique_datePublication'}>{datePublication}</TableCell>
                    <TableCell data-test={'historique_statut'}><StatutItem statut={statut} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelDisplayedRows={({count, page}) => "page "+(page + 1)+" sur "+(Math.ceil(count/rowsPerPage))}
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={historiques.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage=""
            style={{backgroundColor: '#f5f5f5'}}
          />
        </Paper>
      ) : (
        <div>Aucun historique de créé...</div>
      )}
    </>
  )
}

import { TabPanel } from '../../common/tab-pannel'
import { Panel } from '../../common/panel'
import * as PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { DataTable } from './table/data-table'

export const DataTab = ({ titles }) => {
  const activeTab = useSelector(state => state.activeTabs)
  const idcc = useSelector(state => state.conventionModule.conventionCollective.idcc)
  const storedTheme = useSelector(state => state.conventionModule.theme && state.conventionModule.theme.nom)
  const history = useHistory()
  const theme = useMemo(() => titles[activeTab], [titles, activeTab])
  const loader = useSelector(state => state.loader)
  const handleChange = t => {
    if(t === activeTab) return
    const theme = titles[t]
    history.push('/tableur/' + idcc + '/theme/' + (theme.label ? theme.label : theme))
  }

  const showLoader = useMemo(() => loader || (theme && !theme.label && storedTheme !== theme )|| (theme && theme.label && theme.label !== storedTheme), [theme, storedTheme, loader])


  return <>
    {showLoader ? (
    <LinearProgress />
    ) :  <TabPanel titles={titles} onChange={handleChange}>
      {
        titles.map(
          (t, idx) =>
            <Panel value={activeTab} index={idx} key={t.label ? t.label : t}>
              {activeTab === idx && <DataTable />}
            </Panel>
        )
      }
    </TabPanel> }</>
}


DataTab.propTypes = {
  titles: PropTypes.any,
  activeTab: PropTypes.any,
  prop1: PropTypes.func,
}

import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { firstPage, lastPage, nextPage, previousPage, setPageSize } from '../../../store/actions/tableur-action'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from '@material-ui/icons'
import { getComputedData } from '../../../store/selectors/tableur-selectors'

function pageNumberSelector() {
  return state => Math.ceil(getComputedData(state).length / state.tableurModule.pageSize)
}

const useStyles = makeStyles(theme => ({
  pagination: {
    width: 272,
    marginRight: 10,
    backgroundColor: 'whitesmoke',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

export function TablePagination() {
  const classes = useStyles()
  const page = useSelector(state => state.tableurModule.page)
  const pageNumber = useSelector(pageNumberSelector())
  const pageSize = useSelector(state => state.tableurModule.pageSize)
  const dispatch = useDispatch()

  useEffect(() => {
    if(page && page + 1 >= pageNumber) dispatch( lastPage(pageNumber - 1) )
  }, [pageNumber])

  function handleFirst() {
    dispatch(firstPage())
  }

  function handlePrevious() {
    dispatch(previousPage())
  }

  function handleNext() {
    dispatch(nextPage())
  }

  function handleLast() {
    dispatch(lastPage(pageNumber - 1))
  }

  function handleChangeSize({ target: { value } }) {
    dispatch(setPageSize(value))
  }

  return (
    <Paper className={classes.pagination} elevation={1} style={{ marginRight: 15 }}>
      <Select
        displayEmpty
        label="taille"
        input={<Input />}
        value={pageSize}
        onChange={handleChangeSize}
        inputProps={{ 'aria-label': 'Without label' }}
        onClose={() => {}}
        onOpen={() => {}}
        data-test={`NOMBRE_LIGNES`}
        style={{marginLeft: 12}}
      >
        <MenuItem data-test="5" value={5}>
          5
        </MenuItem>
        <MenuItem data-test="10" value={10}>
          10
        </MenuItem>
        <MenuItem data-test="20" value={20}>
          20
        </MenuItem>
        <MenuItem data-test="50" value={50}>
          50
        </MenuItem>
      </Select>
      <IconButton
        disabled={page === 0}
        aria-label="PREMIER"
        className={classes.margin}
        size="small"
        data-test="PREMIER"
        onClick={handleFirst}
      >
        <FirstPage fontSize="inherit" />
      </IconButton>
      <IconButton
        disabled={page === 0}
        aria-label="PRECEDENT"
        className={classes.margin}
        size="small"
        data-test="PRECEDENT"
        onClick={handlePrevious}
      >
        <NavigateBefore fontSize="inherit" />
      </IconButton>
      {page + 1} / {pageNumber ? pageNumber : 1}
      <IconButton
        disabled={pageNumber === (page + 1)}
        aria-label="SUIVANT"
        className={classes.margin}
        size="small"
        data-test="SUIVANT"
        onClick={handleNext}
      >
        <NavigateNext fontSize="inherit" />
      </IconButton>
      <IconButton
        disabled={pageNumber === (page + 1)}
        aria-label="DERNIER"
        className={classes.margin}
        size="small"
        data-test="DERNIER"
        onClick={handleLast}
      >
        <LastPage fontSize="inherit" />
      </IconButton>
    </Paper>
  )
}

export const SET_API_MODE = 'bo-paye/api-mode/SET_API_MODE'

const initialState = {
  apiMode: false,
}

export default function settingsModule(state = initialState, action) {
  switch (action.type) {
    case SET_API_MODE:
      return { ...state, apiMode: action.value }
    default:
      return state
  }
}

export const mapToJson = values => {
  const out = {}
  if (values) values.forEach((value, key) => (out[key] = value))
  return out
}

export const dispatchActions=(dispatch,...actions)=>{
  let p=Promise.resolve()
  actions.forEach(action=>{if(action) p=p.then(()=>dispatch(action))})
  return p
}

export const dispatchActionsArray=(dispatch,actions)=>{
  let p=Promise.resolve()
  actions.forEach(action=>{if(action) p=p.then(()=>dispatch(action))})
  return p
}


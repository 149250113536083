import React, { useEffect, useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { TableurCellReferentiel } from './Referentiel/tableur-cell-referentiel'
import { useDispatch, useSelector } from 'react-redux'
import { TableurCellList } from './list/tableur-cell-list'
import { isGroup, isList, isReferentiel } from '../../../../../../store/constants/type-helper'
import { TableurCellUnitOfListExclusion } from './list/tableur-cell-unit-of-list-exclusion'
import { SimpleCellType } from './simple-cell-type'
import {
  canRenderValues,
  getChildData,
  getValueFromReference,
  isNonGroupReferenceWithValuesLoaded,
  isSimpleOrDate,
  isUnitOfListExclusion,
} from './tableur-cell-helper'
import { handleTmpData } from '../../../../../../store/actions/tableur-action'
import _ from 'lodash'
import { getChildrenData, isAnObject } from '../data-row-helper'
import { getDataIdFromrState, TableurAction } from '../action-row'

function cellContentSelector(line, parent, component, role, values) {
  return state => {
    const data1 = state.tableurModule.data
    const dataIdFromrState = getDataIdFromrState(state, line)
    if (data1.length && dataIdFromrState) {
      const data = data1.find(d => dataIdFromrState === d.id + '_' + d.idCC)
      const dataValue = parent ? getChildData(component, parent, role, data) : data[role][component.nom]
      const compValue = isNonGroupReferenceWithValuesLoaded(dataValue, component, values)
        ? getValueFromReference(parent, values, dataValue, component)
        : dataValue
      if (!parent && isList(component.type)) {
        const compDefaultValue = component.unit.defaultValue
        if (
          (!dataValue || (_.isArray(dataValue) && dataValue.length === 0)) &&
          (!compValue || (_.isArray(compValue) && compValue.length === 0)) &&
          compDefaultValue
        ) {
          return [[compDefaultValue], [compDefaultValue]]
        }
      } else {
        const compDefaultValue = component.defaultValue
        if (!dataValue && !compValue && compDefaultValue) {
          if (!parent) return [compDefaultValue, compDefaultValue]
          else {
            if (!isEmptyObject(data[role][parent.nom])) {
              if (Object.keys(parent.defaultValues).includes(component.nom) && parent.defaultValues[component.nom])
                return [parent.defaultValues[component.nom], parent.defaultValues[component.nom]]
              return [compDefaultValue, compDefaultValue]
            }
          }
        }
      }

      return [compValue, dataValue]
    }
    return [undefined, undefined]
  }
}

export const isEmptyObject = (obj, isListExclus) => {
  if (!obj) return true
  if (isListExclus) {
    return obj['inclus'] && obj['exclus']
      ? Object.values(obj['inclus']).length === 0 && Object.values(obj['exclus']).length === 0
      : true
  } else {
    let objValues = Object.values(obj)
    let objects = objValues.filter(v => isAnObject(v))
    let nonObjects = objValues.filter(v => !isAnObject(v))
    return (
      nonObjects.filter(v => v.length > 0).length === 0 ||
      objects.forEach(object => Object.values(object).filter(v => v.length > 0).length === 0)
    )
  }
}

export function getOnEdit(action, dataId) {
  return action.action === TableurAction.ON_EDIT && action.dataId === dataId
}

export function tmpdata(state, dataId, role, component) {
  const newVar = state.tableurModule.tmpData.get(dataId)
  if (newVar) return getChildrenData(newVar[role], component)
}

export function cellEditModeSelector(role, component, idx) {
  return state => {
    const onEdit = state.tableurModule.onEditComps[role].find(path => path === component.getComponentPath())
    const tmp = tmpdata(state, getDataIdFromrState(state, idx), role, component)
    return !!((tmp || tmp === '' || tmp === 0) && onEdit)
  }
}

function editValueSelector(role, component, line) {
  return state => {
    const onEdit = state.tableurModule.onEditComps[role].find(path => path === component.getComponentPath())
    const tmp = tmpdata(state, getDataIdFromrState(state, line), role, component)
    if ((tmp || tmp === '' || tmp === 0) && onEdit) return tmp
    else return false
  }
}

export function TableurCellContent({ component, parent, role, editDataTest, tooltipContent, line }) {
  const values = useSelector(state => {
    return state.tableurModule.values.get ? state.tableurModule.values.get(parent ? parent.nom : component.nom) : {}
  })
  const cellMode = useSelector(cellEditModeSelector(role, component, line))
  const [compValue, dataKey] = useSelector(cellContentSelector(line, parent, component, role, values), _.isEqual)
  const editValue = useSelector(editValueSelector(role, component, line), _.equals)
  const isUnitOfExclusion = useMemo(() => isUnitOfListExclusion(component, parent), [component, parent])
  const dispatch = useDispatch()
  const setTmpData = (key, value, component, myTmpData) =>
    dispatch(handleTmpData(key, value, component, myTmpData, role === 'inputs', line))

  const lineEditMode = useSelector(state => {
    const action = state.tableurModule.action
    const dataId = getDataIdFromrState(state, line)
    const selectedLine =
      state.tableurModule.action.action === TableurAction.ON_EDIT_MULTILINE &&
      !!state.tableurModule.selected.find(s => s.id + '_' + s.idCC === dataId)
    if (action) return getOnEdit(action, dataId) || selectedLine
  })
  //console.log('cell content ===> ' + line + ' ' + role + ' ' + component.getComponentPath()+' cellMode='+cellMode+' lineMode='+lineEditMode, editValue)

  const cellEditMode = cellMode || lineEditMode
  useEffect(() => {
    if (!compValue && !dataKey && !cellEditMode && isList(component.type)) tooltipContent('')
  }, [compValue, dataKey, cellEditMode])
  return (
    <>
      {(compValue || dataKey || cellEditMode) && (
        <>
          {isUnitOfExclusion ? (
            <TableurCellUnitOfListExclusion
              tooltipContent={tooltipContent}
              component={component}
              compValue={compValue}
              cellEditMode={cellEditMode}
              lineEditMode={lineEditMode}
              editValue={editValue}
              editDataTest={editDataTest}
              setTmpData={setTmpData}
              parent={parent}
              role={role}
              line={line}
            />
          ) : isGroup(component.type) ? (
            <div data-test={cellEditMode || lineEditMode ? editDataTest : ''}>
              {compValue && compValue.key ? compValue.key : !isEmptyObject(compValue, false) && '...'}
            </div>
          ) : (
            canRenderValues(compValue, values, cellEditMode, component) && (
              <>
                {isSimpleOrDate(component) && (
                  <SimpleCellType
                    component={component}
                    dataKey={cellEditMode || !dataKey ? '' : dataKey.key}
                    compValue={compValue}
                    tooltipContent={tooltipContent}
                    cellEditMode={cellEditMode}
                    lineEditMode={lineEditMode}
                    editValue={editValue}
                    editDataTest={editDataTest}
                    setTmpData={setTmpData}
                    line={line}
                    role={role}
                  />
                )}
                {isReferentiel(component.type) && (
                  <TableurCellReferentiel
                    code={compValue}
                    component={component}
                    cellEditMode={cellEditMode}
                    tooltipContent={tooltipContent}
                    editValue={editValue}
                    editDataTest={editDataTest}
                    setTmpData={setTmpData}
                    lineEditMode={lineEditMode}
                    line={line}
                  />
                )}
                {isList(component.type) && (
                  <TableurCellList
                    dataKey={dataKey}
                    values={compValue}
                    component={component}
                    tooltipContent={tooltipContent}
                    cellEditMode={cellEditMode}
                    lineEditeMode={lineEditMode}
                    editValue={editValue}
                    editDataTest={editDataTest}
                    setTmpData={setTmpData}
                    line={line}
                  />
                )}
              </>
            )
          )}
        </>
      )}
    </>
  )
}

TableurCellContent.propTypes = {
  d: PropTypes.any,
  role: PropTypes.any,
  component: PropTypes.any,
}

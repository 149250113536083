import getPayeService  from './envService'

import {INPUT_ENDPOINT as API_ENDPOINT} from './endpoints'

export const inputService = {
  getInputs,
  getInputById,
  editInput,
  createInput,
  deleteInputById,
  clearInput,
}

function getInputs(setData, setLoading) {
  setLoading(true)
  getPayeService()
    .get(API_ENDPOINT)
    .then(res => setData(res))
    .then(() => setLoading(false))
}

function getInputById(id, setData) {
  getPayeService().getById(API_ENDPOINT, id, setData)
}

function createInput(payload) {
  getPayeService().post(API_ENDPOINT, payload)
}

function editInput(id, payload) {
  getPayeService().put(API_ENDPOINT, payload, id)
}

function deleteInputById(id, setData) {
  getPayeService().deleteById(API_ENDPOINT, id).then(() => {
    getInputs(setData)
  })
}

function clearInput() {
  getPayeService().clear(API_ENDPOINT)
}

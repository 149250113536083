import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import { EditSaveButton } from './edit-save-button'
import { useCCForm } from './hooks'

export const IdccDate = ({
  ccDate,
  onChange,
  isLabel = false,
  onSave,
  label,
  ccField,
}) => {
  const [setEditable, isActive, disabled] = useCCForm(ccField, isLabel)

  return (
    <>
      {disabled ? (
        <>
          <Grid item xs={10}>
            <TextField
              data-test={'date_fermeture_label'}
              label={label}
              className={'TextField'}
              value={ccDate}
              margin="normal"
              fullWidth
              disabled
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={10}>
          <TextField
            id={'date_fermeture_label_edit'}
            type="date"
            data-test={'date_fermeture_label'}
            className={'TextField'}
            value={ccDate}
            onChange={onChange}
            margin="normal"
            fullWidth />
        </Grid>
      )}
      {isLabel && (
        <Grid item xs={2}>
          <EditSaveButton
            editable={isActive}
            setEditable={setEditable}
            dataTest={ccField.name}
            onSave={onSave}
          />
        </Grid>
      )}
    </>
  )
}


import * as PropTypes from 'prop-types'
import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'

export function SelectCCData({ onChange, selectedValue, setOnSelect }) {
  const allCC = useSelector(state => state.conventionModule.conventionsCollectives)
  const titreAllCCs = 'Toutes les conventions collectives'
  const options = [...allCC.sort((a, b) => a.titre.localeCompare(b.titre)), {idcc: 'all', titre: titreAllCCs}]
  return (
    <Autocomplete data-test="select-cc-data"
                  variant="standard"
                  size="small"
                  options={options}
                  getOptionLabel={cc => cc.idcc + ' - ' + cc.titre}
                  style={{ width: 300 }}
                  onChange={onChange}
                  onBlur={() => setOnSelect(false)}
                  renderInput={(params) => <TextField {...params} label={selectedValue ? selectedValue : 'Selectionner convention'}
                                                      variant="outlined"/>}/>
  )
}

SelectCCData.propTypes = {
  onChange: PropTypes.func,
  setOnSelect: PropTypes.func,
  selectedValue: PropTypes.string,
  allCC: PropTypes.any,
}

import { Type } from '../../store/constants/type'
import { useSelector } from 'react-redux'

export const setValueIfTypeMatching = (type, setTmpValue, value) => {
  if (type === Type.TEXT || value === '' || value.match(/^[0-9]*(\.)?[0-9]*$/)) setTmpValue(value)
}

export const useValueExists = val => {
  return useSelector(state => {
    const map = state.conventionModule.values.values
    if (!map.size) return false
    for (let [k, v] of map) {
      k = `${k}` // for disable waring
      if ('' + v === val) return true
    }
    return false
  })
}

export const getKey = (map, i = '1') => {
  for (let [k] of map) {
    if ('' + k === i) return getKey(map, '' + (Number.parseInt(i) + 1))
  }
  return i
}

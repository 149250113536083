import {
  CC_ENDPOINT,
  DATA_ENDPOINT,
  INPUT_ENDPOINT,
  OUTPUT_ENDPOINT,
  REFERENTIEL_ENDPOINT,
  THEME_ENDPOINT, VERSION_LATEST_ENDPOINT,
} from '../../api/endpoints'
import { Data } from '../constants/data'
import { setLoader, setTableurLoader } from './loader-action'
import {
  ADD_ACTION, ADD_ALL_IN_SELECTED,
  ADD_DATA,
  ADD_DATA_TO_SAVE,
  ADD_IN_SELECTED,
  ADD_SET_OUTPUT,
  ADD_TABLEUR_FILTER,
  ADD_TMP_DATA,
  CLEAR_ACTION,
  CLEAR_DATA,
  CLEAR_DATA_TO_SAVE,
  CLEAR_EXPANSIONS,
  CLEAR_ON_EDIT_COMPS,
  CLEAR_SELECTED,
  CLEAR_SET_OUTPUT, CLEAR_TABLEUR,
  CLEAR_TABLEUR_FILTER,
  CLEAR_TABLEUR_INPUTS,
  CLEAR_TABLEUR_OUTPUTS,
  CLEAR_TABLEUR_REFERENTIELS,
  CLEAR_TABLEUR_VALUES,
  CLEAR_TMP_DATA,
  DELETE_DATA_TO_SAVE,
  EXPAND_GROUPS,
  FIRST_PAGE,
  INIT_PAGINATION,
  LAST_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE, REMOVE_ALL_FROM_SELECTED,
  REMOVE_FROM_SELECTED,
  SET_ACTIVE_INPUTS,
  SET_ACTIVE_OUTPUTS,
  SET_ALL_EDIT_LINES,
  SET_COLUMN_MODE,
  SET_DATA,
  SET_DATA_TO_SAVE,
  SET_DOUBLON_MODE,
  SET_EDIT_MODE,
  SET_ON_EDIT_COMPS,
  SET_PAGE_SIZE,
  SET_SELECT_MODE,
  SET_TABLEUR_INPUT,
  SET_TABLEUR_OUTPUT,
  SET_TABLEUR_REFERENTIEL,
  SET_TABLEUR_VALUES,
  SET_TMP_DATA,
  SET_TMP_IDX,
} from '../reducers/tableur-reducer'
import getPayeService from '../../api/envService'
import {
  getCCById,
  getCCTheme,
  getCCThemes,
  getLegalCCThemeOutputs,
  getStatutFromData,
  initCCComponentLoading,
  setCCTheme,
} from './convention-collective-action'
import { Type } from '../constants/type'
import { HeaderExpansion } from '../constants/header-expansion'
import { getComponentValuesPromise } from './values-action'
import { isGroup, isList } from '../constants/type-helper'
import Referentiel, { ReferentielValeur } from '../constants/referentiel'
import { setUnitComponent } from './component-action'
import { showAlert } from './alert-action'
import {
  cloneObject,
  createDatatoSave,
  createNewTmpData,
  getNextNonUnitComp,
  handleTmpDataExtracted,
  isChildGroup,
  isCurrentComponentIsAnotherGroup,
} from '../../Components/tableur/tab/table/row/data-row-helper'
import { TableurAction } from '../../Components/tableur/tab/table/row/action-row'
import { getActiveComponents } from '../../Components/tableur/tab/table/row/data-rows'
import { createEditeValueByType } from '../../Components/tableur/tab/table/row/table-cell-and-tooltip'
import { setTab } from './tab-action'
import { Statut } from '../constants/historique'
import { getComputedData } from '../selectors/tableur-selectors'
import { getCCWorkflows, setWorkflow } from './workflow-action'
import { ModelInstanceWorkflow, WorkflowStatut } from '../constants/ModelInstanceWorkFlow'
import { downloadFile } from '../../api/paye-service-api'
import { getVersion, getVersionChangeLogs, setVersion } from './version-action'

export const setDoublonMode = value => dispatch => {
  dispatch({ type: SET_DOUBLON_MODE, value })
}

export const setColumnMode = value => dispatch => {
  dispatch({ type: SET_COLUMN_MODE, value })
}

function setData(data) {
  return { type: SET_DATA, value: data }
}

function addData(data) {
  return { type: ADD_DATA, value: data }
}

export const addTableurFilter = (componentKey, newFilter) => ({
  type: ADD_TABLEUR_FILTER,
  value: { key: componentKey, values: newFilter },
})

const setTableurComponent = (components, isInput) => ({
  type: isInput ? SET_TABLEUR_INPUT : SET_TABLEUR_OUTPUT,
  value: components,
})

export const setSelectMode = (value = false) => ({
  type: SET_SELECT_MODE,
  value,
})

export const setEditMode = (value = false) => ({
  type: SET_EDIT_MODE,
  value,
})

function setTableurRef(referentiel) {
  return {
    type: SET_TABLEUR_REFERENTIEL,
    value: referentiel,
  }
}

export const addDataToSave = data => dispatch => {
  dispatch({
    type: ADD_DATA_TO_SAVE,
    value: data,
  })
}

export const removeDataToSave = id => dispatch => {
  dispatch({
    type: DELETE_DATA_TO_SAVE,
    value: id,
  })
}

export const setAllEditLines = data => dispatch => {
  dispatch({
    type: SET_ALL_EDIT_LINES,
    value: data,
  })
}

const clearTableurCoponentsByRole = isInput => ({ type: isInput ? CLEAR_TABLEUR_INPUTS : CLEAR_TABLEUR_OUTPUTS })

function getCCThemeData(idcc, themeName) {
  return getPayeService()
    .getGrandchildren(CC_ENDPOINT, idcc, THEME_ENDPOINT, themeName, DATA_ENDPOINT)
    .then(res => buildDataListFromJson(res))
    .then(data =>
      data
        ? !isAll(idcc)
          ? data.sort((a, b) => a.id - b.id)
          : data.sort((a, b) => a.id - b.id).sort((a, b) => sortStrAsNum(a.idCC, b.idCC))
        : data
    )
}

export const sortStrAsNum = (a, b) =>
  !isNaN(a) && !isNaN(b) ? Number.parseInt(a) - Number.parseInt(b) : a.localeCompare(b)

export const loadData = themeName => (dispatch, getState) => {
  const idcc = getState().conventionModule.conventionCollective.idcc
  Promise.resolve()
    .then(() => dispatch(setTableurLoader(true)))
    .then(() => getCCThemeData(idcc, themeName))
    .then(data => {
      return dispatch(setData(data))
    })
    .catch(e => console.log(e))
    .finally(() => dispatch(setTableurLoader(false)))
}

export const EMPTY_DATA = { inputs: {}, outputs: {} }

export const addEmptyData = newLines => (dispatch, getState) => {
  const {
    conventionCollective: { idcc },
    theme: { nom, statut },
  } = getState().conventionModule
  newLines.forEach(newLine => (newLine.statut = Statut.MODIFICATION_EN_COURS))
  Promise.resolve()
    .then(() => dispatch(setTableurLoader(true)))
    .then(() => getPayeService().postChildren(newLines, CC_ENDPOINT, idcc, THEME_ENDPOINT, nom, DATA_ENDPOINT))
    .then(ids => {
      const promises = []
      ids.forEach(id =>
        promises.push(
          getPayeService()
            .getGrandchild(CC_ENDPOINT, idcc, THEME_ENDPOINT, nom, DATA_ENDPOINT, id)
            .then(res => buildDataFromJson(res))
        )
      )
      return Promise.all(promises)
        .then(data => data.sort((a, b) => a.id - b.id))
        .then(data => dispatch(addData(data)))
    })
    .then(() => {
      if (statut !== Statut.MODIFICATION_EN_COURS) return dispatch(getCCTheme(idcc, nom))
    })
    .catch(error => console.log(error))
    .finally(() => dispatch(setTableurLoader(false)))
}

export const addToCreateDataInData = data => (dispatch, getSate) => {
  let tmpIdx = getSate().tableurModule.tmpIdx
  data.forEach(d => (d.id = --tmpIdx))
  return Promise.resolve()
    .then(() => dispatch({ type: SET_TMP_IDX, tmpIdx }))
    .then(() => dispatch(addData(data)))
}

export function buildReferentielValeursFromJSON(data) {
  return data ? data.map(d => new ReferentielValeur(d.code, d.label ? d.label : d.libelle, d.text, d.defaultValue)) : []
}

function buildReferentielFromJSON(data) {
  return new Referentiel(
    data.nom ? data.nom : data.name,
    data.description,
    buildReferentielValeursFromJSON(data.valeurs ? data.valeurs : data.values)
  )
}

export function getAllUnitComponents(c) {
  return [
    ...(isGroup(c.type)
      ? c.enfants.flatMap(e => getAllUnitComponents(e))
      : isList(c.type)
      ? getAllUnitComponents(c.unit)
      : [c]),
  ]
}

const setTableurReferentiels = () => (dispatch, getState) => {
  const inputs = getState().tableurModule.inputs
  const outputs = getState().tableurModule.outputs
  const allRefComp = new Set(
    [...inputs, ...outputs]
      .flatMap(c => getAllUnitComponents(c))
      .filter(c => c.type === Type.REFERENCE)
      .map(c => c.referentiel)
  )
  return Promise.resolve()
    .then(() => dispatch({ type: CLEAR_TABLEUR_REFERENTIELS }))
    .then(() => {
      const promises = []
      allRefComp.forEach(comp => {
        promises.push(
          getPayeService()
            .getById(REFERENTIEL_ENDPOINT, comp)
            .then(data => buildReferentielFromJSON(data))
        )
      })
      return Promise.all(promises).then(referentiels => dispatch(setTableurRef(referentiels)))
    })
}

function setValuesMap(value) {
  return { type: SET_TABLEUR_VALUES, value }
}

const getTableurValues = (idcc, nom, input) => getComponentValuesPromise(idcc, nom, true, input.nom)

const setTableurValues = themeName => (dispatch, getState) =>
  Promise.resolve()
    .then(dispatch({ type: CLEAR_TABLEUR_VALUES }))
    .then(() => {
      const inputs = getState().tableurModule.inputs
      const idcc = getState().conventionModule.conventionCollective.idcc
      if (isAll(idcc)) return
      const promises = []
      inputs.forEach(i => {
        promises.push(getTableurValues(idcc, themeName, i, dispatch))
      })
      return Promise.all(promises).then(values => {
        const valuesMap = values.reduce((acc, curr, idx) => {
          acc.set(inputs[idx].nom, curr)
          return acc
        }, new Map())
        dispatch(setValuesMap(valuesMap))
      })
    })
    .catch(e => console.log(e))

export function isAll(idcc) {
  return idcc === 'all'
}

function getChildrenUnit(c, dispatch) {
  const childrenPromises = []
  c.enfants.forEach(e => pushUnit(childrenPromises, e, dispatch))
  return childrenPromises
}

function pushUnit(promises, c, dispatch) {
  promises.push(
    setUnitComponent(c, dispatch).then(unit => {
      if (unit) c.unit = unit
      return Promise.resolve(c)
    })
  )
}

function setUnitOfChildren(comps, dispatch, promises) {
  comps
    .filter(c => isGroup(c.type))
    .forEach(c => {
      if (isGroup(c.type)) setUnitOfChildren(c.enfants, dispatch, promises)
      const childrenPromises = getChildrenUnit(c, dispatch, promises)
      promises.push(...childrenPromises)
    })
}

const getComponentTableurByRole = (themeName, isInput) => (dispatch, getState) => {
  const { idcc } = getState().conventionModule.conventionCollective
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => dispatch(clearTableurCoponentsByRole(isInput)))
    .then(() => initCCComponentLoading(dispatch, idcc, themeName, isInput ? INPUT_ENDPOINT : OUTPUT_ENDPOINT))
    .then(comps => {
      const promises = []
      setUnitOfChildren(comps, dispatch, promises)
      return Promise.all(promises).then(() => comps)
    })
    .then(comps => {
      const promises = []
      comps.forEach(c => {
        pushUnit(promises, c, dispatch)
      })
      return Promise.all(promises).then(components => {
        dispatch(setTableurComponent(components, isInput))
      })
    })
    .catch(error => console.log(error))
    .finally(() => dispatch(setLoader()))
}

export const initAllTableur = (notParentThemes, theme, themes, idccParam) => async (dispatch) => {
  return Promise.resolve()
    .then(() => dispatch(initTableurTheme(notParentThemes, theme, idccParam)))
    .catch(e => console.log(e))
}

export const loadRefAndValues = () => dispatch => {
  return (
    Promise.resolve()
      //.then(() => dispatch(setTableurLoader(true)))
      .then(() => dispatch(setTableurReferentiels()))
      .catch(e => console.log(e))
  )
  //.finally(() => dispatch(setTableurLoader(false)))
}

export const initTableurTheme = (notParentThemes, themeName, idccParam) => {
  return async (dispatch, getState) => {
    return Promise.resolve()
      .then(() => dispatch(setLoader(true)))
      .then(() => dispatch(getCCById(idccParam)))
      .then(() => dispatch(setDoublonMode(false)))
      .then(() => {
        return dispatch(clearTableurData())
      })
      .then(() => dispatch(getCCThemes(idccParam)))
      .then(() => dispatch(setCCTheme(getState().conventionModule.themes.find(t => t.nom === themeName))))
      .then(() => dispatch(clearAllTableur()))
      .then(() => getState().tableurModule.expansions.size > 0 && dispatch({ type: CLEAR_EXPANSIONS }))
      .then(() => dispatch(getLegalCCThemeOutputs('9999', themeName)))
      .then(() =>
        Promise.all([
          dispatch(getComponentTableurByRole(themeName, true)),
          dispatch(getComponentTableurByRole(themeName, false)),
        ])
      )
      .then(() =>
        Promise.all([
          dispatch(setActiveComp(getActiveComponents(getState().tableurModule.inputs), true)),
          dispatch(setActiveComp(getActiveComponents(getState().tableurModule.outputs), false)),
        ])
      )
      .then(() => dispatch(loadRefAndValues(themeName)))
      .then(() =>
        dispatch(
          getCCWorkflows(idccParam, workflows => {
            const currentWorkflow = workflows.find(
              workflow =>
                workflow.statut &&
                workflow.statut[themeName.toUpperCase()] === WorkflowStatut.EN_COURS &&
                workflow.idcc === idccParam
            )
            if (currentWorkflow) {
              dispatch(setWorkflow(currentWorkflow))
            } else {
              dispatch(setWorkflow(ModelInstanceWorkflow.NULL))
            }
            dispatch(loadData(themeName))
          })
        )
      )
      .then(() => dispatch(getVersion("latest")))
      .then(() => dispatch(getVersionChangeLogs()))
      .catch(e => console.log(e))
      .finally(() => dispatch(setLoader(false)))
  }
}

function buildDataListFromJson(json) {
  //TODO: fix coté back comprendre pk il y a des données complètement vides
  return json ? json.filter(j => j).map(j => buildDataFromJson(j)) : []
}

function buildDataFromJson(json) {
  const data = new Data(json.id, json.inputs, json.outputs, getStatutFromData(json), json.historique, json.editable)
  if (json.idCC) data.idCC = json.idCC
  return data
}

export const expandColumn = (header, expanded = false, role) => dispatch => {
  dispatch({ type: EXPAND_GROUPS, value: new HeaderExpansion(header, expanded, role) })
}

export const setTableurAction = (action, dataId) => dispatch =>
  dispatch({
    type: ADD_ACTION,
    value: { action, dataId },
  })

export const clearTableurAction = () => dispatch => dispatch({ type: CLEAR_ACTION })

export const clearTableurData = () => dispatch => dispatch({ type: CLEAR_DATA })

export const clearTableur = () => dispatch => dispatch({ type: CLEAR_TABLEUR })

export function getDataFromState(state, idx) {
  return getComputedData(state)[idx + state.tableurModule.page * state.tableurModule.pageSize]
}

export const deleteDataLine = (idcc, nom, idx) => (dispatch, getState) => {
  const data = getDataFromState(getState(), idx)
  return Promise.resolve()
    .then(() => dispatch(setTableurLoader(true)))
    .then(() => dispatch(clearAllTableur(false)))
    .then(() =>
      getPayeService().deleteChild(
        CC_ENDPOINT,
        data.idCC ? data.idCC : idcc,
        THEME_ENDPOINT,
        nom,
        DATA_ENDPOINT,
        data.id
      )
    )
    .then(() => dispatch(loadData(nom)))
    .catch(error => console.log(error))
    .finally(() => dispatch(setTableurLoader(false)))
}

function clearOnEditComps() {
  return { type: CLEAR_ON_EDIT_COMPS }
}

function initPagination() {
  return { type: INIT_PAGINATION }
}

export const nextPage = () => ({ type: NEXT_PAGE })
export const previousPage = () => ({ type: PREVIOUS_PAGE })
export const firstPage = () => ({ type: FIRST_PAGE })
export const lastPage = value => ({ type: LAST_PAGE, value })
export const setPageSize = value => ({ type: SET_PAGE_SIZE, value })
const clearTableurFilter = () => ({ type: CLEAR_TABLEUR_FILTER })

export const clearAllTableur = (all = true) => (dispatch, getState) => {
  const action = getState().tableurModule.action
  const editMode = getState().tableurModule.editMode
  const selectMode = getState().tableurModule.selectMode
  const dataToSave = getState().tableurModule.dataToSave
  const tmpData = getState().tableurModule.tmpData
  const selected = getState().tableurModule.selected
  const data = getState().tableurModule.data
  const onEditComps = getState().tableurModule.onEditComps
  const page = getState().tableurModule.page
  const pageSize = getState().tableurModule.pageSize
  const filter = getState().tableurModule.filter
  const promises = []
  if (all && data.length) promises.push(dispatch(clearTableurData()))
  if (all && (page !== 0 || pageSize !== 10)) promises.push(dispatch(initPagination()))
  if (!all && data.length && data.find(d => d.id < 0)) promises.push(dispatch(setData(data.filter(d => d.id > 0))))
  if (tmpData && tmpData.size) promises.push(dispatch(clearTmpData()))
  if (action !== TableurAction.NULL) promises.push(dispatch(clearTableurAction()))
  if (selectMode) promises.push(dispatch(setSelectMode()))
  if (editMode) promises.push(dispatch(setEditMode()))
  if (selected && selected.length) promises.push(dispatch(clearSelected()))
  if (all && filter && filter.size) promises.push(dispatch(clearTableurFilter()))
  if (dataToSave && dataToSave.size) promises.push(dispatch(clearToSave()))
  if (onEditComps && (onEditComps.inputs.length || onEditComps.outputs.length))
    promises.push(dispatch(clearOnEditComps()))
  return Promise.all(promises)
}
export const removeMultiLines = () => (dispatch, getState) => {
  const toDeleteList = getState().tableurModule.selected
  const idcc = getState().conventionModule.conventionCollective.idcc
  const { nom, statut } = getState().conventionModule.theme
  if (toDeleteList.length)
    return Promise.resolve()
      .then(() => dispatch(setTableurLoader(true)))
      .then(() => {
        const promises = []
        toDeleteList.forEach(data => {
          promises.push(
            () => getPayeService().deleteChild(
              CC_ENDPOINT,
              data.idCC && data.idCC !== 'undefined' ? data.idCC : idcc,
              THEME_ENDPOINT,
              nom,
              DATA_ENDPOINT,
              data.id
            )
          )
        })
        return  Promise.resolve().then( () => resolveAndThen(promises, dispatch))
      })
      .then(() => dispatch(clearAllTableur(false)))
      .then(() => {
        if (statut !== Statut.MODIFICATION_EN_COURS) return dispatch(getCCTheme(idcc, nom))
      })
      .then(() => dispatch(loadData(nom)))
      .catch(error => console.log(error))
      .finally(() => dispatch(setTableurLoader()))
}

function createMultiLinesForSave(toSaveList, idcc, promises, nom, isCreation = false, workflow = false) {
  Array.from(toSaveList.values())
    .filter(v => (isCreation ? v.id < 0 : v.id > 0))
    .reduce((acc, curr) => {
      const idCC = curr.idCC ? curr.idCC : idcc
      if (isCreation) delete curr.id
      delete curr.idCC
      if (isAll(idcc)) {
        delete curr.inputs.idCC
        delete curr.inputs.idccRattachement
        delete curr.inputs.titre
      }
      if (acc.get(idCC)) acc.get(idCC).push(curr)
      else acc.set(idCC, [curr])
      return acc
    }, new Map())
    .forEach((v, k) => {
      isCreation
        ? promises.push(() => getPayeService().postChildren(v, CC_ENDPOINT, k, THEME_ENDPOINT, nom, DATA_ENDPOINT))
        : promises.push(() => getPayeService().patchChild(v, CC_ENDPOINT, k, THEME_ENDPOINT, nom, DATA_ENDPOINT))
    })
}

export function resolveAndThen(promises, dispatch) {
  return promises.reduce((prev, cur) => {
    return prev.then(() =>
      cur().catch(e => {
        if (!e.doublons) console.log(e)
        else e.doublons.forEach(doublon => dispatch(showAlert('error', doublon.getMessage())))
      })
    )
  }, Promise.resolve())
}

export const saveMultiLines = () => (dispatch, getState) => {
  const toSaveList = getState().tableurModule.dataToSave
  const workflow = getState().workflowModule !== ModelInstanceWorkflow.NULL
  const { idcc } = getState().conventionModule.conventionCollective
  const { nom, statut } = getState().conventionModule.theme
  if (toSaveList.size)
    return Promise.resolve()
      .then(() => dispatch(setTableurLoader(true)))
      .then(() => {
        const promises = []
        createMultiLinesForSave(toSaveList, idcc, promises, nom, true, workflow)
        createMultiLinesForSave(toSaveList, idcc, promises, nom, false, workflow)
        return Promise.resolve().then(() => resolveAndThen(promises))
      })
      .then(() => dispatch(clearAllTableur(false)))
      .then(() => {
        if (statut !== Statut.MODIFICATION_EN_COURS) return dispatch(getCCTheme(idcc, nom))
      })
      .then(() => dispatch(loadData(nom)))
      .catch(error => console.log(error))
      .finally(() => dispatch(setTableurLoader()))
}

export const setSelected = (shouldSelect, id, idCC) => dispatch => {
  dispatch({ type: shouldSelect ? ADD_IN_SELECTED : REMOVE_FROM_SELECTED, value: { id, idCC } })
}

export const selectAll = (shouldSelect) => (dispatch, getState) => {
  dispatch({
    type: shouldSelect ? ADD_ALL_IN_SELECTED : REMOVE_ALL_FROM_SELECTED,
    value: getComputedData(getState()).map( d => ({ id: d.id, idCC: d.idCC }))
  })
}

export const clearSelected = () => dispatch => {
  dispatch({ type: CLEAR_SELECTED })
}

export const clearToSave = () => dispatch => {
  dispatch({ type: CLEAR_DATA_TO_SAVE })
}

export const clearSetOutputs = () => dispatch => {
  dispatch({ type: CLEAR_SET_OUTPUT })
}
export const clearTmpData = () => dispatch => {
  dispatch({ type: CLEAR_TMP_DATA })
}

export const alertCopyPastError = (parentPath, componentName, editValue, line) => (dispatch, getState) =>
  Promise.resolve().then(() => {
    return Promise.resolve().then(() => {
      if (getState().alerts.list.length <= 10) {
        const message =
          'Erreur de collage - ligne ' +
          (line + 1) +
          ' valeur invalide pour ' +
          (parentPath ? parentPath + '.' : '') +
          componentName +
          ' : "' +
          editValue +
          '"'
        console.error(message)
        dispatch(showAlert('error', message, true))
      }
    })
  })

export const addSetOutPut = (localSetOutput, id) => ({
  type: ADD_SET_OUTPUT,
  value: { localSetOutput, id },
})

export const addTmpData = tmpData => ({
  type: ADD_TMP_DATA,
  value: { tmpData, id: tmpData.id + '_' + tmpData.idCC },
})

export const setActiveComp = (comps, isInput) => ({
  type: isInput ? SET_ACTIVE_INPUTS : SET_ACTIVE_OUTPUTS,
  value: comps,
})

export const handleTmpData = (key, value, component, myTmpData, isInput, line) => (dispatch, getState) => {
  const conventions = getState().conventionModule.conventionsCollectives
  const referentiels = getState().tableurModule.referentiels
  const page = getState().tableurModule.page
  const pageSize = getState().tableurModule.pageSize
  const data = getComputedData(getState())[line + page * pageSize]
  const tmpData = getState().tableurModule.tmpData.get(data.id + '_' + data.idCC)
  const legalOutputs = getState().conventionModule.legalOutputs
  handleTmpDataExtracted(
    myTmpData,
    tmpData,
    data,
    value,
    isInput,
    key,
    component,
    dispatch,
    line,
    referentiels,
    conventions,
    data.idCC,
    legalOutputs
  )
}

function setOnEditComps(value) {
  return { type: SET_ON_EDIT_COMPS, value }
}

const createTmpDataFromPast = (splitLines, isInput = true, startIdx, component, dataToSaveInput, newTmpData) => (
  dispatch,
  getState
) => {
  const activeComponents = getState().tableurModule[isInput ? 'activeInputs' : 'activeOutputs']
  const outputComps = getState().tableurModule.activeOutputs
  const conventions = getState().conventionModule.conventionsCollectives
  const isAllIdcc = isAll(getState().conventionModule.conventionCollective.idcc)
  const referentiels = getState().tableurModule.referentiels
  const data = getComputedData(getState())
  const tmpData = getState().tableurModule.tmpData.get(data.id + '_' + data.idCC)
  const legalOutputs = getState().conventionModule.legalOutputs

  const promises = []
  splitLines.forEach((splitText, line) => {
    return promises.push(
      extractedNextDataAsync(
        isAllIdcc,
        newTmpData ? newTmpData : tmpData,
        data[startIdx + line],
        legalOutputs,
        splitText,
        activeComponents,
        component ? component : activeComponents[0],
        isInput,
        dataToSaveInput,
        dispatch,
        startIdx + line,
        referentiels,
        conventions,
        outputComps
      )
    )
  })

  let onEditComps

  Promise.all(promises)
    .then(toSaveAndTmpData =>
      toSaveAndTmpData.reduce(
        (acc, [dataToSave, tmpData, comps]) => {
          if (!onEditComps) onEditComps = comps
          acc.allDataToSave.set(dataToSave.id, dataToSave)
          acc.alltmpData.set(tmpData.id + '_' + tmpData.idCC, tmpData)
          return acc
        },
        { allDataToSave: new Map(), alltmpData: new Map() }
      )
    )
    .then(allData => {
      return Promise.resolve()
        .then(() => dispatch(setOnEditComps(onEditComps)))
        .then(() => dispatch(setDataToSave(allData.allDataToSave)))
        .then(() => dispatch(setTmpData(allData.alltmpData)))
        .then(() => dispatch(setEditMode(true)))
    })
    .then(() => {
      if (getState().alerts.list.filter(a => a.isTableurError).length) dispatch(clearAllTableur(false))
    })
}

function setAllTmpDataFalse(activeComponents) {
  activeComponents.forEach(c => {
    c.withTmpData(false)
    if (isGroup(c.type)) setAllTmpDataFalse(c.enfants)
  })
}

function setTmpData(value) {
  return { type: SET_TMP_DATA, value }
}

function setDataToSave(value) {
  return { type: SET_DATA_TO_SAVE, value }
}

const SERVER_CACHE_NAME = "servercachename"
export const getExcelFile = (exportUrl, message) => () => {
  return Promise.resolve()
    .then(() => downloadFile(exportUrl, 'HEAD'))
    .then(response => downloadFile(exportUrl, 'GET', 0, message, response.headers[SERVER_CACHE_NAME]))
    .catch(e => console.error(e))
}
export const setNextLines = (splitLines, component, isInput, idx) => (dispatch, getState) => {
  if (!splitLines || !splitLines.length) return //dispatch(setAllEditLines(new Map()))
  const allDataLength = getComputedData(getState()).length
  const startIdx = idx + getState().tableurModule.pageSize * getState().tableurModule.page

  return Promise.resolve()
    .then(() => {
      return dispatch(setTableurLoader(true))
    })
    .then(() => {
      const numberOfEditbaleLines = startIdx + splitLines.length
      if (numberOfEditbaleLines > allDataLength) {
        const nbLineToCreate = numberOfEditbaleLines - allDataLength
        const newData = []
        for (let i = 0; i < nbLineToCreate; i++) newData.push(cloneObject(EMPTY_DATA))
        return dispatch(addToCreateDataInData(newData))
      }
    })
    .then(() => {
      return dispatch(
        createTmpDataFromPast(
          splitLines.map(l => l.split('\t')),
          isInput,
          startIdx,
          component
        )
      )
    })
    .catch(e => console.log(e))
    .finally(() => dispatch(setTableurLoader(false)))
}

function addToOnEdit(onEditComps, isInput, currentComponent, parentPath) {
  const parent = parentPath ? parentPath + '.' : ''
  onEditComps[isInput ? 'inputs' : 'outputs'].push(parent + currentComponent.getComponentPath())
}

function notAsync(
  isAll,
  tmpData,
  data,
  legalOutputs,
  activeComponents,
  splitText,
  c,
  isInput,
  dispatch,
  line,
  referentiels,
  conventions,
  outputComps,
  dataToSaveInput,
  onEditCompsInput
) {
  let newTmpData =
    tmpData && tmpData.id === data.id && tmpData.idCC === data.idCC ? cloneObject(tmpData) : cloneObject(data)
  let dataToSave = false

  let tmpGroup = []
  let currentGroup = false
  let groupSize = 0
  let allUnitComponents = []

  let tmpGroup2 = []
  let currentGroup2 = false
  let groupSize2 = 0
  let allUnitComponents2 = []

  let idx1 = 0
  let hasOutputs = false

  let idcComponent = 0
  let adjustGroup = 0
  let currentIndex = 0
  let lastGroup = { isOpen: false, isOpen2: false, size: 0 }
  setAllTmpDataFalse(activeComponents)
  const onEditComps = onEditCompsInput ? onEditCompsInput : { inputs: [], outputs: [] }
  for (; idx1 < splitText.length; idx1++) {
    if (lastGroup.size && !lastGroup.isOpen) {
      adjustGroup = lastGroup.size
      lastGroup = { ...lastGroup, isOpen: false, isOpen2: false }
    }
    currentIndex = activeComponents.indexOf(c) + idcComponent - adjustGroup
    let currentComponent = activeComponents[currentIndex]
    if (!currentComponent && !currentGroup) {
      hasOutputs = true
      break
    }
    ;[currentComponent, idcComponent] = getNextNonUnitComp(currentComponent, idcComponent, activeComponents, c)
    if (!currentComponent && !currentGroup) {
      hasOutputs = true
      break
    }
    if (currentComponent) addToOnEdit(onEditComps, isInput, currentComponent)
    if (currentComponent && currentComponent.type === Type.GROUP && !currentGroup) {
      tmpGroup = []
      currentGroup = currentComponent
      allUnitComponents = currentGroup.enfants
      groupSize = allUnitComponents.length
      currentComponent.enfants.forEach(c => addToOnEdit(onEditComps, isInput, c, currentComponent.getComponentPath()))
    }
    if (!!currentGroup) {
      if (!!currentGroup2) tmpGroup2.push(splitText[idx1])
      else if (!isCurrentComponentIsAnotherGroup(currentComponent, currentGroup)) {
        tmpGroup.push(splitText[idx1])
      }
      if (currentComponent === currentGroup) {
        const nextComp = activeComponents[activeComponents.indexOf(c) + idcComponent + 1]
        if (nextComp && nextComp.parentType === Type.GROUP) {
          currentComponent = nextComp
          idcComponent++
        }
      }

      if (isCurrentComponentIsAnotherGroup(currentComponent, currentGroup) || isChildGroup(currentGroup, tmpGroup)) {
        tmpGroup2 = []
        tmpGroup2.push(splitText[idx1])
        if (currentComponent) {
          currentGroup2 = currentComponent
        } else {
          lastGroup.isOpen2 = false
          currentGroup2 = currentGroup.enfants[tmpGroup.length - 1]
        }
        allUnitComponents2 = currentGroup2.enfants
        groupSize2 = allUnitComponents2.length
        currentGroup2.enfants.forEach(c => addToOnEdit(onEditComps, isInput, c, currentGroup2.getComponentPath()))
        addToOnEdit(onEditComps, isInput, currentGroup2)
        const nextComp = activeComponents[activeComponents.indexOf(c) + idcComponent + 1]
        if (nextComp && nextComp.parentType === Type.GROUP) {
          currentComponent = nextComp
          idcComponent++
        }
      }

      if (!!currentGroup2 && tmpGroup2.length === groupSize2) {
        tmpGroup = tmpGroup.concat(tmpGroup2)
        if (currentComponent && currentComponent.parentPath.split('.')[1] === currentGroup2.nom) {
          lastGroup.isOpen2 = true
        }
        if (!lastGroup.isOpen2) lastGroup.size = lastGroup.size - 1
        tmpGroup2 = []
        currentGroup2 = false
        groupSize2 = 0
      }
      if (tmpGroup.length >= groupSize || idx1 === splitText.length - 1) {
        newTmpData = createNewTmpData(
          createEditeValueByType(currentGroup, tmpGroup.join('\t')),
          newTmpData,
          isInput ? 'inputs' : 'outputs',
          // legalOutputs,
          currentGroup.nom,
          currentGroup,
          dispatch,
          line,
          referentiels,
          conventions,
          data.idCC,
          legalOutputs,
          true
        )
        if (currentComponent && currentComponent.parentPath.split('.')[0] === currentGroup.nom) {
          lastGroup.isOpen = true
        }
        if (!lastGroup.isOpen) lastGroup.size = lastGroup.size + groupSize - 1
        tmpGroup = []
        currentGroup = false
        groupSize = 0
      }
      idcComponent++

      continue
    }
    newTmpData = createNewTmpData(
      createEditeValueByType(currentComponent, splitText[idx1]),
      newTmpData,
      isInput ? 'inputs' : 'outputs',
      // legalOutputs,
      currentComponent.nom,
      currentComponent,
      dispatch,
      line,
      referentiels,
      conventions,
      data.idCC,
      legalOutputs,
      true
    )
    idcComponent++
  }

  const allComp = [...activeComponents, ...allUnitComponents]
  if (typeof newTmpData === 'object') {
    const role = isInput ? 'inputs' : 'outputs'
    dataToSave = cloneObject(newTmpData)
    Object.keys(newTmpData[role]).forEach(key => {
      let idx
      const component = allComp.find((c, i) => {
        idx = i
        return c.nom === key
      })
      dataToSave = createDatatoSave(component, dataToSave, role, key, newTmpData[role][key])
      if (isAll) {
        dataToSave.idCC = dataToSave.inputs.idCC
      }
    })
  }
  if (isInput && hasOutputs && splitText.slice(idx1 + 1).length) {
    return notAsync(
      isAll,
      newTmpData,
      data,
      legalOutputs,
      outputComps,
      splitText.slice(idx1 + 1),
      outputComps[0],
      false,
      dispatch,
      line,
      referentiels,
      conventions,
      outputComps,
      dataToSave,
      onEditComps
    )
    //return createTmpDataFromPast(splitText, false, line, null, dataToSave, newTmpData, outPutComps)
  } else {
    if (!isInput && dataToSaveInput) dataToSave['inputs'] = dataToSaveInput.inputs
    return [dataToSave, newTmpData, onEditComps]
  }
}

export async function extractedNextDataAsync(
  isAll,
  tmpData,
  data,
  legalOutputs,
  splitText,
  activeComponents,
  c,
  isInput,
  dataToSaveInput,
  dispatch,
  line,
  referentiels,
  conventions,
  outputComps
) {
  return notAsync(
    isAll,
    tmpData,
    data,
    legalOutputs,
    activeComponents,
    splitText,
    c,
    isInput,
    dispatch,
    line,
    referentiels,
    conventions,
    outputComps,
    dataToSaveInput
  )
}

import React from 'react'
import { AddItemContainer } from '../../conventions-collectives/themes/add-item-container'
import { patchCCThemesComp } from '../../../store/actions/theme-action'
import { useItemContainer } from '../../common/hooks/item-container-hooks'
import { SelectComponentTheme } from './select-component-theme'
import { ComponentTable } from './components-table'

export const CCComponentList = ({ allComponents, components, isInputs = false }) => {
  const listType = isInputs ? 'input' : 'output'
  const [selectedComp, handleSelection, handleClickOnSave] = useItemContainer(
    selectedComp => allComponents.filter(t => selectedComp.includes(t.nom)),
    (dispatch, comp) => dispatch(patchCCThemesComp(comp, isInputs))
  )
  return (
    <>
      <AddItemContainer
        onSave={handleClickOnSave}
        addDataTest={`ajouter un ${listType}`}
        saveDataTest={`ajouter cet ${listType}`}
      >
        <SelectComponentTheme
          components={allComponents}
          onChange={handleSelection}
          type={listType}
          selectedComp={selectedComp}
        />
      </AddItemContainer>
      {components ? (
        <ComponentTable components={components} listType={listType} />
      ) : (
        <div>{`Aucun ${listType} ajouté...`}</div>
      )}
    </>
  )
}

import { Type } from './type'

export function isGroup(type) {
  return type.name === Type.GROUP.name || type.name === Type.GROUP_DIVISIBLE.name
}

export function isSimpleType(type) {
  return type.name === Type.TEXT.name || type.name === Type.NUMERIC.name
}

export function isDate(type) {
  return type.name === Type.DATE.name
}

export function isNumeric(type) {
  return type.name === Type.NUMERIC.name
}

export function isText(type) {
  return type.name === Type.TEXT.name
}

export function isReferentiel(type) {
  return type.name === Type.REFERENCE.name
}

export function isList(type) {
  return type.name === Type.LIST.name || type.name === Type.LIST_EXCLUSION.name
}

export function isListExclusion(type) {
  return type.name === Type.LIST_EXCLUSION.name
}

export function hasExpansion(type) {
  return type.name === Type.LIST_EXCLUSION.name || type.name === Type.GROUP.name
}

export function isValueReference(dataComponent) {
  return dataComponent && dataComponent.refType && dataComponent.refType === 'VALUE'
}

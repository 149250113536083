import store from '../store/store'
import { showAlert } from '../store/actions/alert-action'

export function getMessage(updateEndpoint, prop, code, message) {
  updateEndpoint.http.method[prop].status[code].message = typeof message !== 'function' ? () => message : message
}

export const endpointBuilder = function(endpoint) {
  let apiEndpoint = { ...endpoint }
  const result = {
    build: function() {
      return apiEndpoint
    },
    endpoint: function(endpoint) {
      apiEndpoint.endpoint = endpoint
    },
    alert: { on: {} },
  }
  result.alert.on.status = function(prop, code, severity, message) {
    let updateEndpoint = { http: { method: {} } }
    updateEndpoint.http.method[prop] = { status: {} }
    updateEndpoint.http.method[prop].status[code] = { severity: severity }
    if (message) getMessage(updateEndpoint, prop, code, message)
    apiEndpoint = { ...updateEndpoint }
    return result
  }
  return result
}

export function showAndReturnHandledError(httpHandler, endpoint, error) {
  const { severity, message, defaultValue } = httpHandler
  if (message) store.dispatch(showAlert(severity, message(endpoint)))
  return severity === 'error' ? Promise.reject(error) : Promise.resolve(defaultValue || null)
}

export function showAndReturnStandardError(error) {
  const errorMessage = error.message ? ' : ' + error.message : ''
  const message = error.error ? error.error + errorMessage : "Une requête n'a pas abouti"
  store.dispatch(showAlert('error', message))
  return Promise.reject(error)
}

export function showAndReturnUnknownError(error) {
  console.log(error)
  store.dispatch(showAlert('error', 'Erreur Inconnue'))
  return Promise.reject(error)
}



export function selectStatus(methodStatus, object, defaultStatus) {
  return methodStatus && methodStatus['' + object.status] ? methodStatus : defaultStatus
}

export function handleError(status, error, endpoint) {
  let httpHandler = null
  if (status && status[error.status]) {
    httpHandler = {
      message: status[error.status].message,
      severity: status[error.status].severity || 'success',
      defaultValue: status[error.status].defaultValue,
    }
  } else if (endpoint.http && endpoint.http.status && endpoint.http.status[error.status]) {
    httpHandler = {
      message: endpoint.http.status[error.status].message,
      severity: endpoint.http.status[error.status].severity || 'error',
    }
  }
  return httpHandler
}


import Referentiel from '../constants/referentiel'

export const SET_REFERENTIELS = 'paye-bo/convention-collective/SET_REFERENTIELS'
export const SET_REFERENTIEL = 'paye-bo/convention-collective/SET_REFERENTIEL'


const initialState = {
    referentiels: [],
    referentiel : Referentiel.NULL
}

export default function referentiels(state = initialState, action) {
    switch (action.type) {
      case SET_REFERENTIELS:
        return { ...state, referentiels: action.value }  
      case SET_REFERENTIEL:
          return { ...state, referentiel: action.value }   
    default:
        return state
    }
}
import { Button, FormGroup, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { DropzoneDialog } from 'material-ui-dropzone'
import React, { useState } from 'react'
import getPayeService from '../../api/envService'
import { IMPORT_TEXTUALISATION_ENDPOINT } from '../../api/endpoints'

export function ImportTextualisation() {

  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(true)
  const handleSave = files => {
    if (files.length === 1) {
      setOpen(false)
      getPayeService().postFile(IMPORT_TEXTUALISATION_ENDPOINT, files[0], 'dataFile')
    }
  }
  return <Grid container style={{marginTop: 30}}>
    <Grid item xs={5}>
      <Typography data-test={'Import Textualisation'} variant='h5' style={{ lineHeight: 1.8 }}>
        Import Textualisation
      </Typography>
    </Grid>
    <Grid xs={6}>
      <FormGroup>
        <Button variant='contained' color='primary' onClick={handleClick} data-test='choose-file'>
          choisir fichier textualisation
        </Button>
        <DropzoneDialog
          showAlerts={false}
          open={open}
          onSave={handleSave}
          dropzoneClass='drop-zone'
          acceptedFiles={[
            'application/vnd.ms-excel',
            'application/octet-stream',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            '.xlsx',
          ]}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={() => setOpen(false)}
          filesLimit={1}
          submitButtonText='Importer'
          cancelButtonText='Annuler'
        />
      </FormGroup>

    </Grid>
  </Grid>}
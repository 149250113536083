import { Theme } from './theme'
import { Statut } from './historique'

export class CcTheme extends Theme {
  key
  statut
  constructor(
    idTheme,
    nom,
    description,
    themeParent,
    themeParentId,
    idcc,
    statut = Statut.MODIFICATION_EN_COURS,
    historique,
    inputType
  ) {
    super(idTheme, nom, description, themeParent, themeParentId)
    this.key = `${idcc}_${nom}`
    this.inputType=inputType
    this.statut = statut
    this.historique = historique
  }
}

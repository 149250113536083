import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { expandColumn, selectAll } from '../../../../../store/actions/tableur-action'
import TableCell from '@material-ui/core/TableCell'
import { isGroup } from '../../../../../store/constants/type-helper'
import Icon from '@material-ui/core/Icon'
import { Type } from '../../../../../store/constants/type'
import * as PropTypes from 'prop-types'
import green from '@material-ui/core/colors/green'
import pink from '@material-ui/core/colors/pink'
import { HeaderExpansion } from '../../../../../store/constants/header-expansion'
import { FilterList } from '@material-ui/icons'
import { Component } from '../../../../../store/constants/component'
import { EMPTY_LIST, TableurCellFilter } from './tableur-cell-filter'
import { CHANGE_LOG_TYPE, Statut } from '../../../../../store/constants/historique'
import Checkbox from '@material-ui/core/Checkbox'
import { getShouldHide } from '../row/table-cell-and-tooltip'

const styleClose = { color: green[300], cursor: 'pointer', height: '15px', width: '15px' }
const styleOpen = { color: pink[300], cursor: 'pointer', height: '15px', width: '15px' }

const defaultStyle = { whiteSpace: 'nowrap' }

const filterStyle = {
  display: 'inline-block',
  marginRight: 4,
  width: 12,
  height: 12,
  color: 'red',
  cursor: 'pointer',
}

function getComponentKey(role, componentPath, isUnitOfExlusion, isExlude, component) {
  return (
    role +
    's.' +
    (componentPath ? componentPath + '.' : '') +
    (isUnitOfExlusion ? (isExlude ? 'exclus' : 'inclus') : component.nom)
  )
}

function hasFilterSelector(componentKey) {
  return state => {
    const filter = state.tableurModule.filter.get(componentKey)
    return filter ? !!filter.length : false
  }
}

export function DataHeaderCell({
                                 component,
                                 role,
                                 title,
                                 componentPath,
                                 isUnitOfExlusion,
                                 isExlude,
                                 classes,
                                 notPublished = false,
                                 left = '',
                                 parent,
                               }) {
  const titleRef = useRef()
  const iconRef = useRef()
  const componentKey = getComponentKey(role, componentPath, isUnitOfExlusion, isExlude, component)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState()
  const [allSelected, setAllSelected] = useState()
  const [expanded, setExpanded] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [showNotPublished, setShowNotPublished] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const [tmpFilter, setTmpFilter] = useState(EMPTY_LIST)
  const icon = useMemo(() => (expanded ? 'keyboard_arrow_left' : 'keyboard_arrow_right'), [expanded])
  const expansions = useSelector(state => state.tableurModule.expansions)
  const hasFilter = useSelector(hasFilterSelector(componentKey))
  const selectMode = useSelector(state => state.tableurModule.selectMode)
  const theme = useSelector(state => state.conventionModule.theme)
  const latestVersion = useSelector(state => state.versionModule.version)
  const latestVersionChangeLog = useSelector(state => state.versionModule.versionChangeLogs)
  const shouldHide = useSelector(state => {
    const path = !componentPath
      ? componentPath
      : componentPath + '.' + (isUnitOfExlusion ? (isExlude ? 'exclus' : 'inclus') : component.nom)
    return getShouldHide(state.tableurModule.hideColumns, state, component, path)
  })

  useEffect(() => {
    setContainerWidth(titleRef.current.offsetWidth + iconRef.current.offsetWidth)
    if (
      !!expansions.size &&
      expansions.get(
        HeaderExpansion.getKeyByPath(componentPath ? componentPath + '.' + component.nom : component.nom, role),
      )
    )
      setExpanded(true)
  }, [])

  useEffect(() => {
    setAllSelected(false)
  }, [selectMode])

  useEffect(() => {
    if (component.autonome && !isUnitOfExlusion) {
      if (notPublished) {
        setShowNotPublished(true)
        return
      }
      if (!component.version) {
        setShowNotPublished(true)
        return
      }
      if (latestVersion.statut.name !== Statut.PUBLIE.name){
        if(latestVersionChangeLog.filter(log => log.type !== CHANGE_LOG_TYPE.SPLIT_COMPOSANT_REFERENTIAL).filter(log => log.parameters.themesImplies.filter(t => t == theme.nom).length > 0)
          .filter(
            log => log.parameters.composantsImplies.filter(compName => compName == component.nom).length > 0,
          ).length > 0){
          setShowNotPublished(true)
        }
        if (latestVersion.statut.name !== Statut.PUBLIE.name && latestVersion.nom === component.version){
            setShowNotPublished(true)
        }
      }
    } else {
      setShowNotPublished(notPublished)
    }
  }, [notPublished, latestVersionChangeLog])

  const containerStyle = useMemo(
    () =>
      containerWidth
        ? {
          ...defaultStyle,
          width: containerWidth,
        }
        : defaultStyle,
    [containerWidth],
  )

  const getComponentPath = () => (componentPath ? componentPath + '.' : '') + component.nom

  const handleClick = () => {
    dispatch(expandColumn(getComponentPath(), !expanded, role))
    setExpanded(!expanded)
  }

  const handleSelectAll = () => {
    dispatch(selectAll(!allSelected))
    setAllSelected(!allSelected)
  }
  let cellStyle = {
    display: shouldHide ? 'none' : '',
    position: component === Component.NULL ? 'sticky' : '',
    left: component === Component.NULL ? left : '',
    zIndex: component === Component.NULL ? 1001 : '',
    minWidth: role === 'ID' ? 71 : '',
  }
  return <>
    <TableCell
      style={cellStyle}
      onMouseEnter={() => setShowFilter(true)}
      onMouseLeave={() => setShowFilter(false)}
      className={showNotPublished ? classes.bgcolor_component_not_published : ''}
    >
      <div style={containerStyle} data-test={role + '_header'}>
        {(showFilter || anchorEl || hasFilter) && component !== Component.NULL && <span style={filterStyle}>
            <FilterList onClick={event => setAnchorEl(event.currentTarget)} style={{ fontSize: 12 }} />
          </span>}
        {anchorEl && <TableurCellFilter
            type={isUnitOfExlusion ? Type.LIST : component.type}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            component={component}
            setShowFilter={setShowFilter}
            componentKey={componentKey}
            tmpFilter={tmpFilter}
            setTmpFilter={setTmpFilter}
            parent={parent}
          />}
        <span ref={titleRef}>
          <> {(role !== 'Actions' || !selectMode) && title} </>
        </span>
        <>
          {role === 'Actions' && selectMode && <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              checked={allSelected}
              onClick={handleSelectAll}
            />}
        </>

        <span ref={iconRef}>
          {(isGroup(component.type) || component.type === Type.LIST_EXCLUSION) && <Icon
              data-test={`${expanded ? 'close' : 'expand'}_${role}_${component.label}`}
              fontSize="small"
              style={expanded ? styleOpen : styleClose}
              onClick={handleClick}
            >
              {icon}
            </Icon>}
        </span>
      </div>
    </TableCell>
    {isGroup(component.type) &&
    expanded &&
    component.enfants
      .map(e => {
        let result = { ...e }
        result.version = component.version
        return result
      })
      .map(e => <DataHeaderCell
          notPublished={showNotPublished}
          classes={classes}
          key={e.nom}
          component={e}
          role={role}
          title={e.label}
          componentPath={getComponentPath()}
          parent={component}
        />)}
    {component.type === Type.LIST_EXCLUSION && expanded && <>
        <DataHeaderCell
          notPublished={showNotPublished}
          classes={classes}
          isUnitOfExlusion
          title={`${component.nom}-inclus`}
          component={component.unit}
          role={role}
          componentPath={getComponentPath()}
        />
        <DataHeaderCell
          notPublished={showNotPublished}
          classes={classes}
          isUnitOfExlusion
          isExlude
          title={`${component.nom}-exclus`}
          component={component.unit}
          role={role}
          componentPath={getComponentPath()}
        />
      </>}
  </>
}

DataHeaderCell.propTypes = {
  c: PropTypes.any,
  role: PropTypes.string,
  onClick: PropTypes.any,
}

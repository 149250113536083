import Toolbar from '@material-ui/core/Toolbar'
import { ContainerTitle } from '../common/container-title'
import { Grid } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import GridOnIcon from '@material-ui/icons/GridOn'
import * as PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

export function ThemeToolbar({ classes, theme, titre }) {
  const history = useHistory()
  const idcc = useSelector(state => state.conventionModule.conventionCollective.idcc)
  const hanldOnClick = () => history.push('/tableur/' + idcc + '/theme/' + theme.nom)
  return <>{
    !!theme.nom
    && <Toolbar style={{ padding: 0 }}>
      <ContainerTitle classes={classes} title={`Thème ${theme.nom} de la convention ${titre}`}/>
      <Grid item xs={1}>
        <Tooltip title="Tableur">
          <IconButton aria-label="Tableur" data-test="Modéliser ce thème" onClick={hanldOnClick}>
            <GridOnIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
    </Toolbar>
  }</>
}

ThemeToolbar.propTypes = {
  classes: PropTypes.any,
  theme: PropTypes.any,
  titre: PropTypes.any,
}

import { Historique } from './historique'

export class HistoriqueDetail extends Historique {
  themes

  constructor(historique, themes = [], creations = 0, modifications = 0, suppressions = 0){
    super(historique.id, historique.idcc, historique.nom, historique.statut, historique.description, historique.datePublication)
    this.themes = themes
    this.creations = creations
    this.modifications = modifications
    this.suppressions = suppressions
  }

  static NULL = new HistoriqueDetail(Historique.NULL)
}
import { Box, Typography } from '@material-ui/core'
import React from 'react'

export const Panel = ({ children, value, index, onclick, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    onClick={onclick}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
)

import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, Grid, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { AddItemButtons } from '../../../../conventions-collectives/themes/add-item-buttons'
import { hasIndex } from '../blocks/block-textualisation-popper-content'
import { TextParamType } from '../../../../../store/constants/TextParamType'
import { TextParam } from '../../../../../store/constants/TextParam'

const selectedStyle = { width: 276, backgroundColor: '#3f51b5', color: 'white', fontWeight: 'bold' }
const standardStyle = { width: 276 }

const selectedStyleCC = { width: 184, backgroundColor: '#3f51b5', color: 'white', fontWeight: 'bold' }
const standardStyleCC = { width: 184 }

const IGNORE = TextParamType.IGNORE
const LEGEND = TextParamType.LEGEND
const REMOVE_BLOCK = TextParamType.REMOVE_BLOCK
const RETEXT = TextParamType.RE_TEXT

const NEW_DISPO = "nouvelles_dispositions"
const OLD_DISPO = "anciennes_dispositions"

export function ModelTextualisationPopperContent({
                                                   className,
                                                   parameters = [],
                                                   handleCancel,
                                                   handleSave,
                                                   idx,
                                                   fromCC
                                                 }) {
  const storeComps = useSelector(state => state.componentModule.components)
  const storeThemes = useSelector(state => state.themeModule.themes)
  const [selectedType, setSelectedType] = useState(TextParamType.IGNORE)
  const [selectedTypeDispo, setSelectedTypeDispo] = useState([])
  const [selectedComp, setSelectedComp] = useState('')
  const [tmpThemes, setTmpThemes] = useState([])
  const [tmpParent, setTmpParent] = useState('')

  useEffect(() => {
    if (!hasIndex(idx)) return
    setSelectedType(parameters[idx].type)
    setSelectedComp(parameters[idx].component)
    setSelectedTypeDispo([...parameters[idx].dispositionOption])
    setTmpThemes([...parameters[idx].themesOption])
    setTmpParent(parameters[idx].parent)
  }, [idx, parameters])


  const innerHandleSave=  () => {
    const textParam = fromCC
      ? new TextParam(selectedComp, selectedType, selectedTypeDispo, tmpThemes, tmpParent)
      : new TextParam(selectedComp, selectedType)
    handleSave(textParam, idx)
  }

  let isIgnore = selectedType === IGNORE
  let isLegend = selectedType === LEGEND
  let isRemoveBlock = selectedType === REMOVE_BLOCK
  let isRetext = selectedType === RETEXT
  let hasNew = selectedTypeDispo.includes(NEW_DISPO)
  let hasOld = selectedTypeDispo.includes(OLD_DISPO)
  let onChangeType = (type) => setSelectedType(type)
  let onChangeDispo = (dispo) => setSelectedTypeDispo( selectedTypeDispo.includes(dispo) ? [...selectedTypeDispo.filter(d => d !== dispo)] : [...selectedTypeDispo, dispo])

  const allComp = useMemo(() => storeComps.filter(
    c => parameters && !parameters.find(p => p.component === c.nom)),
    [parameters]
  )

  function handleChangeTheme(e) {
    const value = e.target.value
    setTmpThemes([...value])
  }

  function handleChangeParent(e) {
    setTmpParent(e.target.value)
  }


  return (
    <>
      <Grid item xs={12}>
        <Grid container direction='column'>
          <Grid>
            <Autocomplete
              data-test='ref_composant'
              options={allComp}
              getOptionLabel={c => c.nom}
              value={selectedComp ? storeComps.find(c => c.nom === selectedComp) : null}
              style={{ width: '100%' }}
              onChange={(e, value) => {setSelectedComp(value ? value.nom : '')}}
              renderInput={params => <TextField {...params}
                                                value={params.inputProps.value}
                                                label='Selectionner un composant' />}
            />
          </Grid>
          <Grid style={{marginTop: 20}}>
            <form className={className}>
              <ToggleButtonGroup exclusive color='primary'>
                <ToggleButton style={isIgnore ? fromCC ? selectedStyleCC : selectedStyle : fromCC ? standardStyleCC : standardStyle}
                              selected={isIgnore}
                              value={"IGNORE"}
                              onClick={() => onChangeType(IGNORE)}>
                  <div>Ignore</div>
                </ToggleButton>
                <ToggleButton style={isLegend ? fromCC ? selectedStyleCC : selectedStyle : fromCC ? standardStyleCC : standardStyle}
                              selected={isLegend}
                              value={"LEGEND"}
                              onClick={() => onChangeType(LEGEND)}>Légende</ToggleButton>
                {fromCC && <ToggleButton style={isRemoveBlock ? fromCC ? selectedStyleCC : selectedStyle : fromCC ? standardStyleCC : standardStyle}
                              selected={isRemoveBlock}
                              value={"REMOVE_BLOCK"}
                              onClick={() => onChangeType(REMOVE_BLOCK)}>Détextualiser</ToggleButton>}
                {fromCC && <ToggleButton style={isRetext ? fromCC ? selectedStyleCC : selectedStyle : fromCC ? standardStyleCC : standardStyle}
                                         selected={isRetext}
                                         value={"RETEXT"}
                                         onClick={() => onChangeType(RETEXT)}>Réintégrer</ToggleButton>}
              </ToggleButtonGroup>
            </form>
          </Grid>
          {fromCC &&
          <Grid style={{marginTop: 20}}>
            <form className={className}>
              <ToggleButtonGroup exclusive color='primary'>
                <ToggleButton style={hasNew ? selectedStyle : standardStyle }
                              selected={hasNew}
                              value={NEW_DISPO}
                              onClick={() => onChangeDispo(NEW_DISPO)}>
                  <div>Nouvelles Dispositions</div>
                </ToggleButton>
                <ToggleButton style={hasOld ? selectedStyle : standardStyle}
                              selected={hasOld}
                              value={OLD_DISPO}
                              onClick={() => onChangeDispo(OLD_DISPO)}>Anciennes Dispositions</ToggleButton>
              </ToggleButtonGroup>
            </form>
            <InputLabel style={{marginTop: 20}} htmlFor="popin_version">Selectioner les thèmes concernés</InputLabel>
            <Select
              data-test={'popin_version'}
              id={'popin_version'}
              multiple
              value={tmpThemes}
              input={<Input />}
              onChange={handleChangeTheme}
              renderValue={(selected) => selected.join(', ')}
              fullWidth
              style={{width: '100%'}}
            >
              {storeThemes
                .map(option => (
                  <MenuItem value={option.nom} key={option.nom}>
                    <Checkbox checked={tmpThemes.includes(option.nom)} />
                    <ListItemText primary={`${option.nom}`} />
                  </MenuItem>
                ))}
              <MenuItem value="modifierReferentiel">
                <em>Selectioner les thèmes concernés</em>
              </MenuItem>
            </Select>

            <Grid>
              <InputLabel style={{marginTop: 20}} htmlFor="input_parent">Saisir le parent</InputLabel>
              <Input id="input_parent" value={tmpParent} onChange={handleChangeParent} style={{width: '100%'}}/>
            </Grid>
          </Grid>
          }
        </Grid>
      </Grid>

      <AddItemButtons
        onClick={() => {
        }}
        onSave={innerHandleSave}
        onCancel={handleCancel}
        showList={true}
        addDataTest=''
        cancelDataTest={'ANNULER'}
        saveDataTest={'AJOUTER'}
        isSaveDisabled={!selectedComp}
      />
    </>

  )
}

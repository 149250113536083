import getPayeService from '../../api/envService'
import { CC_ENDPOINT, HISTORIQUE_ENDPOINT, STATUT_ENDPOINT } from '../../api/endpoints'
import { HistoriqueDetail } from '../constants/historique-detail'
import {
  SET_CC_HISTORIQUE_DETAIL,
  SET_CC_HISTORIQUES,
  SET_CC_HISTORIQUES_DETAIL,
  SET_HISTORIQUE_ID,
} from '../reducers/convention-collective-reducer'
import { setLoader } from './loader-action'
import { PUBLISH_PAYLOAD } from './status-action'
import {
  buildCCHistoriquesFromJson,
  getCCHistoriques,
  getHistoriqueByStatus,
  loadConventionTablePage,
} from './convention-collective-action'
import { Statut } from '../constants/historique'
import { showAlert } from './alert-action'

export const setCCHistoriques = historiques => dispatch => {
  dispatch({ type: SET_CC_HISTORIQUES, value: historiques })
  return historiques
}

export const setAllCCHistoriquesDetails = ccHistoriques => dispatch => {
  dispatch({ type: SET_CC_HISTORIQUES_DETAIL, value: ccHistoriques })
  return ccHistoriques
}

export const setHistoriqueId = value => dispatch => {
  dispatch({ type: SET_HISTORIQUE_ID, value })
  return value
}

//export const showJSON = id => dispatch => {
//  const HISTORIQUE_JSON = 'conventions-collectives/idcc/historiques/id/jsonc'
//}

function buildHistoriqueDetailFromJSON(data) {
  return new HistoriqueDetail(
    { ...data, id: data.historiqueId ? data.historiqueId : data.id },
    data.themes,
    data.createdLines ? data.createdLines : data.creations,
    data.updatedLines ? data.updatedLines : data.modifications,
    data.deletedLines ? data.deletedLines : data.suppressions
  )
}

function setHistoriqueDetail(historiqueDetail) {
  return { type: SET_CC_HISTORIQUE_DETAIL, value: historiqueDetail }
}

function getHistoriqueDetail(idcc, id) {
  return getPayeService().getGrandchild(CC_ENDPOINT, idcc, HISTORIQUE_ENDPOINT, id)
}

export const loadHistoriqueDetail = idcc => (dispatch, getState) => {
  const id = getState().conventionModule.historiqueId
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getHistoriqueDetail(idcc, id))
    .then(data => buildHistoriqueDetailFromJSON(data))
    .then(historiqueDetail => dispatch(setHistoriqueDetail(historiqueDetail)))
    .catch(e => console.log(e))
    .finally(dispatch(setLoader(false)))
}

export const publishCCHistorique = idcc => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().patchChild(PUBLISH_PAYLOAD, CC_ENDPOINT, idcc, STATUT_ENDPOINT))
    .then(data => {
      if (data && Array.isArray(data))
        data.forEach(d => {
          if (d && d.error) dispatch(showAlert('error', d.error))
        })
    })
    .then(() => dispatch(getCCHistoriques(idcc)))
    .catch(e => console.log(e))
    .finally(() => dispatch(setLoader(false)))

export const loadPublishAllCC = () => dispatch => {
  const allHistoriqueDetail = new Map()
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getHistoriqueByStatus()
        .then(data => (!data ? [] : buildCCHistoriquesFromJson(data)))
        .then(historiques => {
            const promises = []
            historiques.forEach(historique => promises.push(getHistoriqueDetail(historique.idcc, historique.id)
              .then(data => buildHistoriqueDetailFromJSON(data))
              .then(historiqueDetail => {
                historiqueDetail.description = historique.description
                allHistoriqueDetail.set(historique.idcc, historiqueDetail)
              })))
            return Promise.all(promises)
          },
        ))
    .then(() => dispatch(setAllCCHistoriquesDetails(allHistoriqueDetail)))
    .catch(e => console.error(e))
    .finally(dispatch(setLoader()))
}

export const publishMulti = publishList => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() =>
      getPayeService().patch(
        STATUT_ENDPOINT,
        '',
        '',
        publishList.map(p => ({ idcc: p, status: 'PUBLIE' }))
      )
    )
    .then((data) =>{dispatch(setAllCCHistoriquesDetails(new Map()));return data;})
    .then((data) => {dispatch(loadConventionTablePage());return data;})
    .then(data => {
      if (data && Array.isArray(data))
        data.forEach(d => {
          if (d && d.error) dispatch(showAlert('error', d.error))
        })
    })
    .catch(e => console.error(e))
    .finally(dispatch(setLoader()))
}

import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { inputService } from '../../api/inputService'

const EnhancedTableRow = ({ item, onDelete, classes, nested, onEdit }) => {
  const [composant, setComposant] = React.useState(item)
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (nested && typeof composant === 'number') {
      inputService.getInputById(composant, setComposant)
    }
  }, [nested, composant])

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <TableRow onClick={handleClick}>
        <TableCell
          component="th"
          scope="row"
          className={clsx(nested ? classes.nested : '')}
          data-test="nameInTable"
        >
          <Typography variant="body2">
            {composant.type === 'group' && open ? (
              <ExpandLess className={classes.expandIcon} />
            ) : (
              <ExpandMore className={classes.expandIcon} />
            )}
            {composant.nom}
          </Typography>
        </TableCell>
        <TableCell data-test="labelInTable">{composant.label}</TableCell>
        <TableCell data-test="descriptionInTable">
          {composant.description}
        </TableCell>
        <TableCell data-test="typeInTable">{composant.type}</TableCell>
        <TableCell>
          <IconButton
            className={classes.button}
            aria-label="Edit"
            onClick={() => {
              onEdit(composant.id)
            }}
          >
            <EditIcon />
          </IconButton>
          {typeof onDelete === 'function' ? (
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={() => {
                onDelete(composant.id)
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
      {composant.type === 'group' && open
        ? composant.composant.map(nestedItem => {
            return <EnhancedTableRow key={nestedItem} item={nestedItem} classes={classes} nested />
          })
        : null}
    </React.Fragment>
  )
}

EnhancedTableRow.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  classes: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  nested: PropTypes.bool,
}

export default EnhancedTableRow

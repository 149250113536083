export const SET_COMPONENT_TRANSFORMATION_RULES = 'paye-bo/SET_COMPONENT_TRANSFORMATION_RULES'

const initialState = {
  transformationRules: [],
}

export default function transformationRuleModule(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT_TRANSFORMATION_RULES:
      return { ...state, transformationRules: action.value }
    default:
      return state
  }
}

export const SET_ACTIVE_TAB = 'paye-bo/activeTabs/SET_CC_THEME_COMP_TAB'

export default function activeTabs(state = 0, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.value
    default:
      return state
  }
}

import React, { useMemo, useState } from 'react'
import { DataCellButton } from './cell/data-cell-button'
import { cellEditModeSelector, getOnEdit, TableurCellContent } from './cell/tableur-cell-content'
import * as PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Type } from '../../../../../store/constants/type'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { setNextLines } from '../../../../../store/actions/tableur-action'
import { useDispatch, useSelector } from 'react-redux'
import {
  hasExpansion,
  isGroup,
  isList,
  isListExclusion,
  isReferentiel,
  isText,
} from '../../../../../store/constants/type-helper'
import { getParentName } from './data-row-helper'
import { HeaderExpansion } from '../../../../../store/constants/header-expansion'
import TableCell from '@material-ui/core/TableCell'
import { getValueBetweenChevron } from './cell/list/tableur-cell-list'
import _ from 'lodash'
import { getDataIdFromrState, TableurAction } from './action-row'
import { getListCodesfromFormule } from './cell/past-helper'
import { getUsedColumns } from '../../../../../store/selectors/tableur-selectors'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #dadde9',
    maxWidth: 800
  },
}))(Tooltip)

function cleanNumIfNeeded(component, text) {
  return component.type === Type.NUMERIC && text ? text.replaceAll(',', '.') : text
}

function splitAndClean(component, text) {
  return component.type === Type.LIST
    ? text.split(';').length === 1 && !text.split(';')[0]
      ? []
      : getListCodesfromFormule(text, isReferentiel(component.unit.type))
          .split(';')
          .map(t => cleanNumIfNeeded(component.unit, t))
    : cleanNumIfNeeded(component, text)
}

function splitAndCreatGroup(component, text) {
  const res = {}
  const split = text.split('\t')
  let supp = 0
  component.enfants.forEach((c, idx) => {
    const index = idx + supp
    const splitElement = isGroup(c.type) ? split.slice(index, index + c.enfants.length).join('\t') : split[index]
    if (splitElement) res[c.nom] = createEditeValueByType(c, splitElement)
    if (isGroup(c.type)) supp += c.enfants.length - 1
  })
  return res
}

function getRefCodes(text) {
  return text.split('-')[0].trim()
}

function getlistExRefCodes(text, isRef) {
  return getListCodesfromFormule(getRefCodes(text), isRef)
}

function getExclusionsCodes(text, isReferentiel) {
  const exclus = getValueBetweenChevron(text)
  return (
    getlistExRefCodes(
      text.includes(',<') ? text.split(',<')[0] : text.includes(';<') ? text.split(';<')[0] : text.split('<')[0],
      isReferentiel
    ) +
    '<' +
    getlistExRefCodes(exclus.substring(1, exclus.length - 1), isReferentiel) +
    '>'
  )
}

export const createEditeValueByType = (component, text) =>
  isGroup(component.type)
    ? splitAndCreatGroup(component, text)
    : isReferentiel(component.type) || (component.unit && isReferentiel(component.unit.type))
    ? isListExclusion(component.type) && isReferentiel(component.unit.type)
      ? splitAndClean(component, getExclusionsCodes(text, isReferentiel(component.unit.type)))
      : splitAndClean(component, getRefCodes(text))
    : splitAndClean(component, text.trim())

export function getShouldHide(hideColumns, state, component, compPath) {
  let path = compPath ? compPath : (component.parentPath ? component.parentPath + '.' : '') + component.nom
  return component.nom && hideColumns && !getUsedColumns(state).has(path)
}

function showCellSelector(component, prefix) {
  return state => {
    const hideColumns = state.tableurModule.hideColumns
    const shouldHide =
      component.parentType === Type.LIST_EXCLUSION
        ? getShouldHide(
            hideColumns,
            state,
            component,
            component.parentPath + '.' + (component.exclude ? 'exclus' : 'inclus')
          )
        : getShouldHide(hideColumns, state, component)
    if (shouldHide) return false
    else
      return hasExpansion(component.parentType)
        ? state.tableurModule.expansions.get(HeaderExpansion.getExpansionKey(component, prefix))
        : true
  }
}

function getLineEditMode(idx) {
  return state => {
    const dataId = getDataIdFromrState(state, idx)
    const action = state.tableurModule.action
    const selectedLine =
      state.tableurModule.action.action === TableurAction.ON_EDIT_MULTILINE &&
      !!state.tableurModule.selected.find(s => s.id + '_' + s.idCC === dataId)
    return action && (getOnEdit(action, dataId) || selectedLine)
  }
}

function replaceInnerEOL(text) {
  const regExp = /(\t|^)"[^"]*"(\r\n|$|\t)/
  let replace = ''
  const res = text.replace(regExp, txt => {
    replace = txt.replace(/"[^"]*"/, t => t.replace(/\r\n/g, '\n'))
    return replace
  })
  const indexOf = res.indexOf(replace) + replace.length
  const prefix = res.substring(0, indexOf)
  return res === text || !text.match(regExp) ? res : prefix + replaceInnerEOL(res.replace(prefix, ''))
}

export function getSplitLines(text) {
  return replaceInnerEOL(text).split('\r\n')
}

export function TabCellAndTooltip({ activeComponents, component, prefix, line }) {
  const [anchorEl, setAnchorEl] = useState()
  const [tooltipContent, setTooltipContent] = useState('')
  const showCell = useSelector(showCellSelector(component, prefix))
  const cellEditMode = useSelector(cellEditModeSelector(prefix + 's', component, line))
  const lineEditMode = useSelector(getLineEditMode(line))

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const parent = useMemo(() => findRoot(activeComponents, component), [])
  const dataTest = useMemo(
    () =>
      component.parentType === Type.LIST_EXCLUSION
        ? getDataTestForListExclusion(prefix, component, line)
        : getDataTest(prefix, component, line),
    []
  )

  const dispatch = useDispatch()

  const pastClipboard = () =>
    navigator.clipboard.readText().then(async text => {
      if (text) {
        const splitLines = getSplitLines(text)
        if (text.endsWith('\r\n')) splitLines.pop()
        handleClose()
        dispatch(setNextLines(splitLines, component, prefix === 'input', line))
      }
    })

  const cellContent = useMemo(
    () => (
      <TableurCellContent
        parent={parent}
        role={prefix + 's'}
        component={component}
        tooltipContent={setTooltipContent}
        editDataTest={dataTest}
        line={line}
      />
    ),
    []
  )

  const dataCellButton = useMemo(
    () => (
      <DataCellButton
        onClick={handleClick}
        data-test={dataTest}
        data-test-column={component.nom}
        data-test-cell={component.nom + '_' + line}
      >
        {cellContent}
      </DataCellButton>
    ),
    []
  )

  //console.log('celltooltip ===> ' + line + ' ' + prefix + ' ' + component.getComponentPath() + ' type exclu ==> ' + isListExclusion(component.type) + 'cell-edit-mode===>'+cellEditMode+' lineEditMode==>'+lineEditMode, tooltipContent)

  return (
    <TableCell style={{ display: showCell ? '' : 'none' }}>
      {cellEditMode || lineEditMode ? (
        <>{cellContent}</>
      ) : (
        <>
          {tooltipContent ? (
            <HtmlTooltip title={tooltipContent}>
              <div>{dataCellButton}</div>
            </HtmlTooltip>
          ) : (
            <>{dataCellButton}</>
          )}
          {!!parent || (
            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={pastClipboard} data-test={Boolean(anchorEl) ? 'menu_coller' : ''}>
                Coller
              </MenuItem>
            </Menu>
          )}
        </>
      )}
    </TableCell>
  )
}

TabCellAndTooltip.propTypes = {
  title: PropTypes.string,
  prefix: PropTypes.any,
  data: PropTypes.any,
  component: PropTypes.any,
  activeComponents: PropTypes.any,
  idx: PropTypes.any,
  tooltipContent: PropTypes.func,
}

function findRoot(activeComponents, component) {
  return activeComponents.find(ac => {
    return component.parentPath && component.parentPath.split('.')[0] === ac.nom
  })
}

function getDataTestForListExclusion(prefix, component, line) {
  return (
    prefix + '_' + line + '_' + getParentName(component.parentPath) + '-' + (component.exclude ? 'exclus' : 'inclus')
  )
}

function getDataTest(prefix, component, line) {
  return prefix + '_' + line + '_' + component.label
}

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { patchCCValue, setIsFocusValueFree } from '../../store/actions/values-action'
import ComponentTextValues from './component-text-values'
import ComponentNumericValues from './component-numeric-values'
import ComponentReferenceValues from './component-reference-values'
import ComponentGroupValues from './component-group-values'
import ComponentListValues from './component-list-values'
import ComponentListExclusionValues from './component-list-exclusion-values'
import * as PropTypes from 'prop-types'
import { Type } from '../../store/constants/type'
import _ from 'lodash'


export function ComponentSimpleValues({ type,popinAnchorEl }) {
  const [component, values,referentiel, idcc, themeName, role, handleSave, handleDelete]= useLoadPage(
    type
  )  

  switch (type) {
    case Type.TEXT:
      return (
        <ComponentTextValues
          component={component}
          onDelete={handleDelete}
          onSave={handleSave}
          values={values}
        />
      )
    case Type.NUMERIC:
      return (
        <ComponentNumericValues
          component={component}
          onDelete={handleDelete}
          onSave={handleSave}
          values={values}
        />
      )
    case Type.REFERENCE:
      return (
        <ComponentReferenceValues
          component={component}
          role={role}
          values={values}
          onSave={handleSave}
          onDelete={handleDelete}
          referentiel={referentiel}
          popinAnchorEl={popinAnchorEl}
        />
      )
    case Type.GROUP:
      return (<ComponentGroupValues
        component={component}
        onSave={handleSave}
        onDelete={handleDelete}
        values={values}
        idcc={idcc}
        themeName={themeName}
        role={role}
        popinAnchorEl={popinAnchorEl}
      />)
    case Type.LIST:
      return (<ComponentListValues
        values={values}
        component={component}
        onDelete={handleDelete}
        onSave={handleSave}
        popinAnchorEl={popinAnchorEl}
        idcc={idcc}
        isInput={role === 'input'}
        themeName={themeName}
      />)
      case Type.LIST_EXCLUSION:
        return (<ComponentListExclusionValues
          values={values}
          component={component}
          onDelete={handleDelete}
          onSave={handleSave}
          popinAnchorEl={popinAnchorEl}
          idcc={idcc}
          isInput={role === 'input'}
          themeName={themeName}
        />)
    default:
      return (<></>)
  }
}


const useLoadPage = ( type) => {
  const {idcc, themeName, role} = useParams()
  const component = useSelector(state => state.conventionModule[role])
  const values = useSelector(state => state.conventionModule.values.values)
  const referentiel = useSelector(state => state.conventionModule.referentiel)
  const events = useLoadEvents(role, values, type)
  return [component, values,referentiel, idcc, themeName, role,...events]

}

const useLoadEvents = (role, values, type) => {
  const dispatch = useDispatch()
  const dispatchValue = map => {
    return dispatch(patchCCValue(map, role))
  }

  const handleSave = datas => {
    let promise = Promise.resolve()
    if (datas) {
      if (_.isArray(datas)) {
        let map = new Map([...values])
        datas.forEach(data => map.set(data.key, data.value))
        promise.then(() => dispatchValue(map))
      } else {
        let keyValue = datas.key
        let valueValue = datas.value
        if (valueValue) {
          let vals = new Map([
            ...values,
            [
              keyValue,
              type === Type.NUMERIC && typeof valueValue === 'string' ? Number.parseFloat(valueValue) : valueValue,
            ],
          ])
          promise.then(() => dispatchValue(vals))
        }
      }
      promise.then(() => dispatch(setIsFocusValueFree(true)))
    }
    return promise
  }
  const handleDelete = key => {
    const cleanedValue = new Map([...values])
    if (_.isArray(key)) {
      key.forEach(k => cleanedValue.delete(k))
    } else {
      cleanedValue.delete(key)
    }
    return Promise.resolve().then(() => dispatchValue(cleanedValue))
  }


  useEffect(() => {
    dispatch(setIsFocusValueFree(true))
  }, [dispatch])
  return [handleSave, handleDelete]
}

ComponentSimpleValues.propTypes = {
  type: PropTypes.any.isRequired,
}

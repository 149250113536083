import {
  CollapseBtn,
  CollapseIcon,
  Content,
  Footer,
  Header,
  Root,
  Sidebar,
  SidebarTrigger,
  SidebarTriggerIcon,
} from '@mui-treasury/layout'
import React from 'react'
import { standardLayoutPreset } from '@mui-treasury/layout/presets'

import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import { ThemeProvider } from '@material-ui/styles'

import AppFooter from './AppFooter'
import AppHeader from './AppHeader'
import NavContent from './NavContent'
import NavHeader from './NavHeader'
import Alerts from '../Components/alerts/Alerts'
import Confirmations from '../Components/alerts/Confirmations'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { blueGrey } from '@material-ui/core/colors'

const theme = createMuiTheme()

class AppLayout extends React.Component {
  constructor(props) {
    super(props)
    this.appFooter = React.createRef()
    this.popinAnchorEl = React.createRef()
  }

  render() {
    return (
        <Root config={standardLayoutPreset}>
          {({ headerStyles, sidebarStyles }) => (
            <>
              <CssBaseline/>
              <ThemeProvider theme={theme}>
              <Header className="App-layout-header">
                <Toolbar >
                  <CollapseBtn component={IconButton} className={headerStyles.leftTrigger}>
                    <CollapseIcon/>
                  </CollapseBtn>
                  <SidebarTrigger className={headerStyles.leftTrigger}>
                    <SidebarTriggerIcon/>
                  </SidebarTrigger>
                  <AppHeader/>
                  <NavHeader/>
                </Toolbar>
              </Header>
              <Content className="App-layout-content">
                <div ref={this.popinAnchorEl}></div>
                <Container maxWidth={false}>
                  {React.cloneElement(this.props.children, { popinAnchorEl: this.popinAnchorEl })}
                </Container>
              </Content>
              <Sidebar>
                  <>
                    <div className={sidebarStyles.container}>
                      <NavContent/>
                    </div>
                    <CollapseBtn className={sidebarStyles.collapseBtn}>
                      <CollapseIcon/>
                    </CollapseBtn>
                  </>
              </Sidebar>
              <div ref={this.appFooter}>
                <Footer className="App-layout-footer">
                  <AppFooter/>
                </Footer>
              </div>
              </ThemeProvider>
              <Alerts timeout={20000} locateRef={this.appFooter} querySelector=".App-layout-footer"/>
              <Confirmations timeout={5000} locateRef={this.appFooter} querySelector=".App-layout-footer"/>
            </>
          )}
        </Root>
    )
  }
}

export default AppLayout

import React from 'react'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Box,
  makeStyles,
  Chip,
  FormControl,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(atheme => ({
    contentRoot: {
      ...atheme.mixins.gutters(),
      paddingTop: atheme.spacing(2),
      paddingBottom: atheme.spacing(2),
    },
    content: {
      padding: atheme.spacing(3),
    },
    formControl: {
      margin: atheme.spacing(1),
      maxWidth: "100%",
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      '& > *' : {
        width:'80%',
        marginRight: atheme.spacing(1)
      }
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: atheme.spacing(3),
    },
  }))

function getFind(workflows, workflowRef, wf) {
  const strings = workflows.filter(w => Object.keys(w.workflowRef).length).map(w => Object.values(w.workflowRef)[0])
  return strings.includes(wf.nom)
}

function CcWorkflowFormInstance({dataTest,workflowRef,onValueChange,idcc,disabled=false}) {
    const classes = useStyles();
    const workflows=useSelector(state=>state.workflowModule.workflows)
    const instances=workflows.filter(wf=>wf.idcc === idcc)
      .filter(wf=>wf.modelWorkflow === workflowRef)
      .filter(wf => !getFind(workflows, workflowRef, wf))

    function handleValuesChange(value){
      onValueChange && onValueChange(value)
    }

    return (
      <Box>
        <FormControl component="fieldset"  className={classes.formControl}>
          <Autocomplete data-test={`${dataTest}_list`}
                  renderOption={(option) => (
                      <span role="option" key={option.nom} data-test={`${dataTest}_list_li`}>{option.referenceAccord || option.description}</span>
                  )}
                  options={instances}
                  getOptionLabel={val => val.referenceAccord || val.description}
                  onChange={(e,value)=>handleValuesChange(value)}
                  renderInput={(params) => <TextField {...params} label={`Choisir le workflow de référence pour ${workflowRef}`}/>}
                  disabled={disabled}
                  // renderValue={(selected) => (
                  //   <div className={classes.chips}>
                  //     {selected.map((value) => (
                  //       <Chip key={value} label={referentiel.valeurs.find(v=>v.code===value).libelle} className={classes.chip} />
                  //     ))}
                  //   </div>
                  // )}
                  />
        </FormControl>
      </Box>
    )
  }

  CcWorkflowFormInstance.propTypes = {
    dataTest : PropTypes.string.isRequired,
    workflowRef : PropTypes.string.isRequired,
    onValueChange : PropTypes.func
  }

  export default CcWorkflowFormInstance


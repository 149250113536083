import { ModelReplacement } from './ModelReplacement'
import { Type } from './type'

export class ModelRefField {
  type
  component
  targetOperator
  targetValue
  selectedTargetValue
  newValue

  constructor(
    type = Type.NULL,
    component = '',
    targetOperator = '',
    targetValue = '',
    refReplacement = ModelReplacement.NULL,
    selectedTargetValue
  ) {
    this.type = type
    this.component = component
    this.targetOperator = targetOperator
    this.targetValue = targetValue
    this.newValue = refReplacement
    this.selectedTargetValue = selectedTargetValue
  }

  static NULL = new ModelRefField()
}

export const ALL_REF_TYPES = [
  Type.NULL,
  Type.TEXT,
  Type.NUMERIC,
  Type.REFERENCE,
  Type.DATE,
  Type.LIST,
  Type.LIST_EXCLUSION,
  Type.TYPE_THEME,
]
export const ALL_NEW_TYPES = [
  Type.NULL,
  Type.TEXT,
  Type.NUMERIC,
  Type.REFERENCE,
  Type.DATE,
  Type.LIST,
  Type.LIST_EXCLUSION,
]

export class OperationUnit {
  name
  label
  constructor(name, label) {
    this.name = name
    this.label = label ? label : name
  }

  static NULL = new OperationUnit('')
  static JOUR = new OperationUnit('jour')
  static MOIS = new OperationUnit('mois')
  static ANNEE = new OperationUnit('annee', 'année')
  static ALL = [OperationUnit.NULL, OperationUnit.JOUR, OperationUnit.MOIS, OperationUnit.ANNEE]
  static fromStr = str => (str ? OperationUnit.ALL.find(o => str.toLowerCase() === o.name) : str)
}

import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { getCCById, loadThemeComponentsOfCC } from '../../store/actions/convention-collective-action'
import { Grid, makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { useParams } from 'react-router'
import { ThemeTabs } from './theme-tabs'
import { PayeLabel } from '../common/paye-label'
import LinearProgress from '@material-ui/core/LinearProgress'
import { setTab } from '../../store/actions/tab-action'
import { Breadcrumb } from '../common/breadcrumb'
import { useBreadcrumb } from '../common/hooks/breadcrumbs-hooks'
import { ThemeToolbar } from './theme-toolbar'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

export const ThemeDetail = () => {
  const classes = useStyles()
  const [theme, titre, idcc] = useLoadPage()
  const loader = useSelector(state => state.loader)
  const [breadcrumbValues] = useBreadcrumb([idcc, `/detail-convention/${idcc}`, theme.nom])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div>
          <Paper className={classes.contentRoot} elevation={1}>
            <ThemeToolbar classes={classes} theme={theme} titre={titre} />
            <Breadcrumb values={breadcrumbValues} />
            <PayeLabel dataName={'idTheme'} disabled={true} otherLabel={'ID Theme'} value={theme.idTheme || ''} />
            <PayeLabel dataName={'nom'} disabled={true} value={theme.nom} />
            <PayeLabel dataName={'description'} disabled={true} value={theme.description} />
            <PayeLabel dataName={'themeParent'} disabled={true} value={theme.themeParent} />
          </Paper>
          {loader ? <LinearProgress /> : <ThemeTabs />}
        </div>
      </Grid>
    </Grid>
  )
}

function useLoadPage() {
  const { idcc, themeName } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCCById(idcc))
    dispatch(loadThemeComponentsOfCC(idcc, themeName))
    dispatch(setTab(0))
  }, [dispatch, idcc, themeName])

  const {
    conventionCollective: { titre },
    theme,
  } = useSelector(state => state.conventionModule)

  return [theme, titre, idcc]
}

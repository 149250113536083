import React,{useState,useEffect} from 'react'
import * as PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import InputText from '../../../input-text'
import CellActions from './cell-actions'

/**
 * Permet d'éditer et de modifier une valeur de type string
 */
export default function RowEditText({dataTestKey,initialValue,dataTestPrefix,save,onChange,onSave,onCancel,placeholder}) {
    const [updateAvailable,setUpdateAvailable] = useState(true)
    const [editedValue,setEditedValue] = useState(initialValue)

    const handleSave = () => {
        setUpdateAvailable(false)
        if(onSave) {
            if(!onSave(editedValue)){
                setUpdateAvailable(true)
            }
        }
    }

    const handleCancel = () => {
        setEditedValue(null)
        setUpdateAvailable(false)
        if(onCancel) onCancel()
    }

    const handleChange = (e,newVal) => {
        setEditedValue(newVal)
        if(onChange)
            onChange(newVal)
    }

    useEffect(()=>{
        setEditedValue(initialValue)
    },[initialValue])

    return (
        <>
            <TableCell key="1">
                <InputText 
                    initialValue={initialValue} 
                    dataTest={dataTestPrefix+"_edit_"+(initialValue==null ? "" : ""+initialValue)}
                    onChange={(e,newVal)=>handleChange(e,newVal)}
                    dataKey={dataTestKey}
                    placeholder={placeholder}/>
            </TableCell>
            <CellActions key="2" 
                dataTestKey={dataTestKey}
                dataTestPrefix={dataTestPrefix} 
                save={updateAvailable && save}
                cancel={true}
                edit={false}
                remove={false}
                onSave={()=>handleSave()}
                onCancel={()=>handleCancel()}/>
        </>
    )
}

RowEditText.propTypes =  {
    dataTestKey:PropTypes.string.isRequired,
    dataTestPrefix:PropTypes.string.isRequired,
    initialValue:PropTypes.string,
    save:PropTypes.bool.isRequired,
    onChange:PropTypes.func,
    onSave:PropTypes.func,
    onCancel:PropTypes.func,
    placeholder:PropTypes.string,
}
export class ModelReplacement {
  value
  operation
  refField
  operationUnit

  constructor(value, refField, operation, operationUnit = '') {
    this.value = value
    this.refField = refField
    this.operation = operation
    this.operationUnit = operationUnit
  }

  static NULL = new ModelReplacement('', '', '', '')
}
import * as PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

export function Breadcrumb({ values }) {
  const history = useHistory()
  return (
    <>
      {values.map((v, index) => (
        <span key={v.label}>
          <span data-test={`breadcrumb_${index}`} style={v.goTo && { cursor: 'pointer' }}>
            <span data-test={`breadcrumb_${v.label}`} onClick={() => v.goTo && history.push(v.goTo)}>
              {v.label}
            </span>
          </span>
          {index < values.length - 1 && ' > '}
        </span>
      ))}
    </>
  )
}

Breadcrumb.propTypes = {
  values: PropTypes.array.isRequired,
}

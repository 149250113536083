import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { ActionForm, withDefaultHeaderLabel, withEditableRow, withFormTable, withHeaders } from '../Table/form-table'
import { Grid, TableCell } from '@material-ui/core'
import { getKey } from './helper'
import AddListValues from './add-list-values'
import CellStringRenderer from '../common/table/editable/renderer/cell-string-renderer'
import { Type } from '../../store/constants/type'
import { stashPopinContext } from '../../store/actions/values-action'
import { dispatchActions } from '../../store/actions/common'
import ReferenceEdit from './component-reference-edit'
import { getCCChildReferentielById, patchReferentielValues } from '../../store/actions/referentiel-action'
import { setLoader } from '../../store/actions/loader-action'
import _ from 'lodash'

function ActionNouveau(props) {
  return <ActionForm buttonName="Nouveau" buttonDataTest="Nouvelle valeur" {...props} />
}

ActionNouveau.propTypes = {
  childIndex: PropTypes.number.isRequired,
}

function CellStringSplitterRenderer(column) {
  let Component = ({ rowKey, rowValue, component, childrenReferentiel }) => {
    const unit = component.unit
    const getValue = value => {
      if (unit.type === Type.REFERENCE) {
        const v = childrenReferentiel[unit.nom].values.find(val => val.code === value)
        return `${v.code} - ${v.libelle}`
      } else {
        return value
      }
    }
    return (
      <TableCell data-test={`component_split_cell_value`} data-key={rowKey} align="left">
        {rowValue[column].map(
          (value, index) =>
            value && (
              <React.Fragment key={index}>
                <span data-test={`component_split_label_${getValue(value)}`}>{`${getValue(value)}`}</span>
                <br />
              </React.Fragment>
            )
        )}
      </TableCell>
    )
  }
  Component.propTypes = {
    rowValue: PropTypes.any.isRequired,
    rowKey: PropTypes.string.isRequired,
    component: PropTypes.any.isRequired,
    childrenReferentiel: PropTypes.any.isRequired,
  }
  return Component
}

function CellRenderer(column) {
  let Component = ({ rowKey, rowValue }) => {
    return (
      <CellStringRenderer
        align="left"
        dataTestKey={rowKey}
        dataTestPrefix="component"
        mapper={v => v[column]}
        rowValue={rowValue}
      />
    )
  }
  Component.propTypes = {
    rowValue: PropTypes.any.isRequired,
    rowKey: PropTypes.string.isRequired,
  }
  return Component
}

let FormTable = withFormTable(
  [ActionNouveau],
  withHeaders([
    withDefaultHeaderLabel('description'),
    withDefaultHeaderLabel('valeur'),
    withDefaultHeaderLabel('actions'),
  ]),
  withEditableRow(AddListValues, [CellRenderer('description'), CellStringSplitterRenderer('valeur')])
)

export default function ComponentListValues({ component, values, onSave, onDelete, popinAnchorEl }) {
  const [valuesMap, setValuesMap] = useState(new Map([]))
  const [rowsEditKey, setRowsEditKey] = useState([])
  const [popInChildComponent, setPopInChildComponent] = useState(null)
  const loader = useSelector(state => state.loader)
  const childrenReferentiel = useSelector(state => state.conventionModule.childrenReferentiel)
  const childrenValues = useSelector(state => state.conventionModule.childValues)
  const popinContext = useSelector(state => state.conventionModule.popinContext)

  const dispatch = useDispatch()

  useEffect(() => {
    const isStash = popinContext.componentNom === component.nom
    if (isStash) {
      setValuesMap(new Map(popinContext.valuesMap))
      setRowsEditKey(popinContext.rowsEditKey)
      if (popinContext.childComponent) {
        setPopInChildComponent(popinContext.childComponent)
      } else {
        setPopInChildComponent(null)
      }
    } else {
      if (rowsEditKey.length === 0) setValuesMap(values)
    }
  }, [values, popinContext, component, dispatch, rowsEditKey])

  const handleActionEdit = () => {
    var newValuesMap = new Map(valuesMap)
    var key = getKey(newValuesMap)
    newValuesMap.set(key, { description: '', valeur: [] })
    setValuesMap(newValuesMap)
    setRowsEditKey([...rowsEditKey, key])
    return false
  }
  const handleRowCancel = () => {
    dispatch(stashPopinContext(''))
    setRowsEditKey([])
  }

  const handleSave = datas => {
    dispatch(stashPopinContext(''))
    setRowsEditKey([])
    onSave && onSave(datas)
  }

  const validateRowValue = rowValue => {
    return rowValue.description && rowValue.description.length > 1
  }

  const isValueEqual = (val1, val2) => {
    return val1.description === val2.description
  }

  const handleDelete = key => {
    dispatch(stashPopinContext(''))
    onDelete && onDelete(key)
  }

  const handleClosePopinChild = () => {
    dispatchActions(dispatch, stashPopinContext(component.nom, { childComponent: null, valuesMap, rowsEditKey }))
  }

  const handleEditReferentiel = childComponent => {
    dispatch(stashPopinContext(component.nom, { childComponent, valuesMap, rowsEditKey }))
  }

  const handleSavePopinChild = datas => {
    dispatchActions(
      dispatch,
      setLoader(true),
      datas ? patchReferentielValues(datas.referentielId, datas.refValuesMap) : undefined,
      getCCChildReferentielById(popInChildComponent),
      setLoader(false)
    )
  }

  const handleRowEdit = rowKey => {
    setRowsEditKey([...rowsEditKey, rowKey])
  }

  const unit = component.unit

  return loader ? <></> : (
    <Grid container direction="column" justify="center" alignItems="stretch">
      <Grid item style={{ width: '100%' }}>
        <FormTable
          valuesMap={valuesMap}
          dataTestPrefix="component"
          component={component}
          onActionEdit={handleActionEdit}
          onRowEdit={handleRowEdit}
          onSave={handleSave}
          onDelete={handleDelete}
          onRowCancel={handleRowCancel}
          onEditReferentiel={handleEditReferentiel}
          validateRowValue={validateRowValue}
          isValueEqual={isValueEqual}
          rowsEditKey={rowsEditKey}
          childrenReferentiel={childrenReferentiel}
          childrenValues={childrenValues}
          popinChild={popInChildComponent}
        />
        {unit.type === Type.REFERENCE && (
          <div>
            <ReferenceEdit
              component={unit}
              referentielId={childrenReferentiel[unit.nom].id}
              refValues={childrenReferentiel[unit.nom].values}
              open={_.isEqual(popInChildComponent, unit)}
              onClose={handleClosePopinChild}
              onSave={handleSavePopinChild}
              popinAnchorEl={popinAnchorEl}
            />
          </div>
        )}
      </Grid>
    </Grid>
  )
}
ComponentListValues.propTypes = {
  component: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  idcc: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
  isInput: PropTypes.bool.isRequired,
}

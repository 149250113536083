import React,{useState,useEffect} from 'react'
import * as PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import InputNumeric from '../../../input-numeric'
import CellActions from './cell-actions'
import {StringFloatConverter} from '../converter/primitive-converter'

/**
 * Permet d'éditer et de modifier une valeur de type float
 */
export default function RowEditNumeric({dataTestKey,initialValue,dataTestPrefix,save,onChange,onSave,onCancel}) {
    const [updateAvailable,setUpdateAvailable] = useState(true)
    const [editedValue,setEditedValue] = useState(initialValue)

    const handleSave = () => {
        setUpdateAvailable(false)
        if(onSave) {
            if(!onSave(editedValue)){
                setUpdateAvailable(true)
            }
        }
    }

    const handleCancel = () => {
        setEditedValue(null)
        setUpdateAvailable(false)
        if(onCancel) onCancel()
    }

    const handleChange = (e,newVal) => {
        let value=StringFloatConverter().to(newVal);
        setEditedValue(value)
        if(onChange)
            onChange(value)
    }

    useEffect(()=>{
        setEditedValue(initialValue)
    },[initialValue])

    return (
        <>
            <TableCell key="1">
                <InputNumeric 
                    initialValue={initialValue} 
                    dataTest={dataTestPrefix+"_edit_"+(initialValue ? ""+initialValue : "")}
                    onChange={(e,newVal)=>handleChange(e,newVal)}
                    dataKey={dataTestKey}/>
            </TableCell>
            <CellActions key="2" 
                dataTestKey={dataTestKey}
                dataTestPrefix={dataTestPrefix} 
                save={updateAvailable && save}
                cancel={true}
                edit={false}
                remove={false}
                onSave={()=>handleSave()}
                onCancel={()=>handleCancel()}/>
        </>
    )
}

RowEditNumeric.propTypes =  {
    dataTestKey:PropTypes.string.isRequired,
    dataTestPrefix:PropTypes.string.isRequired,
    initialValue:PropTypes.number,
    save:PropTypes.bool.isRequired,
    onChange:PropTypes.func,
    onSave:PropTypes.func,
    onCancel:PropTypes.func
}
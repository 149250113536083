export class CompValues {
  component
  value
  shouldRemove

  constructor(
    component = '',
    value = '',
    shouldRemove = false,
    ) {
    this.component = component
    this.value = value
    this.shouldRemove = shouldRemove
  }

  static NULL = new CompValues()
}
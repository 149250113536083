import _ from "lodash"

export function StringFloatConverter() {
    return {
        //string to float
        to : (value)=>{
            if(value && value !== '' && _.isString(value)){
                let result= Number.parseFloat(value)
                if(_.isNumber(result) && !_.isNaN(result)){
                    return result;
                }else{
                    return null;
                }
            }else{
                return null
            }
        },
        //float to string
        from : (value) =>{
            if(_.isNumber(value) && !_.isNaN(value)){
                return ""+value
            }else{
                return ""
            }
        }
    }

}
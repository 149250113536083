import React from 'react'
import * as PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { makeStyles, useTheme } from '@material-ui/core'
import { Panel } from '../common/panel'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  conventionItem: {
    paddingTop: 14,
    paddingBottom: 10,
    borderBottom: `1px solid #dadde7`,
  },
}))

const attachedCCsHeaders = ['Idcc', 'Titre']

export function CcAttechedTab({ value, index, attachedCCs }) {
  const theme = useTheme()
  const classes = useStyles()

  function getClassName() {
    if (index === 1) return 'cc_regionales'
    return 'cc_rattachees'
  }

    return (
      <Panel value={value} index={index} dir={theme.direction}>
      <Paper>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {attachedCCsHeaders.map(header => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {attachedCCs.map(({ idcc, titre }) => (
                <TableRow hover key={idcc} data-test={'attachedCC_row_' + idcc} className={getClassName()}>
                  <TableCell data-test={`idcc_${idcc}`}>{idcc}</TableCell>
                  <TableCell data-test={`titre_${titre}`}>{titre}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Panel>
  )
}

CcAttechedTab.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
  attachedCCs: PropTypes.any,
}

import React from 'react'
import * as PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import {Button} from '@material-ui/core'



export default function Action({buttonName,buttonDataTest,showForm,showButton,edit,onEdit,buttonVisibility=true,children}){
    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1,
        },
        useCaseButton: {
          margin: theme.spacing(1),
          visibility:buttonVisibility ? 'visible' : 'hidden'
        }
      }))
    const classes = useStyles()

    const handleEdit = () => {
        if(onEdit){
            onEdit()
        }
    }

    return (showForm || showButton) && (
            <Grid item>
                {showForm ? (
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    {children}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (<Button
                        data-test={buttonDataTest}
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={handleEdit}
                        className={classes.useCaseButton}
                        disabled={!edit}                      
                    >
                    {buttonName}
                    </Button>)
                }

            </Grid>
    )
}


Action.propTypes = {
    buttonName:PropTypes.string.isRequired,
    buttonDataTest:PropTypes.string.isRequired,
    showForm:PropTypes.bool.isRequired,
    showButton:PropTypes.bool.isRequired,
    edit:PropTypes.bool.isRequired,
    onEdit:PropTypes.func,
    buttonVisibility:PropTypes.bool
}
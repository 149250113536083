import {
  isDate,
  isGroup,
  isListExclusion,
  isSimpleType,
  isValueReference,
} from '../../../../../../store/constants/type-helper'

export function hasValuesLoadedInComponent(values) {
  return !!values
}

export function isUnitOfListExclusion(component, parent) {
  // return ((!!parent && isListExclusion(parent.type)) || isListExclusion(component.parentType)) && hasValuesLoadedInComponent(values)
  return (!!parent && isListExclusion(parent.type)) || isListExclusion(component.parentType)
}

export function canRenderValues(compValue, values, cellEditMode) {
  return (
    cellEditMode || (!!compValue && (!compValue.key || (!!compValue.key && values && values.values && values.size)))
  )
}

export function isNonGroupReferenceWithValuesLoaded(dataComponent, component, values) {
  return isValueReference(dataComponent)
    && !isGroup(component.type)
    && valuesContainsKey(values, dataComponent)
}

export function isSimpleOrDate(component) {
  return isSimpleType(component.type) || isDate(component.type)
}

function valuesContainsKey(values, dataComponent) {
  return values && values.values.get(dataComponent.key)
}

function getDataWithParentPath(data, component) {
  const path = component.parentPath + (isGroup(component.parentType) ? '.' + component.nom : '')
  return getPropByString(data, path)
}

export function getChildData(component, parent, role, data) {
  const dataElement = data[role][parent.nom]
  return !isGroup(parent.type) || (dataElement && dataElement.key)
    ? dataElement
    : getDataWithParentPath(data[role], component, parent)
}

export function getValueFromReference(parent, values, dataComponent, component) {
  return parent ? getChildValue(values, dataComponent, component, parent) : values.values.get(dataComponent.key)
}

function cleanedPropertyPath(component, parent) {
  return component.parentPath.replace(parent.nom + '.', '')
}

function getListChildValue(values, dataComponent) {
  return values.values.get(dataComponent.key)
}

function getChildValue(values, dataComponent, component, parent) {
  if (!isGroup(parent.type)) return getListChildValue(values, dataComponent)
  if (component.parentPath === parent.nom) return values.values.get(dataComponent.key)[component.nom]
  else
    return getPropByString(
      values.values.get(dataComponent.key),
      cleanedPropertyPath(component, parent) + (isGroup(component.parentType) ? '.' + component.nom : '')
    )
}

export function getPropByString(obj, propString) {
  if (!propString) return obj
  const props = propString.split('.')
  let i = 0
  for (let iLen = props.length - 1; i < iLen; i++) {
    const prop = props[i]
    const candidate = obj[prop]
    if (candidate !== undefined) obj = candidate
    else break
  }
  return obj[props[i]]
}

import React from 'react'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 900,
    minWidth: 0,
    fontSize: 18,
  },
  grow: {
    flexGrow: 1,
  },
}))

const AppHeader = () => {
  const classes = useStyles()
  return (
    <>
      <Typography noWrap color={'textPrimary'} className={classes.header}>
        PAYE - BackOffice
      </Typography>
      <div className={classes.grow} />
    </>
  )
}

export default AppHeader

import React from 'react'
import * as PropTypes from 'prop-types'
import CellActions from './renderer/cell-actions'

export function withCells(cells){
    return (props)=>cells.map((Cell,index)=>{
      return (<Cell {...props} key={index}/>)
    })
}

export function withActions(RowEditor,Cells){

    let Component=(props)=>{
   
       if(props.showForm){
           return <RowEditor {...props}/>
       }else{
           return  (
               <>
                   <Cells {...props}/>
                   <CellActions 
                       dataTestPrefix={props.dataTestPrefix} 
                       dataTestKey={props.dataTestKey}
                       save={false}
                       cancel={false}
                       edit={props.edit}
                       check={props.check}
                       remove={props.remove}
                       onEdit={props.onEdit}
                       onRemove={props.onDelete}/>
               </>
           )
       }
   }
   Component.propTypes = {
       dataTestPrefix:PropTypes.string.isRequired,
       dataTestKey:PropTypes.string.isRequired,
       showForm:PropTypes.bool.isRequired,
       edit:PropTypes.bool.isRequired,
       remove:PropTypes.bool.isRequired,
       onEdit:PropTypes.func,
       onDelete:PropTypes.func,
   }

   return Component
}
    
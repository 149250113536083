import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import Paper from '@material-ui/core/Paper'
import { makeStyles, MenuItem, Select, TablePagination } from '@material-ui/core'
import { WorkflowStatut } from '../../../store/constants/ModelInstanceWorkFlow'
import { WorkflowRow } from './workflow-row'
import { Pagination } from '@material-ui/lab'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  conventionItem: {
    paddingTop: 14,
    paddingBottom: 10,
    borderBottom: `1px solid #dadde7`,
  },
}))

const headers = ['', 'nom', 'description', 'referenceAccord', 'type', 'date validation', 'utilisateur', 'statut']
const headersWithID = ['', 'idcc', 'nom', 'description', 'referenceAccord', 'type', 'utilisateur', 'statut']


export function getWFNumber(a) {
  return +a.substring(1)
}

const CCWorkflowList = ({fromCC}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles()
  const workflows = useSelector(state => {
    let workflows = state.workflowModule.workflows
    return fromCC ? workflows.sort((a, b) => getWFNumber(b.nom) - getWFNumber(a.nom)) : workflows
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const referentiels = useSelector(state => state.referentiels.referentiels)
  const themes = useSelector(state => state.themeModule.themes)
  const workflowModels = useSelector(state => state.workflowModule.models)
  const components = useSelector(state => state.componentModule.components)
  const getStatut = (statut) => {
    let themes = Object.keys(statut)
    let result = WorkflowStatut.NULL
    for (let t of themes) {
      if (statut[t] === WorkflowStatut.PUBLIE) {
        return WorkflowStatut.PUBLIE
      } else if (statut[t] === WorkflowStatut.EN_COURS) {
          return WorkflowStatut.EN_COURS
      } else {
        result = WorkflowStatut.VALIDE
      }
    }
    return result
  }

  const headersChoose = useMemo( () => fromCC ? headers : headersWithID, [fromCC])
  return (
    <>
      <Paper>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headersChoose.map(header => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {workflows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(instance => (
                <WorkflowRow key={fromCC ? instance.nom : instance.idcc}
                             instance={instance}
                             model={workflowModels.find(m => m.nom === instance.modelWorkflow)}
                             referentiels={referentiels}
                             statut={getStatut(instance.statut)}
                             themes={themes}
                             comps={components}
                             fromCC={fromCC}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/*<TablePagination
          labelDisplayedRows={({count, page}) => "page "+(page + 1)+" sur "+(Math.ceil(count/rowsPerPage))}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={workflows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage=""
          style={{backgroundColor: '#f5f5f5'}}
        />*/}
        <Grid container direction="row" justify="flex-end" style={{paddingBottom:10, paddingTop: 10}}>
          <Grid item>
            <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
              <MenuItem value={5} >5</MenuItem>
              <MenuItem value={10} >10</MenuItem>
              <MenuItem value={20} >20</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <Pagination count={Math.ceil(workflows.length/rowsPerPage)}
                        siblingCount={1}
                        boundaryCount={2}
                        page={page + 1}
                        onChange={(e,n) => handleChangePage(e, n -1)}
                        showFirstButton={true}
                        showLastButton={true}/>
          </Grid>
        </Grid>

      </Paper>
    </>
  )
}

export default CCWorkflowList

import { useTheme } from '@material-ui/core'
import { TabPanel } from '../common/tab-pannel'
import { Panel } from '../common/panel'
import { CCComponentList } from './components/cc-component-list'
import React from 'react'
import { useInputsTheme } from './hooks/hooks'

export function ThemeTabs() {
  const theme = useTheme()
  const [inputs, outputs, allInputs, allOutputs] = useInputsTheme()
  return (
    <TabPanel titles={['INPUTS', 'OUTPUTS']}>
      <Panel value={1} index={1} dir={theme.direction}>
        <CCComponentList
          components={inputs}
          allComponents={allInputs}
          isInputs
        />
      </Panel>
      <Panel value={2} index={2} dir={theme.direction}>
        <CCComponentList components={outputs} allComponents={allOutputs} />
      </Panel>
    </TabPanel>
  )
}

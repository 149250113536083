import { Version } from '../constants/version'

export const SET_VERSIONS = 'paye-bo/versions/SET_VERSIONS'
export const SET_VERSION = 'paye-bo/versions/SET_VERSION'
export const SET_VERSION_CHANGE_LOGS = 'paye-bo/versions/SET_VERSION_CHANGE_LOGS'

const initialState = {
  versions: [],
  version: Version.NULL,
  versionChangeLogs: [],
}

export default function versionModule(state = initialState, action) {
  switch (action.type) {
    case SET_VERSIONS:
      return { ...state, versions: action.value }
    case SET_VERSION:
      return { ...state, version: action.value }
    case SET_VERSION_CHANGE_LOGS:
      return { ...state, versionChangeLogs: action.value }
    default:
      return state
  }
}

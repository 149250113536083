import Task from '../constants/task'
export const SET_TASKS = 'paye-bo/tasks/SET_TASKS'
export const SET_TASK = 'paye-bo/tasks/SET_TASK'
export const SET_LOGS = 'paye-bo/tasks/SET_LOGS'

const initialState = {
    tasks : [],
    task : Task.NULL,
    logs : []
}

export default function taskModule(state = initialState, { type, value }) {
    switch (type) {
      case SET_TASKS:
        return { ...state, tasks: value }
      case SET_TASK:
          return { ...state, task: value }
      case SET_LOGS:
              return { ...state, logs: value }
      default:
          return state
    }
}
export class Blocks {
  blocks
  sortedPartNames

  constructor(blocks = [], sortedPartNames = []) {
    this.blocks = blocks
    this.sortedPartNames = sortedPartNames
  }

  static NULL = new Blocks()
}
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setEditMode, setSelected, setTableurAction } from '../../../../../store/actions/tableur-action'
import Checkbox from '@material-ui/core/Checkbox'
import { Grid } from '@material-ui/core'
import { getComputedData } from '../../../../../store/selectors/tableur-selectors'
import { Statut } from '../../../../../store/constants/historique'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: -4,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

export function getDataIdFromrState(state, idx) {
  const ps = state.tableurModule.pageSize
  const p = state.tableurModule.page
  const d = getComputedData(state)[idx + (p * ps)]
  if (d) return d.id + '_' + d.idCC
}

export function isEditableFromState(state, idx) {
  const ouvert = state.conventionModule.conventionCollective.ouvert || state.conventionModule.conventionCollective.statut !== Statut.PUBLIE
  const ps = state.tableurModule.pageSize
  const p = state.tableurModule.page
  const d = getComputedData(state)[idx + (p * ps)]
  return d && d.editable && ouvert
}

export function dataIdSelector(idx) {
  return state => {
    return getDataIdFromrState(state, idx)
  }
}

export function isEditableSelector(idx) {
  return state => {
    return isEditableFromState(state, idx)
  }
}

export function ActionRow({ idx, backgroundClass }) {
  const classesLocal = useStyles()
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)
  const hasNoAction = useSelector(state => state.tableurModule.action === TableurAction.NULL && !state.tableurModule.editMode)
  const dataId = useSelector(dataIdSelector(idx))
  const selected = useSelector(state => state.tableurModule.selected)
  const editable = useSelector(isEditableSelector(idx))

  //!!data.length && data.slice(page * pageSize, Math.min(page * pageSize + pageSize, data.length)).map((d, idx) => {

  useEffect(() => {
      setChecked(dataId && !!selected.find(s => s.id === Number.parseInt(dataId.split('_')[0])))
  }, [selected, dataId])

  const handleDelete = () => {
    dispatch(setTableurAction(TableurAction.ON_DELETE, dataId))
  }
  const handleEdit = () => {
    dispatch(setTableurAction(TableurAction.ON_EDIT, dataId))
    dispatch(setEditMode(true))
  }
  const selectMode = useSelector(state => state.tableurModule.selectMode)
  const id = dataId ? Number.parseInt(dataId.split('_')[0]) : null
  const onClick = () => {
    dispatch(setSelected(!checked, id, dataId.split('_')[1]))
  }
  return <>
    <TableCell style={{position: 'sticky', left:  0, zIndex:1000, borderBottom: '1px solid rgba(224, 224, 224, 1)'}} className={backgroundClass}>
      {selectMode
        ? <Checkbox data-test={'select_' + (idx + 1) + ''}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={checked}
                    onChange={onClick}/>
        : <>
          {
            hasNoAction && editable && <Grid container direction="row" wrap="nowrap">
              <IconButton aria-label="delete"
                          className={classesLocal.margin}
                          data-test={'Supprimer_' + idx}
                          onClick={handleDelete}>
                <DeleteIcon fontSize="small"/>
              </IconButton>
              <IconButton
                aria-label="edit"
                className={classesLocal.margin}
                data-test={'modifier_' + idx}
                onClick={handleEdit}>
                <EditIcon fontSize="small"/>
              </IconButton>
            </Grid>
          }
        </>
      }
    </TableCell>
    <TableCell style={{position: 'sticky', left:  79, zIndex:1000}} className={backgroundClass}>{id && id > 0 ? id : ''}</TableCell>
  </>
}

ActionRow.propTypes = {
  prefix: PropTypes.any,
  data: PropTypes.any,
  idx: PropTypes.any,
}

export class TableurAction {
  name

  constructor(name = '') {
    this.name = name
  }

  static ON_DELETE = new TableurAction('ON_DELETE')
  static ON_EDIT = new TableurAction('ON_EDIT')
  static ON_EDIT_MULTILINE = new TableurAction('ON_EDIT_MULTILINE')
  static NULL = new TableurAction()
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { createConvention, setConvention } from '../../store/actions/convention-collective-action'
import { IdccInpput } from './fields/idcc-input'
import { CheckPersonnalise } from './fields/check-personnalise'
import { TitreInput } from './fields/titre-input'
import { IdccList } from './fields/idcc-list'
import { CreationButtons } from './fields/creation-button'
import { CCField } from '../../store/constants/active-cc-field'
import ConventionCollective from '../../store/constants/convention-collective'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

const initialState = {
  idcc: '',
  titre: '',
  idccNational: '',
  idccRattachement: '',
  personnalise: false,
}

export const AddConventionForm = () => {
  const classes = useStyles()
  const [state, setState] = useState(initialState)
  const [conventionsRattachees, setIdccRattachmentList] = useState([])
  const [idccNationaux, setIdccNationaux] = useState([])

  const { conventionsCollectives, goToCCTable } = useSelector(state => state.conventionModule)
  const loader = useSelector(state => state.loader)
  const dispatch = useDispatch()
  useEffect(() => {
    setIdccRattachmentList(conventionsCollectives.filter(c => !c.idccRattachement))
    setIdccNationaux(conventionsCollectives.filter(c => !c.idccNational))
  }, [conventionsCollectives])

  const history = useHistory()
  useEffect(() => {
    if (goToCCTable) history.push('/conventions-collectives')
    dispatch(setConvention(ConventionCollective.NULL))
  }, [goToCCTable, history])

  const handleChange = prop => ({ target: { value } }) => setState({ ...state, [prop]: value })
  const handleClick = async () => {
    await dispatch(createConvention(state, () => history.push(`/detail-convention/${state.idcc}`)))

  }
  const onChangePersonnalise = () =>
    setState({
      ...state,
      personnalise: !state.personnalise,
    })
  return (
    <main className={classes.content}>
      <br />
      <br />
      {loader ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Paper className={classes.contentRoot} elevation={1}>
                <Typography data-test="addForm" className={classes.title} variant="h6">
                  {"Ajout d'une convention"}
                </Typography>
                <form className={classes.container}>
                  <Grid container spacing={2}>
                    <IdccInpput className={classes.textField} idcc={state.idcc} onChange={handleChange} />
                    <CheckPersonnalise
                      className={'TextField'}
                      personnalise={state.personnalise}
                      onChange={onChangePersonnalise}
                    />
                    <TitreInput className={'TextField'} titre={state.titre} onChange={handleChange} />
                    <IdccList
                      idcc={state.idccNational}
                      onChange={handleChange('idccNational')}
                      idccListInput={idccNationaux}
                      label={'IDCC National'}
                      ccField={CCField.IDCC_NATIONAL}
                    />
                    <IdccList
                      idcc={state.idccRattachement}
                      onChange={handleChange('idccRattachement')}
                      idccListInput={conventionsRattachees}
                      ccField={CCField.IDCC_RATTACHEMENT}
                      label={'IDCC de Rattachement'}
                    />
                  </Grid>
                  <br />
                  <CreationButtons
                    classeName={classes.button}
                    onSave={handleClick}
                    cc={{ idcc: state.idcc, titre: state.titre }}
                  />
                </form>
              </Paper>
            </div>
          </Grid>
        </Grid>
      )}
    </main>
  )
}

import React from 'react'
import * as PropTypes from 'prop-types'
import CCWorkflowList from '../conventions-collectives/workflow/cc-workflow-list'

export function WorkflowInProgressTab(props) {
  return <CCWorkflowList />
}


WorkflowInProgressTab.propTypes = {
  classes: PropTypes.any,
  childrenTheme: PropTypes.any,
}
import { Menu } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import { TextBlockType } from '../../../../../store/constants/TextBlockType'

function getParent(parent) {
  if(!parent) return ''
  let split = parent.split('.')
  return split.filter((p,i) => i !== split.length - 1).join('.')
}

export const NEW_CONDITION = "##NEW##"

export function CardsMenu({ anchorEl, block, idx, onClose, handleTextPopoverOpen, parent, childIdx, grandChildIdx }) {
  return <Menu anchorEl={anchorEl}
               onClose={onClose}
               open={Boolean(anchorEl)}>
    <MenuItem onClick={e => handleTextPopoverOpen(e, idx, getParent(parent), childIdx, grandChildIdx)}>
      modifier
    </MenuItem>
    {
      block.type === TextBlockType.GROUP &&
      <MenuItem onClick={e => handleTextPopoverOpen(e, idx, parent, childIdx, grandChildIdx)}>
        Ajouter un enfant
      </MenuItem>
    }
    {
      block.conditionalTexts && block.conditionalTexts.map( (conditionalText, condiIdx) => <MenuItem key={conditionalText.name} onClick={e => handleTextPopoverOpen(e, idx, getParent(parent), childIdx, grandChildIdx, condiIdx)}>
        {conditionalText.name}
        </MenuItem>

      )

    }
    <MenuItem onClick={e =>handleTextPopoverOpen(e, idx, getParent(parent), childIdx, grandChildIdx, NEW_CONDITION)}>
    Ajouter une condition
    </MenuItem>

  </Menu>
}
import { Statut } from './historique'

export default class ConventionCollective {
  idcc
  titre
  idccNational
  idccRattachement
  personnalise
  ccRegionales
  ccRattaches
  statut
  themes
  description
  alreadyPublished
  dateFermeture
  ouvert

  constructor(
    idcc,
    titre,
    idccNational,
    idccRattachement,
    personnalise = false,
    ccRegionales = [],
    ccRattaches = [],
    statut = Statut.NULL,
    themes = [],
    description = '',
    alreadyPublished = false,
    dateFermeture,
    ouvert= true
  ) {
    this.idcc = idcc
    this.titre = titre
    this.idccNational = idccNational
    this.idccRattachement = idccRattachement
    this.personnalise = personnalise
    this.ccRegionales = ccRegionales
    this.ccRattaches = ccRattaches
    this.statut = statut
    this.themes = themes
    this.description = description
    this.alreadyPublished = alreadyPublished
    this.dateFermeture = dateFermeture
    this.ouvert = ouvert
  }

  static NULL = new ConventionCollective('', '', '', '', false, [], [])
  static ALL = new ConventionCollective('all', 'Toutes les conventions', '', '', false, [], [])
}

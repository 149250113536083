import React from 'react'
import * as PropTypes from 'prop-types'
import _ from "lodash"

function getChildProps(child,childProps={},index){
   return {...childProps,childIndex:index}
}

function isChildOfType(child,type){
    return !type || child.type === type
}

export function withPropsMapping(comp,mapping){
    let createComponent = (Component)=>{
        let mapProps=(props)=>{
            if(!mapping){
                return props
            }
            let result={}
            Object.keys(props).forEach(key=>{
                if(mapping[key]){
                    result[mapping[key]]=props[key]
                }else{
                    result[key]=props[key]
                }
            })
            return result
        }
        let newComponent = (props)=>{
            return (<Component {...(mapProps(props))}/>)
        }
        return newComponent
    }
    if(_.isArray(comp)){
        return comp.map(c=>createComponent(c))
    }else{
        return createComponent(comp)
    }
}

export function OnlyOneChild({children,childType,errorMessage,ignoreIfNotFound=false,...childProps}){
    if(React.Children.count(children)===0 && ignoreIfNotFound){
        return  null
    }
    if(!childType){
        try{
            let child=React.Children.only(children)
            return React.cloneElement(child,getChildProps(child,childProps,0))
        }catch(e){
            console.log(e)
            throw new Error(errorMessage+" -> "+e.message)
        }
    }else{
        let childs=React.Children.toArray(children).filter((child)=>{
            return isChildOfType(child,childType)
          })
        if(childs.length !== 1){
            throw new Error(errorMessage+" -> Expected one child, find "+childs.length)
        }
        return React.cloneElement(childs[0],getChildProps(childs[0],childProps,0))
    }

}

OnlyOneChild.propTypes = {
    childType:PropTypes.any,
    errorMessage:PropTypes.string,
    ignoreIfNotFound:PropTypes.bool,
}

export function SelectChild({children,childType,...childProps}){
    let result
    result=React.Children.toArray(children).filter((child)=>{
              return isChildOfType(child,childType)
            }).map((child,index)=>React.cloneElement(child,getChildProps(child,childProps,index)))
    return result
}

SelectChild.propTypes={
    childType:PropTypes.any,
    childProps:PropTypes.any
}


export function filterChildType(children,childType){
    return React.Children.toArray(children).filter(child=>child.type===childType)
}
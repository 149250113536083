import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import * as PropTypes from 'prop-types'
import React from 'react'

export const IdccInpput = ({ className, idcc, onChange, isLabel = false }) => <Grid item xs={6}>
  <TextField
    data-test={isLabel ? 'idcc_label' : 'idcc'}
    label={'IDCC'}
    className={className}
    value={idcc}
    onChange={onChange('idcc')}
    margin="normal"
    inputProps={{ maxLength: 4 }}
    fullWidth
    disabled={isLabel}
  />
</Grid>

IdccInpput.propTypes = {
  className: PropTypes.string,
  idcc: PropTypes.string,
  onChange: PropTypes.func,
  isLabel: PropTypes.bool,
}

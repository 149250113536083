export const LOGIN_FAILURE = 'paye-bo/login-state/AUTH_LOGIN_FAILURE'
export const LOGIN_START = 'paye-bo/login-state/AUTH_LOGIN_START'
export const LOGIN_SUCCESS = 'paye-bo/login-state/AUTH_LOGIN_SUCCESS'
export const LOGOUT = 'paye-bo/login-state/AUTH_LOGOUT'

export default function loginState(state = 'loading', action) {
  switch (action.type) {
    case LOGIN_START:
      return 'pending'
    case LOGIN_FAILURE:
      return 'failure'
    case LOGIN_SUCCESS:
      return 'success'
    case LOGOUT:
      return 'logged-out'
    default:
      return state
  }
}

import React from 'react'
import { LinearProgress, ThemeProvider } from '@material-ui/core'
import { useSelector } from 'react-redux'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { blueGrey } from '@material-ui/core/colors'

const theme2 = createMuiTheme({
  palette: {
    secondary: blueGrey
  },
})

const AppFooter = () => {

  const footerLoader = useSelector( state => !!state.tableurModule.exportLoader)

  return (
    <>
      <div style={{ margin: 'auto', textAlign: 'center', height: 0 }}>
      </div>
      <ThemeProvider theme={theme2}>
      {footerLoader && <LinearProgress style={{ width: 'calc(100% + 200px)', right: 30 }} color='secondary' />}

      </ThemeProvider>
    </>
  )
}

AppFooter.propTypes = {}
AppFooter.defaultProps = {}

export default AppFooter

import makeStyles from '@material-ui/core/styles/makeStyles'
import { ValidationButton } from '../../../../common/validation-button'
import { CancelButton } from '../../../../common/cancel-button'
import * as PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  top: {},
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

export const VALIDATION = 'la validation de'
export const VALIDATIONWF = 'la validation du workflow pour'
export const ANNULERWF = 'l\'annulation du workflow pour'
export const SUPPRESSION = 'la suppression de'
export const MODIFICATION = 'la modification de'

export function ConfirmMessage({ isMulti, onClick, onClick1, dataTest, styleMessage, action }) {
  const specificStyle = styleMessage || {}
  const classesButton = useStyles()
  const message = 'Confirmez-vous ' + action + ' ' + (isMulti ? 'ces lignes' : 'cette ligne') + ' ?'
  return (
    <div style={{ ...specificStyle, whiteSpace: 'nowrap' }} data-test={dataTest}>
      {message}
      <ValidationButton size="small" data-test="oui" className={classesButton.margin} onClick={onClick}>
        OUI
      </ValidationButton>
      <CancelButton data-test="non" size="small" className={classesButton.margin} onClick={onClick1}>
        NON
      </CancelButton>
    </div>
  )
}

ConfirmMessage.propTypes = {
  idx: PropTypes.any,
  classesButton: PropTypes.any,
  onClick: PropTypes.func,
  onClick1: PropTypes.func,
}
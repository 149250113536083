import { Theme } from '../constants/theme'

export const SET_THEMES = 'paye-bo/themes/SET_THEMES'
export const SET_THEME = 'paye-bo/themes/SET_THEME'
export const SET_THEME_INPUTS = 'paye-bo/themes/SET_THEME_INPUTS'
export const SET_THEME_OUTPUTS = 'paye-bo/themes/SET_THEME_OUTPUTS'

const initialState = {
  themes: [],
  theme: Theme.NULL,
  inputs: [],
  outputs: [],
}

export default function themeModule(state = initialState, action) {
  switch (action.type) {
    case SET_THEMES :
      return { ...state, themes: action.value }
    case SET_THEME :
      return { ...state, theme: action.value }
    case SET_THEME_INPUTS :
      return { ...state, inputs: action.value }
    case SET_THEME_OUTPUTS :
      return { ...state, outputs: action.value }
    default :
      return state
  }
}

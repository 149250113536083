import { Component } from '../constants/component'

export const SET_COMPONENTS = 'paye-bo/themes/SET_COMPONENTS'
export const SET_COMPONENT = 'paye-bo/themes/SET_COMPONENT'
export const SET_USED_COMPONENT = 'paye-bo/themes/SET_USED_COMPONENT'


const initialState = {
  components: [],
  component: Component.NULL,
  usedComponents: [],
}

export default function componentModule(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENTS :
      return { ...state, components: action.value.sort((a,b) => a.nom < b.nom ? -1 : 1) }
    case SET_COMPONENT :
      return { ...state, component: action.value }
    case SET_USED_COMPONENT :
      return { ...state, usedComponents: action.value }
    default :
      return state
  }
}
import { SET_ALERT, UNSET_ALERT, SET_CONFIRMATION, UNSET_CONFIRMATION } from '../reducers/alert-reducer'


export const showAlert = (severity, message, isTableurError = false) => {
  return {
    type: SET_ALERT,
    value: {
      severity: severity ? severity : 'info',
      message: message ? message : '',
      isTableurError,
    },
  }
}

export const showConfirmation = (messages, onSave,confirmMessage = "Supprimer") => {
  return {
    type: SET_CONFIRMATION,
    value: {
      messages: messages ? messages : [],
      onSave: onSave,
      confirmMessage : confirmMessage
    },
  }
}

export const closeConfirmation = (key) => {
  return {
    type: UNSET_CONFIRMATION,
    key: key,
  }
}

export const closeAlert = (key) => {
  return {
    type: UNSET_ALERT,
    key: key,
  }
}


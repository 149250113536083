import { Data } from './data'
import { Statut,getStatutFromString } from './historique'

export class CcData extends Data {
  key
  historique
  reference
  action
  statut
  constructor(key, { id, inputs, outputs, historique, reference, action, workflow,statut,editable } = Data.NULL, historiques) {
    super(id, inputs, outputs,null,null,editable)
    this.key = key
    this.historique = historique
    this.reference = reference
    this.action = action
    if(statut && statut != "" && statut.name != ""){
      this.statut=getStatutFromString(statut.name ? statut.name : statut)
    }else{
      const find = historiques.find(h => h.nom === historique)
      if(find){
        this.statut=find.statut.name === Statut.PUBLIE.name ? Statut.PUBLIE : Statut.MODIFICATION_EN_COURS
      }else if(statut && statut != "" && statut.name != ""){
        this.statut=getStatutFromString(statut.name ? statut.name : statut)
      }else{
        this.statut=Statut.NULL
      }
    }
  }
}

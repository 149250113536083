import React from 'react'
import * as PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import { Save,Cancel,Edit,Delete,Check } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  actions: {
    textAlign:"left"
  },
  action: {
    cursor:"pointer"
  }
}))
export default function CellActions({edit,check,save,remove,cancel,dataTestPrefix,dataTestKey,onSave,onCancel,onRemove,onEdit}) {
    const classes = useStyles()
    const handleSave = (e) => {
        e.stopPropagation()
        if(onSave) {
            onSave()
        }
    }

    const handleCancel = (e) => {
        e.stopPropagation()
        if(onCancel) {
            onCancel()
        }
    }

    const handleRemove = (e) => {
        e.stopPropagation()
        if(onRemove) {
            onRemove()
        }
    }

    const handleEdit = (e) => {
        e.stopPropagation()
        if(onEdit) {
            onEdit()
        }
    }

    return (
        <TableCell className={classes.actions}>
            {save && !check && (<span className={classes.action}>
                <Save onClick={handleSave} data-test={`${dataTestPrefix}_save_${dataTestKey}`} />
            </span>)}
            {save && check && (<span className={classes.action}>
                <Check onClick={handleSave} data-test={`${dataTestPrefix}_save_${dataTestKey}`} />
            </span>)}
            {edit && (<span className={classes.action}>
                <Edit onClick={handleEdit} data-test={`${dataTestPrefix}_edit_${dataTestKey}`} />
            </span>)}
            {remove && (<span className={classes.action}>
               <Delete onClick={handleRemove} data-test={`${dataTestPrefix}_delete_${dataTestKey}`} />
            </span>)}

            {cancel && (<span className={classes.action}>
                <Cancel onClick={handleCancel} data-test={`${dataTestPrefix}_cancel_${dataTestKey}`} />
            </span>)}
         </TableCell>
    )
}

CellActions.propTypes = {
    save:PropTypes.bool.isRequired,
    edit:PropTypes.bool.isRequired,
    remove:PropTypes.bool.isRequired,
    cancel:PropTypes.bool.isRequired,
    dataTestPrefix:PropTypes.string.isRequired,
    dataTestKey:PropTypes.string.isRequired,
    onSave:PropTypes.func,
    onEdit:PropTypes.func,
    onRemove:PropTypes.func,
    onCancel:PropTypes.func
}


import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionForm,
  withEditableRow,
  withFormTable,
  withDefaultHeader,
  withDefaultHeaderLabel,
  withHeaders,
  NULL_NUMBER,
} from '../Table/form-table'
import * as PropTypes from 'prop-types'
import CellStringRenderer from '../common/table/editable/renderer/cell-string-renderer'
import ReferentielValues, { RowTextEditor } from '../Referentiels/referentiel-values'
import { Grid, Button, Popover, Paper, makeStyles } from '@material-ui/core'
import { ContainerTitle } from '../common/container-title'
import { getKey } from './helper'
import { patchReferentielValues } from '../../store/actions/referentiel-action'
import { stashPopinContext } from '../../store/actions/values-action'
import { dispatchActions } from '../../store/actions/common'
import { setLoader } from '../../store/actions/loader-action'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

const cellMapper = (allReferentielValues, descriptor, rowValue) => {
  let ref = allReferentielValues.find(val => val.code === rowValue)
  if (ref) {
    return ref[descriptor]
  } else {
    return ''
  }
}

function CellRenderer(column) {
  let Component = ({ rowKey, rowValue, allReferentielValues }) => {
    return (
      <CellStringRenderer
        align="left"
        dataTestKey={rowKey}
        dataTestPrefix="component"
        mapper={v => cellMapper(allReferentielValues, column, v)}
        rowValue={rowValue}
      />
    )
  }
  Component.propTypes = {
    rowValue: PropTypes.string.isRequired,
    rowKey: PropTypes.string.isRequired,
    allReferentielValues: PropTypes.array.isRequired,
  }
  return Component
}

function ActionSelect({
  onSaveReferentiel,
  onSelectReferentiel,
  onCheckUnicityReferentiel,
  popinOpen,
  onEdit,
  onCancel,
  refValuesMap,
  allRefValuesMap,
  referentielNom,
  popinAnchorEl,
  ...props
}) {
  const classes = useStyles()
  const [itemsSelected, setItemsSelected] = useState([])

  const handleCancel = () => {
    setItemsSelected([])
    onCancel()
  }

  const handleSelect = (rowKey, rowValue, selected) => {
    if (selected) {
      let newItemsSelected = [...itemsSelected, { rowKey: rowKey, rowValue: rowValue }]
      setItemsSelected(newItemsSelected)
    } else {
      let newItemsSelected = itemsSelected.filter(val => val.rowKey !== rowKey)
      setItemsSelected(newItemsSelected)
    }
  }

  const handleValider = () => {
    onSelectReferentiel(itemsSelected.map(val => val.rowValue))
    setItemsSelected([])
  }

  const handleEdit = childIndex => {
    if (onEdit) {
      onEdit(childIndex)
    }
  }

  return (
    <ActionForm buttonName="Choisir" buttonDataTest="Choisir dans le référentiel" onEdit={handleEdit} {...props}>
      <Popover
        id={`popin_${referentielNom}`}
        open={popinOpen}
        anchorEl={popinAnchorEl.current}
        style={{ marginTop: '10px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper className={classes.contentRoot}>
          <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between" alignItems="flex-start" wrap="nowrap">
                <Grid item>
                  <ContainerTitle classes={classes} title={`Valeurs de ${referentielNom} de type reference`} />
                </Grid>
                <Grid item>
                  <Button data-test={'Close'} size="medium" onClick={handleCancel}>
                    X
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                  <Button
                    data-test={'AnnulerTop'}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Fermer
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-test={'ValiderTop'}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={handleValider}
                  >
                    Sélectionner
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="space-around" spacing={2}>
                <ReferentielValues
                  onSave={onSaveReferentiel}
                  onSelect={handleSelect}
                  values={refValuesMap}
                  referentielValuesMap={allRefValuesMap}
                  onCheckUnicity={onCheckUnicityReferentiel}
                  referentielNom={referentielNom}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                  <Button
                    data-test={'AnnulerBottom'}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Fermer
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-test={'ValiderBottom'}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={handleValider}
                  >
                    Sélectionner
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    </ActionForm>
  )
}

ActionSelect.propTypes = {
  onSaveReferentiel: PropTypes.func.isRequired,
  onSelectReferentiel: PropTypes.func.isRequired,
  onCheckUnicityReferentiel: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  refValuesMap: PropTypes.any.isRequired,
  allRefValuesMap: PropTypes.any.isRequired,
  referentielNom: PropTypes.string.isRequired,
}

let FormTable = withFormTable(
  [ActionSelect],
  withHeaders([withDefaultHeader('headerCode'), withDefaultHeader('headerLibelle'), withDefaultHeader('headerText'), withDefaultHeaderLabel('actions')]),
  withEditableRow(RowTextEditor, [CellRenderer('code'), CellRenderer('libelle'), CellRenderer('text')])
)
export default function ComponentReferenceValues({ onSave, onDelete, values, component, referentiel, popinAnchorEl }) {
  const popinContext = useSelector(state => state.conventionModule.popinContext)
  const [refValuesMap, setRefValuesMap] = useState(new Map([]))
  const [alertsOnValue, setAlertsOnValue] = useState([])
  const [actionEditKey, setActionEditKey] = useState(NULL_NUMBER)

  const allRefValuesMap = new Map([])
  referentiel.values.forEach(val => {
    allRefValuesMap.set(val.id, val)
  })
  const dispatch = useDispatch()

  useEffect(() => {
    const isStash = popinContext.componentNom === component.nom
    if (isStash) {
      setActionEditKey(popinContext.actionEditKey)
    } else {
      setActionEditKey(NULL_NUMBER)
    }
    let newRefValuesMap = new Map([])
    let componentValues = Array.from(values).map(val => val[1])
    referentiel.values.forEach(v => {
      if (!componentValues.find(val => val === v.code)) {
        newRefValuesMap.set(v.id, { code: v.code, libelle: v.libelle, text: v.text })
      }
    })
    setRefValuesMap(newRefValuesMap)
    setAlertsOnValue([])
  }, [referentiel, values, component, popinContext])

  const validateRowValue = value => {
    if (value) {
      if (_.isArray(value)) {
        return value.length > 0
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const handleCheckUnicity = itemFound => {
    if (itemFound) {
      if (alertsOnValue.length === 0 || alertsOnValue[0] !== itemFound[1].code) setAlertsOnValue([itemFound[1].code])
    } else {
      if (alertsOnValue.length > 0) setAlertsOnValue([])
    }
  }

  const handleSelectReferentiel = itemsSelected => {
    let newValues = new Map(values)
    let tempValues = []
    itemsSelected.forEach(val => {
      let v = {
        key: getKey(newValues),
        value: val.code,
      }
      newValues.set(v.key, v.value)
      tempValues.push(v)
      return v
    })
    dispatch(stashPopinContext(''))
    setActionEditKey(NULL_NUMBER)
    if (onSave) {
      onSave(tempValues)
    }
  }

  const handleSaveReferentiel = datas => {
    let newValues = new Map([...allRefValuesMap, [datas.key, datas.value]])
    dispatchActions(dispatch, setLoader(true), patchReferentielValues(referentiel.id, newValues), setLoader(false))
  }

  const handleActionEdit = index => {
    dispatch(stashPopinContext(component.nom, { actionEditKey: index }))
    return true
  }

  const handleActionCancel = () => {
    setActionEditKey(NULL_NUMBER)
    dispatch(stashPopinContext(''))
  }

  function handleDelete(rowKey) {
    dispatch(stashPopinContext(''))
    if (onDelete) {
      onDelete(rowKey)
    }
  }

  return (
    <Grid container direction="column" justify="center" alignItems="stretch">
      <Grid item>
        <FormTable
          onSave={() => {}}
          onDelete={handleDelete}
          onSaveReferentiel={handleSaveReferentiel}
          onSelectReferentiel={handleSelectReferentiel}
          onCheckUnicityReferentiel={handleCheckUnicity}
          onActionEdit={handleActionEdit}
          onActionCancel={handleActionCancel}
          valuesMap={values}
          headerName={component.nom}
          validateRowValue={validateRowValue}
          dataTestPrefix="component"
          headerCode="Code"
          headerLibelle="Libellé"
          headerText="Text"
          referentielNom={referentiel.nom}
          allReferentielValues={Array.from(allRefValuesMap).map(val => val[1])}
          edit={false}
          alertsOnValue={alertsOnValue}
          refValuesMap={refValuesMap}
          allRefValuesMap={allRefValuesMap}
          actionEditKey={actionEditKey}
          popinOpen={actionEditKey !== NULL_NUMBER}
          popinAnchorEl={popinAnchorEl}
        />
      </Grid>
    </Grid>
  )
}

ComponentReferenceValues.propTypes = {
  role: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  component: PropTypes.any.isRequired,
}

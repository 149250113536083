import axios from 'axios'
import { getStoreName } from './paye-service-db'
import store from '../store/store'
import { logOut } from '../store/actions/login-state-action'
import { showAlert } from '../store/actions/alert-action'
import {
  CC_THEME_ENDPOINT,
  CC_THEME_INPUTS_ENDPOINT,
  CC_THEME_OUTPUTS_ENDPOINT,
  COMPONENTS_ENDPOINT,
  THEME_ENDPOINT,
  THEME_INPUTS_ENDPOINT,
  THEME_OUTPUTS_ENDPOINT,
  DATA_ENDPOINT,
  REFERENTIEL_VALUES_ENDPOINT,
  CC_THEME_DATA_ENDPOINT,
  WORKFLOW_ENDPOINT,
  MODELE_WORKFLOW_ENDPOINT,
  CC_ENDPOINT, BLOCKS_ENDPOINT,
} from './endpoints'
import _ from 'lodash'
import { VALUES_ENDPOINT } from '../store/actions/values-action'
import Doublon, { TypeDoublon } from '../store/constants/Doublons'
import { setExportLoader } from '../store/actions/loader-action'
import { getCCTheme } from '../store/actions/convention-collective-action'
import { loadData } from '../store/actions/tableur-action'

const TIMEOUT = 0
const START_RESPONSE = '--- start response ---'
const START_STREAM = '--- start stream ---'
const END_RESPONSE = '--- end response ---'
const END_STREAM = '--- end stream ---'

function httpError(response) {
  if (response && response.status === 401) {
    if (response.headers && response.headers.authentication === 'loginFailed') {
      return Promise.reject(response.data)
    } else {
      store.dispatch(logOut())
    }
  } else if (response && response.status === 400) {
    store.dispatch(showAlert('error', response.data.message))
    return Promise.reject({ status: 400, error: response.data })
  } else if (response && response.status === 403) {
    return Promise.reject({ status: 403, error: response.data })
  } else if (response && response.status === 502) {
    return Promise.reject({ status: 502, error: response.data, response: response })
  } else if (response && response.status === 'DOUBLONS') {
    const doublons = response.doublons.flatMap(d =>
      d.errors.map(e => new Doublon(TypeDoublon.fromStr(e.cause), d.idcc, d.id, e.id))
    )
    console.log(doublons)
    return Promise.reject({
      status: 'DOUBLONS',
      doublons,
    })
  }
  if (response) return Promise.reject(response.data)
  else {
    return Promise.reject({ status: '000', error: 'Requête annulée' })
  }
}

axios.interceptors.response.use(
  function(response) {
    if (_.isString(response.data) && response.data.startsWith(START_RESPONSE)) {
      let startResponse = response.data.substring(START_RESPONSE.length)
      let data = null
      if (startResponse.startsWith(START_STREAM)) {
        startResponse = startResponse.substring(START_STREAM.length)
        data = startResponse.substring(0, startResponse.lastIndexOf(END_STREAM))
        startResponse = startResponse.substring(startResponse.lastIndexOf(END_STREAM) + END_STREAM.length)
      }
      startResponse = startResponse.substring('status:'.length)
      let status = startResponse.substring(0, startResponse.indexOf(':'))
      startResponse = startResponse.substring(startResponse.indexOf(':') + 1)
      let error = null
      if (startResponse.indexOf(END_RESPONSE) > 0) {
        error = startResponse.substring(0, startResponse.indexOf(END_RESPONSE))
      }
      status = parseInt(status)
      if (status === 200 || status === 202) {
        let jsonData = JSON.parse(data)
        return !jsonData.error
          ? Promise.resolve({
              status: status,
              data: jsonData,
            })
          : httpError({ status: 'DOUBLONS', doublons: jsonData.error.errors })
      } else if (status === 400) {
        return httpError({
          status: status,
          data: {
            message: error,
          },
        })
      } else {
        return httpError({
          status: status,
          data: null,
        })
      }
    }
    if (response.data.error && response.data.error.cause === 'DOUBLONS') {
      const doublons = response.data.error.errors.flatMap(d =>
        d.errors.map(e => new Doublon(TypeDoublon.fromStr(e.cause), d.idcc, d.id, e.id))
      )
      return Promise.reject({
        status: 'DOUBLONS',
        doublons,
      })
    }
    return response
  },
  function(error) {
    return httpError(error.response)
  }
)

const get = async (apiEndpoint, headers = {}) =>
  await axios({ url: completeUrl(apiEndpoint), timeout: TIMEOUT, headers })

const getById = async (apiEndpoint, id, headers = {}) =>
  await axios({ url: completeUrlWithId(apiEndpoint, id), timeout: TIMEOUT, headers })

const getChildren = async (apiEndpoint, id, childrenEndpoint) =>
  await axios({
    url: `${completeUrlWithId(apiEndpoint, id)}/${childrenEndpoint}`,
    timeout: TIMEOUT,
  })

const getIfExists = endpoints => (endpoints ? '/' + endpoints : '')

const CancelToken = axios.CancelToken

const CANCELABLE_STORE = [
  CC_THEME_DATA_ENDPOINT.endpoint,
  CC_THEME_INPUTS_ENDPOINT.endpoint,
  CC_THEME_OUTPUTS_ENDPOINT.endpoint,
]

const currentRequests = new Map()

function createAndSaveCancelToken(storeName) {
  const cancelable = CANCELABLE_STORE.includes(storeName)
  if (!cancelable) return
  const currentCanceler = currentRequests.get(storeName)
  if (currentCanceler) {
    currentCanceler('ANNULATION DE ' + storeName)
  }
  return new CancelToken(function executor(c) {
    currentRequests.set(storeName, c)
  })
}

const getGrandchildren = async (
  apiEndpoint,
  id,
  childEndpoint,
  childId,
  grandChildrenEndpoint,
  grandChildId,
  grandGrandChildrenEndpoint
) => {
  let url = `${completeUrlWithId(apiEndpoint, id)}/${childEndpoint}/${childId}/${grandChildrenEndpoint}${getIfExists(
    grandChildId
  )}${getIfExists(grandGrandChildrenEndpoint)}`
  return axios({
    url: url,
    timeout: TIMEOUT,
    cancelToken: createAndSaveCancelToken(
      getStoreName(apiEndpoint, childEndpoint, grandChildrenEndpoint, grandGrandChildrenEndpoint)
    ),
  }).then(response => {
    if (
      !(
        (grandChildrenEndpoint === DATA_ENDPOINT.endpoint || grandChildrenEndpoint === VALUES_ENDPOINT) &&
        id === 'all' &&
        !!response.data &&
        !!Object.keys(response.data)
      )
    )
      return response
    else {
      const keys = Object.keys(response.data)
      const allCC = store.getState().conventionModule.conventionsCollectives
      let newData
      if (grandChildrenEndpoint === DATA_ENDPOINT.endpoint) {
        newData = keys.flatMap(k =>
          response.data[k].map(d => {
            const cc = allCC.find(cc => cc.idcc === k)
            return {
              ...d,
              idCC: cc.idcc,
              inputs: { ...d.inputs, titre: cc.titre, idCC: cc.idcc, idccRattachement: cc.idccRattachement },
            }
          })
        )
      } else {
        newData = keys.flatMap(k =>
          response.data[k].map(d => {
            const cc = allCC.find(cc => cc.idcc === k)
            return {
              ...d,
              idCC: cc.idcc,
              inputs: { ...d.inputs, titre: cc.titre, idCC: cc.idcc, idccRattachement: cc.idccRattachement },
            }
          })
        )
      }

      return { ...response, data: newData }
    }
  })
}

const getGrandchild = async (apiEndpoint, id, childEndpoint, childId, grandChildrenEndpoint, grandchildId) => {
  const gcID = grandChildrenEndpoint === DATA_ENDPOINT.endpoint ? grandchildId.id : grandchildId
  const end = grandChildrenEndpoint ? `/${grandChildrenEndpoint}/${gcID}` : ''
  return axios({
    url: `${completeUrlWithId(apiEndpoint, id)}/${childEndpoint}/${childId}${end}`,
    timeout: TIMEOUT,
  })
}

const getGrandFather = async (grandChildrenEndpoint, id, childEndpoint, childId, apiEndpoint) => {
  if (!!childId) {
    return axios({
      url: `${completeUrlWithId(grandChildrenEndpoint, id)}/${childEndpoint}/${childId}/${apiEndpoint}`,
      timeout: TIMEOUT,
    })
  } else {
    return axios({
      url: `${completeUrlWithId(grandChildrenEndpoint, id)}/${childEndpoint}/${apiEndpoint}`,
      timeout: TIMEOUT,
    })
  }
}

const patchChild = async (
  payload,
  apiEndpoint,
  id,
  childEndpoint,
  childId,
  grandChildrenEndpoint,
  grandChildId,
  grandGrandChildEndpoint,
  method
) => {
  return await axios(
    metaData(
      `${completeUrl(apiEndpoint, id)}/${childEndpoint}${getIfExists(childId)}${getIfExists(
        grandChildrenEndpoint
      )}${getIfExists(grandChildId) + getIfExists(grandGrandChildEndpoint)}`,
      getChildrenPayload(
        getStoreName(apiEndpoint, childEndpoint, grandChildrenEndpoint, grandGrandChildEndpoint),
        payload
      ),
      !method ? 'patch' : method
    )
  )
}

const patch = async (apiEndpoint, id, childrenEndpoint, payload) =>
  await axios(
    metaData(
      completeUrl(apiEndpoint, id) + getIfExists(childrenEndpoint),
      getChildrenPayload(getStoreName(apiEndpoint, childrenEndpoint), payload),
      'patch'
    )
  )

const post = async (apiEndpoint, payload, headers) =>
  await axios(
    metaData(completeUrl(apiEndpoint), getChildrenPayload(getStoreName(apiEndpoint), payload), 'post', headers)
  )

const postChildren = async (payload, apiEndpoint, id, childEndpoint, childId, grandChildEnpoint) =>
  await axios(
    metaData(completeChildrenUrl(apiEndpoint, id, childEndpoint, childId, grandChildEnpoint), payload, 'post')
  )

const put = async (apiEndpoint, payload, id) =>
  await axios(
    metaData(completeUrlWithId(apiEndpoint, id), getChildrenPayload(getStoreName(apiEndpoint), payload), 'put')
  )

const deleteById = async (apiEndpoint, id) =>
  axios({
    url: completeUrlWithId(apiEndpoint, id),
    timeout: TIMEOUT,
    method: 'delete',
  })

const deleteChild = async (apiEndpoint, id, childEndpoint, childId, grandChildEnpoint, grandChildId) =>
  await axios({
    url: completeChildrenUrl(apiEndpoint, id, childEndpoint, childId, grandChildEnpoint, grandChildId),
    timeout: TIMEOUT,
    method: 'delete',
  })

const clear = async apiEndpoint => await axios.delete(completeUrl(apiEndpoint))

const postFile = async (apiEndpoint, file, name) => {
  let formData = new FormData()
  formData.append(name, file)
  return await axios.post(completeUrl(apiEndpoint), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const patchFile = async (apiEndpoint, file, name) => {
  let formData = new FormData()
  formData.append(name, file)
  return await axios.patch(completeUrl(apiEndpoint), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const metaData = (apiEndpoint, payload, method, headers = {}) => {
  return { url: apiEndpoint, method: method, timeout: TIMEOUT, data: payload, headers }
}

const completeUrlWithId = (apiEndpoint, id) => {
  return completeUrl(`${apiEndpoint}${id ? '/'+id : ''}`)
}

const completeUrl = (apiEndpoint, id) => {
  if (store.getState().user.testmode) {
    return `/testmode/${apiEndpoint}${id ? '/' + id : ''}`
  } else {
    return `/api/${apiEndpoint}${id ? '/' + id : ''}`
  }
}

const completeChildrenUrl = (apiEndpoint, id, childEndpoint, childId, grandChildEnpoint, grandChildId) => {
  if (store.getState().user.testmode) {
    return `/testmode/${apiEndpoint}/${id}${and(childEndpoint) +
      and(childId) +
      and(grandChildEnpoint) +
      and(grandChildId)}`
  } else {
    return `/api/${apiEndpoint}/${id}${and(childEndpoint) + and(childId) + and(grandChildEnpoint) + and(grandChildId)}`
  }
}

const and = str => (str ? '/' + str : '')

function mapToExternalComponent(payload) {
  function extracted() {
    return {
      name: payload.nom,
      label: payload.label,
      role: payload.role.label,
      description: payload.description,
      referentielName: payload.referentiel,
      defaultValue: payload.defaultValue,
      defaultValues: payload.defaultValues,
      type: payload.type.label,
      childrenNames: payload.enfants.map(child => child.nom),
      unit: payload.unit,
      autonome: payload.autonome,
      lower: payload.lower,
      upper: payload.upper,
      bornes: payload.bornes,
      ferme: payload.ferme,
      ouvertAGauche: payload.ouvertAGauche,
      ouvertADroite: payload.ouvertADroite,
      ouvert: payload.ouvert,
      consistency: payload.consistency,
      movedComp: payload.movedComp,
      split2ndRefName: payload.split2ndRefName,
      textMap: Object.fromEntries(Array.from(payload.textMap).map(entry => [entry[0], Object.fromEntries(entry[1])])),
      customText: payload.customText,
    }
  }

  if (payload.version) {
    let version = payload.version
    let transformationRule = payload.transformationRule
    payload = payload.component
    if (transformationRule)
      return {
        version: version,
        param: extracted(),
        transformationRule: transformationRule,
      }
    return {
      version: version,
      param: extracted(),
    }
  } else if (!payload.version && payload.component) {
    payload = payload.component
    return {
      param: extracted(),
    }
  }

  return extracted()
}

function getFragment(f) {
  return {
    ...f,
    type: f.type.label.toUpperCase(),
  }
}

function getCondition(c) {
  return {
    ...c,
    block: getBlock(c.block)
  }
}

function getBlock(b) {
  return {
    fragments: b.fragments.map(f => getFragment(f)),
    pluralFragments: b.pluralFragments.map(f => getFragment(f)),
    children: b.children.map(c => getBlock(c)),
    type: b.type.label.toUpperCase(),
    uniqValueOnly: b.uniqValueOnly,
    separable: b.separable,
    conditionalTexts: b.conditionalTexts.map(c => getCondition(c)),
  }
}

function getChildrenPayload(storeName, payload) {
  if (!payload) return []
  switch (storeName) {
    case CC_THEME_ENDPOINT.endpoint:
      return payload.map(p => p.nom)
    case CC_THEME_INPUTS_ENDPOINT.endpoint:
      return payload.map(p => p.nom)
    case THEME_INPUTS_ENDPOINT.endpoint:
      return payload.map(p => {
        return { component: p.nom, rank: p.rank, version: p.version }
      })
    case THEME_OUTPUTS_ENDPOINT.endpoint:
      return payload.map(p => {
        return { component: p.nom, rank: p.rank, version: p.version }
      })
    case CC_ENDPOINT.endpoint:
      if (_.isArray(payload)) {
        return payload.map(val => {
          return { ...val, closureDate: val.dateFermeture }
        })
      } else {
        return { ...payload, closureDate: payload.dateFermeture }
      }
    case CC_THEME_OUTPUTS_ENDPOINT.endpoint:
      return payload.map(p => p.nom)
    // case CC_THEME_INPUTS_VALUES_ENDPOINT.endpoint:
    //   return payload
    case THEME_ENDPOINT.endpoint:
      if (_.isArray(payload)) {
        return payload.map(val => {
          return { ...val, name: val.nom }
        })
      } else {
        return { ...payload, name: payload.nom }
      }
    case COMPONENTS_ENDPOINT.endpoint:
      if (_.isArray(payload)) {
        return payload.map(val => {
          return mapToExternalComponent(val)
        })
      } else {
        return mapToExternalComponent(payload)
      }

    case REFERENTIEL_VALUES_ENDPOINT.endpoint:
      return payload.map(val => {
        return { code: val.code, label: val.libelle, text: val.text, defaultValue: val.defaultValue }
      })
    case MODELE_WORKFLOW_ENDPOINT.endpoint:
      return {
        ...payload,
        textualisation: {
          parameters: payload.textualisation.parameters.map(p => ({ ...p, type: p.type.label.toUpperCase() })),
          blocks: payload.textualisation.blocks.map(b => getBlock(b)),
        },
      }
      case BLOCKS_ENDPOINT.endpoint:
      return {
        ...payload,
          blocks: payload.blocks.map(b => getBlock(b)),
      }
    case WORKFLOW_ENDPOINT.endpoint:
      if (_.isArray(payload)) {
        return payload.map(val => {
          let s2 = {}
          Object.keys(val.statut).forEach(s => {
            s2[s] = val.statut[s].name
          })
          return { ...val, statut: s2 }
        })
      } else {
        let s2 = {}
        if(payload.statut){
          Object.keys(payload.statut).forEach(s => {
            s2[s] = payload.statut[s].name
          })
        }
        return { ...payload, statut: s2 }
      }

    default:
      return payload
  }
}

export async function downloadFile(url, method, incr = 0, message, serverName) {
  const urlComplete = url + (serverName ? '&serverName=' + serverName : '')
  return Promise.resolve()
    .then(() => incr === 0 && store.dispatch(setExportLoader(true)))
    .then(() =>
      axios({
        url: urlComplete,
        method,
        responseType: 'blob', // important
      })
    )
    .then(response => {
      store.dispatch(setExportLoader(false))
      if (method === 'HEAD') return response
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', response.headers['content-disposition'].split('; ')[1].split('=')[1])
      document.body.appendChild(link)
      link.click()
    })
    .catch(e => {
      if (incr < 60 && e && e.status === 502) {
        const servercachename = e.response.headers['servercachename']
        return setTimeout(() => downloadFile(url, method, incr + 1, message, servercachename), 100)
      } else {
        store.dispatch(setExportLoader(false))
        if (message) store.dispatch(showAlert('error', message))
        throw e
      }
    })
}

export async function validateTheme(
  payload,
  apiEndpoint,
  id,
  childEndpoint,
  childId,
  grandChildrenEndpoint,
  method,
  serverName,
  incr = 0
) {
  return Promise.resolve()
    .then(() => incr === 0 && store.dispatch(setExportLoader(true)))
    .then(() =>
      patchChild(
        { ...payload, serverName },
        apiEndpoint.endpoint,
        id,
        childEndpoint.endpoint,
        childId,
        grandChildrenEndpoint.endpoint
      )
    )
    .then(() => {
      return Promise.resolve()
        .then(() =>
          store.dispatch(
            showAlert('success', 'Validation de la convention ' + id + ' pour le thème ' + childId + ' réussie')
          )
        )
        .then(() => store.dispatch(setExportLoader(false)))
        .then(() => store.getState().tableurModule.exportLoader || store.dispatch(getCCTheme(id, childId)))
        .then(() => store.getState().tableurModule.exportLoader || store.dispatch(loadData(childId)))
    })
    .catch(e => {
      if (incr < 60 && e && e.status === 502) {
        return setTimeout(
          () =>
            validateTheme(
              payload,
              apiEndpoint,
              id,
              childEndpoint,
              childId,
              grandChildrenEndpoint,
              method,
              e.response.headers['servercachename'],
              incr + 1
            ),
          100
        )
      } else if (e && Array.isArray(e)) {
        store.dispatch(setExportLoader(false))
        try {
          const doublons = e.flatMap(d =>
            d.errors
              .map(e => new Doublon(TypeDoublon.fromStr(e.cause), d.idcc, d.id, e.id))
              .forEach(doublon => store.dispatch(showAlert('error', doublon.getMessage())))
          )

          const res = Promise.resolve()
            .then(() => store.dispatch(getCCTheme(id, childId)))
            .then(() => store.dispatch(loadData(childId)))
          return Promise.reject({
            status: 'DOUBLONS',
            doublons,
          })
        } catch (e) {
          console.error(e)
          store.dispatch(
            showAlert('error', e.message ? e.message : 'Problème inatendu rencontré lors de la lecture des doublons')
          )
        }
      } else {
        console.error(e)
        store.dispatch(setExportLoader(false))
        if (e.cause) store.dispatch(showAlert('error', e.cause))
        else
          store.dispatch(
            showAlert('error', e.message ? e.message : 'Problème inatendu rencontré lors de la lecture des doublons')
          )
      }
    })
}

export const payeService = {
  get,
  getChildren,
  getById,
  post,
  postChildren,
  put,
  deleteById,
  deleteChild,
  clear,
  patch,
  patchChild,
  getGrandchildren,
  getGrandchild,
  postFile,
  getGrandFather,
  patchFile,
}

import User from '../constants/user'

export const SET_USER = 'paye-bo/user/SET_USER'

export default function user(state = {user:User.NULL,testmode:window.location.host === "localhost:3000"}, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.value }
    default:
      return state
  }
}

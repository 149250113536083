import {ModelInstanceWorkflow} from '../constants/ModelInstanceWorkFlow'
import { Blocks } from '../constants/Blocks'
export const SET_MODELS = 'paye-bo/workflows/SET_MODELS'
export const SET_BLOCKS = 'paye-bo/workflows/SET_BLOCKS'
export const SET_WORKFLOWS = 'paye-bo/workflows/SET_WORKFLOWS'
export const SET_WORKFLOW = 'paye-bo/workflows/SET_WORKFLOW'


const initialState = {
  models: [],
  workflows : [],
  workflow : ModelInstanceWorkflow.NULL,
  blocks: Blocks.NULL
}

export default function workflowModule(state = initialState, action) {
  switch (action.type) {
    case SET_MODELS:
      return { ...state, models: action.value }
    case SET_BLOCKS:
      return { ...state, blocks: action.value }
    case SET_WORKFLOWS:
        return { ...state, workflows: action.value }
    case SET_WORKFLOW:
            return { ...state, workflow: !action.value ? ModelInstanceWorkflow.NULL : action.value }
    default:
      return state
  }
}
import { Grid, makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import React, { useState } from 'react'
import getPayeService from '../../api/envService'
import { IMPORT_DATA_ENDPOINT } from '../../api/endpoints'
import * as PropTypes from 'prop-types'
import { ImportStructure } from './ImportStructure'
import { ImportTextualisation } from './ImportTextualisation'
import { ImportChat } from './ImportChat'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))
const ImportData = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div>
          <Paper elevation={1} style={{ padding: 15 }}>
            <ImportStructure />
            <ImportChat />
            <ImportTextualisation />
          </Paper>
        </div>
      </Grid>
    </Grid>
  )
}

export default ImportData

import withStyles from '@material-ui/core/styles/withStyles'
import pink from '@material-ui/core/colors/pink'
import Button from '@material-ui/core/Button'

export const CancelButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: pink[500],
    '&:hover': {
      backgroundColor: pink[700],
    },
  },
}))(Button)

import { Type } from './type'
import { Role } from './role'

export class Component {
  nom
  label
  role
  referentiel
  description
  type
  exclude
  enfants
  unit
  autonome
  defaultValue
  defaultValues
  parentType = Type.TEXT
  parentPath
  hasTmpData
  lower
  upper
  bornes
  ferme
  ouvertAGauche
  ouvertADroite
  ouvert
  consistency
  textMap
  customText

  constructor(
    nom = '',
    label = '',
    role = Role.INPUT_OUTPUT,
    referentiel = '',
    defaultValue = '',
    defaultValues = {},
    description = '',
    type = Type.TEXT,
    enfants = [],
    unit,
    autonome = false,
    lower = false,
    upper = false,
    bornes = false,
    ferme,
    ouvertAGauche,
    ouvertADroite,
    ouvert,
    consistency = false,
    textMap = new Map(),
    customText,
    parentPath = '',
    parentType = Type.TEXT,
    exclude = false,
    hasTmpData = false
  ) {
    this.bornes = bornes
    this.ferme = ferme
    this.ouvertAGauche = ouvertAGauche
    this.ouvertADroite = ouvertADroite
    this.ouvert = ouvert
    this.nom = nom
    this.label = label
    this.role = role
    this.referentiel = referentiel
    this.defaultValue = defaultValue
    this.defaultValues = defaultValues
    this.description = description
    this.type = type
    this.enfants = enfants
    this.unit = unit
    this.parentPath = parentPath
    this.parentType = parentType
    this.exclude = exclude
    this.hasTmpData = hasTmpData
    this.autonome = autonome
    this.lower = lower
    this.upper = upper
    this.consistency = consistency
    this.textMap = textMap
    this.customText = customText
  }

  static clone(source = Component.NULL) {
    return new Component(
      source.nom,
      source.label,
      source.role,
      source.referentiel,
      source.defaultValue,
      source.defaultValues,
      source.description,
      source.type,
      source.enfants,
      source.unit,
      source.autonome,
      source.lower,
      source.upper,
      source.parentPath,
      source.parentType,
      source.exclude,
      source.hasTmpData,
      source.bornes,
      source.ferme,
      source.ouvertAGauche,
      source.ouvertADroite,
      source.ouvert,
      source.consistency,
      source.textMap,
      source.customText
    )
  }

  withParentPath(parentPath = '') {
    this.parentPath = parentPath
    return this
  }

  withParentType(parentType = Type.TEXT) {
    this.parentType = parentType
    return this
  }

  withExclude(exclude = false) {
    this.exclude = exclude
    return this
  }

  withTmpData(hasTmpData = false) {
    this.hasTmpData = hasTmpData
    return this
  }

  getComponentPath() {
    return (this.parentPath ? this.parentPath + '.' : '') + this.nom
  }

  static NULL = new Component()

  static NULL_UNIT = new Component('', '', Role.INPUT_OUTPUT, '', '', Type.TEXT, [])

  static IDCC = new Component(
    'idCC',
    'idCC',
    Role.INPUT,
    '',
    null,
    null,
    'Identifiants de la convention collective',
    Type.TEXT
  )
  static TITRE = new Component('titre', 'titre', Role.INPUT, '', null, null, 'Nom de la convention', Type.TEXT)
  static IDCC_RATTACHEMENT = new Component(
    'idccRattachement',
    'idccRattachement',
    Role.INPUT,
    '',
    null,
    null,
    'identifiants de rattachement',
    Type.TEXT
  )
  static DEFAULT_CC_COMPONENTS = [Component.TITRE, Component.IDCC, Component.IDCC_RATTACHEMENT]
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { isApiConfig } from '../api/envService'
import { logOut } from '../store/actions/login-state-action'

const listInit = [
  {
    primaryText: 'Versions',
    route: '/versions',
    icon: 'collections',
  },
  {
    primaryText: 'Référentiels',
    route: '/referentiels',
    icon: 'menu_book',
  },
  {
    primaryText: 'Composants',
    route: '/composants',
    icon: 'extension',
  },
  {
    primaryText: 'Thèmes',
    route: '/themes',
    icon: 'widgets',
  },
  {
    primaryText: 'Conventions Collectives',
    route: '/conventions-collectives',
    icon: 'assignment',
  },
  {
    primaryText: 'Tableur',
    route: '/tableur',
    icon: 'grid_on',
  },
  {
    primaryText: 'Workflows',
    route: '/workflows',
    icon: 'account_tree',
  },
  {
    primaryText: 'Autorisations clients',
    route: '/access',
    icon: 'verified_user',
    modelisateurOnly: true,
  },
  {
    primaryText: 'Tâches',
    route: '/tasks',
    icon: 'list',
  },
]

function getElement(modelisateurOnly, isModelisateur) {
  if(!modelisateurOnly) return true
  else return modelisateurOnly && isModelisateur
}

const NavContent = () => {
  const [selected, setSelected] = useState(null)
  const user = useSelector(state => state.user.user)
  const [isModelisateur, setIsModelisateur] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const list = listInit.slice()
  useEffect(() => {
    setIsModelisateur(user.role && user.role.toUpperCase() === 'MODELISATEUR')
  }, [user])
  if (isApiConfig() && isModelisateur) {
    list.push({
      primaryText: 'Import Data',
      route: '/import-data',
      icon: 'input',
    })
  }

  const handleClick = index => {
    setSelected(index)
    history.push(list[index].route)
  }

  const handleSettings = () => {
    history.push('/settings')
  }

  const logout = () => {
    dispatch(logOut())
  }

  return (
    <List data-test="menus">
      {list.map(({ primaryText, icon, modelisateurOnly }, i) => (
        <div key={i}>{ getElement(modelisateurOnly, isModelisateur) && <ListItem
          key={i}
          selected={i === selected}
          button
          onClick={() => {
            handleClick(i)
          }}
        >
          <ListItemIcon>
            <Icon>{icon}</Icon>
          </ListItemIcon>
          <ListItemText data-test={primaryText} primary={primaryText} primaryTypographyProps={{ noWrap: true }} />
        </ListItem>
      }</div>
      ))}
      <Divider style={{ margin: '12px 0' }} />
      {isModelisateur && (
        <ListItem button onClick={() => handleSettings()}>
          <ListItemIcon>
            <Icon>settings</Icon>
          </ListItemIcon>
          <ListItemText primary={'Settings'} data-test={'Settings'} primaryTypographyProps={{ noWrap: true }} />
        </ListItem>
      )}
      <ListItem
        button
        onClick={() => {
          logout()
        }}
      >
        <ListItemIcon>
          <Icon>eject</Icon>
        </ListItemIcon>
        <ListItemText data-test={'Déconnexion'} primary={'Déconnexion'} primaryTypographyProps={{ noWrap: true }} />
      </ListItem>
    </List>
  )
}

NavContent.propTypes = {}
NavContent.defaultProps = {}

export default NavContent

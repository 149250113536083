import React, { useEffect, useMemo, useState } from 'react'
import { Grid, makeStyles, Table, TableBody, TableHead, TableRow, useTheme } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../common/container-title'
import { useDispatch, useSelector } from 'react-redux'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Checkbox from '@material-ui/core/Checkbox'
import * as PropTypes from 'prop-types'
import { SelectButton } from './selectButton'
import { TabPanel } from '../common/tab-pannel'
import { Panel } from '../common/panel'
import Button from '@material-ui/core/Button'
import { getModels, initWorkflowsConfiguration } from '../../store/actions/workflow-action'
import { useHistory } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { TypeThemeTab } from './type-theme_tab'
import { WorkflowInProgressTab } from './workflow_in_progress_tab'
import { ValidationButton } from '../common/validation-button'
import { DropzoneDialog } from 'material-ui-dropzone'
import getPayeService from '../../api/envService'
import { IMPORT_WORKFLOW_ENDPOINT } from '../../api/endpoints'
import { TextualisationTextElements } from './detail/textualisation-element/blocks/textualisationTextElements'
import { SeparableTab } from './separable_tab'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  container: { maxWidth: '80%', overflowX: 'hidden', margin: 'auto', marginTop: 16, maxHeight: 500 },
  container2: { maxWidth: '80%', overflowX: 'hidden', margin: 'auto', marginTop: 16 },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  formControlPopper: {
    margin: 0,
    marginTop: 16,
    width: '100%',
  },
  popper: {
    ...theme.mixins.gutters(),
    minWidth: 380,
    maxWidth: 600,
    margin: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

function withParentId(theme, themes) {
  const found = themes.find(t => t.nom === theme.themeParent)
  if (found) theme.idTheme = found.idTheme
  return theme
}

function findAllChildren(themes) {
  return () =>
    themes
      .map(t => (t.themeParent ? withParentId(t, themes) : t))
      .filter(theme => !themes.find(t => theme.nom === t.themeParent))
      .sort((a, b) => a.nom.localeCompare(b.nom))
      .sort((a, b) => a.idTheme.localeCompare(b.idTheme))
}

SelectButton.propTypes = { theme: PropTypes.any }

export const Workflows = () => {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const loader = useSelector(state => state.loader)
  const themes = useSelector(state => state.themeModule.themes)
  const models = useSelector(state => state.workflowModule.models.sort((a, b) => a.nom.localeCompare(b.nom)))
  const testmode = useSelector(state => state.user.testmode)
  const isModelisateur = useSelector(state => state.user.user.role === 'MODELISATEUR')
  const [open, setOpen] = useState(false)
  useEffect(() => {
    dispatch(initWorkflowsConfiguration())
  }, [])
  const childrenTheme = useMemo(findAllChildren(themes), [themes])
  const handleAddClick = () => history.push('/workflows/model/create')


  const handleExport = nom =>
    window.open(
      '/' +
      (testmode ? 'testmode' : 'api') +
      '/modele-workflow/' +
      (nom ?  nom + '/file' : 'modelFile'),
      '_blank',
    )

  const openImportDialog = () => setOpen(true)

  const handlePostFile = files => {
    if (files.length === 1) {
      setOpen(false)
      getPayeService().postFile(IMPORT_WORKFLOW_ENDPOINT, files[0], 'dataFile')
        .then( () => dispatch(getModels()))
    }
  }


  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={6} />
        <Grid item xs={3} container justify='flex-end' />
      </Grid>
      <br />
      <br />
      {loader ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.contentRoot} elevation={1}>
              <ContainerTitle variant='h6' classes={classes} title='Configuration des modèles de workflows' />
            </Paper>
            <Grid container spacing={0} justify='center'>
              <Grid item  xs={10}>
                  <Grid container spacing={0} justify='center'>
                    <Grid container direction='row' justify='flex-end'>
                      <Grid item style={{ marginBottom: 10,  marginTop: 16}}>
                        <Button
                          data-test='Nouveau Modèle de workflow'
                          color='primary'
                          variant='contained'
                          size='medium'
                          onClick={handleAddClick}
                          className={classes.margin}
                        >
                          NOUVEAU
                        </Button>
                      </Grid>
                    </Grid>
                    {!!models.length && (
                    <Grid item  xs={12} >
                      <TableContainer component={Paper} style={{ marginTop: 10, width: '100%' }}>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell>Nom</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Suppression output</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {models.map((model, idx) => (
                            <TableBody key={model.nom}>
                              <TableRow>
                                <TableCell data-test={`modele_nom_${idx}`}>
                                  <Link href='#' onClick={() => history.push('/workflows/model/create/' + model.nom)}>
                                    {model.nom}
                                  </Link>
                                </TableCell>
                                <TableCell data-test={`modele_description_${idx}`}>{model.description}</TableCell>
                                <TableCell data-test={`modele_type_${idx}`}>{model.type.description}</TableCell>
                                <TableCell value={model.clearOutput}>
                                  <Checkbox
                                    data-test={`modele_suppressionOutput_${idx}`}
                                    checked={model.clearOutput}
                                    disabled
                                    value={model.clearOutput}
                                  />
                                </TableCell>
                                <TableCell data-test={`modele_export_${idx}`}>{<Button
                                  data-test='Nouveau Modèle de workflow'
                                  color='primary'
                                  variant='outlined'
                                  size='medium'
                                  onClick={() => handleExport(model.nom)}
                                  className={classes.margin}
                                >
                                  exporter
                                </Button>}</TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        </Table>
                      </TableContainer>
                    </Grid>
                    )}
                    <Grid container direction='row' justify='flex-end' spacing={1}>
                      <Grid item style={{ marginTop: 10 }}>
                        <Button
                          data-test='Nouveau Modèle de workflow'
                          color='primary'
                          variant='contained'
                          size='medium'
                          onClick={() => handleExport()}
                          className={classes.margin}
                        >
                          exporter Tout
                        </Button>
                      </Grid>
                      { isModelisateur && <Grid item  style={{ marginTop: 10 }}>
                        <ValidationButton
                          data-test='Nouveau Modèle de workflow'
                          variant='contained'
                          size='medium'
                          onClick={openImportDialog}
                          className={classes.margin}
                        >
                          importer
                        </ValidationButton>
                      </Grid>}
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TabPanel titles={['Workflow en cours', 'Thèmes', 'Textualisation', 'Séparateurs']}>
            <Panel value={2} index={2} dir={theme.direction}>
              <WorkflowInProgressTab />
            </Panel>
            <Panel value={1} index={1} dir={theme.direction}>
              <TypeThemeTab key={theme.nom} classes={classes} childrenTheme={childrenTheme} />
            </Panel>
            <Panel>
              <TextualisationTextElements classes={classes} />
            </Panel>
            <Panel>
              <SeparableTab />
            </Panel>
          </TabPanel>
          <DropzoneDialog
            showAlerts={false}
            open={open}
            onSave={handlePostFile}
            dropzoneClass='drop-zone'
            acceptedFiles={[
              'application/json',
              '.json',
            ]}
            showPreviews={true}
            maxFileSize={5000000}
            onClose={() => setOpen(false)}
            filesLimit={1}
            submitButtonText='Importer'
            cancelButtonText='Annuler'
          />
        </Grid>
      )}
    </main>
  )
}

import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { SimpleCard } from './simple-card'
import * as PropTypes from 'prop-types'
import { CardsMenu } from './cards-menu'
import { GroupCard } from './group-card'
import { hasIndex } from './block-textualisation-popper-content'
import { TextFragmentType } from '../../../../../store/constants/TextFragmentType'
import { TextBlockType } from '../../../../../store/constants/TextBlockType'
import CancelIcon from '@material-ui/icons/Cancel'
import { ArrowBackIos, ArrowForwardIos, ArrowLeft, ArrowRight } from '@material-ui/icons'

export function ChildrenGroupCard({ block, fragment, setBlocks, blocks, idx, handleTextPopoverOpen, childIdx }) {
  const [anchorElMenu, setAnchorElMenu] = useState()
  const [open, setOpen] = useState(false)

  function handleOpenMenu(e) {
    setAnchorElMenu(e.target)
  }

  function innerHandleTextPopoverOpen(event, idx, parent, childIdx, grandChildIdx, condition) {
    setAnchorElMenu(null)
    handleTextPopoverOpen(event, idx, block.fragments[0].component, childIdx, grandChildIdx, condition)
  }

  function innerHandleTextPopoverOpenGroup(event, idx, prt, idx2, idx3, condition) {
    setAnchorElMenu(null)
    handleTextPopoverOpen(event, idx, prt, idx2, idx3,condition)
  }

  return <Grid container direction='row'
               alignItems='center'>
    <Grid>
        <span style={{ color: '#e91e63' }}>
          [{fragment.component}]{block.children.length && open ? ' ' : ''}
        </span>
    </Grid>
    { !!block.children.length &&
      <Grid>
        <div onClick={() => setOpen(!open)} style={{lineHeight: '1px'}}>
          { open ? <ArrowLeft fontSize='small' /> : <ArrowRight fontSize='small' /> }
        </div>
      </Grid>
    }
    {
      open && block.children.map((child, i) => <Grid item xs='auto' key={i}>
          {child.type === TextBlockType.GROUP
            ? <GroupCard key={i}
                         childIdx={hasIndex(childIdx) ? childIdx : i}
                         idx={idx}
                         grandChildIdx={hasIndex(childIdx) ? i : null}
                         blocks={blocks}
                         setList={setBlocks}
                         parent={block.fragments[0].component}
                         handleTextPopoverOpen={innerHandleTextPopoverOpenGroup}
                         block={child} />
            : <SimpleCard idx={idx}
                          childIdx={hasIndex(childIdx) ? childIdx : i}
                          grandChildIdx={hasIndex(childIdx) ? i : null}
                          edit={handleOpenMenu}
                          setList={setBlocks}
                          handleTextPopoverOpen={innerHandleTextPopoverOpen}
                          blocks={blocks}
                          block={child} />
          }
        </Grid>,
      )
    }

    {
      anchorElMenu
      && <CardsMenu anchorEl={anchorElMenu}
                    idx={idx}
                    onClose={() => setAnchorElMenu(null)}
                    handleTextPopoverOpen={innerHandleTextPopoverOpen}
                    block={block}
                    parent={block.fragments[0].component} />
    }
  </Grid>
}

ChildrenGroupCard.propTypes = {
  fragment: PropTypes.any,
  block: PropTypes.any,
  prop2: PropTypes.func,
  idx: PropTypes.number,
  childIdx: PropTypes.number,
  grandChildIdx: PropTypes.number,
  parent: PropTypes.string,
  blocks: PropTypes.array,
  setBlocks: PropTypes.func,
  openMenu: PropTypes.func,
  handleTextPopoverOpen: PropTypes.func,
}
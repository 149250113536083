import React, { useEffect, useState } from 'react'
import { TableurCellReferentiel } from '../Referentiel/tableur-cell-referentiel'
import { useSelector } from 'react-redux'
import { setRefListToolTip } from './tableur-cell-unit-of-list-exclusion'

export function TableurCellReferentielList({ values, prefix = '', component, tooltipContent, ignoreToolType = false }) {
  const [withTooltip, setWithTooltip] = useState(false)
  const referentielValues = useSelector(state => state.tableurModule.referentiels.find(r => r.nom === component.referentiel).valeurs.filter(v => values.includes(v.code)))
  useEffect(() => {
    setRefListToolTip(tooltipContent, values, referentielValues, setWithTooltip)
  }, [values, referentielValues])
  return (
    <>
      {values.map((v, idx) => {
        return <div key={v} data-test={`${prefix}_${idx}`}>
            {
              !ignoreToolType && withTooltip && idx > 3
                ? idx === 4 ? '...' : ''
                :<TableurCellReferentiel code={v} component={component} ignoreToolType={ignoreToolType}/>
            }
          </div>
      })}
    </>
  )
}

import { useState } from 'react'
import { useDispatch } from 'react-redux'

export const useItemContainer = (getSelectedItems, handleClickOnSave) => {
  const [selectedItem, setSelectedTheme] = useState([])
  const handleSelection = e => {
    setSelectedTheme(e.target.value)
  }
  const dispatch = useDispatch()
  return [selectedItem, handleSelection, () => handleClickOnSave(dispatch, getSelectedItems(selectedItem))]
}

import Typography from '@material-ui/core/Typography'
import React from 'react'
import * as PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { useDispatch, useSelector } from 'react-redux'
import { setDoublonMode } from '../../store/actions/tableur-action'
import { DataCellButton } from './tab/table/row/cell/data-cell-button'

export function SubtitleCCData({ idcc, title, onClickCC }) {
  return (
    <Grid item container direction={'row'} justify="space-between">
      <Grid>
        <DataCellButton onClick={onClickCC}>
          <Typography variant={'h6'}>
            <span data-test={`idcc-${idcc}`}>{idcc}</span> - <span data-test={`title-${title}`}>{title}</span>
          </Typography>
        </DataCellButton>
      </Grid>
    </Grid>
  )
}

SubtitleCCData.propTypes = {
  idcc: PropTypes.any,
  title: PropTypes.any,
}

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { StatutItem } from './statut-item'
import { Box, Button, Checkbox, Collapse, Grid, makeStyles, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import React, { useState } from 'react'
import { Type } from '../../../store/constants/type'
import { useDispatch, useSelector } from 'react-redux'
import GridOnIcon from '@material-ui/icons/GridOn'
import { useHistory } from 'react-router-dom'
import { initTableurFromWorkflow } from '../../../store/actions/theme-action'
import { patchInstance, TextButton } from '../textButton'
import { AddCircle } from '@material-ui/icons'
import { WorkflowStatut } from '../../../store/constants/ModelInstanceWorkFlow'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../../common/container-title'
import { ModelTextualisationPopperContent } from '../../workflows/detail/textualisation-element/params/model-textualisation-popper-content'
import { TextParamType } from '../../../store/constants/TextParamType'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  popper: {
    ...theme.mixins.gutters(),
    minWidth: 380,
    maxWidth: 780,
    margin: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  container: { maxWidth: '80%', overflowX: 'hidden', margin: 'auto', marginTop: 16 },
  container2: { maxWidth: '80%', overflowX: 'hidden', marginTop: 16 },
  formControl: {
    margin: 0,
    marginTop: 16,
    width: '100%',
  },
  area: {
    '&:focus-visible': {
      border: '#3f51b5 solid',
    },
  }
}))

function getListValue(value, props, referentielName) {
  const values = value.map(
    val =>
      val + ' - ' + props.referentiels.find(r => r.nom === referentielName).valeurs.find(v => v.code === val).libelle
  )
  return values.join(', ')
}

export const WorkflowRow = props => {
  const classes = useStyles()
  const testmode = useSelector(state => state.user.testmode)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState()
  const [idxElement, setIdxElement] = useState(0)
  const { nom, description, referenceAccord, modelWorkflow, dateValidation, user, idcc } = props.instance
  const formatDate = date => {
    if (date) return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    else return ''
  }

  function getValue(type, value, referentielName) {
    switch (type) {
      case Type.REFERENCE.name: {
        if (Array.isArray(value)) return getListValue(value, props, referentielName)
        const libelle = props.referentiels.find(r => r.nom === referentielName).valeurs.find(v => v.code === value)
          .libelle
        return value + ' - ' + libelle
      }
      case Type.LIST.name: {
        return getListValue(value, props, referentielName)
      }
      case Type.LIST_EXCLUSION.name: {
        const inclus = value.inclus.map(
          val =>
            val +
            ' - ' +
            props.referentiels.find(r => r.nom === referentielName).valeurs.find(v => v.code === val).libelle
        )
        const exclus = value.exclus.map(
          val =>
            val +
            ' - ' +
            props.referentiels.find(r => r.nom === referentielName).valeurs.find(v => v.code === val).libelle
        )
        return '{ inclus : [' + inclus.join() + '], exclus : [' + exclus.join() + '] }'
      }
      default:
        return value
    }
  }

  function getFormValue(field, value) {
    if (!value || !props.model) return value
    const fieldModel = props.model.formFields.find(ff => ff.field === field)
    let type = fieldModel.typeForm.name
    let referentielName = fieldModel.referentielName
    if (type === 'WORKFLOW') {
      type = fieldModel.workflowField.typeForm.name
      referentielName = fieldModel.workflowField.referentielName
    }
    return getValue(type, value, referentielName)
  }

  function getInputThemeValue(theme) {
    const foundInput = props.instance.themesInputType.find(it => it.theme === theme)
    if (!foundInput) return ''
    const value = foundInput.value
    const compName = props.themes.find(t => t.nom === theme).inputType
    if (!value) return value
    const comp = props.comps.find(c => c.nom === compName)
    if(!comp) return ''
    const type = comp.type.name
    const referentiel =
      type === Type.REFERENCE.name ? comp.referentiel : props.comps.find(c => c.nom === comp.unit.nom).referentiel
    return getValue(type, value, referentiel)
  }

  function findInputThemeInstance(theme) {
    return props.instance.themesInputType.find(it => it.theme === theme)
  }

  const history = useHistory()

  function handleOpenTableur(theme) {
    dispatch(initTableurFromWorkflow(idcc, theme, history))
  }



  const handleSaveParameters = (parameter, i) =>  {
    const params = [...props.instance.parameters]
    if (!i && i !== 0) params.push(parameter)
    else params.splice(i, 1, parameter)

    dispatch(patchInstance(props.fromCC, {idcc: props.instance.idcc, wfName: props.instance.nom, source: props.instance.source, refAccord: props.instance.referenceAccord, parameters: params}))
    setAnchorEl(null)
  }


  const handleDeleteParameters = (i) =>  {
    const params = [...props.instance.parameters]
    params.splice(i, 1)

    dispatch(patchInstance(props.fromCC, {idcc: props.instance.idcc, wfName: props.instance.nom, source: props.instance.source, refAccord: props.instance.referenceAccord, parameters: params}))
    setAnchorEl(null)
  }



  const handlePopoverOpen = (event, idx) => {
    setAnchorEl(event.target)
    setIdxElement(idx)
  }

  const openParam = Boolean(anchorEl)
  const id = openParam ? 'popin_Ajout_element_reference' : undefined


  function handleCancel() {
    setAnchorEl(null)
  }

return (
    <>
      <TableRow
        style={open ? { border: 0 } : {}}
        hover
        onClick={() => setOpen(!open)}
        data-test={'workflow_row_' + nom}
        className="workflow_row"
      >
        <TableCell style={{ borderBottom: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            // onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <>
          {props.fromCC || (
            <TableCell style={{ borderBottom: 0 }} data-test={'workflow_idcc'}>
              {idcc}
            </TableCell>
          )}
        </>
        <TableCell style={{ borderBottom: 0 }} data-test={'workflow_nom'}>
          {nom}
        </TableCell>
        <TableCell style={{ borderBottom: 0 }} data-test={'workflow_description'}>
          {description}
        </TableCell>
        <TableCell style={{ borderBottom: 0 }} data-test={'workflow_referenceAccord'}>
          <TextButton wfField="referenceAccord" fromCC={props.fromCC} wf={props.instance}/>
        </TableCell>
        <TableCell style={{ borderBottom: 0 }} data-test={'workflow_type'}>
          {modelWorkflow}
        </TableCell>
        <>
          {props.fromCC && (
            <TableCell style={{ borderBottom: 0 }} data-test={'workflow_dateValidation'}>
              {formatDate(dateValidation)}
            </TableCell>
          )}
        </>
        <TableCell style={{ borderBottom: 0 }} data-test={'workflow_user'}>
          {user}
        </TableCell>
        <TableCell style={{ borderBottom: 0 }} data-test={'workflow_statut'}>
          <StatutItem statut={props.statut} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 0, backgroundColor: '#f5f5f5' }} />
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 0, backgroundColor: '#f5f5f5' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <br />
            <Box sx={{ marginTop: 20 }}>
              <Typography variant="h6" gutterBottom component="div">
                Formulaire
              </Typography>
              <Table size="small" style={{ backgroundColor: 'white' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Champ</TableCell>
                    <TableCell>Valeur</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                      <TableRow key="source">
                        <TableCell>Source</TableCell>
                        <TableCell>
                          <TextButton wfField="source" fromCC={props.fromCC} wf={props.instance}/>
                        </TableCell>
                      </TableRow>
                    {props.instance.fieldsInputType.map(({ field, value, ignore }) => (
                      <TableRow key={field}>
                        <TableCell>{field}</TableCell>
                        <TableCell>
                          <>
                            <Checkbox disabled style={{ margin: 0, padding: 0 }} checked={!ignore} size="small" />
                            <span style={{ verticalAlign: 'middle' }}>{getFormValue(field, value)}</span>
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                    {!!Object.keys(props.instance.workflowRef).length &&
                    <TableRow key="ref">
                      <TableCell>Workflow de reference</TableCell>
                      <TableCell>{Object.values(props.instance.workflowRef)[0]}</TableCell>
                    </TableRow>}
                  </>
                </TableBody>
              </Table>
            </Box>
            <br />
            <Box sx={{ marginTop: 20 }}>
              <Typography variant="h6" gutterBottom component="div">
                Thème
              </Typography>
              <Table size="small" aria-label="purchases" style={{ backgroundColor: 'white' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Thème</TableCell>
                    <TableCell>Input Type</TableCell>
                    <TableCell>Tableur</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.instance.themes.map(theme => (
                    <TableRow key={theme}>
                      <TableCell>{theme}</TableCell>
                      <TableCell>
                        <>
                          {findInputThemeInstance(theme) && (
                            <Checkbox
                              disabled
                              style={{ margin: 0, padding: 0 }}
                              checked={!findInputThemeInstance(theme).ignore}
                              size="small"
                            />
                          )}
                          <span style={{ verticalAlign: 'middle' }}>{getInputThemeValue(theme)}</span>
                        </>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          aria-label="Tableur"
                          data-test="Modéliser ce thème"
                          onClick={() => handleOpenTableur(theme)}
                        >
                          <GridOnIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            {
              props.statut === WorkflowStatut.PUBLIE && !props.instance.parameters.length || <>
                <br />
                <Box>
                  <Grid container direction='row'>
                    <Typography variant='h6' gutterBottom component='div'>
                      Paramètres
                    </Typography>
                    { props.statut === WorkflowStatut.PUBLIE || <span onClick={handlePopoverOpen} style={{ color: '#3f51b5' , lineHeight: '44px', marginLeft: '10px', cursor: 'pointer'}}><AddCircle fontSize='small' /></span>}
                  </Grid>
                  { !props.instance.parameters.length ||
                    <Table size='small' aria-label='purchases' style={{ backgroundColor: 'white' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Composant</TableCell>
                          <TableCell>Légender</TableCell>
                          <TableCell>Ignorer</TableCell>
                          <TableCell>Détextualiser</TableCell>
                          <TableCell>Réintégrer</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.instance.parameters.map((param, index) => (
                          <TableRow key={param.component}>
                            <TableCell>
                              <Link href='#' onClick={e => handlePopoverOpen(e, index)}>
                                {param.component}
                              </Link>
                            </TableCell>
                            <TableCell> <Checkbox
                              disabled
                              style={{ margin: 0, padding: 0 }}
                              checked={param.type === TextParamType.LEGEND}
                              size='small'
                            />
                            </TableCell>
                            <TableCell> <Checkbox
                              disabled
                              style={{ margin: 0, padding: 0 }}
                              checked={param.type === TextParamType.IGNORE}
                              size='small'
                            />
                            </TableCell>
                            <TableCell> <Checkbox
                              disabled
                              style={{ margin: 0, padding: 0 }}
                              checked={param.type === TextParamType.REMOVE_BLOCK}
                              size='small'
                            />
                            </TableCell>
                            <TableCell> <Checkbox
                              disabled
                              style={{ margin: 0, padding: 0 }}
                              checked={param.type === TextParamType.RE_TEXT}
                              size='small'
                            />
                            </TableCell>
                            <TableCell><span style={{cursor: "pointer"}} onClick={() => handleDeleteParameters(index)}>X</span></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                </Box>
              </>
            }
            <br />
            <Grid container justify="flex-end" spacing={2}>
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  datatest="PREVISUALISER CC JSON"
                  onClick={() => {
                    return window.open(
                      '/' +
                        (testmode ? 'testmode' : 'api') +
                        '/bo-textualisation/conventions-collectives/' +
                        idcc +
                        '/rapport/' +
                        props.instance.nom,
                      '_blank'
                    )
                  }}
                >
                  VISUALISER JSON
                </Button>
              </Grid>
              <Grid>
                <Button
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="primary"
                  datatest="PREVISUALISER PDF"
                  onClick={() => {
                    return window.open(
                      '/' +
                        (testmode ? 'testmode' : 'api') +
                        '/bo-textualisation/conventions-collectives/' +
                        idcc +
                        '/file/' +
                        props.instance.nom,
                      '_blank'
                    )
                  }}
                >
                  VISUALISER PDF
                </Button>
              </Grid>
            </Grid>
            <br />
          </Collapse>
        </TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 0, backgroundColor: '#f5f5f5' }} />
      </TableRow>
      {openParam && <Popover anchorEl={anchorEl}
                        id={id}
                        open={openParam}
                        anchorPosition={{ top: 270, left: 660 }}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}>
        <Paper className={classes.popper} elevation={1}>
          <ContainerTitle variant='h5' classes={classes} title='Création élément de textualisation' />
          <Grid container direction='row' justify='flex-end'>
            <ModelTextualisationPopperContent
              handleSave={handleSaveParameters}
              handleCancel={handleCancel}
              parameters={[...props.instance.parameters]}
              idx={idxElement}
              fromCC
            />
          </Grid>
        </Paper>
      </Popover>}
    </>
  )
}

import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import { TypeFormField } from '../../../../store/constants/TypeFormField'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect } from 'react'

export function ModelFormPopperContent({ className, handleChange, tmpFormElement }) {
  const workflows = useSelector(state => state.workflowModule.models)
  const allReferentiels = useSelector(state => state.referentiels.referentiels)
  const workflowNames = workflows.map(w => w.nom)
  const selectedWorkflow = workflows.find(w => w.nom === tmpFormElement.workflowRef)

  useEffect(() => {
    if(tmpFormElement.typeForm === TypeFormField.WORKFLOW) handleChange(false, 'required')
  },[tmpFormElement.typeForm])

  return <Grid item xs={12}>
    <Grid container direction="row">
      <FormControlLabel
        style={{ lineHeight: '40px' }}
        control={
          <Checkbox
          onClick={() => handleChange(!tmpFormElement.required, 'required')}
          data-test="formulaire_obligatoire"
          value={ tmpFormElement.required}
          disabled={tmpFormElement.typeForm === TypeFormField.WORKFLOW}
          color="primary"
          checked={tmpFormElement.required}/>
        }
        label="Obligatoire"
      />


    </Grid>
    <Grid>
      <TextField data-test={'formulaire_champ'}
                 label={'Champ'}
                 value={tmpFormElement.field}
                 className={'TextField'}
                 margin="normal"
                 fullWidth
                 onChange={e => handleChange(e.target.value, 'field')}/>
    </Grid>
    <Grid>
      <form className={className}>
        <InputLabel id="type_formulaire_type_label">
          Type
        </InputLabel>
        <Select labelId="type_formulaire_type_label"
                label="Type"
                data-test="formulaire_type"
                style={{ width: '100%' }}
                value={tmpFormElement.typeForm ? tmpFormElement.typeForm.name : ''}
                onChange={e => handleChange(TypeFormField.fromStr(e.target.value), 'typeForm')}>
          {TypeFormField.ALL.map((type) =>
            <MenuItem data-test={'formulaire_type_' + type.name} key={type.name}
                      value={type.name ? type.name : null}>
              {type.name ? type.description : 'aucun'}
            </MenuItem>,
          )}
        </Select>
      </form>
    </Grid>

    {
      (tmpFormElement.typeForm === TypeFormField.LIST_EXCLUSION ||tmpFormElement.typeForm === TypeFormField.LIST ||  tmpFormElement.typeForm === TypeFormField.REFERENCE) &&
      <>
        <Grid>

          <form className={className}>
            <InputLabel id="formulaire_liste_ref_label">
              Référentiel
            </InputLabel>
            <Select labelId="formulaire_liste_ref_label"
                    label="Type"
                    data-test="formulaire_liste_ref"
                    style={{ width: '100%' }}
                    value={tmpFormElement.referentielName}
                    onChange={e => handleChange(e.target.value, 'referentielName')}>
              {allReferentiels.map(referentiel =>
                <MenuItem data-test={'formulaire_liste_ref_' + referentiel.nom} key={referentiel.nom}
                          value={referentiel.nom}>
                  {referentiel.nom}
                </MenuItem>,
              )}
            </Select>
          </form>
        </Grid>
      </>
    }
    {
      tmpFormElement.typeForm === TypeFormField.WORKFLOW &&
      <>
        <Grid>
          <form className={className}>
            <InputLabel id="formulaire_workflow_ref_label">
              Workflow
            </InputLabel>
            <Select labelId="formulaire_workflow_ref_label"
                    label="Type"
                    data-test="formulaire_workflow_ref"
                    style={{ width: '100%' }}
                    value={tmpFormElement.workflowRef}
                    onChange={e => handleChange(e.target.value, 'workflowRef')}>
              {workflowNames.map(workflow =>
                <MenuItem data-test={'formulaire_workflow_ref_' + workflow} key={workflow}
                          value={workflow}>
                  {workflow}
                </MenuItem>,
              )}
            </Select>
          </form>
        </Grid>
        {tmpFormElement.workflowRef &&
        <Grid>
          <form className={className}>
            <InputLabel id="formulaire_workflow_champ_label">
              Champ Workflow
            </InputLabel>
            <Select labelId="formulaire_workflow_champ_label"
                    data-test="formulaire_workflow_champ"
                    style={{ width: '100%' }}
                    value={tmpFormElement.workflowField ? tmpFormElement.workflowField.field : tmpFormElement.workflowField}
                    onChange={e => handleChange(selectedWorkflow.formFields.find(ff => ff.field === e.target.value), 'workflowField')}>
              {selectedWorkflow.formFields.map(({ field, typeForm }) =>
                <MenuItem data-test={'formulaire_workflow_champ_' + field}
                          key={field}
                          value={field}>
                  {field}
                </MenuItem>,
              )}
            </Select>
          </form>
        </Grid>
        }
      </>
    }
  </Grid>
}
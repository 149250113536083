export class TextFragmentType {
  name
  label

  constructor(name, label) {
    this.name = name
    this.label = label ? label : name
  }

  static NULL = new TextFragmentType('null')
  static COMPONENT = new TextFragmentType('component')
  static TEXT = new TextFragmentType('text')
  static GROUP = new TextFragmentType('group')
  static LIST = new TextFragmentType('list')
  static ALL = [
    TextFragmentType.NULL,
    TextFragmentType.COMPONENT,
    TextFragmentType.TEXT,
    TextFragmentType.GROUP,
    TextFragmentType.LIST,
  ]
  static fromStr = str => str ? TextFragmentType.ALL.find(f => f.name.toUpperCase() === str.toUpperCase()) : str
}
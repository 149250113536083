import React,{useEffect,useState} from 'react'
import {withFormTable,withEditableRow,withDefaultHeader,withDefaultHeaderLabel,ActionForm,withHeaders} from '../Table/form-table'
import RowEditText from '../common/table/editable/renderer/row-edit-text'
import CellStringRenderer from '../common/table/editable/renderer/cell-string-renderer'
import {IDENTITY_CONVERTER} from '../common/table/editable/converter/converter'
import * as PropTypes from 'prop-types'
import { getKey } from '../components/helper'


function RowTextEditor(props){
  return (
    <RowEditText
          dataTestPrefix="component"
          dataTestKey={props.rowKey}
          initialValue={props.rowValue}
          onChange={props.onChange}
          onSave={props.onSave}
          save={props.save}
          onCancel={props.onCancel}
      />)
}

RowTextEditor.propTypes = {
}

function CellRenderer({rowKey,rowValue}){
  return <CellStringRenderer 
    align="left"  
    dataTestKey={rowKey} 
    dataTestPrefix="component"
    mapper={IDENTITY_CONVERTER.to}
    rowValue={rowValue}/>
}
CellRenderer.propTypes = {
  dataTestKey:PropTypes.string,
  dataTestPrefix:PropTypes.string,
  rowValue:PropTypes.string
}

function ActionNouveau(props){
  return (<ActionForm buttonName="Nouveau" buttonDataTest="Nouvelle valeur" {...props}>
          </ActionForm>)
}

let FormTable=withFormTable(
  [ActionNouveau],
  withHeaders([withDefaultHeader("header"),withDefaultHeaderLabel("actions")]),
  withEditableRow(RowTextEditor,[CellRenderer])
)
export default  function ComponentTextValues({onSave,onDelete,values,component}) { 
      const [valuesMap,setValuesMap] = useState(new Map([]))
      const [rowsEditKey,setRowsEditKey] = useState([])

      useEffect(()=>{
        if(rowsEditKey.length === 0) setValuesMap(values)
      },[values,rowsEditKey])

      const handleDelete = (key)=>{
        if(onDelete){
          onDelete(key)
        }
      }

      const handleSave = (datas)=>{
        setRowsEditKey([])
        if(onSave) {
          onSave(datas)
        }
      }

      const handleRowCancel = ()=>{
        setRowsEditKey([])
      }

      const validateRowValue = (rowValue)=>{
          return rowValue && rowValue !== ''
      }
      const handleActionEdit = ()=>{
        var newValuesMap = new Map(valuesMap)
        var key=getKey(newValuesMap)
        newValuesMap.set(key,"")
        setValuesMap(newValuesMap)
        setRowsEditKey([...rowsEditKey,key])
        return false
      }
      return (
      <FormTable 
        onActionEdit={handleActionEdit}
        onSave={handleSave} 
        onDelete={handleDelete} 
        onRowCancel={handleRowCancel}
        valuesMap={valuesMap} 
        rowsEditKey={rowsEditKey}
        validateRowValue={validateRowValue}
        dataTestPrefix="component"
        header={component.nom}>
      </FormTable>)
}

ComponentTextValues.propTypes = {
    onSave:PropTypes.func.isRequired,
    onDelete:PropTypes.func.isRequired,
    values:PropTypes.any.isRequired,
    component:PropTypes.any.isRequired
}

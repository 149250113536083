export class TaskStatut {
    name
    label
  
    constructor(name, label) {
      this.name = name
      this.label = label
    }
  
    static IDLE = new TaskStatut('IDLE', 'en attente')
    static STARTED = new TaskStatut('STARTED', "en cours d'exécution")
    static FAILED = new TaskStatut('FAILED', 'En échec')
    static SUCCESS = new TaskStatut('SUCCESS', 'Succès')
    static NULL = new TaskStatut('', '')
  }
  
  export const ALL_STATUT = [
    TaskStatut.IDLE,
    TaskStatut.STARTED,
    TaskStatut.FAILED,
    TaskStatut.SUCCESS,
    TaskStatut.NULL,
  ]
  export const getStatutFromString = (string = '') => {
    const toCompare = string.name ? string.name : string
    const statut = ALL_STATUT.find(r => r.name === toCompare.toUpperCase())
    if (statut) return statut
    throw new Error('Le statut ' + string + ' est inconnu')
  }
  
  export const getStatutFromLabel = (string = '') => {
    const toCompare = string.label ? string.label : string
    const statut = ALL_STATUT.find(r => r.label === toCompare)
    if (statut) return statut
    throw new Error('Le statut ' + string + ' est inconnu')
  }

export default class Task {
    id
    description
    creationDate
    startDate
    endDate
    duration
    userName
    status
    nbTotal
    nbDone
  
    constructor(
        id,
        description,
        creationDate,
        startDate,
        endDate,
        duration,
        userName,
        status,
        nbTotal,
        nbDone
    ) {
        this.id=id
        this.description=description
        this.creationDate=creationDate
        this.startDate=startDate
        this.endDate=endDate
        this.duration=duration
        this.userName=userName
        this.status=status
        this.nbTotal=nbTotal
        this.nbDone=nbDone
    }
    static NULL = new Task('', '', '', '', '','','','')
}
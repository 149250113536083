import { Button, FormGroup, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { DropzoneDialog } from 'material-ui-dropzone'
import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import getPayeService from '../../api/envService'
import { IMPORT_DATA_CHAT_ENDPOINT } from '../../api/endpoints'

export function ImportChat() {
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(true)
  const handleSave = files => {
    if (files.length === 1) {
      setOpen(false)
      getPayeService().postFile(IMPORT_DATA_CHAT_ENDPOINT, files[0], 'dataFile')

    }
  }

  return <Grid container>
    <Grid item xs={2}>
      <Typography data-test={'Import Chat'} variant='h5' style={{ lineHeight: 1.8 }}>
        Import Chat
      </Typography>
    </Grid>
    <Grid xs={3}>
    </Grid>
    <Grid xs={6}>
      <FormGroup>
        <Button variant='contained' color='primary' onClick={handleClick} data-test='choose-file'>
          choisir fichier Chat
        </Button>
        <DropzoneDialog
          showAlerts={false}
          open={open}
          onSave={handleSave}
          dropzoneClass='drop-zone'
          acceptedFiles={[
            'application/vnd.ms-excel',
            'application/octet-stream',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            '.xlsx',
          ]}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={() => setOpen(false)}
          filesLimit={1}
          submitButtonText='Importer'
          cancelButtonText='Annuler'
        />
      </FormGroup>

    </Grid>
  </Grid>
}

ImportChat.propTypes = {
  state: PropTypes.shape({ addOnly: PropTypes.bool }),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
}
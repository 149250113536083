import React from 'react'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Box,
  makeStyles,
  FormControl,
  FormLabel,
  TextField, Checkbox,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(atheme => ({
  contentRoot: {
    ...atheme.mixins.gutters(),
    paddingTop: atheme.spacing(2),
    paddingBottom: atheme.spacing(2),
  },
  content: {
    padding: atheme.spacing(3),
  },
  formControl: {
    margin: atheme.spacing(1),
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      width: '40%',
      marginRight: atheme.spacing(1),
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: atheme.spacing(3),
  },
}))

function CcWorkflowFormListExclusion({
                                       dataTest,
                                       nom,
                                       referentielName,
                                       referenceValues = { inclus: [], exclus: [] },
                                       onValueChange,
                                       disabled = false,
                                       ignore,
                                       changeIgnore,
                                       required = false,
                                     }) {
  const classes = useStyles()
  const allReferentiels = useSelector(state => state.referentiels.referentiels)

  const referentiel = allReferentiels.find(ref => ref.nom === referentielName)

  const referentielValues = referentiel.valeurs

  function handleValuesChange(values) {
    onValueChange && onValueChange(values)
  }

  return (
    <Box>
      <FormControl component='fieldset' className={classes.formControl}>
        <Grid container direction='row' style={{ width: '100%' }} alignItems='center' spacing={1}>
          <Grid item xs={1}>
            <Checkbox disabled={required} style={{margin: 0, padding: 0}} checked={!ignore} onClick={() => changeIgnore(!ignore)} size='small' color='primary' />
          </Grid>
          <Grid item xs={10}>
            <FormLabel component='legend'>{nom}</FormLabel>
          </Grid>
        </Grid>
        <FormControl>
          <Autocomplete data-test={`${dataTest}_inclus`}
                        multiple
                        renderOption={(option) => (
                          <span role='option' key={option.code}
                                data-test={`${dataTest}_reference_li`}>{option.code} - {option.libelle}</span>
                        )}
                        options={referentielValues.filter(v => referenceValues.exclus.indexOf(v.code) < 0)}
                        getOptionLabel={val => val.code + ' - ' + val.libelle}
                        onChange={(e, value) => handleValuesChange({
                          inclus: value.map(v => v.code),
                          exclus: referenceValues.exclus,
                        })}
                        renderInput={(params) => <TextField {...params} label={'inclus'} />}
                        value={referentielValues.filter(v => referenceValues.inclus.indexOf(v.code) >= 0)}
                        disabled={disabled || ignore}
          />
        </FormControl>
        <FormControl>
          <Autocomplete data-test={`${dataTest}_exclus`}
                        multiple
                        options={referentiel.valeurs.filter(v => referenceValues.inclus.indexOf(v.code) < 0)}
                        getOptionLabel={val => val.code + ' - ' + val.libelle}
                        onChange={(e, value) => handleValuesChange({
                          inclus: referenceValues.inclus,
                          exclus: value.map(v => v.code),
                        })}
                        renderInput={(params) => <TextField {...params} label={'exclus'} />}
                        value={referentiel.valeurs.filter(v => referenceValues.exclus.indexOf(v.code) >= 0)}
                        disabled={disabled || ignore} />
        </FormControl>
      </FormControl>
    </Box>
  )
}

CcWorkflowFormListExclusion.propTypes =
  {
    dataTest: PropTypes.string.isRequired,
    nom
:
PropTypes.string.isRequired,
  referentielName
:
PropTypes.string.isRequired,
  referenceValue
:
PropTypes.string,
  onValueChange
:
PropTypes.func
}

export default CcWorkflowFormListExclusion


import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { SelectButton } from './selectButton'
import React from 'react'
import * as PropTypes from 'prop-types'

export function TypeThemeTab(props) {
  return <TableContainer component={Paper} className={props.classes.container}>
    <Table stickyHeader size='small'>
      <TableHead>
        <TableRow>
          <TableCell>IdTheme</TableCell>
          <TableCell>Nom</TableCell>
          <TableCell>Input Type</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.childrenTheme.map((theme, idx) => (
          <TableRow key={idx}>
            <TableCell data-test={`idTheme_${idx}`}>{theme.idTheme}</TableCell>
            <TableCell data-test={`nom_${idx}`}>{theme.nom}</TableCell>
            <TableCell data-test={`inputType_${idx}`}>
              <SelectButton theme={theme} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
}


TypeThemeTab.propTypes = {
  classes: PropTypes.any,
  childrenTheme: PropTypes.any,
}
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, Paper, Switch, withStyles } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ContainerTitle } from '../common/container-title'
import React, { useEffect, useState } from 'react'
import { getApiMode, updateApiMode } from '../../store/actions/settings-action'
import Typography from '@material-ui/core/Typography'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: 16,
    marginBottom: 16,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  vert: {
    color: '#00e676',
  },
}))

export const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

export const BoSettings = () => {
  const classes = useStyles()
  const loader = useSelector(state => state.loader)
  const [localApiMode, setLocalApiMode] = useState(false)
  const apiMode = useSelector(state => state.settingsModule.apiMode)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApiMode())
  }, [])

  useEffect(() => {
    setLocalApiMode(apiMode)
  }, [apiMode])

  let handleChangeApiMode = () => {
    dispatch(updateApiMode(!apiMode))
  }

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={6} />
        <Grid item xs={3} container justify="flex-end" />
      </Grid>
      <br />
      <br />
      {
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Paper className={classes.contentRoot} elevation={1}>
                <ContainerTitle classes={classes} title="Paramètre de l'outil de modélisation" />
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>API CLIENT</Grid>
                    <Grid item>
                      <GreenSwitch
                        className={classes.vert}
                        checked={localApiMode}
                        onChange={handleChangeApiMode}
                        name="apiModeChecke"
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </Paper>
            </div>
          </Grid>
        </Grid>
      }
    </main>
  )
}

import getPayeService from '../../api/envService'
import { CC_ENDPOINT, INPUT_ENDPOINT, OUTPUT_ENDPOINT, THEME_ENDPOINT } from '../../api/endpoints'
import { ComponentValues } from '../constants/component-values'
import {
  SET_CC_FOCUS_VALUE,
  SET_CC_VALUES,
  SET_CC_CHILD_VALUES,
  SET_POPIN_CONTEXT,
} from '../reducers/convention-collective-reducer'
import { setLoader } from './loader-action'
import { patchReferentielValues } from './referentiel-action'
import { mapToJson } from './common'
import _ from 'lodash'

export const VALUES_ENDPOINT = 'values'

const setCCChildValues = values => ({ type: SET_CC_CHILD_VALUES, value: values })
export const getCompChildValues = (idcc, themeName, isInput, componentName) => dispatch => {
  getPayeService()
    .getGrandchildren(
      CC_ENDPOINT,
      idcc,
      THEME_ENDPOINT,
      themeName,
      isInput ? INPUT_ENDPOINT : OUTPUT_ENDPOINT,
      componentName,
      VALUES_ENDPOINT,
    )
    .then(data => buildValuesFromJson(data))
    .then(compValues => {
      dispatch(setCCChildValues(Array.from(compValues.values)))
    })
}
const setCCValues = values => ({ type: SET_CC_VALUES, value: values })
export const setIsFocusValueFree = value => ({
  type: SET_CC_FOCUS_VALUE,
  value,
})

export function getComponentValuesPromise(idcc, themeName, isInput, componentName) {
  return getPayeService()
    .getGrandchildren(
      CC_ENDPOINT,
      idcc,
      THEME_ENDPOINT,
      themeName,
      isInput ? INPUT_ENDPOINT : OUTPUT_ENDPOINT,
      componentName,
      VALUES_ENDPOINT,
    )
    .then(data => buildValuesFromJson(data))
}

export const getCompValues = (idcc, themeName, isInput, componentName) => dispatch =>
  getComponentValuesPromise(idcc, themeName, isInput, componentName)
    .then(compValues =>
      dispatch(setCCValues(compValues)),
    )

export const patchCCValueAndReferentielValues = (values, role, idRef, valuesMap) => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => dispatch(patchReferentielValues(idRef, valuesMap)))
    .then(() => dispatch(patchCCValue(values, role)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))

export const patchCCValue = (values, role) => (dispatch, getState) =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => {
      const {
        conventionCollective: { idcc },
        theme,
        [role]: { nom },
      } = getState().conventionModule
      const isInput = role === 'input'
      return patchCompValue(values, idcc, theme, isInput, nom)
        .then(() =>
          dispatch(getCompValues(idcc, theme.nom, isInput, nom)),
        )
    })
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))

export const stashPopinContext = (componentNom, context) => {
  if (componentNom) {
    let value = { componentNom }
    Object.keys(context).forEach(key => {
      if (_.isMap(context[key])) {
        value[key] = Array.from(context[key]) //du à la sérialization par le plugin de l'état: Map vide => objet vide
      } else {
        value[key] = context[key]
      }
    })
    return {
      type: SET_POPIN_CONTEXT,
      value: value,
    }
  } else {
    return {
      type: SET_POPIN_CONTEXT,
      value: { componentNom: '' },
    }
  }
}

export const patchCompValue = async (values, idcc, theme, isInput, nom) =>
  await getPayeService().patchChild(
    mapToJson(values),
    CC_ENDPOINT,
    idcc,
    THEME_ENDPOINT,
    theme.nom,
    isInput ? INPUT_ENDPOINT : OUTPUT_ENDPOINT,
    nom,
    VALUES_ENDPOINT,
  )

function buildValuesFromJson(data) {
  const values = new Map()
  for (let key in data) values.set(key, data[key])
  return new ComponentValues(values)
}

export class TypeFormField {
  name
  description

  constructor(name, description) {
    this.name = name
    this.description = description
  }

  static DATE = new TypeFormField('DATE', 'date')
  static NUMERIC = new TypeFormField('NUMERIC', 'numeric')
  static TYPE_THEME = new TypeFormField('TYPE_THEME', 'typeTheme')
  static THEMES = new TypeFormField('THEMES', 'themes')
  static TEXT = new TypeFormField('TEXT', 'text')
  static WORKFLOW = new TypeFormField('WORKFLOW', 'workflow')
  static REFERENCE = new TypeFormField('REFERENCE', 'reference')
  static LIST = new TypeFormField('LIST', 'liste')
  static LIST_EXCLUSION = new TypeFormField('LIST_EXCLUSION', 'list-exclusion')
  static NULL = new TypeFormField('', '')
  static ALL = [
    TypeFormField.NULL,
    TypeFormField.DATE,
    TypeFormField.TYPE_THEME,
    TypeFormField.TEXT,
    TypeFormField.NUMERIC,
    TypeFormField.WORKFLOW,
    TypeFormField.REFERENCE,    
    TypeFormField.LIST, 
    TypeFormField.LIST_EXCLUSION
  ]
  static fromStr = str => str ? TypeFormField.ALL.find(f => f.name === str.toUpperCase()) : str
}
import React,{useState} from 'react'
import * as PropTypes from 'prop-types'

export default function InputText({dataTest,dataKey,initialValue,onChange,placeholder="",pattern=`^.*$`, maxInputSize}) {
    const patternRegexp = new RegExp(pattern,"g");
    const [inputValue,setInputValue] = useState(!!initialValue ? ""+initialValue : "")
    const [inputOldValue,setInputOldValue] = useState(initialValue)

    const checkPattern = (value)=>{
        return value.match(patternRegexp)
    }

    const handleChange = (e) => {
        let input=e.target;
        if (checkPattern(input.value)) {
            setInputValue(input.value)
            if(onChange){
                onChange(e,input.value)
            }
        }
    }

    const filterInput = (e) => {
        let input=e.target;
        if (checkPattern(input.value)) {
            setInputOldValue(input.value);
            input.oldSelectionStart = input.selectionStart;
            input.oldSelectionEnd = input.selectionEnd;
          } else  {
            setInputValue(inputOldValue);
            input.setSelectionRange(input.oldSelectionStart, input.oldSelectionEnd);
            e.preventDefault()
          }
    }
    return (
        <input
        data-key={dataKey}
        data-test={dataTest}
        type="text"
        value={inputValue}
        onInput={filterInput}
        maxLength={maxInputSize ? maxInputSize : null}
        onKeyDown={filterInput}
        onKeyUp={filterInput}
        onMouseDown={filterInput}
        onMouseUp={filterInput}
        onSelect={filterInput}
        onContextMenu={filterInput}
        onDrop={filterInput}
        onChange={handleChange}
        placeholder={placeholder}/>
    )
}

InputText.propTypes =  {
    dataTest:PropTypes.string,
    initialValue:PropTypes.string,
    dataKey:PropTypes.string,
    placeholder:PropTypes.string,
    onChange:PropTypes.func
}
import _ from "lodash"

export function desc(a, b, orderBy) {
  let v1=a[orderBy]
  let v2=b[orderBy]
  if(_.isFinite(_.toNumber(v1)) && _.isFinite(_.toNumber(v2))){
    v1=_.toNumber(v1)
    v2=_.toNumber(v2)
  }
  if(_.isString(v1) && _.isString(v2)){
    return v2.localeCompare(v1)
  }


  if (v2 < v1) {
    return -1
  }
  if (v2 > v1) {
    return 1
  }
  return 0
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTypeFromString, Type } from '../../../../store/constants/type'
import { Grid } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { ALL_NEW_TYPES, OperationUnit } from '../../../../store/constants/ModelRefField'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import {
  getPossibleOperators,
  getPossibleTargetValues,
  getSameTypeComps,
  setWFComp,
} from '../workflow-helper'

export function ModelNewPopperContent({
  className,
  handleChange,
  tmpNewElement,
  formFields,
  handleReplacementChange,
  tmpModelReplacement,
}) {
  const [referentielValues, setReferentielValues] = useState([])
  const [referentielName, setReferentielName] = useState('')

  const allComp = useSelector(state => state.componentModule.components)
  const referentiels = useSelector(state => state.referentiels.referentiels)
  const allComputedComp = useMemo(getSameTypeComps(allComp, tmpNewElement), [allComp, tmpNewElement.type])
  const possibleTargetValues = useMemo(getPossibleTargetValues(formFields, tmpNewElement,referentielName), [formFields, tmpNewElement,referentielName])
  const possibleOperators = useMemo(getPossibleOperators(tmpNewElement), [tmpNewElement])

  function setComp(value) {
    setWFComp(value, tmpNewElement, referentiels, setReferentielValues,setReferentielName)
  }

  return (
    <Grid witem xs={12}>
      <Grid container direction="column">
        <Grid>
          <form className={className}>
            <InputLabel id="new_type_label">Type</InputLabel>
            <Select
              labelId="new_type_label"
              label="Type"
              data-test="new_type"
              style={{ width: '100%' }}
              value={tmpNewElement.type ? tmpNewElement.type.name : ''}
              onChange={e => handleChange(getTypeFromString(e.target.value), 'type')}
            >
              {ALL_NEW_TYPES.map(type => (
                <MenuItem data-test={'new_type_' + type.name} key={type.name} value={type.name ? type.name : null}>
                  {type.name ? type.label : 'aucun'}
                </MenuItem>
              ))}
            </Select>
          </form>
        </Grid>
        {!!tmpNewElement.type && (
          <Grid>
            <Autocomplete
              data-test="new_composant"
              options={allComputedComp}
              getOptionLabel={c => c.nom}
              style={{ width: '100%' }}
              onChange={(e, value) => {
                handleChange(value ? value.nom : value, 'component')
                setComp(value)
              }}
              renderInput={params => {
                return <TextField {...params} value={params.inputProps.value} label="Selectionner input" />
              }}
              inputValue={tmpNewElement ? tmpNewElement.component : ''}
            />
          </Grid>
        )}
        {!!tmpNewElement.component && (
          <>
            <Grid>
              <form className={className}>
                <InputLabel id="new_remplacement_operationlabel">Operateur de remplacement</InputLabel>
                <Select
                  labelId="new_remplacement_operation_label"
                  label="Type"
                  data-test="new_remplacement_operation"
                  style={{ width: '100%' }}
                  value={tmpModelReplacement.operation}
                  onChange={e => handleReplacementChange(e.target.value, 'operation')}
                >
                  {possibleOperators.map(operator => (
                    <MenuItem
                      data-test={'new_remplacement_operation_' + operator.value}
                      key={operator.value}
                      value={operator.value}
                    >
                      {operator.value ? operator.value : 'aucun'}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            </Grid>

            {tmpNewElement.type === Type.DATE &&
              tmpModelReplacement.operation &&
              tmpModelReplacement.operation !== '=' && (
                <Grid>
                  <form className={className}>
                    <InputLabel id="remplacement_unit_label">Unité</InputLabel>
                    <Select
                      labelId="remplacement_unit_label"
                      data-test="new_remplacement_unit"
                      style={{ width: '100%' }}
                      value={tmpModelReplacement.operationUnit.label}
                      onChange={e =>
                        handleReplacementChange(
                          OperationUnit.ALL.find(o => o.name === e.target.value),
                          'operationUnit'
                        )
                      }
                    >
                      {OperationUnit.ALL.map(possibleValue => {
                        return (
                          <MenuItem
                            data-test={'new_remplacement_unit_' + possibleValue.name}
                            key={possibleValue.name}
                            value={possibleValue.name}
                          >
                            {possibleValue.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </form>
                </Grid>
              )}
            {!!tmpModelReplacement.operation && (
              <>
                <Grid>
                  {(tmpModelReplacement.operation !== '=' || tmpNewElement.type !== Type.DATE) && (
                    <>
                      {tmpNewElement.type === Type.REFERENCE ? (
                        <form className={className}>
                          <InputLabel id="new_remplacement_value_label">Valeur de Remplacement</InputLabel>
                          <Select
                            labelId="new_remplacement_value_label"
                            data-test="new_remplacement_value"
                            style={{ width: '100%' }}
                            value={tmpModelReplacement.value}
                            disabled={tmpModelReplacement.refField}
                            onChange={e => handleReplacementChange(e.target.value, 'value')}
                          >
                            <MenuItem>vide</MenuItem>
                            {referentielValues.map(possibleValue => (
                              <MenuItem
                                data-test={'new_remplacement_value_' + possibleValue.code}
                                key={possibleValue.code}
                                value={possibleValue.code}
                              >
                                {possibleValue.code + ' - ' + possibleValue.libelle}
                              </MenuItem>
                            ))}
                          </Select>
                        </form>
                      ) : (
                        <TextField
                          data-test={'new_remplacement_value'}
                          label={'Valeur de Remplacement'}
                          value={tmpModelReplacement.value}
                          disabled={
                            tmpModelReplacement.refField &&
                            tmpModelReplacement.type !== Type.NUMERIC &&
                            tmpModelReplacement.type !== Type.DATE
                          }
                          className={'TextField'}
                          margin="normal"
                          placeholder="vide"
                          fullWidth
                          onChange={e => handleReplacementChange(e.target.value, 'value')}
                        />
                      )}
                    </>
                  )}
                </Grid>
                <Grid>
                  {
                    <form className={className}>
                      <InputLabel id="new_remplacement_ref_label">Reference de Remplacement</InputLabel>
                      <Select
                        labelId="new_remplacement_ref"
                        data-test="new_remplacement_ref"
                        style={{ width: '100%' }}
                        disabled={
                          tmpModelReplacement.value &&
                          tmpNewElement.type !== Type.NUMERIC &&
                          tmpNewElement.type !== Type.DATE
                        }
                        value={tmpModelReplacement.refField}
                        onChange={e => handleReplacementChange(e.target.value, 'refField')}
                      >
                        <MenuItem>vide</MenuItem>
                        {possibleTargetValues.map(possibleValue => (
                          <MenuItem
                            data-test={'new_remplacement_ref_' + possibleValue}
                            key={possibleValue}
                            value={possibleValue}
                          >
                            {possibleValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </form>
                  }
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import payeReducer from './reducers'

const devToolsEnhancer =
  typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : x => x

export function makeStore() {
  //const nodeEnv = process.env.NODE_ENV
  //const shouldPersist = false

  const enhancer = compose(applyMiddleware(thunk), devToolsEnhancer)
  //compose(offline(offlineConfig), applyMiddleware(thunk, offlineChain), devToolsEnhancer)

  const store = createStore(payeReducer, enhancer)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextGTR = require('./reducers').default
      store.replaceReducer(nextGTR)
    })
  }

  return store
}

const store = makeStore()

if (module.hot) {
  module.hot.accept('./reducers', () => {
    const nextGTR = require('./reducers').default
    store.replaceReducer(nextGTR)
  })
}

export default store

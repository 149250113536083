import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { WithExcludTooltip, withExclusion } from '../list-exclusion/with-exclusion-tooltip'
import {
  isList,
  isListExclusion,
  isNumeric,
  isReferentiel,
  isSimpleType,
  isText,
} from '../../../../../../../store/constants/type-helper'
import { TableurCellReferentielList } from './tableur-cell-referentiel-list'
import { Type } from '../../../../../../../store/constants/type'
import { TableurCellSimpleList } from './tableur-cell-Simple-list'
import { TableurCellReferentiel } from '../Referentiel/tableur-cell-referentiel'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { isEmptyObject } from '../tableur-cell-content'

export function getValueBetweenChevron(v) {
  return v.substring(v.indexOf('<'), v.indexOf('>') + 1)
}

export function getExcelValueToSave(value, component) {
  if (value && (value.inclus || value.exclues || value.key || Array.isArray(value))) return value
  if (value && !value.inclus && !value.exclues && !value.key && component.type === Type.LIST_EXCLUSION) {
    const str2 = getValueBetweenChevron(value.replace(',<', ';<'))
    str2.substring(0, str2.length - 1).substring(0, 1)
    const inclus = value.replace(str2, '').split(';')
    if (inclus[inclus.length - 1] === '') inclus.splice(inclus.length - 1, 1)
    return {
      inclus: inclus,
      exclus: str2.substring(1, str2.length - 1) ? str2.substring(1, str2.length - 1).split(';') : [],
    }
  } else return value ? value.split(';') : []
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

function getListTextValues(values) {
  if (exceedMaxElements(values)) {
    let listTextValues = values.slice(0, 5)
    listTextValues.push('...')
    return listTextValues
  }
  return values
}

function exceedMaxElements(values) {
  return values && values.length > 5
}

export function TableurCellList({
  dataKey,
  values,
  component,
  tooltipContent,
  parent,
  cellEditMode,
  lineEditeMode,
  editValue,
  editDataTest,
  setTmpData,
  line,
}) {
  const [tmpValue, setTmpValue] = useState(cellEditMode ? (editValue ? editValue : '') : isEmptyObject)
  const [tmpValues, setTmpValues] = useState(values ? values : [])
  const [addMode, setAddMode] = useState(false)
  const [tmpAddValue, setTmpAddValue] = useState('')
  const classes = useStyles()
  const referentielValues = useSelector(state => {
    const referentiels = state.tableurModule.referentiels
    if (isReferentiel(component.unit.type) && referentiels) {
      const find = referentiels.find(r => r.nom === component.unit.referentiel)
      if (find) return find.valeurs
    }
  })

  //useEffect(() => {
  //   if (lineEditeMode) setTmpValue(tmpValue)
  // }, [lineEditeMode])

  function addValue() {
    if (tmpAddValue || tmpValues === 0) {
      const oldTmpValues = tmpValues ? tmpValues : []
      const newValue = [...oldTmpValues, tmpAddValue]
      setTmpValues(newValue)
      setTmpData(component.nom, newValue, component)
    }
    setAddMode(false)
    setTmpAddValue('')
  }

  function handleChange({ target: { value } }) {
    setTmpValue(value)
    if (Array.isArray(value)) setTmpValues(value)
    const valueToSave = getExcelValueToSave(value, component)
    setTmpData(component.nom, valueToSave, component)
  }

  function handleAddInput({ target: { value } }) {
    setTmpAddValue(value)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') addValue()
  }

  useEffect(() => {
    setTmpValue(cellEditMode ? (editValue ? editValue : '') : values)
  }, [editValue, values, cellEditMode])

  useEffect(() => {
    const hasDataForTooltip = referentielValues && values && referentielValues.length && values.length
    if (
      values &&
      dataKey &&
      !cellEditMode &&
      (isListExclusion(component.type) || (component.type.name === Type.LIST.name && dataKey.key))
    ) {
      tooltipContent(
        <div data-test={'lines_tooltip_' + (dataKey.key ? dataKey.key : '')}>
          {withExclusion(values) ? (
            <WithExcludTooltip
              inclus={values.inclus}
              unit={component.unit}
              exclus={values.exclus}
              component={component}
            />
          ) : (
            <>
              {component.unit.type === Type.REFERENCE && (
                <TableurCellReferentielList tooltipContent={tooltipContent} values={values} component={component.unit} />
              )}
              {isSimpleType(component.unit.type) && <TableurCellSimpleList values={values} parent={parent} />}
            </>
          )}
        </div>
      )
    } else if (component.type.name === Type.LIST.name && exceedMaxElements(values) && isText(component.unit.type)) {
      tooltipContent(<>{<TableurCellSimpleList values={values} parent={parent} />}</>)
    } else if (hasDataForTooltip && component.type.name === Type.LIST.name && isReferentiel(component.unit.type) && (exceedMaxElements(values) || referentielValues.filter(rv => values.includes(rv.code) && rv.libelle.length > 30).length) ) {
      tooltipContent(<>
        {referentielValues
          .filter(rv => values.includes(rv.code))
          .map(v => <div key={v.code}>{v.code} - {v.libelle}</div>)
        }
      </>)
    }
  }, [values, dataKey, component])
  return (
    <>
      {
        <>
          {(cellEditMode || lineEditeMode) && (
            <>
              {!lineEditeMode ? (
                <TextField
                  data-test={editDataTest + '_edit'}
                  type={'text'}
                  value={Array.isArray(tmpValue) ? tmpValue.join(';') : tmpValue}
                  disabled={!lineEditeMode}
                  onChange={handleChange}
                  label={component.label}
                />
              ) : (
                <>
                  {
                    <>
                      {isListExclusion(component.type) ? (
                        <div data-test={editDataTest} />
                      ) : (
                        <>
                          {!addMode ? (
                            <>
                              {tmpValues && Array.isArray(tmpValues) && (
                                <Select
                                  multiple
                                  displayEmpty
                                  label={component.unit.label}
                                  input={<Input />}
                                  value={tmpValues}
                                  renderValue={selected => (
                                    <div className={classes.chips}>
                                      {selected.map(value => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                      ))}
                                    </div>
                                  )}
                                  onChange={handleChange}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  data-test={`${editDataTest}_edit`}
                                >
                                  {isReferentiel(component.unit.type)
                                    ? referentielValues.map(value => (
                                        <MenuItem key={value.code} value={value.code}>
                                          <Checkbox checked={!!tmpValues.find(v => value.code === v)} />
                                          <ListItemText primary={value.code + ' - ' + value.libelle} />
                                        </MenuItem>
                                      ))
                                    : tmpValues.map(value => (
                                        <MenuItem key={value} value={value}>
                                          <Checkbox checked={true} />
                                          <ListItemText primary={value} />
                                        </MenuItem>
                                      ))}
                                </Select>
                              )}
                              {isReferentiel(component.unit.type) || <AddIcon onClick={() => setAddMode(true)} />}
                            </>
                          ) : (
                            <>
                              {isSimpleType(component.unit.type) && (
                                <div style={{ whiteSpace: 'nowrap' }}>
                                  {component.unit.type.name === Type.NUMERIC.name ? (
                                    <TextField
                                      data-test={editDataTest + '_edit'}
                                      type={'number'}
                                      value={tmpAddValue}
                                      onChange={handleAddInput}
                                      label={component.unit.label}
                                      onKeyPress={handleKeyPress}
                                      onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                    />
                                  ) : (
                                    <TextField
                                      data-test={editDataTest + '_edit'}
                                      type={'text'}
                                      value={tmpAddValue}
                                      onChange={handleAddInput}
                                      label={component.unit.label}
                                      onKeyPress={handleKeyPress}
                                    />
                                  )}
                                  <SaveIcon onClick={addValue} />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  }
                </>
              )}
            </>
          )}
          {!lineEditeMode && !cellEditMode && (
            <>
              {dataKey.key ? (
                dataKey.key
              ) : (
                <>
                  {component.type === Type.LIST && (
                    <div>
                      {isReferentiel(component.unit.type) &&
                        values.map((v, idx) => (
                          idx > 3
                            ? idx === 4 ? '...' : ''
                            : <div key={v}>
                            <TableurCellReferentiel
                              code={v}
                              nom={component.unit.referentiel}
                              component={component.unit}
                              line={line}
                            />
                          </div>
                        ))}
                      {isNumeric(component.unit.type) && values.map(v => <div key={v}>{v}</div>)}
                      {isText(component.unit.type) && getListTextValues(values).map(v => <div key={v}>{v}</div>)}
                    </div>
                  )}
                  {component.type === Type.LIST_EXCLUSION && !isEmptyObject(values, true) && '...'}
                </>
              )}
            </>
          )}
        </>
      }
    </>
  )
}

TableurCellList.propTypes = { cv: PropTypes.any }

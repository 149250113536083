import _ from 'lodash'

/**
 * Un converter permet de convertir une valeur S en une autre T.
 * Il doit contenir deux méthodes: to et from
 * to : (S) -> T
 * from: (T,S) -> S
 * le deuxième argument de from n'est pas obligatoire et correspond souvent à la valeur S précédemment convertie
 */

export function isConverter(converter)  {
    return converter && _.isFunction(converter.from) && _.isFunction(converter.to)
}

export const IDENTITY_CONVERTER = {
    to : (value)=>{return value},
    from : (value)=>{return value}
}
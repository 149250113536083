import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import * as PropTypes from 'prop-types'
import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import { TableurCellReferentielList } from '../list/tableur-cell-referentiel-list'
import { isReferentiel } from '../../../../../../../store/constants/type-helper'

export function withExclusion(values) {
  return values && (!!values.inclus || !!values.exclus)
}

export function WithExcludTooltip({ exclus, inclus, unit}) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>inclus</TableCell>
          <TableCell>exclus</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            {
              isReferentiel(unit.type)
              ?<TableurCellReferentielList prefix="include" values={inclus} component={unit} ignoreToolType/>
              :inclus.map( (i, idx) => <div key={idx} data-test={'include_' + idx}>{i}</div>)
            }
          </TableCell>
          <TableCell>
            {
              isReferentiel(unit.type)
                ?<TableurCellReferentielList prefix="exclude" values={exclus} component={unit} ignoreToolType/>
                :exclus.map( (e, idx) => <div key={idx} data-test={'exclude_' + idx}>{e}</div>)
            }
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

WithExcludTooltip.propTypes = {
  inclus: PropTypes.any,
  unit: PropTypes.any,
  exclus: PropTypes.any,
  key: PropTypes.any,
}

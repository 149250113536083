import React,{useState,useEffect} from 'react'
import {makeStyles,Grid,Button,Popover,Paper} from "@material-ui/core"
import { ContainerTitle } from '../common/container-title'
import ReferentielValues from '../Referentiels/referentiel-values'
import * as PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    contentRoot: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    title :  {
      "display":"inline"
    }
  }))

export default function ReferenceEdit({component,referentielId,refValues,open,onClose,onSave,popinAnchorEl}){
    const classes = useStyles()
    const [refValuesMap,setRefValuesMap]=useState(new Map([]))
  
    useEffect(()=>{
      const newRefValuesMap=new Map([])
      refValues.forEach(val=>{
        newRefValuesMap.set(val.id,val)
      })
      setRefValuesMap(newRefValuesMap)
    },[refValues])
  
    const handleSave= (datas)=>{
      const newRefValuesMap=new Map(refValuesMap)
      newRefValuesMap.set(datas.key,datas.value)
      setRefValuesMap(newRefValuesMap)
      onSave && onSave({referentielId,refValuesMap:newRefValuesMap})
    }
  
    const handleCancel = () =>{
      onClose && onClose()
    }
    
    return (<Popover id={`popin_${component.nom}`} 
                        open={open} 
                        anchorEl={popinAnchorEl.current} 
                        style={{marginTop:"10px"}}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
            >
              <Paper className={classes.contentRoot}>
                <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                  <Grid item>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" wrap="nowrap">
                      <Grid item>
                        <ContainerTitle 
                            classes={classes}
                            title={`Valeurs de ${component.nom} de type reference`}
                          />
                      </Grid>
                      <Grid item>
                        <Button
                                data-test={"Close"}
                                size="medium"
                                onClick={handleCancel}
                            >
                            X
                          </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justify="space-around" spacing={2}>
                      <Grid item xs={12}>
                        <ReferentielValues 
                          referentielNom={component.referentiel}
                          referentielValuesMap={refValuesMap}
                          values={refValuesMap}
                          onSave={handleSave}
                          />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
      </Popover>)
  }
  ReferenceEdit.propTypes = {
    component:PropTypes.any.isRequired,
    refValues:PropTypes.array.isRequired,
    open:PropTypes.bool.isRequired,
    popinAnchorEl:PropTypes.any.isRequired
  }
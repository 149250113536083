import { Component } from '../constants/component'
import { getRoleFromString } from '../constants/role'
import { getTypeFromString, Type } from '../constants/type'
import {
  getCCById,
  getCCTheme,
  getCCThemeComponents,
  getCCWithComponent,
  setCCChildComponent,
  setCCComponent,
  setCCTheme,
} from './convention-collective-action'
import { deleteComponentFromTheme, getThemeInputs, getThemeOutputs, setTheme } from './theme-action'
import { showAlert, showConfirmation } from './alert-action'
import { dispatchActionsArray } from './common'
import getPayeService, { setMethodStatus } from '../../api/envService'
import { setLoader } from './loader-action'
import { getCompChildValues, getCompValues } from './values-action'
import { getCCChildReferentielById, getCCReferentielById } from './referentiel-action'
import {
  CC_ENDPOINT,
  COMPONENTS_ENDPOINT,
  INPUT_ENDPOINT,
  OUTPUT_ENDPOINT,
  THEME_ENDPOINT,
  USED_COMPONENTS_ENDPOINT,
  VERSION_ENDPOINT,
} from '../../api/endpoints'
import { SET_COMPONENT, SET_COMPONENTS, SET_USED_COMPONENT } from '../reducers/components-reducer'
import _ from 'lodash'
import { Statut } from '../constants/historique'
import { TransformationType } from '../constants/transformation-rule'

export const createComponent = (component, nextTodo) => dispatch => {
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().post(COMPONENTS_ENDPOINT, component))
    .then(() => nextTodo && nextTodo())
    .catch(error => console.error(error))
    .then(() => dispatch(setLoader(false)))
}

export const updateComponent = (component, version, transformationRule, nextStep) => dispatch => {
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() =>
      getPayeService().patch(COMPONENTS_ENDPOINT, component.nom, null, {
        component,
        version: version ? version.nom : '',
        transformationRule: transformationRule ? transformationRule : '',
      })
    )
    .catch(error => console.error(error))
    .then(() => dispatch(setLoader(false)))
    .then(() => nextStep && nextStep())
}

const deleteComponentDeep = (ccs, componentName, deleteComponent) => {
  const COMPONENTS_ENDPOINT_WITHOUT_ALERT = { ...COMPONENTS_ENDPOINT }
  setMethodStatus(COMPONENTS_ENDPOINT_WITHOUT_ALERT, 'deleteById', {
    '200': {},
    '202': {},
    '204': {},
  })
  return Promise.resolve()
    .then(() => {
      return deleteComponentFromTheme(ccs, componentName)
    })
    .then(() => {
      return deleteComponent
        ? getPayeService().deleteById(COMPONENTS_ENDPOINT_WITHOUT_ALERT, componentName)
        : Promise.resolve()
    })
}

export const deleteComponentByName = (ccs, componentName, deleteComponent) => {
  return Promise.resolve()
    .then(() => getPayeService().get(COMPONENTS_ENDPOINT))
    .then(components => buildComponentsFromJson(components))
    .then(components => {
      var result = [() => deleteComponentDeep(ccs, componentName, deleteComponent)]
      var deleteParentComponent = childComponentName => {
        components.forEach(comp => {
          let compName = comp.nom
          if (comp.unit && comp.unit.nom === childComponentName) {
            result.push(() => deleteComponentDeep(ccs, compName, true))
            deleteParentComponent(comp.nom)
          } else if (comp.enfants && !!comp.enfants.find(child => child.nom === childComponentName)) {
            if (comp.enfants.length === 1) {
              result.push(() => deleteComponentDeep(ccs, compName, true))
              deleteParentComponent(compName)
            } else {
              let newComp = _.cloneDeep(comp)
              _.remove(newComp.enfants, child => child.nom === childComponentName)
              result.push(() => {
                return getPayeService().patch(COMPONENTS_ENDPOINT, newComp.nom, null, newComp)
              })
            }
          }
        })
      }
      deleteParentComponent(componentName)
      var promise = Promise.resolve()
      for (let i = result.length - 1; i >= 0; i--) {
        promise = promise.then(result[i])
      }
      return promise
    })
}

const canDeleteThemeComp = (theme, componentName, versions, isInputs) => {
  return Promise.resolve()
    .then(
      () =>
        theme &&
        getPayeService().getChildren(THEME_ENDPOINT, theme.themeName, isInputs ? INPUT_ENDPOINT : OUTPUT_ENDPOINT)
    )
    .then(async components => {
      if (!theme) return
      let c = components.find(comp => comp.nom === componentName)
      if (c && c.version) {
        let check = false
        for (let version of versions) {
          if (!check) {
            check = c.version === version.nom
          }
          if (check && version.statut.name === Statut.PUBLIE.name) {
            return Promise.reject({ errorType: 'COMPONENT_USED_IN_VERSION' })
          }
        }
      }
      return Promise.resolve()
    })
}

export const confirmDeleteComponent = (componentName, theme, deleteAction) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => {
      if (theme) {
        return Promise.resolve([theme])
      } else {
        return getPayeService().get(THEME_ENDPOINT)
      }
    })
    .then(async themes => {
      let versions = await getPayeService().get(VERSION_ENDPOINT)
      versions.sort((v1, v2) => {
        if (v1.id === v2.id) {
          return 0
        } else if (v1.id < v2.id) {
          return -1
        } else {
          return 1
        }
      })
      return Promise.all(
        themes.map(theme =>
          Promise.all([
            canDeleteThemeComp(theme, componentName, versions, true),
            canDeleteThemeComp(theme, componentName, versions, false),
          ])
        )
      )
    })
    .then(() => getCCWithComponent(componentName, theme))
    .then(ccs => {
      let messages = []
      {
        let ccsDesc = ccs.map(cc => `${cc.idcc} - ${cc.titre}`).join(', ')
        if (ccs.length > 0) {
          messages.push({
            content: `Les CC "${ccsDesc}" sont actuellement concernées par le composant "${componentName}". Les données associées à ce composant seront perdues définitivement. Souhaitez-vous vraiment le supprimer ?`,
            severity: 'warning',
          })
        } else {
          messages.push({
            content: `Souhaitez-vous vraiment supprimer le composant "${componentName}" ?`,
            severity: 'warning',
          })
        }
      }
      return { ccs: ccs, messages: messages }
    })
    .then(({ ccs, messages }) => {
      if (messages && messages.length > 0) {
        const errors = messages.filter(message => message.severity === 'error')
        if (errors.length > 0) {
          const alerts = errors.map(err => showAlert('error', err.content))
          return dispatchActionsArray(dispatch, alerts)
        } else {
          const warnings = messages.map(mes => mes.content)
          return dispatch(
            showConfirmation(warnings, () => {
              dispatch(deleteAction(ccs))
            })
          )
        }
      }
    })
    .catch(error => {
      if (error.errorType === 'COMPONENT_USED_IN_VERSION') {
        dispatch(
          showAlert('error', 'Ce composant est déjà publié sur ce thème, il ne peut donc pas être dissocié du thème')
        )
      } else {
        console.log(error)
      }
    })
    .finally(() => {
      dispatch(setLoader(false))
    })
}

export const deleteComponent = (componentName, nextTodo) => {
  return confirmDeleteComponent(componentName, null, () => dispatch => {
    return Promise.resolve()
      .then(() => dispatch(setLoader(true)))
      .then(() => getPayeService().deleteById(COMPONENTS_ENDPOINT, componentName))
      .then(() => {
        !!nextTodo && nextTodo(dispatch)
      })
      .catch(error => {
        console.error(error)
        if (error && error.message) {
          dispatch(showAlert('error', error.message))
        } else {
          dispatch(showAlert('error', error))
        }
      })
      .finally(() => dispatch(setLoader(false)))
  })
}

export const getChildGroupComponentValues = (idcc, themeName, isInput, component) => dispatch => {
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => dispatch(getCompChildValues(idcc, themeName, isInput, component.nom)))
    .then(() => {
      return Promise.all(
        component.enfants
          ? component.enfants.map(enfant =>
              dispatch(getCCThemeChildComponentValues(idcc, themeName, isInput, component, enfant))
            )
          : []
      )
    })
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

function getUnitComponent(component) {
  return Promise.resolve(getPayeService().getById(COMPONENTS_ENDPOINT, component.unit.nom)).then(unit =>
    buildComponentFromJson(unit)
  )
}

export function setUnitComponent(component, dispatch, action) {
  if (component.unit && component.unit !== Component.NULL_UNIT && component.unit.nom) {
    if (!component.unit.label)
      return getUnitComponent(component).then(unit => {
        component.unit = unit
        if (action) dispatch(action())
        return unit
      })
    else return Promise.resolve(component.unit)
  }
  return Promise.resolve()
}

export const getCCThemeComponentValues = (idcc, themeName, isInput, componentName) => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => dispatch(getCCById(idcc)))
    .then(() => dispatch(getCCTheme(idcc, themeName)))
    .then(() =>
      getPayeService().getGrandchild(
        CC_ENDPOINT,
        idcc,
        THEME_ENDPOINT,
        themeName,
        isInput ? INPUT_ENDPOINT : OUTPUT_ENDPOINT,
        componentName
      )
    )
    .then(data => {
      return buildComponentFromJson(data, true)
    })
    .then(component => {
      dispatch(setCCComponent(component, isInput))
      return component
    })
    .then(component => {
      if (component.referentiel) {
        dispatch(getCCReferentielById(component.referentiel))
      }
      const promise = setUnitComponent(component, dispatch, () => setCCComponent(component, isInput))
      if (promise) {
        return promise
          .then(unit => unit && dispatch(getCCThemeChildComponentValues(idcc, themeName, isInput, component, unit)))
          .then(() => {
            return component
          })
      } else {
        return component
      }
    })
    .then(component => {
      return component.enfants
        ? component.enfants.map(enfant =>
            dispatch(getCCThemeChildComponentValues(idcc, themeName, isInput, component, enfant))
          )
        : []
    })
    .then(() => dispatch(getCompValues(idcc, themeName, isInput, componentName)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))

export const getCCThemeChildComponentValues = (
  idcc,
  themeName,
  isInput,
  parentComponent,
  childComponent
) => dispatch => {
  Promise.resolve()
    .then(() => {
      dispatch(setCCChildComponent(childComponent, isInput))
    })
    .then(() => {
      if (childComponent.referentiel) {
        dispatch(getCCChildReferentielById(childComponent))
      } else if (
        (parentComponent.type === Type.LIST || parentComponent.type === Type.LIST_EXCLUSION) &&
        childComponent.type === Type.GROUP
      ) {
        dispatch(getChildGroupComponentValues(idcc, themeName, isInput, childComponent))
      }
      //à décommenter lors de l'implémentation du type référence pour les sous groupes
      // else if(parentComponent.type === Type.GROUP && childComponent.type === Type.GROUP){
      //   return Promise.all(childComponent.enfants ? childComponent.enfants.map(enfant=>dispatch(getCCThemeChildComponentValues(idcc,themeName,isInput,childComponent,enfant))) : [])
      // }
    })
}

export const getComponent = compName =>
  getPayeService()
    .getById(COMPONENTS_ENDPOINT, compName)
    .then(comp => buildComponentFromJson(comp))

export const loadComponents = (needUnitText = false, setInit) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().get(COMPONENTS_ENDPOINT))
    .then(data => data.map(d => buildComponentFromJson(d, needUnitText)))
    .then(components => dispatch(setComponents(components)))
    .then(() => !!setInit && setInit(true))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const loadAllUsedComp = () => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().get(USED_COMPONENTS_ENDPOINT))
    .then(components => dispatch(setUsedComponents(components)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const loadAllThemeUsedComp = themeName => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().getChildren(THEME_ENDPOINT, themeName, USED_COMPONENTS_ENDPOINT))
    .then(componentNames => dispatch(setUsedComponents(componentNames)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const loadComponent = compName => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().getById(COMPONENTS_ENDPOINT, compName))
    .then(data => buildComponentFromJson(data, true))
    .then(component => dispatch(setComponent(component)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const setComponent = component => {
  return { type: SET_COMPONENT, value: component }
}

export const setComponents = components => {
  return { type: SET_COMPONENTS, value: components }
}

export const setUsedComponents = components => {
  return { type: SET_USED_COMPONENT, value: components }
}

export const getCCThemeComponentsPromise = (theme, idcc) => dispatch => {
  const ccThemePromise = dispatch(setCCTheme(theme))
  const ccOutputsPromise = dispatch(getCCThemeComponents(idcc, theme.nom))
  const ccInputsPromise = dispatch(getCCThemeComponents(idcc, theme.nom, true))
  return Promise.all([ccInputsPromise, ccOutputsPromise, ccThemePromise])
}

export const getThemeComponentsPromise = theme => dispatch => {
  const themePromise = dispatch(setTheme(theme))
  const themeInputsPromise = dispatch(getThemeInputs(theme.nom))
  const themeOutputsPromise = dispatch(getThemeOutputs(theme.nom))
  return Promise.all([themeInputsPromise, themeOutputsPromise, themePromise])
}

export const buildComponentFromJson = (data, needUnitText = false) => {
  return new Component(
    data.name ? data.name : data.nom,
    data.label,
    getRoleFromString(data.role),
    data.referentialName ? data.referentialName : data.referentiel,
    data.defaultValue,
    data.defaultValues,
    data.description,
    getTypeFromString(data.type),
    data.children || data.enfants
      ? buildComponentsFromJson(data.children ? data.children : data.enfants, needUnitText)
      : [],
    data.unit && (data.unit.name || data.unit.nom)
      ? buildComponentFromJson(data.unit, needUnitText)
      : needUnitText && data.unit && !data.unit.type
      ? { nom: data.unit }
      : Component.NULL_UNIT,
    data.autonome,
    data.lower,
    data.upper,
    data.bornes,
    data.ferme,
    data.ouvertAGauche,
    data.ouvertADroite,
    data.ouvert,
    data.consistency,
    data.textMap
      ? new Map(Object.entries(data.textMap).map(entry => [entry[0], new Map(Object.entries(entry[1]))]))
      : new Map(),
    data.customText
  )
}

export function buildComponentsFromJson(data, needUnitText = false) {
  return data.map(d => buildComponentFromJson(d, needUnitText))
}

import React from 'react'
import { Grid } from '@material-ui/core'
import CcWorkflowFormSimple from './inputs/cc-workflow-form-simple'
import CcWorkflowFormList from './inputs/cc-workflow-form-list'
import CcWorkflowFormListExclusion from './inputs/cc-workflow-form-list-exclusion'
import { TypeFormField } from '../../../store/constants/TypeFormField'

export default function CcWorkflowFormField({
                                              formField,
                                              fieldsInputType,
                                              onFieldValueChange,
                                              disabled = false,
                                              ignore,
                                              changeIgnore,
                                              required,
                                            }) {
  function getValue() {
    const find = fieldsInputType.find(f => f.field === formField.field)
    if (find) return find.value
  }


  return (
    <Grid item key={formField.field}>
      {(formField.typeForm === TypeFormField.DATE ||
        formField.typeForm === TypeFormField.TEXT ||
        formField.typeForm === TypeFormField.NUMERIC) && (
        <CcWorkflowFormSimple
          dataTest={`formField_input_${formField.field}`}
          nom={formField.field}
          type={formField.typeForm}
          onValueChange={(value) => onFieldValueChange && onFieldValueChange(formField.field, value)}
          value={getValue()}
          disabled={disabled}
          ignore={ignore}
          required={required}
          changeIgnore={changeIgnore}
        />
      )
      }
      {(formField.typeForm === TypeFormField.LIST || formField.typeForm === TypeFormField.REFERENCE) && (
        <CcWorkflowFormList dataTest={`formField_input_${formField.field}`}
                            nom={formField.field}
                            referentielName={formField.referentielName}
                            referenceValues={getValue()}
                            onValueChange={(values) => onFieldValueChange && onFieldValueChange(formField.field, values)}
                            disabled={disabled}
                            ignore={ignore}
                            required={required}
                            changeIgnore={changeIgnore} />
      )}
      {formField.typeForm === TypeFormField.LIST_EXCLUSION && (
        <CcWorkflowFormListExclusion
          dataTest={`formField_input_${formField.field}`}
          nom={formField.field}
          referentielName={formField.referentielName}
          referenceValues={getValue()}
          onValueChange={(values) => onFieldValueChange && onFieldValueChange(formField.field, values)}
          disabled={disabled}
          required={required}
          ignore={ignore}
          changeIgnore={changeIgnore}
        />
      )}
    </Grid>
  )
}

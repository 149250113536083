import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getPossibleComparators,
  getPossibleOperators,
  getPossibleTargetValues,
  getSameTypeComps,
  getWFInputValue,
  setWFComp,
} from '../workflow-helper'
import { getTypeFromString, Type } from '../../../../store/constants/type'
import { Grid } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { ALL_REF_TYPES, OperationUnit } from '../../../../store/constants/ModelRefField'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

export function ModelDoublonPopperContent({
  className,
  handleChange,
  tmpDoublonElement,
  formFields,
  handleReplacementChange,
  tmpModelReplacement,
}) {
  const [referentielValues, setReferentielValues] = useState([])
  const [referentielName, setReferentielName] = useState('')
  const allComp = useSelector(state => state.componentModule.components)
  const referentiels = useSelector(state => state.referentiels.referentiels)
  const allComputedComp = useMemo(getSameTypeComps(allComp, tmpDoublonElement), [allComp, tmpDoublonElement.type])
  const possibleComparators = useMemo(getPossibleComparators(tmpDoublonElement), [tmpDoublonElement])
  const possibleTargetValues = useMemo(getPossibleTargetValues(formFields, tmpDoublonElement,referentielName), [
    formFields,
    tmpDoublonElement,
    referentielName
  ])
  const possibleOperators = useMemo(getPossibleOperators(tmpDoublonElement), [tmpDoublonElement])

  function setComp(value) {
    setWFComp(value, tmpDoublonElement, referentiels, setReferentielValues, setReferentielName)
  }

  useEffect(() => {
    if (tmpDoublonElement) setComp(tmpDoublonElement.component)
  }, [tmpDoublonElement])

  return (
    <Grid witem xs={12}>
      <Grid container direction="column">
        <Grid>
          <form className={className}>
            <InputLabel id="double_type_label">Type</InputLabel>
            <Select
              labelId="double_type_label"
              label="Type"
              data-test="double_type"
              style={{ width: '100%' }}
              value={tmpDoublonElement.type ? tmpDoublonElement.type.name : ''}
              onChange={e => handleChange(getTypeFromString(e.target.value), 'type')}
            >
              {ALL_REF_TYPES.map(type => (
                <MenuItem data-test={'double_type_' + type.name} key={type.name} value={type.name ? type.name : null}>
                  {type.name ? type.label : 'aucun'}
                </MenuItem>
              ))}
            </Select>
          </form>
        </Grid>
        {!!tmpDoublonElement.type && (
          <Grid>
            <Autocomplete
              data-test="double_composant"
              options={allComputedComp}
              getOptionLabel={c => c.nom}
              style={{ width: '100%' }}
              onChange={(e, value) => {
                handleChange(value ? value.nom : value, 'component')
                setComp(value)
              }}
              inputValue={getWFInputValue(tmpDoublonElement)}
              renderInput={params => {
                return <TextField {...params} value={params.inputProps.value} label="Selectionner input" />
              }}
            />
          </Grid>
        )}
        {!!tmpDoublonElement.type && (
          <Grid>
            <form className={className}>
              <InputLabel id="double_comparateur_label">Comparateur</InputLabel>
              <Select
                labelId="double_comparateur_label"
                label="Type"
                data-test="double_comparateur"
                style={{ width: '100%' }}
                value={tmpDoublonElement.targetOperator}
                onChange={e => handleChange(e.target.value, 'targetOperator')}
              >
                {possibleComparators.map(operator => (
                  <MenuItem
                    data-test={'double_comparateur_' + operator.value}
                    key={operator.value}
                    value={operator.value}
                  >
                    {operator.value ? operator.value : 'aucun'}
                  </MenuItem>
                ))}
              </Select>
            </form>
          </Grid>
        )}
        {!!tmpDoublonElement.targetOperator && (
          <Grid>
            {tmpDoublonElement.type === Type.REFERENCE ? (
              <form className={className}>
                <InputLabel id="double_valeur_comparaison_label">Valeur de Comparaison</InputLabel>
                <Select
                  labelId="double_valeur_comparaison_label"
                  data-test="double_valeur_comparaison"
                  style={{ width: '100%' }}
                  value={tmpDoublonElement.targetValue}
                  onChange={e => handleChange(e.target.value, 'targetValue')}
                >
                  <MenuItem>vide</MenuItem>
                  {referentielValues.map(possibleValue => (
                    <MenuItem
                      data-test={'double_valeur_comparaison_' + possibleValue.code}
                      key={possibleValue.code}
                      value={possibleValue.code}
                    >
                      {possibleValue.code + ' - ' + possibleValue.libelle}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            ) : (
              <>
                {tmpDoublonElement.type === Type.DATE || (
                  <TextField
                    data-test={'double_valeur_comparaison'}
                    label={'Valeur de Comparaison'}
                    value={tmpDoublonElement.targetValue}
                    disabled={tmpDoublonElement.selectedTargetValue}
                    className={'TextField'}
                    margin="normal"
                    fullWidth
                    placeholder="vide"
                    onChange={e => handleChange(e.target.value, 'targetValue')}
                  />
                )}
                <form className={className}>
                  <InputLabel id="double_comparateur_ref_label">Valeur de Comparaison</InputLabel>
                  <Select
                    labelId="double_comparateur_ref_label"
                    data-test="double_comparateur_ref"
                    style={{ width: '100%' }}
                    disabled={tmpDoublonElement.targetValue}
                    value={tmpDoublonElement.selectedTargetValue ? tmpDoublonElement.selectedTargetValue : 'vide'}
                    onChange={e => handleChange(e.target.value, 'selectedTargetValue')}
                  >
                    <MenuItem>vide</MenuItem>
                    {possibleTargetValues.map(possibleValue => (
                      <MenuItem
                        data-test={'double_comparateur_ref_' + possibleValue}
                        key={possibleValue}
                        value={possibleValue}
                      >
                        {possibleValue}
                      </MenuItem>
                    ))}
                  </Select>
                </form>
              </>
            )}
          </Grid>
        )}
        {!!tmpDoublonElement.component && (
          <>
            <Grid>
              <form className={className}>
                <InputLabel id="double_remplacement_operationlabel">Operateur de remplacement</InputLabel>
                <Select
                  labelId="double_remplacement_operation_label"
                  label="Type"
                  data-test="double_remplacement_operation"
                  style={{ width: '100%' }}
                  value={tmpModelReplacement.operation}
                  onChange={e => handleReplacementChange(e.target.value, 'operation')}
                >
                  {possibleOperators.map(operator => (
                    <MenuItem
                      data-test={'double_remplacement_operation_' + operator.value}
                      key={operator.value}
                      value={operator.value}
                    >
                      {operator.value ? operator.value : 'aucun'}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            </Grid>
            {tmpDoublonElement.type === Type.DATE &&
              tmpModelReplacement.operation &&
              tmpModelReplacement.operation !== '=' && (
                <Grid>
                  <form className={className}>
                    <InputLabel id="remplacement_unit_label">Unité</InputLabel>
                    <Select
                      labelId="remplacement_unit_label"
                      data-test="double_remplacement_unit"
                      style={{ width: '100%' }}
                      value={tmpModelReplacement.operationUnit.label}
                      onChange={e =>
                        handleReplacementChange(
                          OperationUnit.ALL.find(o => o.name === e.target.value),
                          'operationUnit'
                        )
                      }
                    >
                      {OperationUnit.ALL.map(possibleValue => {
                        return (
                          <MenuItem
                            data-test={'double_remplacement_unit_' + possibleValue.name}
                            key={possibleValue.name}
                            value={possibleValue.name}
                          >
                            {possibleValue.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </form>
                </Grid>
              )}
            {!!tmpModelReplacement.operation && (
              <>
                <Grid>
                  {(tmpModelReplacement.operation !== '=' || tmpDoublonElement.type !== Type.DATE) && (
                    <>
                      {tmpDoublonElement.type === Type.REFERENCE ? (
                        <form className={className}>
                          <InputLabel id="double_remplacement_value_label">Valeur de Remplacement</InputLabel>
                          <Select
                            labelId="double_valeur_comparaison_label"
                            data-test="double_remplacement_value"
                            style={{ width: '100%' }}
                            value={tmpModelReplacement.value}
                            disabled={tmpModelReplacement.refField}
                            onChange={e => handleReplacementChange(e.target.value, 'value')}
                          >
                            <MenuItem>vide</MenuItem>
                            {referentielValues.map(possibleValue => (
                              <MenuItem
                                data-test={'double_remplacement_value_' + possibleValue.code}
                                key={possibleValue.code}
                                value={possibleValue.code}
                              >
                                {possibleValue.code + ' - ' + possibleValue.libelle}
                              </MenuItem>
                            ))}
                          </Select>
                        </form>
                      ) : (
                        <TextField
                          data-test={'double_remplacement_value'}
                          label={'Valeur de Remplacement'}
                          value={tmpModelReplacement.value}
                          disabled={
                            tmpModelReplacement.refField &&
                            tmpModelReplacement.type !== Type.NUMERIC &&
                            tmpModelReplacement.type !== Type.DATE
                          }
                          className={'TextField'}
                          margin="normal"
                          fullWidth
                          placeholder="vide"
                          onChange={e => handleReplacementChange(e.target.value, 'value')}
                        />
                      )}
                    </>
                  )}
                </Grid>
                <Grid>
                  {
                    <form className={className}>
                      <InputLabel id="double_remplacement_ref_label">Reference de Remplacement</InputLabel>
                      <Select
                        labelId="double_remplacement_ref"
                        data-test="double_remplacement_ref"
                        style={{ width: '100%' }}
                        disabled={
                          tmpModelReplacement.value &&
                          tmpDoublonElement.type !== Type.NUMERIC &&
                          tmpDoublonElement.type !== Type.DATE
                        }
                        value={tmpModelReplacement.refField}
                        onChange={e => handleReplacementChange(e.target.value, 'refField')}
                      >
                        <MenuItem>vide</MenuItem>
                        {possibleTargetValues.map(possibleValue => (
                          <MenuItem
                            data-test={'double_remplacement_ref_' + possibleValue}
                            key={possibleValue}
                            value={possibleValue}
                          >
                            {possibleValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </form>
                  }
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

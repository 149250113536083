import React from "react"
import * as PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'

export default function CellStringRenderer({dataTestPrefix,dataTestKey,mapper,rowValue,align="left"}){
    let value=mapper(rowValue)
    return (
         <TableCell data-test={dataTestPrefix+`_cell_value`} data-key={dataTestKey} align={align}>
            <span data-test={`${dataTestPrefix}_label_${value}`}>{value}</span>
        </TableCell>)
}

CellStringRenderer.propTypes = {
    dataTestPrefix:PropTypes.string.isRequired,
    mapper:PropTypes.func.isRequired,
    rowValue:PropTypes.any,
    dataTestKey:PropTypes.string.isRequired,
    align:PropTypes.string
}


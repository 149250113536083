import React, { useEffect } from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import Fade from '@material-ui/core/Fade'
import * as PropTypes from 'prop-types'

export default function Alert({ severity, children, onClose, timeout }) {
  const [open, setOpen] = React.useState(true)
  const [isClose, setClose] = React.useState(false)
  const close = () => {
    if (!isClose) {
      setClose(true)
      onClose()
    }
  }
  useEffect(() => {
    if (timeout && open && !isClose) {
      const timer = setTimeout(() => {
        if (open && !isClose) {
          setOpen(false)
        }
      }, timeout)
      return () => clearTimeout(timer)
    }
  }, [open, isClose, setOpen, timeout])
  const onTransitionEndClose = () => {
    if (!open) {
      close()
    }
  }
  return (
    <Fade in={open}>
      <MuiAlert onTransitionEnd={onTransitionEndClose} severity={severity} onClose={close}>
        <div style={{wordBreak:'break-word' }}>{children}</div>
      </MuiAlert>
    </Fade>
  )
}

Alert.propTypes = {
  timeout: PropTypes.number,
  severity: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export class WorkflowStatut {
  name
  label

  constructor(name, label) {
    this.name = name
    this.label = label
  }

  static NULL = new WorkflowStatut('', '')
  static EN_COURS = new WorkflowStatut('EN_COURS', 'En cours')
  static VALIDE = new WorkflowStatut('VALIDE', 'Validé')
  static PUBLIE = new WorkflowStatut('PUBLIE', 'publié')
  static CANCEL = new WorkflowStatut('CANCEL', 'Annulé')
  static ALL = [
    WorkflowStatut.NULL,
    WorkflowStatut.EN_COURS,
    WorkflowStatut.PUBLIE,
    WorkflowStatut.VALIDE,
    WorkflowStatut.CANCEL,
  ]
  static fromStr = str => (str ? this.ALL.find(t => t.name.toUpperCase() === str) : this.NULL)
  static fromLabel = str => (str ? this.ALL.find(t => t.label === str) : this.NULL)
}

const parseDate = str => {
  if (!str) return null
  const val = str.split('/')
  return Date.parse(`${val[2]}-${val[1]}-${val[0]}`)
}

export class ModelInstanceWorkflow {
  nom
  description
  referenceAccord
  source
  statut
  themes
  themesInputType
  fieldsInputType
  idcc
  modelWorkflow
  workflowRef
  dateValidation
  parameters

  constructor(
    nom,
    description,
    referenceAccord = '',
    statut = null,
    themes = [],
    themesInputType = [],
    fieldsInputType = [],
    idcc,
    modelWorkflow,
    dateValidation,
    workflowRef,
    user,
    source,
    parameters = []
  ) {
    this.nom = nom
    this.description = description
    this.referenceAccord = referenceAccord
    this.source = source
    this.statut = {}
    if (statut) {
      this.statut = statut
    } else {
      themes.forEach(theme => {
        this.statut[theme.toUpperCase()] = WorkflowStatut.EN_COURS
      })
    }
    this.themes = themes
    this.themesInputType = themesInputType
    this.fieldsInputType = fieldsInputType
    this.idcc = idcc
    this.modelWorkflow = modelWorkflow
    this.dateValidation = dateValidation
    this.workflowRef = workflowRef
    this.user = user
    this.source = source
    this.parameters = parameters
  }

  static NULL = new ModelInstanceWorkflow('', '')
}

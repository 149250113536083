import { ModelTextualisation } from './ModelTextualisation'

export class ModelWorkflow{
  nom
  description
  type
  clearOutput
  formFields
  refFields
  newValues
  doublons
  textualisation
  constructor(nom = '', description = '', type = TypeModele.NULL, clearOutput = false, formFields = [], refFields = [], newValues = [], doublons= [], textualisation = ModelTextualisation.NULL) {
    this.nom = nom
    this.description = description
    this.type = type
    this.clearOutput = clearOutput
    this.formFields = formFields
    this.refFields = refFields
    this.newValues = newValues
    this.doublons = doublons
    this.textualisation = textualisation
  }
}

export class TypeModele {
  name
  description
  constructor(name, description) {
    this.name = name
    this.description = description
  }
  static SIMPLE = new TypeModele("SIMPLE", "duplication simple")
  static UPDATE = new TypeModele("UPDATE", "duplication avec correction de doublons")
  static CREATE = new TypeModele("CREATE", "création simple")
  static NULL = new TypeModele("", "")
  static ALL = [TypeModele.NULL, TypeModele.SIMPLE, TypeModele.UPDATE, TypeModele.CREATE]
  static fromDescription = str => str ? this.ALL.find(t => t.description === str) : str
  static fromStr = str => str ? this.ALL.find(t => t.name.toUpperCase() === str) : str
}
import { Grid } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import * as PropTypes from 'prop-types'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 450,
  },
}))
export const SelectComponentTheme = ({ onChange, components, selectedComp, type }) => {
  const classes = useStyles()
  return (
    <Grid item xs={4}>
      <FormControl className={classes.formControl}>
        <InputLabel id="mutiple-checkbox-label">Sélectionnez</InputLabel>
        <Select
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          multiple
          value={selectedComp}
          onChange={onChange}
          input={<Input />}
          renderValue={selected => selected.join(', ')}
          className={`${type}s_list`}
          data-test={`${type}s_list`}
        >
          {components.map(({ nom }) => (
            <MenuItem
              key={`option_${type}_${nom}`}
              value={nom}
              className={`option_${type}`}
              data-test={`option_${type}_${nom}`}
            >
              <Checkbox checked={selectedComp.indexOf(nom) > -1} />
              <ListItemText primary={nom} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

SelectComponentTheme.propTypes = {
  selectedComp: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  components: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
}

import Paper from '@material-ui/core/Paper'
import { Grid } from '@material-ui/core'
import React from 'react'
import * as PropTypes from 'prop-types'
import { TextCard } from './textCard'
import { TextFragmentType } from '../../../../../store/constants/TextFragmentType'


export function FragmentTextPanel({ fragments, setFragments, isPlural }) {
  return <Paper elevation={1} style={{ backgroundColor: isPlural ? 'pink' : '#fafafa', padding: 10, width: '100%', minHeight: 67 }}>
    <Grid container direction='row'>
      {isPlural && <span style={{color: 'white', fontSize: 'larger', fontWeight: 'bold', lineHeight: '47px', marginRight: 10}}>Pluriel</span>}
      {
        fragments.map((fragment, i) =>
          <TextCard key={i} idx={i} setList={setFragments} list={fragments} isEditable={fragment.type === TextFragmentType.TEXT} fragment={fragment}>
            <span style={{ color: fragment.type === TextFragmentType.TEXT ? '#9fa8da' : '#e91e63' }}>{[fragment.type === TextFragmentType.TEXT ? fragment.text : fragment.component]}</span>
          </TextCard>)
      }
    </Grid>
  </Paper>
}

FragmentTextPanel.propTypes = {
  fragments: PropTypes.arrayOf(PropTypes.any),
  setFragments: PropTypes.func,
}
import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Popover, styled } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../../../../common/container-title'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { BlockTextualisationPopperContent } from './block-textualisation-popper-content'
import * as PropTypes from 'prop-types'
import { SimpleCard } from './simple-card'
import { GroupCard } from './group-card'
import { CardsMenu } from './cards-menu'
import { TextBlockType } from '../../../../../store/constants/TextBlockType'
import { TextBlock } from '../../../../../store/constants/TextBlock'
import { TextFragment } from '../../../../../store/constants/TextFragment'
import { TextFragmentType } from '../../../../../store/constants/TextFragmentType'
import { useDispatch, useSelector } from 'react-redux'
import { AddItemButtons } from '../../../../conventions-collectives/themes/add-item-buttons'
import { ValidationButton } from '../../../../common/validation-button'
import { getBlocks, getModels, updateBlocks } from '../../../../../store/actions/workflow-action'
import { DropzoneDialog } from 'material-ui-dropzone'
import getPayeService from '../../../../../api/envService'
import { IMPORT_BLOCKS_ENDPOINT, IMPORT_WORKFLOW_ENDPOINT } from '../../../../../api/endpoints'

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  margin: 5,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}))

CardsMenu.propTypes = {
  anchorEl: PropTypes.func,
  onClose: PropTypes.func,
  block: PropTypes.any,
  idx: PropTypes.number,
  parent: PropTypes.string,
  handleTextPopoverOpen: PropTypes.func,
}

const FIN_INTRO_BLOCK = new TextBlock([new TextFragment(TextFragmentType.TEXT, "[fin intro]")], TextBlockType.SIMPLE)
const NOUVELLE_LIGNE_BLOCK = new TextBlock([new TextFragment(TextFragmentType.TEXT, "[nouvelle ligne]")], TextBlockType.SIMPLE)
const ET_BLOCK = new TextBlock([new TextFragment(TextFragmentType.TEXT, "et")], TextBlockType.SIMPLE)
const POINT_BLOCK = new TextBlock([new TextFragment(TextFragmentType.TEXT, ".")], TextBlockType.SIMPLE)
const VIRGULE_BLOCK = new TextBlock([new TextFragment(TextFragmentType.TEXT, ",")], TextBlockType.SIMPLE)

export function TextualisationTextElements({ classes }) {
  const [anchorElText, setAnchorElText] = useState()
  const [idxBlock, setIdxBlock] = useState(null)
  const [childIdxBlock, setChildIdxBlock] = useState(null)
  const [grandChildIdxBlock, setGrandChildIdxBlock] = useState(null)
  const [parent, setParent] = useState('')
  const [condition, setCondition] = useState(null)
  const [tmpBlocks, setTmpBlocks] = useState([])
  const [open, setOpen] = useState(false)
  const textu = useSelector(state => state.workflowModule.blocks)
  const isModelisateur = useSelector(state => state.user.user.role === 'MODELISATEUR')
  const testmode = useSelector(state => state.user.testmode)
  const dispatch = useDispatch()

  useEffect(() => {
      if(textu) setTmpBlocks(textu.blocks)
    },
    [textu]
  )



  const handleTextPopoverOpen = (event, idx, parent, childIdx, grandChildId, condition) => {
    setAnchorElText(event.target)
    if (idx !== null) setIdxBlock(idx)
    if (childIdx !== null) setChildIdxBlock(childIdx)
    if (grandChildId !== null) setGrandChildIdxBlock(grandChildId)
    if (parent !== null) setParent(parent)
    setCondition(condition)
  }

  const openText = Boolean(anchorElText)

  function setBlocks(newBlock) {
    setTmpBlocks([...newBlock])
  }

  function handleSaveBlock(newBlock, index) {
    let blocks = [...tmpBlocks]
    if (index || index === 0) blocks.splice(index, 1, newBlock)
    else blocks = [...tmpBlocks, newBlock]
    setTmpBlocks(blocks)
    setAnchorElText(null)
    setIdxBlock(null)
    setChildIdxBlock(null)
    setGrandChildIdxBlock(null)
    setParent('')
  }

  function onSave() {
    dispatch(updateBlocks({blocks: tmpBlocks}))
  }

  function handleCancelText() {
    setAnchorElText(null)
  }

  function openMenu(e, comp) {
    if (comp) setParent(comp.nom)
  }

    const disabledFinIntro = useMemo( () => tmpBlocks.find(b =>JSON.stringify(b) === JSON.stringify(FIN_INTRO_BLOCK) || !tmpBlocks.length), [tmpBlocks])

  const openImportDialog = () => setOpen(true)

  const handlePostFile = files => {
    if (files.length === 1) {
      setOpen(false)
      getPayeService().postFile(IMPORT_BLOCKS_ENDPOINT, files[0], 'dataFile')
        .then( () => dispatch(getBlocks()))
    }
  }

  const handleExport = nom =>
    window.open(
      '/' +
      (testmode ? 'testmode' : 'api') +
      '/modele-workflow/blocksFile' ,
      '_blank',
    )

  return (
    <>
      {openText && <Popover anchorEl={anchorElText}
                               open={openText}
                               anchorPosition={{ top: 270, left: 660 }}
                               anchorOrigin={{
                                 vertical: 'center',
                                 horizontal: 'left',
                               }}
                               transformOrigin={{
                                 vertical: 'center',
                                 horizontal: 'right',
                               }}>
        <Paper className={classes.popper} elevation={1}>
        <ContainerTitle classes={classes} title='Textualisation du rapport' />
        <Grid container direction='row' justify='flex-end'>
          <BlockTextualisationPopperContent
            className={classes.formControlPopper}
            tmpBlocks={tmpBlocks}
            handleCancel={handleCancelText}
            handleSave={handleSaveBlock}
            index={idxBlock}
            childIndex={childIdxBlock}
            grandChildIndex={grandChildIdxBlock}
            parameters={[]}
            parent={parent}
            condition={condition}
          />
        </Grid>
      </Paper>
      </Popover>}


      <Grid item xs={12}>
        <Paper className={classes.contentRoot} elevation={1} data-test={'modele_table_textualisation'}>
          <Grid container direction='row'   >
            <ContainerTitle classes={classes} title='Textualisation du rapport' />
          </Grid>
              <Grid container direction='row' justify='flex-end'>
                <Grid style={{ marginBottom: 16 }}>
                  <Button
                    data-test='Ajouter un element de textualisation'
                    color='primary'
                    variant='contained'
                    size='medium'
                    className={classes.margin}
                    onClick={handleTextPopoverOpen}>
                    NOUVEAU
                  </Button>
                </Grid>
              </Grid>
              <Grid container direction='row' >
                <Grid style={{ marginBottom: 16 }}>
                  <Button
                    onClick={ () => handleSaveBlock(FIN_INTRO_BLOCK, tmpBlocks.length)}
                    disabled={!!disabledFinIntro}
                    style={{marginLeft: 5}}
                    data-test='Ajouter fin intro'
                    size='small'
                    variant="contained"
                    color='primary'
                    className={classes.margin}>
                    [fin intro]
                  </Button>
                  <Button
                    onClick={ () => handleSaveBlock(NOUVELLE_LIGNE_BLOCK, tmpBlocks.length)}
                    style={{marginLeft: 5}}
                    data-test='Nouvelle ligne'
                    size='small'
                    variant="contained"
                    color='primary'
                    className={classes.margin}>
                    [nouvelle ligne]
                  </Button>
                  <Button
                    onClick={ () => handleSaveBlock(ET_BLOCK, tmpBlocks.length)}
                    color='primary'
                    data-test='Ajouter fin intro'
                    style={{marginLeft: 15}}
                    size='small'
                    variant="contained"
                    className={classes.margin}>
                    et
                  </Button>
                  <Button
                    onClick={ () => handleSaveBlock(POINT_BLOCK, tmpBlocks.length)}
                    color='primary'
                    data-test='Ajouter fin intro'
                    style={{marginLeft: 15}}
                    size='small'
                    variant="contained"
                    className={classes.margin}>
                    point
                  </Button>
                  <Button
                    onClick={ () => handleSaveBlock(VIRGULE_BLOCK, tmpBlocks.length)}
                    data-test='Ajouter fin intro'
                    color='primary'
                    style={{marginLeft: 15}}
                    size='small'
                    variant="contained"
                    className={classes.margin}>
                    virgule
                  </Button>
                </Grid>
              </Grid>
              <Grid>
                <Box display='grid' gap={2}>
                  <Paper elevation={1}
                         style={{ backgroundColor: '#fafafa', padding: 10, width: '100%', minHeight: 67 }}>
                    <Grid container direction='row' alignItems='center'>
                      {
                        !!tmpBlocks.length && tmpBlocks.map((block, idx) => {
                          return <Grid key={idx}>
                              {
                                block.type === TextBlockType.GROUP
                                  ? <GroupCard key={idx}
                                               idx={idx}
                                               openMenu={openMenu}
                                               blocks={tmpBlocks}
                                               setList={setBlocks}
                                               handleTextPopoverOpen={handleTextPopoverOpen}
                                               block={block} />
                                  : <SimpleCard idx={idx}
                                                edit={openMenu}
                                                setList={setBlocks}
                                                blocks={tmpBlocks}
                                                handleTextPopoverOpen={handleTextPopoverOpen}
                                                block={block} />
                              }
                            </Grid>
                        })
                      }

                    </Grid>
                    <Grid container direction="row" justify="flex-end">
                      <Grid>
                        <ValidationButton
                          variant="contained"
                          size="medium"
                          color="primary"
                          onClick={onSave}
                          className={classes.margin}
                          disabled={!isModelisateur || ( !!textu && tmpBlocks === textu.blocks) }
                        >
                          ENREGISTRER
                        </ValidationButton>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
                <Grid container direction='row' justify='flex-end' spacing={1}>
                  <Grid item style={{ marginTop: 10 }}>
                    <Button
                      color='primary'
                      variant='contained'
                      size='medium'
                      onClick={handleExport}
                      className={classes.margin}
                    >
                      exporter
                    </Button>
                  </Grid>
                  { isModelisateur && <Grid item  style={{ marginTop: 10 }}>
                    <ValidationButton
                      data-test='Nouveau Modèle de workflow'
                      variant='contained'
                      size='medium'
                      onClick={openImportDialog}
                      className={classes.margin}
                    >
                      importer
                    </ValidationButton>
                  </Grid>}
                </Grid>
              </Grid>
        </Paper>
        <DropzoneDialog
          showAlerts={false}
          open={open}
          onSave={handlePostFile}
          dropzoneClass='drop-zone'
          acceptedFiles={[
            'application/json',
            '.json',
          ]}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={() => setOpen(false)}
          filesLimit={1}
          submitButtonText='Importer'
          cancelButtonText='Annuler'
        />
      </Grid>


    </>
  )
}

import { shallowEqual, useSelector } from 'react-redux'
import { useTableurComponents } from '../../../hooks/hooks'
import * as PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { isDate, isGroup, isSimpleType } from '../../../../../store/constants/type-helper'
import { Type } from '../../../../../store/constants/type'
import { Component } from '../../../../../store/constants/component'
import { DataRow } from './data-row'
import { getComputedData } from '../../../../../store/selectors/tableur-selectors'

export const getActiveComponents = (components, parent = '') => components.flatMap(c => {
  if (isSimpleType(c.type) || isDate(c.type) || c.type.name === Type.LIST.name || c.type.name === Type.REFERENCE.name) return [c]
  if (isGroup(c.type)) {
    return [
      c,
      ...c.enfants.flatMap(e =>
        getActiveComponents(
          [
            Component.clone(e)
              .withParentPath(parent + c.nom)
              .withParentType(c.type),
          ],
          (parent ? parent : '') + c.nom + '.'
        )
      ),
    ]
  }
  if (c.type.name === Type.LIST_EXCLUSION.name) {
    return [
      c,
      Component.clone(c.unit)
        .withParentPath(parent + c.nom)
        .withParentType(c.type)
        .withExclude(false),
      Component.clone(c.unit)
        .withParentPath(parent + c.nom)
        .withParentType(c.type)
        .withExclude(true),
    ]
  }
})

export function DataRows({ classes, isInput, prefix}) {
  const dataIds = useSelector(state => getComputedData(state).map(d => d.id + '_' + d.idCC), shallowEqual)
  const components = useTableurComponents(isInput)
  const pageSize = useSelector(state => state.tableurModule.pageSize)
  const page = useSelector(state => state.tableurModule.page)

  const activeComponents = useMemo(() => getActiveComponents(components), [components])
 // console.log('rows ' + prefix)
  return <>
    {!!components.length && !!dataIds.length && dataIds.slice(page, pageSize).map((d, idx) => {
      return <DataRow key={d}
                      prefix={prefix}
                      dataId={d}
                      activeComponents={activeComponents}
                      line={idx}
                      classes={classes}
                      isInput={isInput}/>
    })}
  </>
}

DataRows.propTypes = {
  classes: PropTypes.any.isRequired,
  prefix: PropTypes.any.isRequired,
  isInput: PropTypes.any.isRequired,
}

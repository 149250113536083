import React,{useEffect,useState} from 'react'
import * as PropTypes from 'prop-types'
import CellStringRenderer from '../common/table/editable/renderer/cell-string-renderer'
import {withEditableRow,withFormTable, withDefaultHeader,withDefaultHeaderLabel,ActionForm,withHeaders} from '../Table/form-table'
import RowAddEditText from './row-add-referentiel-values'
import { getKey } from '../components/helper'
import EnhancedTableToolbar from '../Table/EnhancedTableToolbar'
import _ from 'lodash'

export function RowTextEditor({rowKey,rowValue,creation,onChange,onSave,save,check,onCancel}){
  return (
    <RowAddEditText
          dataTestPrefix="referentiel_component"
          dataTestKey={rowKey}
          initialValue={rowValue}
          onChange={onChange}
          onSave={onSave}
          save={save}
          check={check}
          onCancel={onCancel}
          creation= {creation}
      />)
}
RowTextEditor.propTypes={
  rowKey:PropTypes.string.isRequired,
  rowValue:PropTypes.any.isRequired,
  save:PropTypes.bool.isRequired,
  onChange:PropTypes.func,
  onSave:PropTypes.func,
  onCancel:PropTypes.func,
  creation:PropTypes.bool.isRequired,
}


function CellRenderer(column){
  let Component=  ({rowKey,rowValue})=>{
    return (<CellStringRenderer
       // align="left"
        dataTestKey={rowKey}
        dataTestPrefix="referentiel_component"
        mapper={(v)=>rowValue[column]}
        rowValue={rowValue}/>)
  }
  Component.propTypes = {
    rowValue:PropTypes.object.isRequired,
    rowKey:PropTypes.string.isRequired
  }
  return Component
}

function ActionNouveau(props){
  return (<ActionForm buttonName="Nouveau" buttonDataTest="Nouvelle valeur" {...props}>
          </ActionForm>)
}

function Search(props){
  return (<EnhancedTableToolbar onSearch={props.filterValues} initSearchText={props.initSearchText}>
          </EnhancedTableToolbar>)
}

let FormTable=withFormTable([Search,ActionNouveau],
  withHeaders([withDefaultHeader("headerCode"),withDefaultHeader("headerLibelle"),withDefaultHeader("headerText"),withDefaultHeaderLabel("actions")]),
    withEditableRow(
      RowTextEditor,
      [CellRenderer("code"),CellRenderer("libelle"),CellRenderer("text")])
)

function format(value) {
  if (value) {
    let result = value.toLowerCase()
    result = result.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    result = _.replace(result, '_', '')
    return result
  }
  return value
}

function filter(entries,search) {
  return entries.filter(entry => {
    let item=entry[1]
    if (search) {
      return (
        format(item.code).includes(format(search)) ||
        format(item.libelle).includes(format(search))
      )
    } else {
      return true
    }
  })
}

export default function ReferentielValues({onSave,onDelete,onSelect,onCheckUnicity,referentielValuesMap,referentielNom,check,values,search='',onSearch}) {
  const [valuesMap,setValuesMap] = useState(new Map([]))
  const [rowsEditKey,setRowsEditKey] = useState([])
  const [creation,setCreation] = useState(false)

  useEffect(()=>{
    if(rowsEditKey.length === 0) {
      setValuesMap(new Map(filter([...values.entries()],search)))
    }
  },[values,rowsEditKey,search])

  function validateRowValue(rowValue) {
    return (rowValue && _.isObject(rowValue) && rowValue.code && rowValue.code.length >1 && rowValue.libelle && rowValue.libelle.length > 1)
  }

  function isValueEqual(val1,val2){
    if(val1 && val2){
      return (val1.code === val2.code) || (val1.libelle === val2.libelle)
    }else{
      return false
    }
  }

  function handleCheckUnicity(rowValue,itemChanged){
    let itemFound = Array.from(referentielValuesMap).find(val=>!(itemChanged && itemChanged.length>=2 && itemChanged[0] === val[0]) && isValueEqual(rowValue,val[1]))
    if(itemFound){
      onCheckUnicity && onCheckUnicity(itemFound)
      return false
    }else{
      onCheckUnicity && onCheckUnicity()
      return true
    }
  }

  function handleDelete(rowKey){
    if(onDelete){
      onDelete(rowKey)
    }
  }

  const handleRowCancel = ()=>{
    setRowsEditKey([])
  }

  function handleSave(datas){
    setRowsEditKey([])
    if(onSave){
      onSave(datas)
    }
  }

  const handleActionEdit = ()=>{
    var newValuesMap = new Map(valuesMap)
    var key=createKeyForReferentiel(newValuesMap)
    newValuesMap.set(key,{code:"",libelle:""})
    setValuesMap(newValuesMap)
    setRowsEditKey([...rowsEditKey,key])
    setCreation(true)
    return false
  }

  const handleFilterValues = (text)=>{
    onSearch && onSearch(text)
  }

  const handleRowChange = (rowKey,rowValue)=>{
    var newValuesMap = new Map(valuesMap)
    newValuesMap.set(rowKey,rowValue)
    setValuesMap(newValuesMap)

  }

  const handleRowEdit = ()=>{
    setCreation(false)
  }

  function createKeyForReferentiel(values){
    let vals = new Map([...referentielValuesMap,...values])
    return getKey(vals)
  }

  return (
    <FormTable
      onSave={handleSave}
      onDelete={handleDelete}
      onRowCancel={handleRowCancel}
      onRowChange={handleRowChange}
      onCheckUnicity={handleCheckUnicity}
      onActionEdit={handleActionEdit}
      onRowEdit={handleRowEdit}
      rowsEditKey={rowsEditKey}
      remove={false}
      check={!!check}
      valuesMap={valuesMap}
      headerName={referentielNom}
      validateRowValue={validateRowValue}
      dataTestPrefix="referentiel_component"
      headerCode="Code"
      headerLibelle="Libellé"
      headerText="Texte"
      selectable={!!onSelect}
      onSelect={onSelect}
      isValueEqual={isValueEqual}
      createKey={createKeyForReferentiel}
      creation={creation}
      filterValues={handleFilterValues}
      initSearchText={search}
      >
    </FormTable>)
}

ReferentielValues.propTypes={
  referentielValuesMap:PropTypes.any.isRequired,
  referentielNom:PropTypes.string.isRequired,
  values:PropTypes.any.isRequired,
  search:PropTypes.string,
  onSave:PropTypes.func,
  onDelete:PropTypes.func,
  onCheckUnicity:PropTypes.func,
  onSelect:PropTypes.func,
  onSearch:PropTypes.func
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core'

import EnhancedTable from '../Table/EnhancedTable'
import { inputService } from '../../api/inputService'
import { WrapperLink } from '../../lib/helpers'

const headCells = [
  {
    id: 'nom',
    numeric: false,
    disablePadding: false,
    label: 'Nom',
  },
  { id: 'label', numeric: false, disablePadding: false, label: 'Label' },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
]

export const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    width: '100%',
  },
}))

const Inputs = () => {
  const classes = useStyles()
  const [inputs, setInputs] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    inputService.getInputs(setInputs, setLoading)
  }, [])

  const handleClickDelete = id => {
    inputService.deleteInputById(id, setInputs)
  }

  const handleClickEdit = id => {
    history.push(`/edit-input/${id}`)
  }

  return <main className={classes.content}>
    <Grid container spacing={2} justify="flex-end">
      <Grid item xs={4}>
        <Button variant="contained"
                color="primary"
                className={classes.button}
                component={WrapperLink}
                to="/add-input"
                data-test="AJOUT D'UN INPUT">
          Ajout d'un input
        </Button>
      </Grid>
    </Grid>
    <br/>
    <br/>
    <Grid container spacing={1}>
      {
        loading
          ? <LinearProgress/>
          : <EnhancedTable headCells={headCells}
                           items={inputs}
                           title="Liste des inputs"
                           onDelete={handleClickDelete}
                           onEdit={handleClickEdit}/>
      }
    </Grid>
  </main>
}

Inputs.propTypes = {}

export default Inputs

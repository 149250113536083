import { useSelector } from 'react-redux'
import TableHead from '@material-ui/core/TableHead'
import { DataHeaderCell } from './header/data-header-cell'
import { Component } from '../../../../store/constants/component'
import TableCell from '@material-ui/core/TableCell'
import { DataHeaderRow } from './header/data-header-row'
import React from 'react'
import * as PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'

export function HeaderTable({ classes }) {
  const inputs = useSelector(state => state.tableurModule.inputs)
  const outputs = useSelector(state => state.tableurModule.outputs)
  return <TableHead>
    <TableRow>
      <DataHeaderCell component={Component.NULL} role="Actions" title="Action" classes={classes} left={0} />
      <DataHeaderCell component={Component.NULL} role="ID" title="ID" classes={classes} left={79} />
      <TableCell className={classes.empty} style={{ position: 'sticky', left: 150, zIndex: 1001 }} />
      {inputs.map((c, idx) => <DataHeaderRow
          classes={classes}
          key={c.nom + idx}
          className={classes.row}
          component={c}
          idx={idx}
          role={'input'}
        />)}
      <TableCell className={classes.empty} />
      {outputs.map((c, idx) => <DataHeaderRow
          classes={classes}
          key={c.nom + idx}
          className={classes.row}
          component={c}
          idx={idx}
          role={'output'}
        />)}
    </TableRow>
  </TableHead>
}

HeaderTable.propTypes = {
  classes: PropTypes.any,
  inputs: PropTypes.any,
  prop2: PropTypes.func,
  outputs: PropTypes.any,
  prop4: PropTypes.func,
}

import React, { useEffect, useMemo, useState } from 'react'
import { Grid, TableBody, TableHead, TableRow } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../../../common/container-title'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import { AddItemButtons } from '../../../conventions-collectives/themes/add-item-buttons'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import { ModelReplacement } from '../../../../store/constants/ModelReplacement'
import { ModelNewValue } from '../../../../store/constants/ModelNewValue'
import Link from '@material-ui/core/Link'
import { getNewValue } from '../workflow-helper'
import { ModelNewPopperContent } from './model-new-popper-content'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

export function ModelNewElement({ classes, tmpModel, setTmpModel }) {
  const [anchorEl, setAnchorEl] = useState()
  const [tmpNewElement, setTmpNewElement] = useState(ModelNewValue.NULL)
  const [idxElement, setIdxElement] = useState(0)
  const [tmpModelReplacement, setTmpModelReplacement] = useState(ModelReplacement.NULL)
  const [openPanel, setOpenPanel] = useState(false)

  useEffect(() => {
    if (!tmpModel.newValues || !tmpModel.newValues.length) {
      setOpenPanel(true)
    }
  }, [])
  const handleChange = (value, field) => {
    const newRefElement = { ...tmpNewElement }
    newRefElement[field] = value
    setTmpNewElement(newRefElement)
  }
  const handlePopoverOpen = (event, model, idx) => {
    setAnchorEl(event.currentTarget)
    setTmpNewElement(model ? { ...model } : ModelNewValue.NULL)
    setIdxElement(idx)
    if (model) setTmpModelReplacement(model.newValue)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'popin_Ajout_nouve_valeur' : undefined

  function handleSave() {
    const newNewValues = { ...tmpNewElement, newValue: { ...tmpModelReplacement } }
    let newValues = [...tmpModel.newValues]
    if (idxElement) newValues.splice(idxElement - 1, 1, newNewValues)
    else newValues = [...tmpModel.newValues, newNewValues]
    setTmpModel({ ...tmpModel, newValues })
    setTmpModelReplacement(ModelReplacement.NULL)
    setAnchorEl(null)
    setIdxElement(0)
  }

  function deleteField(component) {
    setTmpModel({ ...tmpModel, newValues: tmpModel.newValues.filter(ff => ff.component !== component) })
  }

  function handleCancel() {
    setTmpNewElement(ModelNewValue.NULL)
    setTmpModelReplacement(ModelReplacement.NULL)
    setAnchorEl(null)
  }

  function handleReplacementChange(value, field) {
    const newModelRepl = { ...tmpModelReplacement }
    newModelRepl[field] = value
    setTmpModelReplacement(newModelRepl)
  }

  const isSaveDisable = useMemo(() => !tmpNewElement || !tmpNewElement.component, [tmpNewElement])

  return (
    <Grid item xs={12}>
      <Paper className={classes.contentRoot} elevation={1} data-test={'modele_table_Nouvelles valeurs'}>
        <Grid container direction='row' style={{ cursor: 'pointer' }} onClick={() => setOpenPanel(!openPanel)}  >

          {
            openPanel
              ? <KeyboardArrowUp  fontSize='large'/>
              : <KeyboardArrowDown fontSize='large' />
          }
          <ContainerTitle classes={classes} title='Nouvelles valeurs' />
        </Grid>
        {
          openPanel &&
          <>
            <Grid container direction='row' justify='flex-end'>
              <Grid item style={{ marginBottom: 16 }}>
                <Button data-test='Ajouter une nouvelle valeur'
                        color='primary'
                        variant='contained'
                        size='medium'
                        className={classes.margin}
                        onClick={handlePopoverOpen}>
                  NOUVEAU
                </Button>
              </Grid>
            </Grid>
            {!!tmpModel.newValues.length && (
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Composant</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Valeur de remplacement</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tmpModel.newValues.map((model, idx) => {
                    const { type, component, newValue } = model
                    return (
                      <TableRow key={component}>
                        <TableCell data-test={`new_component_${idx}`}>
                          <Link href='#' onClick={e => handlePopoverOpen(e, model, idx + 1)}>
                            {component}
                          </Link>
                        </TableCell>
                        <TableCell data-test={`new_type_${idx}`}>{type.label}</TableCell>
                        <TableCell data-test={`new_newValue_${idx}`}>{`${getNewValue(newValue).trim()}`}</TableCell>
                        <TableCell>
                          <Link href='#' onClick={() => deleteField(component)}>
                            X
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            )}
          </>
        }
      </Paper>
      <Popover
        anchorEl={anchorEl}
        id={id}
        open={open}
        anchorReference='anchorPosition'
        anchorPosition={{ top: 270, left: 660 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {open && (
          <Paper className={classes.popper} elevation={1}>
            <ContainerTitle variant='h5' classes={classes} title='Création nouvelle valeur' />
            <ModelNewPopperContent
              className={classes.formControl}
              handleChange={handleChange}
              tmpNewElement={tmpNewElement}
              formFields={tmpModel.formFields}
              handleReplacementChange={handleReplacementChange}
              tmpModelReplacement={tmpModelReplacement}
            />
            <Grid container direction='row' justify='flex-end'>
              <AddItemButtons
                onSave={handleSave}
                onCancel={handleCancel}
                showList={true}
                cancelDataTest={'ANNULER NOUVELLE VALEUR'}
                saveDataTest={'AJOUTER NOUVELLE VALEUR'}
                isSaveDisabled={isSaveDisable}
              />
            </Grid>
          </Paper>
        )}
      </Popover>
    </Grid>
  )
}

import { useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import { ReferentielValeur } from '../../../../../../../store/constants/referentiel'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { exceedMaxChar, getTrunkedTextValue } from '../simple-cell-type'

export function TableurCellReferentiel({
  code,
  component,
  cellEditMode,
  editValue,
  editDataTest,
  setTmpData,
  lineEditMode,
  tooltipContent,
  ignoreToolType = false
}) {
  const [tmpValue, setTmpValue] = useState(editValue || code)

  function handleChange({ target: { value: v } }) {
    setTmpValue(v)
    setTmpData(component.nom, v, component)
  }

  const referentiel = useSelector(state => state.tableurModule.referentiels.find(r => r.nom === component.referentiel))
  useEffect(() => {
    setTmpValue(editValue || code)
  }, [editValue, code, cellEditMode])

  const value = useMemo(() => (referentiel ? referentiel.valeurs.find(v => v.code === code) : ReferentielValeur.NULL), [
    referentiel,
    code,
  ])

  useEffect(() => {
    if(tooltipContent) {
      tooltipContent(value && exceedMaxChar(value.libelle) ? <div>{value.code+' - '+value.libelle}</div> : '')
    }
  }, [value, tooltipContent])

  return (
    <>
      {' '}
      {cellEditMode ? (
        <TextField
          label={component.referentiel}
          value={tmpValue ? tmpValue : ''}
          data-test={editDataTest + '_edit'}
          onChange={handleChange}
          disabled={!lineEditMode}
          select
        >
          <MenuItem value="">Aucune valeur</MenuItem>
          {referentiel &&
            referentiel.valeurs.map(r => (
              <MenuItem value={r.code} key={r.code}>
                {r.code + ' - ' + r.libelle}
              </MenuItem>
            ))}
        </TextField>
      ) : (
        <>{value && <span style={{ whiteSpace: 'nowrap' }}>{
          ignoreToolType
            ? code + ' - ' + value.libelle
            : code + ' - ' + getTrunkedTextValue(value.libelle)
        }</span>}
        </>
      )}
    </>
  )
}

import { TypeFormField } from './TypeFormField'
import { getTypeForm } from '../actions/workflow-action'

export class ModelFormField {
  field
  typeForm
  workflowRef
  workflowField
  referentielName
  required

  constructor(field = '', typeForm = TypeFormField.NULL, required = false, workflowRef = '', workflowField,referentielName = '') {
    this.field = field
    this.typeForm = typeForm
    this.required = required
    this.workflowRef = workflowRef
    if (workflowField) this.workflowField = new ModelFormField(workflowField.field
      , getTypeForm(workflowField)
      , workflowField.required,null,null,workflowField.referentielName)
    this.referentielName=referentielName
  }

  static NULL = new ModelFormField()
}


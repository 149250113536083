import { TableurAction } from '../../Components/tableur/tab/table/row/action-row'
import { getComputedData } from '../selectors/tableur-selectors'

export const ADD_DATA = 'paye-bo/tableur/ADD_DATA'
export const SET_DATA = 'paye-bo/tableur/SET_DATA'
export const SET_TABLEUR_INPUT = 'paye-bo/tableur/ADD_TABLEUR_INPUT'
export const SET_TABLEUR_OUTPUT = 'paye-bo/tableur/ADD_TABLEUR_OUTPUT'
export const CLEAR_TABLEUR_INPUTS = 'paye-bo/tableur/CLEAR_TABLEUR_INPUTS'
export const CLEAR_TABLEUR_OUTPUTS = 'paye-bo/tableur/CLEAR_TABLEUR_OUTPUTS'
export const SET_TABLEUR_REFERENTIEL = 'paye-bo/tableur/ADD_TABLEUR_REFERENTIEL'
export const CLEAR_TABLEUR_REFERENTIELS = 'paye-bo/tableur/CLEAR_TABLEUR_REFERENTIELS'
export const ADD_DATA_TO_SAVE = 'paye-bo/tableur/ADD_DATA_TO_SAVE'
export const SET_DATA_TO_SAVE = 'paye-bo/tableur/SET_DATA_TO_SAVE'
export const DELETE_DATA_TO_SAVE = 'paye-bo/tableur/DELETE_DATA_TO_SAVE'
export const CLEAR_DATA_TO_SAVE = 'paye-bo/tableur/CLEAR_DATA_TO_SAVE'
export const CLEAR_EXPANSIONS = 'paye-bo/tableur/CLEAR_EXPANSIONS'
export const EXPAND_GROUPS = 'paye-bo/tableur/EXPAND_GROUPS'
export const CLEAR_TABLEUR_VALUES = 'paye-bo/tableur/CLEAR_TABLEUR_VALUES'
export const SET_TABLEUR_VALUES = 'paye-bo/tableur/ADD_TABLEUR_VALUES'
export const ADD_ACTION = 'paye-bo/tableur/ADD_ACTION'
export const CLEAR_ACTION = 'paye-bo/tableur/CLEAR_ACTION'
export const CLEAR_DATA = 'paye-bo/tableur/CLEAR_DATA'
export const CLEAR_TABLEUR = 'paye-bo/tableur/CLEAR_TABLEUR'
export const SET_SELECT_MODE = 'paye-bo/tableur/SET_SELECT_MODE'
export const SET_EDIT_MODE = 'paye-bo/tableur/SET_EDIT_MODE'
export const ADD_IN_SELECTED = 'paye-bo/tableur/ADD_IN_SELECTED'
export const ADD_ALL_IN_SELECTED = 'paye-bo/tableur/ADD_ALL_IN_SELECTED'
export const REMOVE_FROM_SELECTED = 'paye-bo/tableur/REMOVE_FROM_SELECTED'
export const REMOVE_ALL_FROM_SELECTED = 'paye-bo/tableur/REMOVE_ALL_FROM_SELECTED'
export const CLEAR_SELECTED = 'paye-bo/tableur/CLEAR_SELECTED'
export const ADD_TABLEUR_ERROR = 'paye-bo/tableur/ADD_TABLEUR_ERROR'
export const RESET_TABLEUR_ERROR = 'paye-bo/tableur/RESET_TABLEUR_ERROR'
export const ADD_SET_OUTPUT = 'paye-bo/tableur/ADD_SET_OUTPUT'
export const CLEAR_SET_OUTPUT = 'paye-bo/tableur/CLEAR_SET_OUTPUT'
export const ADD_TABLEUR_FILTER = 'paye-bo/tableur/ADD_TABLEUR_FILTER'
export const CLEAR_TABLEUR_FILTER = 'paye-bo/tableur/CLEAR_TABLEUR_FILTER'
export const SET_ON_EDIT_COMPS = 'paye-bo/tableur/SET_ON_EDIT_COMPS'
export const CLEAR_ON_EDIT_COMPS = 'paye-bo/tableur/CLEAR_ON_EDIT_COMPS'
export const SET_TMP_DATA = 'paye-bo/tableur/SET_TMP_DATA'
export const ADD_TMP_DATA = 'paye-bo/tableur/ADD_TMP_DATA'
export const CLEAR_TMP_DATA = 'paye-bo/tableur/CLEAR_TMP_DATA'
export const SET_TMP_IDX = 'paye-bo/tableur/SET_TMP_IDX'
export const SET_ALL_EDIT_LINES = 'paye-bo/tableur/SET_ALL_EDIT_LINES'
export const SET_ACTIVE_INPUTS = 'paye-bo/tableur/ACTIVE_INPUTS'
export const SET_ACTIVE_OUTPUTS = 'paye-bo/tableur/ACTIVE_OUTPUTS'
export const SET_TABLEUR_LOADER = 'paye-bo/tableur/SET_TABLEUR_LOADER'
export const SET_EXPORT_LOADER = 'paye-bo/tableur/SET_EXPORT_LOADER'
export const INIT_PAGINATION = 'paye-bo/tableur/INIT_PAGINATION'
export const NEXT_PAGE = 'paye-bo/tableur/NEXT_PAGE'
export const PREVIOUS_PAGE = 'paye-bo/tableur/PREVIOUS_PAGE'
export const FIRST_PAGE = 'paye-bo/tableur/FIRST_PAGE'
export const LAST_PAGE = 'paye-bo/tableur/LAST_PAGE'
export const SET_PAGE_SIZE = 'paye-bo/tableur/SET_PAGE_SIZE'
export const SET_DOUBLON_MODE = 'paye-bo/tableur/SET_DOUBLON_MODE'
export const SET_COLUMN_MODE = 'paye-bo/tableur/SET_COLUMN_MODE'

const NULL_EDIT_COMPS = { inputs: [], outputs: [] }

const initialState = {
  action: TableurAction.NULL,
  activeInputs: [],
  activeOutputs: [],
  allEditLines: new Map(),
  doublonMode: false,
  editMode: false,
  expansions: new Map(),
  filter: new Map(),
  hideColumns: true,
  data: [],
  dataToSave: new Map(),
  inputs: [],
  outputs: [],
  onEditComps: NULL_EDIT_COMPS,
  referentiels: [],
  selectMode: false,
  setOutputs: new Map(),
  tableurError: 0,
  tmpData: new Map(),
  tmpIdx: 0,
  selected: [],
  values: new Map(),
  loader: 0,
  exportLoader: 0,
  page: 0,
  pageSize: 10,
}

function setMap(state, value) {
  return new Map([...state.expansions, [value.getKey(), value.expanded]])
}

function mapFilter(map, predicate) {
  const res = new Map()
  const entries = map.entries()
  let entry = entries.next()
  while (!entry.done) {
    const [key, value] = entry.value
    if (predicate({ key, value })) res.set(key, value)
    entry = entries.next()
  }
  return res
}

export default function tableurModule(state = initialState, { type, value }) {
  switch (type) {
    case SET_DATA:
      return { ...state, data: value }
    case ADD_DATA:
      return { ...state, data: [...state.data, ...value] }
    case CLEAR_DATA:
      return { ...state, data: [] }
    case SET_TABLEUR_REFERENTIEL:
      return { ...state, referentiels: value }
    case CLEAR_TABLEUR_REFERENTIELS:
      return { ...state, referentiels: [] }
    case SET_TABLEUR_INPUT:
      return { ...state, inputs: value }
    case CLEAR_TABLEUR_INPUTS:
      return { ...state, inputs: [] }
    case SET_TABLEUR_OUTPUT:
      return { ...state, outputs: value }
    case CLEAR_TABLEUR_OUTPUTS:
      return { ...state, outputs: [] }
    case CLEAR_TABLEUR_VALUES:
      return { ...state, values: new Map() }
    case SET_TABLEUR_VALUES:
      return { ...state, values: value }
    case ADD_IN_SELECTED:
      return { ...state, selected: [...state.selected, value] }
    case ADD_ALL_IN_SELECTED:
      return { ...state, selected: [...value] }
    case REMOVE_FROM_SELECTED:
      return { ...state, selected: state.selected.filter(v => v.id !== value.id) }
    case REMOVE_ALL_FROM_SELECTED:
      return { ...state, selected: [] }
    case CLEAR_SELECTED:
      return { ...state, selected: [] }
    case SET_DATA_TO_SAVE:
      return { ...state, dataToSave: value }
    case SET_ON_EDIT_COMPS:
      return { ...state, onEditComps: value }
    case CLEAR_ON_EDIT_COMPS:
      return { ...state, onEditComps: NULL_EDIT_COMPS }
    case ADD_DATA_TO_SAVE:
      const dataToSave = !state.dataToSave.size
        ? new Map([[value.id, value]])
        : new Map([...state.dataToSave, [value.id, value]])
      return { ...state, dataToSave }
    case DELETE_DATA_TO_SAVE:
      const cleanedDataToSave = !state.dataToSave
      cleanedDataToSave.delete(value)
      return { ...state, dataToSave: cleanedDataToSave }
    case CLEAR_DATA_TO_SAVE:
      return { ...state, dataToSave: new Map() }
    case CLEAR_EXPANSIONS:
      return { ...state, expansions: new Map() }
    case EXPAND_GROUPS:
      let expansions = new Map()
      if (!value.expanded && state.expansions.size) {
        state.expansions.forEach((v, k) => {
          if (!k.startsWith(value.getKey())) expansions = expansions.set(k, v)
        })
      } else expansions = !state.expansions.size ? new Map([[value.getKey(), value.expanded]]) : setMap(state, value)
      return { ...state, expansions }
    case ADD_ACTION:
      return { ...state, action: value }
    case CLEAR_ACTION:
      return { ...state, action: TableurAction.NULL }
    case SET_SELECT_MODE:
      return { ...state, selectMode: value }
    case SET_EDIT_MODE:
      return { ...state, editMode: value }
    case ADD_TABLEUR_ERROR:
      return { ...state, tableurError: state.tableurError + 1 }
    case RESET_TABLEUR_ERROR:
      return { ...state, tableurError: 0 }
    case ADD_SET_OUTPUT:
      const setOutputs = !state.setOutputs.size
        ? new Map([[value.id, value.localSetOutput]])
        : new Map([...state.setOutputs, [value.id, value.localSetOutput]])
      return { ...state, setOutputs }
    case CLEAR_SET_OUTPUT:
      return { ...state, setOutputs: new Map() }
    case ADD_TABLEUR_FILTER:
      const filter = value.values.length
        ? !state.filter.size
          ? new Map([[value.key, value.values]])
          : new Map([...state.filter, [value.key, value.values]])
        : mapFilter(state.filter, f => f.key !== value.key)
      return { ...state, filter, page: 0 }
    case CLEAR_TABLEUR_FILTER:
      return { ...state, filter: new Map() }
    case SET_TMP_DATA:
      return { ...state, tmpData: value }
    case ADD_TMP_DATA:
      const tmpData = !state.tmpData.size
        ? new Map([[value.id, value.tmpData]])
        : new Map([...state.tmpData, [value.id, value.tmpData]])
      return { ...state, tmpData }
    case CLEAR_TMP_DATA:
      return { ...state, tmpData: new Map(), tmpIdx: 0 }
    case SET_ALL_EDIT_LINES:
      return { ...state, allEditLines: value }
    case SET_ACTIVE_INPUTS:
      return { ...state, activeInputs: value }
    case SET_ACTIVE_OUTPUTS:
      return { ...state, activeOutputs: value }
    case SET_TABLEUR_LOADER:
      return { ...state, loader: value ? state.loader + 1 : state.loader - 1 }
    case SET_EXPORT_LOADER:
      return { ...state, exportLoader: value ? state.exportLoader + 1 : state.exportLoader === 0 ? 0 : state.exportLoader - 1 }
    case INIT_PAGINATION:
      return { ...state, page: 0, pageSize: 10 }
    case NEXT_PAGE:
      const last = Math.floor(getComputedData({ tableurModule: state }).length / state.pageSize)
      return state.page === last ? state : { ...state, page: state.page + 1 }
    case PREVIOUS_PAGE:
      return state.page === 0 ? state : { ...state, page: state.page - 1 }
    case FIRST_PAGE:
      return { ...state, page: 0 }
    case LAST_PAGE:
      return { ...state, page: value }
    case SET_PAGE_SIZE:
      return { ...state, pageSize: value, page: 0 }
    case SET_DOUBLON_MODE:
      return { ...state, doublonMode: value }
    case SET_COLUMN_MODE:
      return { ...state, hideColumns: value }
    case CLEAR_TABLEUR:
      return { ...initialState, hideColumns: state.hideColumns, doublonMode: state.doublonMode, exportLoader: state.exportLoader }
    default:
      return state
  }
}

/*const res = {...state, expansions: [...state.expansions, new ExpansionHeader(value.header, value.expanded)]}
console.log(res)
return res*/

import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getPossibleComparators,
  getPossibleOperators,
  getPossibleTargetValues,
  getSameTypeComps,
  getWFInputValue,
} from '../workflow-helper'
import { getTypeFromString, Type } from '../../../../store/constants/type'
import { Grid } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { ALL_REF_TYPES, OperationUnit } from '../../../../store/constants/ModelRefField'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'

export function ModelRefPopperContent({
  className,
  handleChange,
  tmpRefElement,
  formFields,
  handleReplacementChange,
  tmpModelReplacement,
}) {
  const [referentielValues, setReferentielValues] = useState([])
  const [referentielName, setReferentielName] = useState('')

  const allComp = useSelector(state => state.componentModule.components)
  const referentiels = useSelector(state => state.referentiels.referentiels)
  const allComputedComp = useMemo(getSameTypeComps(allComp, tmpRefElement), [allComp, tmpRefElement.type])
  const possibleComparators = useMemo(getPossibleComparators(tmpRefElement), [tmpRefElement])
  const possibleOperators = useMemo(getPossibleOperators(tmpRefElement), [tmpRefElement])
  const possibleTargetValues = useMemo(getPossibleTargetValues(formFields, tmpRefElement,referentielName), [formFields, tmpRefElement,referentielName])
  const replacementTargetValues = useMemo(()=>{
      return possibleTargetValues
  },[possibleTargetValues,formFields, tmpRefElement,tmpModelReplacement])

  function setComp(value) {
    if(_.isString(value)){
      value=allComp.find(comp=>comp.nom === value)
    }
    if (value && tmpRefElement.type === Type.REFERENCE) {
      const found = referentiels.find(r => r.nom === value.referentiel)
      if (found) {
        setReferentielValues(found.valeurs)
        setReferentielName(value.referentiel)
      }
    }
    else if(value && (tmpRefElement.type === Type.LIST || tmpRefElement.type === Type.LIST_EXCLUSION)){
      if(value.unit){
        const unitComp=allComp.find(comp=>comp.nom === value.unit.nom)
        if(unitComp.type === Type.REFERENCE){
          const found = referentiels.find(r => r.nom === unitComp.referentiel)
          if (found) {
            setReferentielValues(found.valeurs)
            setReferentielName(unitComp.referentiel)
          }
        }
      }
    }
    else {
      setReferentielValues([])
      setReferentielName('')
    }
  }

  useEffect(() => {
    if (tmpRefElement) setComp(tmpRefElement.component)
  }, [tmpRefElement])

  return (
    <Grid item xs={12}>
      <Grid container direction="column">
        <Grid>
          <form className={className}>
            <InputLabel id="ref_type_label">Type</InputLabel>
            <Select
              labelId="ref_type_label"
              label="Type"
              data-test="ref_type"
              style={{ width: '100%' }}
              value={tmpRefElement.type ? tmpRefElement.type.name : ''}
              onChange={e => handleChange(getTypeFromString(e.target.value), 'type')}
            >
              {ALL_REF_TYPES.map(type => (
                <MenuItem data-test={'ref_type_' + type.name} key={type.name} value={type.name ? type.name : ''}>
                  {type.name ? type.label : 'aucun'}
                </MenuItem>
              ))}
            </Select>
          </form>
        </Grid>
        {tmpRefElement.type === Type.TYPE_THEME || (!!tmpRefElement.type && (
          <Grid>
            <Autocomplete
              data-test="ref_composant"
              options={allComputedComp}
              getOptionLabel={c => c.nom}
              style={{ width: '100%' }}
              onChange={(e, value) => {
                handleChange(value ? value.nom : value, 'component')
                setComp(value)
              }}
              inputValue={getWFInputValue(tmpRefElement)}
              renderInput={params => {
                return <TextField {...params} value={params.inputProps.value} label="Selectionner input" />
              }}
            />
          </Grid>
        ))}
        {!!tmpRefElement.type && (
          <Grid>
            <form className={className}>
              <InputLabel id="ref_comparateur_label">Comparateur</InputLabel>
              <Select
                labelId="ref_comparateur_label"
                label="Type"
                data-test="ref_comparateur"
                style={{ width: '100%' }}
                value={tmpRefElement.targetOperator}
                onChange={e => handleChange(e.target.value, 'targetOperator')}
              >
                {possibleComparators.map(operator => (
                  <MenuItem data-test={'ref_comparateur_' + operator.value} key={operator.value} value={operator.value}>
                    {operator.label}
                  </MenuItem>
                ))}
              </Select>
            </form>
          </Grid>
        )}
        {!!tmpRefElement.targetOperator && (
          <Grid>
            <>
            {tmpRefElement.type === Type.REFERENCE ? (
              <form className={className}>
                <InputLabel id="ref_valeur_comparaison_label">Valeur de Comparaison</InputLabel>
                <Select
                  labelId="ref_valeur_comparaison_label"
                  data-test="ref_valeur_comparaison"
                  style={{ width: '100%' }}
                  value={tmpRefElement.targetValue ? tmpRefElement.targetValue : 'vide'}
                  onChange={e => handleChange(e.target.value, 'targetValue')}
                >
                  <MenuItem>vide</MenuItem>
                  {referentielValues.map(possibleValue => (
                    <MenuItem
                      data-test={'ref_valeur_comparaison_' + possibleValue.code}
                      key={possibleValue.code}
                      value={possibleValue.code}
                    >
                      {possibleValue.code + ' - ' + possibleValue.libelle}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            ) : tmpRefElement.type === Type.TYPE_THEME || tmpRefElement.type === Type.DATE || tmpRefElement.type === Type.LIST ||  tmpRefElement.type === Type.LIST_EXCLUSION ||
                (<TextField
                    data-test={'ref_valeur_comparaison'}
                    label={'Valeur de Comparaison'}
                    value={tmpRefElement.targetValue}
                    disabled={!!tmpRefElement.selectedTargetValue}
                    className={'TextField'}
                    margin="normal"
                    fullWidth
                    placeholder="vide"
                    onChange={e => handleChange(e.target.value, 'targetValue')}
                  />
                )
            }
             {
             (<form className={className}>
                  <InputLabel id="ref_comparateur_ref_label">Réference de Comparaison</InputLabel>
                  <Select
                    labelId="ref_comparateur_ref_label"
                    data-test="ref_comparateur_ref"
                    style={{ width: '100%' }}
                    disabled={!!tmpRefElement.targetValue}
                    value={tmpRefElement.selectedTargetValue ? tmpRefElement.selectedTargetValue : 'vide'}
                    onChange={e => handleChange(e.target.value, 'selectedTargetValue')}
                  >
                    <MenuItem>vide</MenuItem>
                    {possibleTargetValues.map(possibleValue => (
                      <MenuItem
                        data-test={'ref_comparateur_ref_' + possibleValue}
                        key={possibleValue}
                        value={possibleValue}
                      >
                        {possibleValue}
                      </MenuItem>
                    ))}
                  </Select>
                </form>)
              }
            </>
          </Grid>
        )}
        {!!tmpRefElement.component && (
          <>
            <Grid>
              <form className={className}>
                <InputLabel id="ref_remplacement_operationlabel">Operateur de remplacement</InputLabel>
                <Select
                  labelId="ref_remplacement_operation_label"
                  label="Type"
                  data-test="ref_remplacement_operation"
                  style={{ width: '100%' }}
                  value={tmpModelReplacement.operation}
                  onChange={e => handleReplacementChange(e.target.value, 'operation')}
                >
                  {possibleOperators.map(operator => (
                    <MenuItem
                      data-test={'ref_remplacement_operation_' + operator.value}
                      key={operator.value}
                      value={operator.value}
                    >
                      {operator.value ? operator.value : 'aucun'}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            </Grid>
            {tmpRefElement.type === Type.DATE &&
              tmpModelReplacement.operation &&
              tmpModelReplacement.operation !== '=' && (
                <Grid>
                  <form className={className}>
                    <InputLabel id="remplacement_unit_label">Unité</InputLabel>
                    <Select
                      labelId="remplacement_unit_label"
                      data-test="ref_remplacement_unit"
                      style={{ width: '100%' }}
                      value={(tmpModelReplacement.operationUnit && tmpModelReplacement.operationUnit.label) || ""}
                      onChange={e =>
                        handleReplacementChange(
                          OperationUnit.ALL.find(o => o.name === e.target.value),
                          'operationUnit'
                        )
                      }
                    >
                      {OperationUnit.ALL.map(possibleValue => {
                        return (
                          <MenuItem
                            data-test={'ref_remplacement_unit_' + possibleValue.name}
                            key={possibleValue.name}
                            value={possibleValue.name}
                          >
                            {possibleValue.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </form>
                </Grid>
              )}
            {!!tmpModelReplacement.operation && (
              <>
                <Grid>
                  {(tmpModelReplacement.operation !== '=' || (tmpRefElement.type !== Type.DATE && tmpRefElement.type !== Type.LIST && tmpRefElement.type !== Type.LIST_EXCLUSION) ) && (
                    <>
                      {tmpRefElement.type === Type.REFERENCE ? (
                        <form className={className}>
                          <InputLabel id="ref_remplacement_value_label">Valeur de Remplacement</InputLabel>
                          <Select
                            labelId="ref_remplacement_value_label"
                            data-test="ref_remplacement_value"
                            style={{ width: '100%' }}
                            value={tmpModelReplacement.value || ''}
                            disabled={!!tmpModelReplacement.refField}
                            onChange={e => handleReplacementChange(e.target.value, 'value')}
                          >
                            <MenuItem>vide</MenuItem>
                            {referentielValues.map(possibleValue => (
                              <MenuItem
                                data-test={'ref_remplacement_value_' + possibleValue.code}
                                key={possibleValue.code}
                                value={possibleValue.code}
                              >
                                {possibleValue.code + ' - ' + possibleValue.libelle}
                              </MenuItem>
                            ))}
                          </Select>
                        </form>
                      ) : (
                        <TextField
                          data-test={'ref_remplacement_value'}
                          label={'Valeur de Remplacement'}
                          value={tmpModelReplacement.value}
                          disabled={
                            (!!tmpModelReplacement.refField) && tmpRefElement.type !== Type.NUMERIC && tmpRefElement.type !== Type.DATE
                          }
                          className={'TextField'}
                          margin="normal"
                          fullWidth
                          placeholder="vide"
                          onChange={e => handleReplacementChange(e.target.value, 'value')}
                        />
                      )}
                    </>
                  )}
                </Grid>
                <Grid>
                  {
                    <form className={className}>
                      <InputLabel id="ref_remplacement_ref_label">Reference de Remplacement</InputLabel>
                      <Select
                        labelId="ref_remplacement_ref"
                        data-test="ref_remplacement_ref"
                        style={{ width: '100%' }}
                        disabled={
                          !!tmpModelReplacement.value &&
                          tmpRefElement.type !== Type.NUMERIC &&
                          tmpRefElement.type !== Type.DATE
                        }
                        value={tmpModelReplacement.refField ? tmpModelReplacement.refField : 'vide'}
                        onChange={e => handleReplacementChange(e.target.value, 'refField')}
                      >
                        <MenuItem>vide</MenuItem>
                        {replacementTargetValues.map(possibleValue => (
                          <MenuItem
                            data-test={'ref_remplacement_ref_' + possibleValue}
                            key={possibleValue}
                            value={possibleValue}
                          >
                            {possibleValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </form>
                  }
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

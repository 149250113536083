import React, { useEffect, useMemo, useState } from 'react'
import { ContainerTitle } from '../common/container-title'
import { Grid, makeStyles } from '@material-ui/core'
import { AccountTree } from '@material-ui/icons'
import Paper from '@material-ui/core/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  getAllConventions,
  getCCThemes,
  setCCTheme,
  setConvention,
} from '../../store/actions/convention-collective-action'
import { useChildrenCCTheme } from '../conventions-collectives/fields/hooks'
import { DataTab } from './tab/data-tab'
import { useHistory } from 'react-router-dom'
import { SelectCCData } from './select-cc-data'
import { SubtitleCCData } from './subtitle-c-c-data'
import ConventionCollective from '../../store/constants/convention-collective'
import { clearTableur, initAllTableur } from '../../store/actions/tableur-action'
import { getVersions } from '../../store/actions/version-action'
import { filterWorkflowsByTheme, getCCWorkflows } from '../../store/actions/workflow-action'
import { TableurMenu } from './tableurMenu'
import { Theme } from '../../store/constants/theme'
import { SelectThemeData } from './select-theme-data'
import { setTab } from '../../store/actions/tab-action'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: 16,
    marginBottom: 16,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}


export const Tableur = () => {
  const [onCCSelect, setCCOnSelect] = useState(false)
  const [onThemeSelect, setThemeOnSelect] = useState(false)
  const [currentThemeGroup, setCurrentThemeGroup] = useState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [convention, themeName, workflows, theme, themes] = useLoadPage()
  const storedTheme = useSelector(state => state.conventionModule.theme && state.conventionModule.theme.nom)

  useEffect(() => {
    return () => dispatch(clearTableur())
  }, [])

  useEffect(() => {
    if(convention !== ConventionCollective.NULL) {
      setCCOnSelect(false)
      dispatch(getCCWorkflows(convention.idcc, () => dispatch(getCCThemes(convention.idcc))))
    }

  }, [convention])

  useEffect(() => {
    if(themes && theme !== Theme.NULL && currentThemeGroup && theme.themeParentId === currentThemeGroup.id) {
      let tab = currentThemeGroup.children.map(t => t.nom).indexOf(theme.nom)
      dispatch(setTab(tab))
    }
  }, [theme, themes, currentThemeGroup])


  useEffect(() => {
    if(themes && theme !== Theme.NULL) {
      let themeGroup = getThemeGroup(themes).find(tg => tg.children.find(t => t.nom === theme.nom))
      setThemeOnSelect(false)
      setCurrentThemeGroup(themeGroup)
    }
  }, [theme.themeParentId])

  const themeTabs = useMemo( () => {
    let tt = currentThemeGroup ? [...currentThemeGroup.children.map(t => t.nom)] :  []
    if (workflows) {
      tt = tt.map(t => {
        if (filterWorkflowsByTheme(t, workflows).length > 0) {
          return {
            label: t,
            icon: <AccountTree />,
          }
        } else {
          return t
        }
      })
    }
    return tt
  }, [currentThemeGroup, workflows])

  const history = useHistory()
  const handleCCChange = (event, { idcc: idccSelected }) => {
    setCCOnSelect(false)
    if(themeName) history.push('/tableur/' + idccSelected + '/theme/' + themeName)
    else history.push('/tableur/' + idccSelected )
  }

  const handleThemeChange = (theme) => {
    setThemeOnSelect(false)
    history.push('/tableur/' + convention.idcc + '/theme/' + theme)
  }

  if (!convention) setConvention(ConventionCollective.NULL)

  const handleSubtitleCCClick = () => setCCOnSelect(true)
  const handleSubtitleThemeClick = () => setThemeOnSelect(true)

  const dataTab = useMemo( () => convention !== ConventionCollective.NULL && storedTheme && <DataTab titles={themeTabs} />, [convention, storedTheme, themeTabs])

  const conventionSelect = useMemo(() =>  convention !== ConventionCollective.NULL && !onCCSelect
    ? convention === 'all_ccs' || <SubtitleCCData idcc={convention.idcc} title={convention.titre} onClickCC={handleSubtitleCCClick} />
    : <SelectCCData onChange={handleCCChange}
                    selectedValue={convention !== ConventionCollective.NULL ? convention.idcc + ' - ' + convention.titre : ''}
                    setOnSelect={setCCOnSelect} />, [convention, onCCSelect])


  const themeSelect = useMemo(() => theme !== Theme.NULL && !onThemeSelect
    ? <div style={{ marginBottom: 20 }}>
        <SubtitleCCData idcc={theme.themeParentId} title={theme.themeParent} onClickCC={handleSubtitleThemeClick} />
    </div>
    : <SelectThemeData onChange={(t, tGroup) => handleThemeChange(t, tGroup)} theme={storedTheme} convention={convention} onFocusOut={() => setThemeOnSelect(false)} />,
    [theme, onThemeSelect, storedTheme, convention]
  )

  return <Grid container>
    <Grid item xs={12}>
      <Paper className={classes.contentRoot} elevation={1}>
        <Grid item container direction={'row'} justify='space-between'>
          <Grid item>
            <ContainerTitle title='Tableur' variant='h4' />
          </Grid>
          <TableurMenu idcc={convention.idcc} />
        </Grid>{conventionSelect}
        <div>{themeSelect}</div>
      </Paper>
      { dataTab }
    </Grid>
  </Grid>
}

function getThemeGroup(themes) {
  return themes
    .filter(t => !!t.idTheme && !t.themeParentId)
    .map(t => ({
      nom: t.nom,
      id: t.idTheme,
      children: themes.filter(child => child.themeParentId === t.idTheme),
    }))
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const useLoadPage = () => {
  const { idcc, theme } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    if (!idcc) dispatch(getVersions())
    dispatch(getAllConventions())
  }, [])
  useEffect(() => {
    if (!idcc) {
      dispatch(setCCTheme(Theme.NULL))
      dispatch(setConvention(ConventionCollective.NULL))
    }
  }, [idcc, dispatch])

  const themes = useSelector(state => state.themeModule.themes)
  const convention = useSelector(state => state.conventionModule.conventionsCollectives.find(cc => cc.idcc === idcc))
  const allWorkflows = useSelector(state => state.workflowModule.workflows)
  const workflows = allWorkflows.filter(wf => wf.idcc === idcc)
  const excludesNames = themes.filter(t => t.themeParent).map(t => t.themeParent)
  const notParentThemes = useChildrenCCTheme(excludesNames).map(t => t.nom)

  useEffect(() => {
    if (idcc && theme) dispatch(initAllTableur(notParentThemes, theme, themes, idcc))
  }, [idcc, theme])

  return [convention ? convention :  idcc === 'all' ? ConventionCollective.ALL : ConventionCollective.NULL, theme, workflows, themes.find(t => t.nom === theme) || Theme.NULL, themes]
}

import React, { useEffect, useMemo, useState } from 'react'
import { Grid, TableBody, TableHead, TableRow } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../../../common/container-title'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import { AddItemButtons } from '../../../conventions-collectives/themes/add-item-buttons'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import { ModelRefField } from '../../../../store/constants/ModelRefField'
import { ModelReplacement } from '../../../../store/constants/ModelReplacement'
import Link from '@material-ui/core/Link'
import { getNewValue, getOperatorAndValue } from '../workflow-helper'
import { ModelDoublonPopperContent } from './model-doublon-popper-content'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

export function ModelDoublonElements({ classes, tmpModel, setTmpModel }) {
  const [anchorEl, setAnchorEl] = useState()
  const [tmpDoublonElement, setDoublonElement] = useState(ModelRefField.NULL)
  const [idxElement, setIdxElement] = useState(0)
  const [tmpModelReplacement, setTmpModelReplacement] = useState(ModelReplacement.NULL)
  const [openPanel, setOpenPanel] = useState(false)

  useEffect(() => {
    if (!tmpModel.doublons || !tmpModel.doublons.length) {
      setOpenPanel(true)
    }
  }, [])
  const handleChange = (value, field) => {
    const newDoublonElement = { ...tmpDoublonElement }
    newDoublonElement[field] = value
    setDoublonElement(newDoublonElement)
  }
  const handlePopoverOpen = (event, model, idx) => {
    setAnchorEl(event.currentTarget)
    setDoublonElement(model ? { ...model } : ModelRefField.NULL)
    setIdxElement(idx)
    if (model) setTmpModelReplacement(model.newValue)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'popin_Ajout_element_reference' : undefined

  function handleSave() {
    const newDoublons = { ...tmpDoublonElement, newValue: { ...tmpModelReplacement } }
    let doublons = [...tmpModel.doublons]
    if (idxElement) doublons.splice(idxElement - 1, 1, newDoublons)
    else doublons = [...tmpModel.doublons, newDoublons]
    setTmpModel({ ...tmpModel, doublons })
    setDoublonElement(ModelRefField.NULL)
    setTmpModelReplacement(ModelReplacement.NULL)
    setAnchorEl(null)
    setIdxElement(0)
  }

  function deleteField(component) {
    setTmpModel({ ...tmpModel, doublons: tmpModel.doublons.filter(ff => ff.component !== component) })
  }

  function handleCancel() {
    setDoublonElement(ModelRefField.NULL)
    setTmpModelReplacement(ModelReplacement.NULL)
    setAnchorEl(null)
  }

  function handleReplacementChange(value, field) {
    const newModelRepl = { ...tmpModelReplacement }
    newModelRepl[field] = value
    setTmpModelReplacement(newModelRepl)
  }

  const isSaveDisable = useMemo(() => !tmpDoublonElement.type, [tmpDoublonElement])

  return (
    <Grid item xs={12}>
      <Paper className={classes.contentRoot} elevation={1} data-test={'modele_table_Valeurs doublons'}>
          <Grid container direction='row' style={{ cursor: 'pointer' }} onClick={() => setOpenPanel(!openPanel)}  >

            {
              openPanel
                ? <KeyboardArrowUp  fontSize='large'/>
                : <KeyboardArrowDown fontSize='large' />
            }
            <ContainerTitle classes={classes} title='Gestion des doublons' />
          </Grid>
        {
          openPanel &&
          <>
            <Grid container direction='row' justify='flex-end'>
              <Grid style={{ marginBottom: 16 }}>
                <Button
                  data-test='Ajouter un element de gestion de doublon'
                  color='primary'
                  variant='contained'
                  size='medium'
                  className={classes.margin}
                  onClick={handlePopoverOpen}
                >
                  NOUVEAU
                </Button>
              </Grid>
            </Grid>
            {!!tmpModel.doublons.length && (
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Composant</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Cible</TableCell>
                    <TableCell>Valeur de remplacement</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tmpModel.doublons.map((model, idx) => {
                    const { type, component, targetOperator, targetValue, newValue, selectedTargetValue } = model
                    return (
                      <TableRow key={component}>
                        <TableCell data-test={`ref_component_${idx}`}>
                          <Link href='#' onClick={e => handlePopoverOpen(e, model, idx + 1)}>
                            {component ? component : type.label}
                          </Link>
                        </TableCell>
                        <TableCell data-test={`ref_type_${idx}`}>{type.label}</TableCell>
                        <TableCell data-test={`ref_cible_${idx}`}>{`${getOperatorAndValue(
                          targetOperator,
                          targetValue,
                          selectedTargetValue,
                        )}`}</TableCell>
                        <TableCell data-test={`ref_newValue_${idx}`}>{`${getNewValue(newValue).trim()}`}</TableCell>
                        <TableCell>
                          <Link href='#' onClick={() => deleteField(component)}>
                            X
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            )}
          </>
        }
      </Paper>
      <Popover anchorEl={anchorEl}
               id={id}
               open={open}
               anchorReference='anchorPosition'
               anchorPosition={{ top: 270, left: 660 }}
               anchorOrigin={{
                 vertical: 'center',
                 horizontal: 'left',
               }}
               transformOrigin={{
                 vertical: 'center',
                 horizontal: 'right',
               }}>
        {open && <Paper className={classes.popper} elevation={1}>
            <ContainerTitle variant='h5' classes={classes} title='Création élément de gestion de doublon' />
            <ModelDoublonPopperContent
              className={classes.formControl}
              handleChange={handleChange}
              tmpDoublonElement={tmpDoublonElement}
              formFields={tmpModel.formFields}
              handleReplacementChange={handleReplacementChange}
              tmpModelReplacement={tmpModelReplacement}
            />
            <Grid container direction='row' justify='flex-end'>
              <AddItemButtons
                onSave={handleSave}
                onCancel={handleCancel}
                showList={true}
                cancelDataTest={'ANNULER ELEMENT DE REFERENCE'}
                saveDataTest={'AJOUTER ELEMENT DE REFERENCE'}
                isSaveDisabled={isSaveDisable}
              />
            </Grid>
          </Paper>}
      </Popover>
    </Grid>
  )
}

import ConventionCollective from '../constants/convention-collective'
import { CCField } from '../constants/active-cc-field'
import { Theme } from '../constants/theme'
import { Component } from '../constants/component'
import { ComponentValues } from '../constants/component-values'
import { HistoriqueDetail } from '../constants/historique-detail'

export const SET_CONVENTIONS = 'paye-bo/convention-collective/SET_CONVENTIONS'
export const SET_CONVENTION = 'paye-bo/convention-collective/SET_CONVENTION'
export const SET_CC_ACTIVE_FIELD = 'paye-bo/convention-collective/SET_CC_ACTIVE_FIELD'
export const SET_CC_HISTORIQUES = 'paye-bo/convention-collective/SET_CC_HISTORIQUES'
export const SET_CC_HISTORIQUES_DETAIL = 'paye-bo/convention-collective/SET_CC_HISTORIQUES_DETAIL'
export const SET_CC_HISTORIQUE_DETAIL = 'paye-bo/convention-collective/SET_CC_HISTORIQUE_DETAIL'
export const SET_HISTORIQUE_ID = 'paye-bo/convention-collective/SET_HISTORIQUE_ID'
export const SET_CC_THEMES = 'paye-bo/convention-collective/SET_CC_THEMES'
export const SET_CC_THEME = 'paye-bo/convention-collective/SET_CC_THEME'
export const SET_CC_INPUT = 'paye-bo/convention-collective/SET_CC_INPUT'
export const SET_CC_OUTPUT = 'paye-bo/convention-collective/SET_CC_OUTPUT'
export const SET_CC_CHILD = 'paye-bo/convention-collective/SET_CC_CHILD'
export const SET_CC_CHILD_VALUES = 'paye-bo/convention-collective/SET_CC_CHILD_VALUES'
export const SET_CC_VALUES = 'paye-bo/convention-collective/SET_CC_VALUES'
export const SET_POPIN_CONTEXT = 'paye-bo/convention-collective/POPIN_CONTEXT'
export const SET_CC_REFERENTIEL = 'paye-bo/convention-collective/SET_CC_REFERENTIEL'
export const SET_CC_CHILD_REFERENTIEL = 'paye-bo/convention-collective/SET_CC_CHILD_REFERENTIEL'
export const SET_CC_FOCUS_VALUE = 'paye-bo/convention-collective/SET_CC_FOCUS_VALUE'
export const SET_CC_THEME_INPUTS = 'paye-bo/convention-collective/SET_CC_THEMES_INPUTS'
export const SET_CC_THEME_OUTPUTS = 'paye-bo/convention-collective/SET_CC_THEMES_OUTPUTS'
export const LEGAL_CC_THEME_OUTPUTS = 'paye-bo/convention-collective/LEGAL_CC_THEMES_OUTPUTS'
export const GO_TO_CC = 'paye-bo/convention-collective/GO_TO_CC'

const initialState = {
  conventionsCollectives: [],
  conventionCollective: ConventionCollective.NULL,
  goToCCTable: false,
  activeCCField: CCField.NULL,
  historiques: [],
  historiqueId: 0,
  historiquesDetails: new Map(),
  historiqueDetail: HistoriqueDetail.NULL,
  themes: [],
  theme: Theme.NULL,
  inputs: [],
  outputs: [],
  childrenComponent: {},
  childrenReferentiel: {},
  childValues: [],
  input: Component.NULL,
  output: Component.NULL,
  values: ComponentValues.NULL,
  isFocusValueFree: true,
  popins: {},
  popinContext: { componentNom: '' },
  legalOutputs: [],
}

export default function conventionModule(state = initialState, { type, value }) {
  switch (type) {
    case SET_CONVENTIONS:
      return { ...state, conventionsCollectives: value }
    case SET_CONVENTION:
      return { ...state, conventionCollective: value }
    case GO_TO_CC:
      return { ...state, goToCCTable: value }
    case SET_CC_ACTIVE_FIELD:
      return { ...state, activeCCField: value }
    case SET_CC_HISTORIQUES:
      return { ...state, historiques: value }
    case SET_CC_HISTORIQUES_DETAIL:
      return { ...state, historiquesDetails: value }
    case SET_HISTORIQUE_ID:
      return { ...state, historiqueId: value }
    case SET_CC_HISTORIQUE_DETAIL:
      return { ...state, historiqueDetail: value }
    case SET_CC_THEMES:
      return { ...state, themes: value }
    case SET_CC_THEME:
      return { ...state, theme: value }
    case SET_CC_THEME_INPUTS:
      return { ...state, inputs: value }
    case SET_CC_THEME_OUTPUTS:
      return { ...state, outputs: value }
    case LEGAL_CC_THEME_OUTPUTS:
      return { ...state, legalOutputs: value }
    case SET_CC_INPUT:
      return { ...state, input: value }
    case SET_CC_OUTPUT:
      return { ...state, ouput: value }
    case SET_CC_CHILD:
      let childrenComponent = { ...state.childrenComponent }
      childrenComponent[value.nom] = value
      return { ...state, childrenComponent: childrenComponent }
    case SET_CC_VALUES:
      return { ...state, values: value }
    case SET_CC_CHILD_VALUES:
      return { ...state, childValues: value }
    case SET_CC_FOCUS_VALUE:
      return { ...state, isFocusValueFree: value }
    case SET_CC_REFERENTIEL:
      return { ...state, referentiel: value }
    case SET_CC_CHILD_REFERENTIEL:
      let childrenReferentiel = { ...state.childrenReferentiel }
      childrenReferentiel[value.component.nom] = value.referentiel
      return { ...state, childrenReferentiel: childrenReferentiel }
    case SET_POPIN_CONTEXT:
      return { ...state, popinContext: value }

    default:
      return state
  }
}

import {
  GO_TO_CC,
  LEGAL_CC_THEME_OUTPUTS,
  SET_CC_ACTIVE_FIELD,
  SET_CC_CHILD,
  SET_CC_INPUT,
  SET_CC_OUTPUT,
  SET_CC_THEME,
  SET_CC_THEME_INPUTS,
  SET_CC_THEME_OUTPUTS,
  SET_CC_THEMES,
  SET_CONVENTION,
  SET_CONVENTIONS,
} from '../reducers/convention-collective-reducer'
import getPayeService, { setMethodStatus } from '../../api/envService'
import { setLoader, setTableurLoader } from './loader-action'
import ConventionCollective from '../constants/convention-collective'
import { CCField } from '../constants/active-cc-field'
import { CcTheme } from '../constants/cc-theme'
import {
  DATA_ENDPOINT,
  CC_ENDPOINT,
  COMPONENTS_ENDPOINT,
  HISTORIQUE_ENDPOINT,
  INPUT_ENDPOINT,
  OUTPUT_ENDPOINT,
  THEME_ENDPOINT, STATUT_ENDPOINT,
} from '../../api/endpoints'
import { getStatutFromLabel, getStatutFromString, Historique, Statut } from '../constants/historique'
import { buildComponentsFromJson, getCCThemeComponentsPromise, getThemeComponentsPromise } from './component-action'
import { buildThemeComponentsFromJson } from './theme-action'
import { isAll } from './tableur-action'
import { Component } from '../constants/component'
import { setCCHistoriques } from './historique-action'
import _ from 'lodash'

export const setActiveCCField = (field = CCField.NULL) => ({
  type: SET_CC_ACTIVE_FIELD,
  value: field,
})
export const setCCThemes = themes => dispatch => {
  dispatch({ type: SET_CC_THEMES, value: themes })
  return themes
}
export const setCCTheme = theme => {
  return { type: SET_CC_THEME, value: theme }
}

export const setCCComponent = (component, isInput) => dispatch => {
  dispatch({ type: isInput ? SET_CC_INPUT : SET_CC_OUTPUT, value: component })
  return component
}

export const setCCChildComponent = component => dispatch => {
  dispatch({ type: SET_CC_CHILD, value: component })
  return component
}

export const setConvention = convention => ({
  type: SET_CONVENTION,
  value: convention,
})

export const setConventions = conventions => ({
  type: SET_CONVENTIONS,
  value: conventions,
})
export const setCCThemesComponents = (components, isInput) => ({
  type: isInput ? SET_CC_THEME_INPUTS : SET_CC_THEME_OUTPUTS,
  value: components,
})
export const setLegalCCThemeOutputs = components => ({
  type: LEGAL_CC_THEME_OUTPUTS,
  value: components,
})

export const goToCCTable = (b = false) => ({ type: GO_TO_CC, value: b })

const deleteComponentData = (cc, theme, componentName) => {
  const DATA_ENDPOINT_NO_ALERT = { ...DATA_ENDPOINT }
  setMethodStatus(DATA_ENDPOINT_NO_ALERT, 'patchChild', {
    '200': {},
    '202': {},
    '204': {},
  })
  return getPayeService()
    .getGrandchildren(CC_ENDPOINT, cc.idcc, THEME_ENDPOINT, theme.nom, DATA_ENDPOINT)
    .then(res => {
      if (res && res.length > 0) {
        var dataLines = res.filter(data => !!data.inputs[componentName] || !!data.outputs[componentName])
        dataLines = dataLines.map(dataLine => {
          delete dataLine.inputs[componentName]
          delete dataLine.outputs[componentName]
          return dataLine
        })
        return getPayeService().patchChild(
          dataLines,
          CC_ENDPOINT,
          cc.idcc,
          THEME_ENDPOINT,
          theme.nom,
          DATA_ENDPOINT_NO_ALERT
        )
      } else {
        return Promise.resolve()
      }
    })
}

export const deleteComponentFromCCTheme = (ccs, themes, componentName, doNotDeleteData) => {
  const INPUT_ENDPOINT_WITHOUT_ALERT = { ...INPUT_ENDPOINT }
  setMethodStatus(INPUT_ENDPOINT_WITHOUT_ALERT, 'deleteChild', {
    '200': {},
    '202': {},
    '204': {},
  })
  const OUTPUT_ENDPOINT_WITHOUT_ALERT = { ...OUTPUT_ENDPOINT }
  setMethodStatus(OUTPUT_ENDPOINT_WITHOUT_ALERT, 'deleteChild', {
    '200': {},
    '202': {},
    '204': {},
  })
  return Promise.resolve()
    .then(async () => {
      for (let cc of ccs) {
        await getPayeService()
          .getChildren(CC_ENDPOINT.endpoint, cc.idcc, THEME_ENDPOINT.endpoint)
          .then(themesDto => {
            return themesDto.map(t => t.nom || t.themeName)
          })
          .then(themesNom => {
            return themes.filter(t => themesNom.find(name => name === t.nom))
          })
          .then(async selectedThemes => {
            for (let theme of selectedThemes) {
              await Promise.resolve()
                .then(() => (doNotDeleteData ? null : deleteComponentData(cc, theme, componentName)))
                .then(() =>
                  getPayeService().deleteChild(
                    CC_ENDPOINT,
                    cc.idcc,
                    THEME_ENDPOINT,
                    theme.nom,
                    INPUT_ENDPOINT_WITHOUT_ALERT,
                    componentName
                  )
                )
                .then(() =>
                  getPayeService().deleteChild(
                    CC_ENDPOINT,
                    cc.idcc,
                    THEME_ENDPOINT,
                    theme.nom,
                    OUTPUT_ENDPOINT_WITHOUT_ALERT,
                    componentName
                  )
                )
            }
          })
      }
    })
    .then(() => {
      return themes
    })
}

export const getCCById = idcc => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getCC(idcc))
    .then(convention => dispatch(setConvention(convention)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

const getCC = idcc =>
  idcc === 'all'
    ? Promise.resolve().then(() => ConventionCollective.ALL)
    : Promise.resolve()
      .then(() => getPayeService().getById(CC_ENDPOINT, idcc))
      .then(data => generateConventionsToStore(data))

export const setCC = idcc => (dispatch, getState) => {
  return idcc === 'all'
    ? dispatch(setConvention(() => ConventionCollective.ALL))
    : dispatch(setConvention(getState().conventionModule.conventionsCollectives.find(cc => cc.idcc === idcc)))}

export const findParentComponent = (componentName, components) => {
  let promise = Promise.resolve()
  if (!components) {
    promise = promise
      .then(() => getPayeService().get(COMPONENTS_ENDPOINT))
      .then(comps => buildComponentsFromJson(comps, true))
  } else {
    promise = promise.then(() => components)
  }
  return promise.then(comps => {
    var result = [componentName]
    var findParentComponent = name => {
      comps.forEach(comp => {
        if (comp.unit && comp.unit.nom === name) {
          result.push(comp.nom)
          findParentComponent(comp.nom, comps)
        } else if (comp.enfants && !!comp.enfants.find(child => child.nom === name)) {
          result.push(comp.nom)
          findParentComponent(comp.nom, comps)
        }
      })
    }
    findParentComponent(componentName, comps)
    return result
  })
}

export const getCCWithComponent = (componentName, theme, components) => {
  return findParentComponent(componentName, components)
    .then(names => {
      return Promise.all(
        names.map(name =>
          getPayeService().getGrandFather(INPUT_ENDPOINT, name, THEME_ENDPOINT, theme ? theme.nom : null, CC_ENDPOINT)
        )
      )
        .then(arrayFromInputs => _.unionBy(...arrayFromInputs, cc => cc.idcc))
        .then(fromInputs => {
          return Promise.all(
            names.map(name =>
              getPayeService().getGrandFather(
                OUTPUT_ENDPOINT,
                name,
                THEME_ENDPOINT,
                theme ? theme.nom : null,
                CC_ENDPOINT
              )
            )
          )
            .then(arrayFromOutputs => _.unionBy(...arrayFromOutputs, cc => cc.idcc))
            .then(fromOutputs => _.unionBy(fromInputs, fromOutputs, cc => cc.idcc))
        })
    })
    .then(data => data.map(d => generateConventionsToStore(d)))
}

function getThemesByIdcc(idcc) {
  return getPayeService().getChildren(CC_ENDPOINT, idcc, THEME_ENDPOINT)
}

export const buildCCHistoriquesFromJson = datas => datas.map(data => buildCCHistoriqueFromJson(data))

export function getStatutFromData(data, fromLabel) {
  if (!!(data.statut || data.statut === '' || data.status || data.status === '')) {
    const hasEnum = data.statut && (data.statut.name || data.statut.name === '')
    return fromLabel
      ? getStatutFromLabel(data.status ? data.status : data.statut)
      : getStatutFromString(hasEnum ? data.statut.name : data.status ? data.status : data.statut)
  } else {
    return Statut.PUBLISHABLE
  }
}

export const buildCCHistoriqueFromJson = (data, idcc) => {
  return new Historique(
    data.id,
    !!idcc ? idcc : data.idcc,
    data.name ? data.name : data.nom,
    getStatutFromData(data),
    data.description,
    data.publicationDate ? data.publicationDate : data.datePublication
  )
}

export const buildCCThemesFromJson = data => data.map(data => buildCCThemeFromJson(data))

export const buildCCThemeFromJson = (data, idcc) => {
  return new CcTheme(
    data.idTheme,
    data.themeName || data.nom,
    data.description,
    data.themeParent,
    data.themeParentId,
    !!idcc ? idcc : data.idcc,
    getStatutFromData(data),
    null,
    data.inputType
  )
}

export const getCCThemes = idcc => dispatch => {
  dispatch(setTableurLoader(true))
  return getThemesByIdcc(idcc)
    .then(data => (!data ? [] : buildCCThemesFromJson(data)))
    .then(themes => dispatch(setCCThemes(themes)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setTableurLoader(false)))
}

export function getHistoriqueByIdcc(idcc) {
  return getPayeService().getChildren(CC_ENDPOINT, idcc, HISTORIQUE_ENDPOINT)
}

export function getHistoriqueByStatus() {
return getPayeService().getChildren(STATUT_ENDPOINT, Statut.VALIDE.name, HISTORIQUE_ENDPOINT)
}

export const getCCHistoriques = idcc => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getHistoriqueByIdcc(idcc))
    .then(data => (!data ? [] : buildCCHistoriquesFromJson(data)))
    .then(historiques => dispatch(setCCHistoriques(historiques)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const loadConventionTablePage = setInit => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => dispatch(getAllConventions()))
    .then(() => dispatch(goToCCTable(false)))
    .then(() => !!setInit && setInit(true))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))

export const getAllConventions = () => dispatch => {
  dispatch(setLoader(true))
  return getPayeService()
    .get(CC_ENDPOINT)
    .then(data => (!data ? [] : buildConventionsFromJson(data)))
    .then(conventions => dispatch(setConventions(conventions)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const createConvention = (convention, doNext) => dispatch => {
  dispatch(setLoader(true))
  getPayeService()
    .post(CC_ENDPOINT, convention)
    .then( () => doNext())
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const updateConvention = convention => dispatch => {
  dispatch(setLoader(true))
  getPayeService()
    .put(CC_ENDPOINT, convention, convention.idcc)
    .then(() => dispatch(getCCById(convention.idcc)))
    .then(() => dispatch(setActiveCCField()))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export const patchCCThemes = (idcc, themes) => dispatch => {
  dispatch(setLoader(true))
  getPayeService()
    .patch(CC_ENDPOINT, idcc, THEME_ENDPOINT, themes)
    .then(() => dispatch(getCCThemes(idcc)))
    .catch(err => console.log(err))
    .finally(() => dispatch(setLoader(false)))
}

export function getCCTheme(idcc, themeName) {
  return dispatch =>
    Promise.resolve()
      .then(() => dispatch(setTableurLoader(true)))
      .then(() => dispatch(getCCThemes(idcc)))
      .then(themes => {
        return themes.find(t => t.nom === themeName)
      })
      .then(theme => {
        dispatch(setCCTheme(theme))
      })
      .catch(err => console.log(err))
      .finally(() => dispatch(setTableurLoader(false)))
}

export const loadThemeComponentsOfCC = (idcc, themeName) => (dispatch, getState) =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => dispatch(getCCTheme(idcc, themeName)))
    .then(() => getState().conventionModule.themes.find(t => t.nom === themeName))
    .then(theme => {
      const ccPromises = dispatch(getCCThemeComponentsPromise(theme, idcc))
      const themePromises = dispatch(getThemeComponentsPromise(theme))
      return Promise.all([ccPromises, themePromises])
    })
    .finally(() => dispatch(setLoader(false)))

export const getCCThemeComponents = (idcc, themeName, isInput) => dispatch =>
  Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => initCCComponentLoading(dispatch, idcc, themeName, isInput ? INPUT_ENDPOINT : OUTPUT_ENDPOINT))
    .then(components => dispatch(setCCThemesComponents(components, isInput)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))

export const getLegalCCThemeOutputs = (idcc, themeName) => dispatch =>
  Promise.resolve()
    // .then(() => dispatch(setLoader(true)))
    .then(() => initCCComponentLoading(dispatch, idcc, themeName, OUTPUT_ENDPOINT))
    .then(components => dispatch(setLegalCCThemeOutputs(components)))
// .catch(error => console.error(error))
// .finally(() => dispatch(setLoader(false)))

function getDafaultComponents(endpoint) {
  return endpoint === INPUT_ENDPOINT ? Component.DEFAULT_CC_COMPONENTS : []
}

function getComponentByIdcc(idcc, themeName, endpoint) {
  return getPayeService()
    .getChildren(THEME_ENDPOINT, themeName, endpoint)
    .then(themeComponents => {
      if (isAll(idcc)) return themeComponents
      else {
        return getPayeService()
          .getGrandchildren(CC_ENDPOINT, idcc, THEME_ENDPOINT, themeName, endpoint)
          .then(comps => {
            return comps.map(comp => themeComponents.find(c => (c.nom || c.name) === (comp.nom || comp.name)))
          })
      }
    })
}

export const initCCComponentLoading = (dispatch, idcc, themeName, endpoint) =>
  getComponentByIdcc(idcc, themeName, endpoint)
    .then(data => {
      const sortedData = data && data.length && data[0].rank ? data.sort((a, b) => a.rank - b.rank) : data
      return buildThemeComponentsFromJson(sortedData, true)
    })
    .then(components => (isAll(idcc) ? [...getDafaultComponents(endpoint), ...components] : components))

function generateConventionsToStore(data) {
  return !data ? ConventionCollective.NULL : buildConventionFromJson(data)
}

export function buildConventionFromJson(data, fromLabel = false) {
  return new ConventionCollective(
    data.idcc,
    data.titre,
    data.idccNational,
    data.idccRattachement,
    data.personnalise,
    data.ccRegionales,
    data.ccRattaches,
    getStatutFromData(data, fromLabel),
    data.themes,
    data.description,
    data.alreadyPublished,
    data.closureDate,
    !data.closed
  )
}

export function buildConventionsFromJson(data) {
  return data.map(d => buildConventionFromJson(d))
}

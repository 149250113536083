import { TabPanel } from '../common/tab-pannel'
import { CcThemeTab } from './themes/cc-theme-tab'
import {CcHistoriqueTab} from './historique/cc-historique-tab'
import {CcWorkflowTab} from './workflow/cc-workflow-tab'
import React from 'react'
import { useSelector } from 'react-redux'
import { CcAttechedTab } from './cc-attached-tab'

const titles = ['Thèmes', 'CONVENTIONS REGIONALES', 'CONVENTIONS RATTACHEES','Historique','Workflow']

export const CCTabs = () => {
  const ccRegionales = useSelector(state => state.conventionModule.conventionCollective.ccRegionales)
  const ccRattaches = useSelector(state => state.conventionModule.conventionCollective.ccRattaches)

  return (
    <TabPanel titles={titles}>
      <CcThemeTab />
      <>
        {ccRegionales && ccRegionales.length > 0 ? (
          <CcAttechedTab value={1} index={1} attachedCCs={ccRegionales} />
        ) : (
          <div data-test={'empty_cc_regionales'}>
            <br />
            Aucune Convention Régionale...
          </div>
        )}
      </>
      <>
        {ccRattaches && ccRattaches.length > 0 ? (
          <CcAttechedTab value={2} index={2} attachedCCs={ccRattaches} />
        ) : (
          <div data-test={'empty_cc_rattachees'}>
            <br />
            Aucune Convention Rattachée...
          </div>
        )}
      </>
      <CcHistoriqueTab/>
      <CcWorkflowTab/>
    </TabPanel>
  )
}

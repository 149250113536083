export class HeaderExpansion {
  header
  expanded
  role

  constructor(header = '', expanded = false, role) {
    this.header = header
    this.expanded = expanded
    this.role = role
  }

  getKey() {
    return this.role + '.' + this.header
  }

  static getExpansionKey (component, role){
    return role + '.' + component.parentPath
  }
  static getKeyByPath (path, role){
    return role + '.' + path
  }
}

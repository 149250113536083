function getThemeComponents(inputs) {
  return inputs ? inputs.map(input => {
    if (input.name) {
      input.nom = input.name
    }
    return input
  }) : []
}

export class Theme {
  idTheme
  nom
  label
  inputLabel
  outputLabel
  description
  themeParent
  themeParentId
  withInputs
  withOutputs
  inputs
  outputs
  statut
  inputType
  customText
  constructor(idTheme, nom, description, themeParent, themeParentId,label,inputLabel,outputLabel,withInputs,withOutputs,inputs,outputs, statut, inputType, customText) {
    this.idTheme = idTheme
    this.nom = nom
    this.description = description
    this.themeParent = themeParent
    this.themeParentId = themeParentId
    this.label=label
    this.inputLabel=inputLabel
    this.outputLabel=outputLabel
    this.withInputs=withInputs
    this.withOutputs=withOutputs
    this.statut = statut
    this.inputs=getThemeComponents(inputs)
    this.outputs=getThemeComponents(outputs)
    this.inputType = inputType
    this.customText = customText
  }
  static clone({ idTheme, nom, description, themeParent, themeParentId, label, inputLabel, outputLabel, withInputs, withOutputs, inputs, outputs, statut, inputType, customText }) {
    const copy = new Theme()
    copy.idTheme = idTheme
    copy.nom = nom
    copy.description = description
    copy.themeParent = themeParent
    copy.themeParentId = themeParentId
    copy.label=label
    copy.inputLabel=inputLabel
    copy.outputLabel=outputLabel
    copy.withInputs=withInputs
    copy.withOutputs=withOutputs
    copy.statut = statut
    copy.inputs=inputs
    copy.outputs=outputs
    copy.inputType=inputType
    copy.customText=customText
    return copy
  }
  static NULL = new Theme('', '', '', '', '','','','','',false,false,[],[])
}

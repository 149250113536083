import Grid from '@material-ui/core/Grid'
import * as PropTypes from 'prop-types'
import React from 'react'
import { EditSaveButton } from './edit-save-button'
import { CCField } from '../../../store/constants/active-cc-field'
import { useCCForm } from './hooks'
import { PayeLabel } from '../../common/paye-label'

export function TitreInput({ titre, onChange, onSave, isLabel = false }) {
  const [setEditable, isTitreActive, disabled] = useCCForm(
    CCField.TITRE,
    isLabel
  )
  return (
    <>
      <PayeLabel
        disabled={disabled}
        value={titre}
        onChange={onChange('titre')}
        dataName={'titre'}
      />
      {isLabel && (
        <Grid item xs={2}>
          <EditSaveButton
            editable={isTitreActive}
            setEditable={setEditable}
            dataTest="titre"
            onSave={onSave}
          />
        </Grid>
      )}
    </>
  )
}

TitreInput.propTypes = {
  titre: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
}

import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import EnhancedTableRow from './EnhancedTableRow'
import { getSorting, stableSort } from './TableUtils'

const useStyles = makeStyles(theme => ({
  button: {},
  nested: {
    paddingLeft: theme.spacing(5),
  },
  expandIcon: {
    paddingRight: 5,
    paddingBottom: 5,
    transform: 'translateY(7px)',
  },
}))

const EnhancedTableBody = props => {
  const { items, onDelete, onEdit, page, rowsPerPage, order, orderBy } = props
  const classes = useStyles()

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage)

  return (
    <TableBody>
      {stableSort(items, getSorting(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item,index) => {
          return (
            <EnhancedTableRow
              key={item.id}
              item={item}
              classes={classes}
              onDelete={onDelete}
              onEdit={onEdit}
              index={index}/>
          )
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  )
}

EnhancedTableBody.propTypes = {
  items: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

export default EnhancedTableBody

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import { WrapperLink } from '../../lib/helpers'
import { useStyles } from '../Inputs/Inputs'
import { ConventionTable } from './convention-table'
import { useDispatch, useSelector } from 'react-redux'
import { loadConventionTablePage } from '../../store/actions/convention-collective-action'
import getPayeService from '../../api/envService'
import { CC_ENDPOINT, CC_ENDPOINT_CHECKDELETION } from '../../api/endpoints'
import { showConfirmation } from '../../store/actions/alert-action'
import { Statut } from '../../store/constants/historique'

export const ConventionsList = () => {
  const classes = useStyles()

  const conventions = useSelector(state => state.conventionModule.conventionsCollectives)
  const loader = useSelector(state => state.loader)
  const [init, setInit] = useState(false)
  const [publishMode, setPublishMode] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadConventionTablePage(setInit))
  }, [dispatch])

  const handleClickEdit = id => {
    history.push(`/edit-conventions-collectives/${id}`)
  }

  const handleClickSave = idcc => {
    setInit(false)
    getPayeService()
      .deleteById(CC_ENDPOINT, idcc)
      .then(() => {
        dispatch(loadConventionTablePage(setInit))
      })
  }

  const handleClickDelete = idcc => {
    getPayeService()
      .getById(CC_ENDPOINT_CHECKDELETION, idcc)
      .then(messages => {
        dispatch(
          showConfirmation(messages, () => {
            handleClickSave(idcc)
          }),
        )
      })
    //conventionServices.deleteCCById(id)
  }

  return (
    <main className={classes.content}>
      <br/>
      <br/>
      {loader || !init ? (
        <LinearProgress/>
      ) : (
        <>
          {publishMode || (
            <Grid container justify="flex-end">
              <Grid>
                <Button variant="contained"
                        color="primary"
                        onClick={() => setPublishMode(true)}
                        style={{ marginBottom: 10,marginRight: 10 }}
                        data-test="publier">
                  {'DEMARRER PUBLICATION'}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained"
                        color="primary"
                        component={WrapperLink}
                        style={{ marginBottom: 10 }}
                        to="/add-convention"
                        data-test="AJOUT D'UNE CONVENTION">
                  {'Ajout d\'une convention'}
                </Button>
              </Grid>
            </Grid>
          )}
          <ConventionTable
            conventions={publishMode ? conventions.filter(i => i.statut === Statut.VALIDE) : conventions}
            title={publishMode ? 'Liste des conventions publiables' : 'Liste des conventions collectives'}
            onDelete={handleClickDelete}
            onEdit={handleClickEdit}
            publishMode={publishMode}
            setPublishMode={setPublishMode}
          />
        </>
      )}
      <Grid container spacing={1}/>
    </main>
  )
}

import React, { useEffect, useState } from 'react'
import { Checkbox, Grid, styled, TableBody, TableHead, TableRow } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../../../../common/container-title'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import Link from '@material-ui/core/Link'
import { ModelTextualisationPopperContent } from './model-textualisation-popper-content'
import * as PropTypes from 'prop-types'
import { CardsMenu } from '../blocks/cards-menu'
import { TextParamType } from '../../../../../store/constants/TextParamType'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  margin: 5,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}))

CardsMenu.propTypes = {
  anchorEl: PropTypes.func,
  onClose: PropTypes.func,
  block: PropTypes.any,
  idx: PropTypes.number,
  parent: PropTypes.string,
  handleTextPopoverOpen: PropTypes.func,
}

export function TextualisationParamElements({ classes, tmpModel, setTmpModel }) {
  const [anchorEl, setAnchorEl] = useState()
  const [idxElement, setIdxElement] = useState(0)
  const [openPanel, setOpenPanel] = useState(false)

  useEffect(() => {
    if (!tmpModel.textualisation.parameters || !tmpModel.textualisation.parameters) {
      setOpenPanel(true)
    }
  }, [])


  const handlePopoverOpen = (event, model, idx) => {
    setAnchorEl(event.target)
    setIdxElement(idx)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popin_Ajout_element_reference' : undefined

  function handleSave(parameter, i) {
    const params = [...tmpModel.textualisation.parameters]
    if (!i && i !== 0) params.push(parameter)
    else params.splice(i, 1, parameter)
    setTmpModel({ ...tmpModel, textualisation: { ...tmpModel.textualisation, parameters: params } })
    setAnchorEl(null)
    setIdxElement(null)
  }

  function deleteField(component) {
    let parameters = tmpModel.textualisation.parameters.filter(ff => ff.component !== component)
    setTmpModel({
      ...tmpModel,
      textualisation: { ...tmpModel.textualisation, parameters },
    })
  }

  function handleCancel() {
    setAnchorEl(null)
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.contentRoot} elevation={1} data-test={'modele_table_textualisation'}>
          <Grid container direction='row' style={{ cursor: 'pointer' }} onClick={() => setOpenPanel(!openPanel)}  >

            {
              openPanel
                ? <KeyboardArrowUp  fontSize='large'/>
                : <KeyboardArrowDown fontSize='large' />
            }
            <ContainerTitle classes={classes} title='Paramètrage du rapport' />
          </Grid>

          {
            openPanel &&
            <>
              <Grid container direction='row' justify='flex-end'>
                <Grid style={{ marginBottom: 16 }}>
                  <Button
                    data-test='Ajouter un element de textualisation'
                    color='primary'
                    variant='contained'
                    size='medium'
                    className={classes.margin}
                    onClick={handlePopoverOpen}>
                    NOUVEAU
                  </Button>
                </Grid>
              </Grid>
              {!!tmpModel.textualisation.parameters.length &&
              <Grid style={{ marginBottom: 50 }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Composant</TableCell>
                      <TableCell>Ignorer</TableCell>
                      <TableCell>Légender</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tmpModel.textualisation.parameters.map((param, idx) => {
                      const { component, type } = param
                      return (
                        <TableRow key={component}>
                          <TableCell data-test={`ref_component_${idx}`}>
                            <Link href='#' onClick={e => handlePopoverOpen(e, component, idx)}>
                              {component}
                            </Link>
                          </TableCell>
                          <TableCell data-test={`ref_ignore_${idx}`}><Checkbox disabled
                                                                               checked={type === TextParamType.IGNORE} /></TableCell>
                          <TableCell data-test={`ref_legend_${idx}`}><Checkbox disabled
                                                                               checked={type === TextParamType.LEGEND} /></TableCell>
                          <TableCell>
                            <Link href='#' onClick={() => deleteField(component)}>
                              X
                            </Link>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Grid>
              }

            </>
          }

        </Paper>
      </Grid>
      {open && <Popover anchorEl={anchorEl}
               id={id}
               open={open}
               anchorPosition={{ top: 270, left: 660 }}
               anchorOrigin={{
                 vertical: 'center',
                 horizontal: 'left',
               }}
               transformOrigin={{
                 vertical: 'center',
                 horizontal: 'right',
               }}>
         <Paper className={classes.popper} elevation={1}>
          <ContainerTitle variant='h5' classes={classes} title='Création élément de textualisation' />
          <Grid container direction='row' justify='flex-end'>
            <ModelTextualisationPopperContent
              className={classes.formControl}
              handleSave={handleSave}
              handleCancel={handleCancel}
              parameters={[...tmpModel.textualisation.parameters]}
              idx={idxElement}
            />
          </Grid>
        </Paper>
      </Popover>}
    </>
  )
}

export class Statut {
  name
  label

  constructor(name, label) {
    this.name = name
    this.label = label
  }

  static MODIFICATION_EN_COURS = new Statut('ENCOURS_MODIFICATION', 'en cours de modification')
  static CREATION_EN_COURS = new Statut('CREATION', 'en cours de création')
  static PUBLISHABLE = new Statut('PUBLISHABLE', 'Activé')
  static MISE_A_JOUR_ENCOURS = new Statut('MISE_A_JOUR_ENCOURS', 'en cours de traitement')
  static PUBLIE = new Statut('PUBLIE', 'publié')
  static VALIDE = new Statut('VALIDE', 'validé')
  static EN_DOUBLON = new Statut('EN_DOUBLON', 'en doublon')
  static DECOMISSIONEE = new Statut('DECOMISSIONNEE', 'décomissionnée')
  static NULL = new Statut('', '')
}

export const ALL_STATUT = [
  Statut.MODIFICATION_EN_COURS,
  Statut.CREATION_EN_COURS,
  Statut.PUBLISHABLE,
  Statut.MISE_A_JOUR_ENCOURS,
  Statut.PUBLIE,
  Statut.VALIDE,
  Statut.EN_DOUBLON,
  Statut.DECOMISSIONEE,
  Statut.NULL,
]
export const getStatutFromString = (string = '') => {
  const toCompare = string.name ? string.name : string
  const statut = ALL_STATUT.find(r => r.name === toCompare.toUpperCase())
  if (statut) return statut
  throw new Error('Le statut ' + string + ' est inconnu')
}

export const getStatutFromLabel = (string = '') => {
  const toCompare = string.label ? string.label : string
  const statut = ALL_STATUT.find(r => r.label === toCompare)
  if (statut) return statut
  throw new Error('Le statut ' + string + ' est inconnu')
}

export class Historique {
  id
  idcc
  nom
  statut
  description
  datePublication
  referenceAccord

  constructor(id, idcc, nom, statut, description, datePublication,referenceAccord='') {
    this.id = id
    this.idcc = idcc
    this.nom = nom
    this.statut = statut
    this.description = description
    this.datePublication = datePublication
    this.referenceAccord=referenceAccord
  }

  static NULL = new Historique('', '', '', Statut.NULL, '', '')
}

export class CHANGE_LOG_TYPE {
  name
  label

  constructor(name, label) {
    this.name = name
    this.label = label
  }

  static NULL = new CHANGE_LOG_TYPE('', () => '')
  static UPDATE_THEME = new CHANGE_LOG_TYPE(
    'UPDATE_THEME',
    content => `modification des thèmes suivants : [${content.theme.nom}]`
  )
  static ADD_COMPOSANT_THEME = new CHANGE_LOG_TYPE(
    'ADD_COMPOSANT_THEME',
    content =>
      `ajout des composants suivants aux thèmes associés : [${content.themeName}:[${content.composantsNames.join(
        ','
      )}]]`
  )
  static PUBLISH_THEME = new CHANGE_LOG_TYPE(
    'PUBLISH_THEME',
    content => `ajout des thèmes : [${content.themesNames.join(',')}]`
  )
  static DELETE_COMPOSANT_THEME = new CHANGE_LOG_TYPE(
    'DELETE_COMPOSANT_THEME',
    content =>
      `suppression des composants suivants aux thèmes associés : [${content.themeName}:[${content.composantsNames.join(
        ','
      )}]]`
  )
  static UPDATE_COMPONENT = new CHANGE_LOG_TYPE(
    'UPDATE_COMPONENT',
    content => `Modification composant: [${content.componentName}]`
  )
  static UPDATE_COMPOSANT_TYPE = new CHANGE_LOG_TYPE(
    'UPDATE_COMPOSANT_TYPE',
    content => `Modification type: [${content.componentName}]`
  )
  static UPDATE_COMPOSANT_UNIT = new CHANGE_LOG_TYPE(
    'UPDATE_COMPOSANT_UNIT',
    content => `Modification type: [${content.componentName}]`
  )
  static UPDATE_COMPOSANT_LABEL = new CHANGE_LOG_TYPE(
    'UPDATE_COMPOSANT_LABEL',
    content => `Modification libellé: [${content.componentName}]`
  )
  static UPDATE_COMPOSANT_REFERENTIAL = new CHANGE_LOG_TYPE(
    'UPDATE_COMPOSANT_REFERENTIAL',
    content => `Modification référentiel associé: [${content.componentName}]`
  )
  static SPLIT_COMPOSANT_REFERENTIAL = new CHANGE_LOG_TYPE(
    'SPLIT_COMPOSANT_REFERENTIAL',
    content => `Séparation du référentiel en deux: [${content.componentName}]`
  )
  static UPDATE_COMPOSANT_CHILDREN = new CHANGE_LOG_TYPE(
    'UPDATE_COMPOSANT_CHILDREN',
    content => `Modification enfants: [${content.componentName}]`
  )
  static MOVE_COMPOSANT = new CHANGE_LOG_TYPE(
    'MOVE_COMPOSANT',
    content => `Modification enfants: [${content.componentName}]`
  )
}

export const ALL_CHANGE_TYPE = [
  CHANGE_LOG_TYPE.NULL,
  CHANGE_LOG_TYPE.UPDATE_THEME,
  CHANGE_LOG_TYPE.ADD_COMPOSANT_THEME,
  CHANGE_LOG_TYPE.DELETE_COMPOSANT_THEME,
  CHANGE_LOG_TYPE.PUBLISH_THEME,
  CHANGE_LOG_TYPE.UPDATE_COMPONENT,
  CHANGE_LOG_TYPE.UPDATE_COMPOSANT_TYPE,
  CHANGE_LOG_TYPE.UPDATE_COMPOSANT_LABEL,
  CHANGE_LOG_TYPE.UPDATE_COMPOSANT_UNIT,
  CHANGE_LOG_TYPE.UPDATE_COMPOSANT_REFERENTIAL,
  CHANGE_LOG_TYPE.SPLIT_COMPOSANT_REFERENTIAL,
  CHANGE_LOG_TYPE.UPDATE_COMPOSANT_CHILDREN,
  CHANGE_LOG_TYPE.MOVE_COMPOSANT,
]

export const getChangeLogType = (string = '') => {
  const toCompare = string.name ? string.name : string
  const change = ALL_CHANGE_TYPE.find(r => r.name.toUpperCase() === toCompare.toUpperCase())
  if (change) return change
  throw new Error('Le change type ' + string + ' est inconnu')
}

export class ChangeLog {
  version
  type
  dateOperation
  auteur
  parameters
  description
  id
  ccs

  constructor(version, type, dateOperation, auteur, parameters, id,ccs=[]) {
    this.version = version
    this.type = type
    this.dateOperation = dateOperation
    this.auteur = auteur
    this.parameters = parameters
    this.id = id
    this.description = type.label(parameters)
    this.ccs=ccs
  }

  static addComposantTheme(version, themeName, composantsNames, dateOperation, auteur,ccs) {
    let parameters = {
      themeName: themeName,
      composantsNames: composantsNames,
      themesImplies : [themeName],
      composantsImplies : composantsNames
    }
    return new ChangeLog(version, CHANGE_LOG_TYPE.ADD_COMPOSANT_THEME, dateOperation, auteur, parameters,null,ccs)
  }

  static deleteComposantTheme(version, themeName, composantsNames, dateOperation, auteur,ccs) {
    let parameters = {
      themeName: themeName,
      composantsNames: composantsNames,
      themesImplies : [themeName],
      composantsImplies : composantsNames
    }
    return new ChangeLog(version, CHANGE_LOG_TYPE.DELETE_COMPOSANT_THEME, dateOperation, auteur, parameters,null,ccs)
  }

  static updateTheme(version, theme, dateOperation, auteur,ccs) {
    let parameters = {
      theme: theme,
      themesImplies : [theme.nom],
      composantsImplies : []
    }
    return new ChangeLog(version, CHANGE_LOG_TYPE.UPDATE_THEME, dateOperation, auteur, parameters,null,ccs)
  }

  static publishTheme(version, themesNames, dateOperation, auteur,ccs) {
    let parameters = {
      themesNames: themesNames,
      themesImplies : themesNames,
      composantsImplies : []
    }
    return new ChangeLog(version, CHANGE_LOG_TYPE.PUBLISH_THEME, dateOperation, auteur, parameters,null,ccs)
  }
  static updateComponent(version, component, dateOperation, auteur,ccs) {
    let parameters = {
      componentName: component,
      themesImplies : [],
      composantsImplies : component
    }
    return new ChangeLog(version, CHANGE_LOG_TYPE.UPDATE_COMPONENT, dateOperation, auteur, parameters,null,ccs)
  }
  static NULL = new ChangeLog('', CHANGE_LOG_TYPE.NULL, '', '', {})
}

import { useSelector } from 'react-redux'
import TableRow from '@material-ui/core/TableRow'
import { ActionRow, getDataIdFromrState, TableurAction } from './row/action-row'
import TableCell from '@material-ui/core/TableCell'
import { DataRow } from './row/data-row'
import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { Statut } from '../../../../store/constants/historique'
import { getComputedData } from '../../../../store/selectors/tableur-selectors'

function isOnUpdateSelector(idx) {
  return state => {
    const page = state.tableurModule.page
    const pageSize = state.tableurModule.pageSize
    const data = getComputedData(state)[idx + pageSize * page]
    return data ? data.statut === Statut.MODIFICATION_EN_COURS : false
  }
}
function isOnValidateSelector(idx) {
  return state => {
    const page = state.tableurModule.page
    const pageSize = state.tableurModule.pageSize
    const data = getComputedData(state)[idx + pageSize * page]
    return data ? data.statut === Statut.VALIDE : false
  }
}
function isOnDuplicateSelector(idx) {
  return state => {
    const page = state.tableurModule.page
    const pageSize = state.tableurModule.pageSize
    const data = getComputedData(state)[idx + pageSize * page]
    return data ? data.statut === Statut.EN_DOUBLON : false
  }
}

export function DataTableRow({ classes, idx }) {
  const activeInputs = useSelector(state => state.tableurModule.activeInputs)
  const activeOutputs = useSelector(state => state.tableurModule.activeOutputs)
  const isOnUpdate = useSelector(isOnUpdateSelector(idx))
  const isOnDuplicate = useSelector(isOnDuplicateSelector(idx))
  const isOnValidate = useSelector(isOnValidateSelector(idx))
  const selectMode = useSelector(state => state.tableurModule.selectMode)
  const hasNoAction = useSelector(state => state.tableurModule.action === TableurAction.NULL && !state.tableurModule.editMode)
  const hasData = useSelector(state => {
    return !!getDataIdFromrState(state, idx)
  })

  let className = useMemo(
    () => isOnUpdate ? classes.orange_line : isOnDuplicate ? classes.red_line : isOnValidate ? classes.bleu_line : classes.white_line
    , [isOnUpdate, isOnDuplicate, isOnValidate, hasData]
  )

  const lineContent = useMemo(
    () => (
      <>
        <ActionRow idx={idx} classes={classes} backgroundClass={className}/>
        <TableCell className={classes.empty} style={{position: 'sticky', left:  150, zIndex:1000}}/>
        {activeInputs.length && (
          <DataRow prefix={'input'} activeComponents={activeInputs} line={idx} classes={classes} isInput={true} />
        )}
        <TableCell className={classes.empty} />
        {activeOutputs.length && (
          <DataRow prefix={'output'} activeComponents={activeOutputs} line={idx} classes={classes} isInput={false} />
        )}
      </>
    ),
    [selectMode, hasNoAction, className]
  )
  return (
    <TableRow
      className={
        className
      }
      style={{ display: (hasData) ? '' : 'none' }}
      data-test={(hasData) ? 'line_' + idx : 'invisible_row '}
    >
      <>{lineContent}</>
    </TableRow>
  )
}

DataTableRow.propTypes = {
  classes: PropTypes.any,
  data: PropTypes.any,
  idx: PropTypes.any,
  activeComponents: PropTypes.any,
  activeComponents1: PropTypes.any,
}

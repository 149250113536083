import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ValidateIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import AccountTree from '@material-ui/icons/AccountTree'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import BOButton from '../../common/bo-button'

import {
  addEmptyData,
  clearAllTableur,
  EMPTY_DATA,
  isAll,
  removeMultiLines,
  saveMultiLines,
  setEditMode,
  setSelectMode,
  setTableurAction,
} from '../../../store/actions/tableur-action'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmMessage, MODIFICATION, SUPPRESSION, VALIDATION,VALIDATIONWF,ANNULERWF } from './table/row/confirm-message'
import { TableurAction } from './table/row/action-row'
import { useParams } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TablePagination } from './table-pagination'
import { validateThemeCC } from '../../../store/actions/theme-action'
import {showAlert} from '../../../store/actions/alert-action'
import {validateWorkflow,annulerWorkflow} from '../../../store/actions/workflow-action'
import { Statut } from '../../../store/constants/historique'
import { ModelInstanceWorkflow,WorkflowStatut } from '../../../store/constants/ModelInstanceWorkFlow'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 55,
    height: 38,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 12,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  container: {
    marginTop: 6,
  },
}))

export const AddDataLineButton = () => {
  const { idcc, theme } = useParams()
  const history = useHistory()
  const testmode = useSelector(state => state.user.testmode)
  const { idcc : idccStored, ouvert, statut: ccStatut } = useSelector(state => state.conventionModule.conventionCollective)
  const themeStored = useSelector(state => state.conventionModule.theme)
  const versions = useSelector(state => state.versionModule.versions)
  const tableurLoader = useSelector(state => state.tableurModule.loader)
  const workflow = useSelector(state => state.workflowModule.workflow)
  const themeId = themeStored ? (themeStored.idTheme ? themeStored.idTheme : themeStored.themeParentId) : ''
  const themeName = themeStored ? (themeStored.themeParent ? themeStored.themeParent : themeStored.nom) : ''
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tmpValue, setTmpValue] = useState(1)
  const editMode = useSelector(state => state.tableurModule.editMode)
  const selectMode = useSelector(state => state.tableurModule.selectMode)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showValidateConfirm, setShowValidateConfirm] = useState(false)
  const [showEditConfirm, setShowEditConfirm] = useState(false)
  const [showValidateWorkflowConfirm,setShowValidateWorkflowConfirm] = useState(false)
  const [showAnnulerWorkflowConfirm,setShowAnnulerWorkflowConfirm] = useState(false)

  const handleChange = ({ target: { value } }) => {
    if ((value > 0 && value <= 99) || value === '') setTmpValue(value)
  }

  function addLine(count) {
    const newLines = []
    for (let i = 0; i < count; i++) newLines.push(EMPTY_DATA)
    dispatch(addEmptyData(newLines))
  }

  const handleClick = () => {
      tmpValue && addLine(tmpValue)
      setTmpValue(1)
  }

  const handleConfirmDelete = () => {
    setShowDeleteConfirm(!showDeleteConfirm)
    dispatch(removeMultiLines())
  }

  const handleConfirmEdit = () => {
    dispatch(saveMultiLines())
    setShowEditConfirm(!showEditConfirm)
  }

  const handleConfirmValidation = () => {
    dispatch(validateThemeCC())
    setShowValidateConfirm(!showValidateConfirm)
  }

  const handleConfirmValidationWorkflow = () => {
    dispatch(validateWorkflow(nextThemeName=>{
      history.push('/tableur/' + idcc + '/theme/' + nextThemeName)
    }))
    setShowValidateWorkflowConfirm(!showValidateWorkflowConfirm)
  }
  const handleDelete = () => {
    setShowDeleteConfirm(!showDeleteConfirm)
  }

  const handleEdit = () => {
    setShowEditConfirm(!showEditConfirm)
  }

  const handleValidate = () => {
    setShowValidateConfirm(!showValidateConfirm)
  }
  const handleValidateWorkflow = () => {
    setShowValidateWorkflowConfirm(!showValidateWorkflowConfirm)
  }
  const handleAnnulerWorkflow = () => {
    setShowAnnulerWorkflowConfirm(!showAnnulerWorkflowConfirm)
  }

  const handleConfirmAnnulerWorkflow = () => {
    dispatch(annulerWorkflow(idcc, nextThemeName=>{
      history.push('/tableur/' + idcc + '/theme/' + nextThemeName)
    }))
    setShowAnnulerWorkflowConfirm(!showAnnulerWorkflowConfirm)
  }
  const handleEditMultiLines = () => {
    dispatch(setEditMode(true))
    setShowEditConfirm(false)
    dispatch(setTableurAction(TableurAction.ON_EDIT_MULTILINE))
    dispatch(setSelectMode())
  }

  const handleCancel = () => {
    dispatch(clearAllTableur(false))
    if (showDeleteConfirm) setShowDeleteConfirm(false)
    if (showEditConfirm) setShowEditConfirm(false)
    if (showValidateConfirm) setShowValidateConfirm(false)
  }

  const handleCancelWorkflow = () => {
    dispatch(clearAllTableur(false))
    if (showDeleteConfirm) setShowDeleteConfirm(false)
    if (showEditConfirm) setShowEditConfirm(false)
    if (showValidateConfirm) setShowValidateConfirm(false)
    if (showValidateWorkflowConfirm) setShowValidateWorkflowConfirm(false)
    if (showAnnulerWorkflowConfirm) setShowAnnulerWorkflowConfirm(false)
  }

  return (
          <Grid direction="row" justify="flex-end" container className={classes.container}>
            <>
              {themeId && idcc && theme && themeStored && (
                <Grid
                  direction="row"
                  container
                  justify="flex-end"
                  className={classes.container}
                >
                  <>
                  {!!tableurLoader && <CircularProgress />}

                  <Paper component="form" style={{ marginRight: 7 }}>
                    {showValidateConfirm && (
                      <ConfirmMessage
                        dataTest={'message_confirmation'}
                        onClick={handleConfirmValidation}
                        onClick1={handleCancel}
                        isMulti
                        styleMessage={{ paddingLeft: 22, paddingRight: 10 }}
                        action={VALIDATION}
                      />
                    )}
                    {showValidateWorkflowConfirm && (
                      <ConfirmMessage
                        dataTest={'message_confirmation'}
                        onClick={handleConfirmValidationWorkflow}
                        onClick1={handleCancelWorkflow}
                        isMulti
                        styleMessage={{ paddingLeft: 22, paddingRight: 10 }}
                        action={VALIDATIONWF}
                      />
                    )}
                    {showAnnulerWorkflowConfirm && (
                      <ConfirmMessage
                        dataTest={'message_confirmation'}
                        onClick={handleConfirmAnnulerWorkflow}
                        onClick1={handleCancelWorkflow}
                        isMulti
                        styleMessage={{ paddingLeft: 22, paddingRight: 10 }}
                        action={ANNULERWF}
                      />
                    )}
                  </Paper>
                  {(!workflow || workflow === ModelInstanceWorkflow.NULL) && themeStored.statut === Statut.MODIFICATION_EN_COURS && (
                    <Fab
                      size="small"
                      style={{ backgroundColor: green['A700'], color: 'white', marginRight: 7 }}
                      aria-label="add"
                    >
                      <ValidateIcon onClick={handleValidate} data-test="valider" />
                    </Fab>
                  )}
                  {workflow && workflow.statut[themeStored.nom.toUpperCase()] === WorkflowStatut.EN_COURS && (
                    <>
                    <Fab
                      size="small"
                      style={{ backgroundColor: green['A700'], color: 'white', marginRight: 7 }}
                      aria-label="add"
                    >
                      <AccountTree onClick={handleValidateWorkflow} data-test="Valider le workflow" />
                    </Fab>
                    <Fab
                    size="small"
                    style={{ backgroundColor: red['A700'], color: 'white', marginRight: 7 }}
                    aria-label="add"
                  >
                    <AccountTree onClick={handleAnnulerWorkflow} data-test="Annuler le workflow" />
                  </Fab>
                  </>
                  )}

                  <TablePagination />
                  {selectMode && (
                    <>
                      <Paper component="form" style={{ marginRight: 15 }}>
                        {showDeleteConfirm && (
                          <ConfirmMessage
                            dataTest={'message_confirmation'}
                            isMulti
                            onClick={handleConfirmDelete}
                            onClick1={handleCancel}
                            styleMessage={{ paddingLeft: 22, paddingRight: 10 }}
                            action={SUPPRESSION}
                          />
                        )}
                      </Paper>
                      <Fab size="small" color="secondary" aria-label="supprimer" style={{ marginRight: 7 }}>
                        <DeleteIcon onClick={handleDelete} data-test="Supprimer" />
                      </Fab>
                      <Fab size="small" color="primary" aria-label="add" style={{ marginRight: 7 }}>
                        <EditIcon onClick={handleEditMultiLines} data-test="modifier" />
                      </Fab>
                    </>
                  )}
                  {editMode && (
                    <>
                      <Paper component="form" style={{ marginRight: 15 }}>
                        {showEditConfirm && (
                          <ConfirmMessage
                            dataTest={'message_confirmation'}
                            onClick={handleConfirmEdit}
                            onClick1={handleCancel}
                            isMulti
                            styleMessage={{ paddingLeft: 22, paddingRight: 10 }}
                            action={MODIFICATION}
                          />
                        )}
                      </Paper>
                      <Fab size="small" color="primary" aria-label="enregistrer" style={{ marginRight: 7 }}>
                        <SaveIcon onClick={handleEdit} data-test="Enregistrer" />
                      </Fab>
                    </>
                  )}
                  {(editMode || selectMode) && (
                    <>
                      <Fab size="small" aria-label="annuler" style={{ marginRight: 7 }}>
                        <CancelIcon onClick={handleCancel} data-test="ANNULER" />
                      </Fab>
                    </>
                  )}
                  {idccStored === 'all' || (
                    <Grid>
                      <BOButton
                          title={`Prévisualiser le Json du thème ${themeName} de la CC ${idccStored}`}
                          url={version=>`/${testmode ? 'testmode' : 'api'}/bo/${version}/themes-data/${idccStored !== 'all' ? idccStored : ''}?idTheme=${themeId}`}
                          dataTest="PREVISUALISER THEME CC JSON"
                          fab={true}
                          versions={versions}
                      />
                    </Grid>
                  )}
                  {!editMode && !selectMode && !isAll(idccStored) && (
                    <>
                      <Fab size="small" color="primary" aria-label="add">
                        <AddIcon onClick={(ouvert || ccStatut !== Statut.PUBLIE) && handleClick} data-test="Ajouter lignes" aria-disabled={!ouvert && ccStatut === Statut.PUBLIE}/>
                      </Fab>
                      <Paper component="form" className={classes.root}>
                        <InputBase
                          id="Nombrelignes"
                          className={classes.input}
                          data-test="nb_lines_to_add"
                          inputProps={{ 'aria-label': 'search google maps' }}
                          type="number"
                          value={tmpValue}
                          onChange={handleChange}
                        />
                      </Paper>
                    </>
                  )}
                  </>
                </Grid>
              )}
            </>
          </Grid>
  )
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, IconButton, makeStyles, Fab, Popover, Grid } from '@material-ui/core'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import { WrapperLink } from '../../lib/helpers'
import { Statut } from '../../store/constants/historique'

const useStyles = makeStyles(theme => ({
  version: {
    marginLeft: 7,
    marginTop: 7,
    marginBottom: 7,
    marginRight: 20,
  },
}))

const BOButton = ({ title, url, dataTest, fab = false, placement = 'top', versions = [],nextVersion = true,disabled=false }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  let versionsOrdered = versions.filter(v => v.statut !== Statut.DECOMISSIONEE)
  placement === 'top'
    ? versionsOrdered.sort((v1, v2) => (v1.id == v2.id ? 0 : v1.id > v2.id ? 1 : -1))
    : versionsOrdered.sort((v1, v2) => (v1.id == v2.id ? 0 : v1.id > v2.id ? -1 : 1))

  const [allVersionsVisible, setAllVersionsVisible] = useState(false)

  versionsOrdered =
    allVersionsVisible || versions.length <= 3
      ? versionsOrdered
      : placement === 'top'
      ? versionsOrdered.slice(versionsOrdered.length - 3, versionsOrdered.length)
      : versionsOrdered.slice(0, 3)

  const oneVersion = versionsOrdered.length == 1 && !nextVersion

  const handleClick = event => {
    // if (versions.length == 1) {
    //   window.open(url(versions[0].nom), '_blank')
    //   return
    // }
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAllVersionsVisible(false)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          placement === 'top'
            ? {
                vertical: 'top',
                horizontal: 'center',
              }
            : {
                vertical: 'bottom',
                horizontal: 'center',
              }
        }
        transformOrigin={
          placement === 'top'
            ? {
                vertical: 'bottom',
                horizontal: 'center',
              }
            : {
                vertical: 'top',
                horizontal: 'center',
              }
        }
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <Grid direction="column" justify="flex-start" alignItems="center" container>
          {nextVersion && placement !== 'top' && (
            <Grid item>
              <Fab
                size="small"
                aria-label="add"
                component={WrapperLink}
                to={url('next')}
                target="_blank"
                data-test="PROCHAINE VERSION"
                className={classes.version}
                variant="extended"
              >
                Prochaine version
              </Fab>
            </Grid>
          )}
          {placement === 'top' && !(allVersionsVisible || versions.length <= 3) && (
            <Grid item>
              <Fab
                size="small"
                aria-label="add"
                component={WrapperLink}
                onClick={() => setAllVersionsVisible(true)}
                data-test={dataTest}
                className={classes.version}
                variant="extended"
              >
                ...
              </Fab>
            </Grid>
          )}
          {versionsOrdered.map(version => {
            return (
              <Grid item key={version}>
                <Fab
                  size="small"
                  aria-label="add"
                  component={WrapperLink}
                  to={url(version.nom)}
                  target="_blank"
                  data-test={dataTest}
                  className={classes.version}
                  variant="extended"
                >
                  {version.nom}
                </Fab>
              </Grid>
            )
          })}
          {nextVersion && placement === 'top' && (
            <Grid item>
              <Fab
                size="small"
                aria-label="add"
                component={WrapperLink}
                to={url('next')}
                target="_blank"
                data-test="PROCHAINE VERSION"
                className={classes.version}
                variant="extended"
              >
                Prochaine version
              </Fab>
            </Grid>
          )}

          {placement !== 'top' && !(allVersionsVisible || versions.length <= 3) && (
            <Grid item>
              <Fab
                size="small"
                aria-label="add"
                component={WrapperLink}
                onClick={() => setAllVersionsVisible(true)}
                data-test={dataTest}
                className={classes.version}
                variant="extended"
              >
                ...
              </Fab>
            </Grid>
          )}
        </Grid>
      </Popover>
      {oneVersion && (
            <Fab
              size="small"
              aria-label="add"
              style={{ marginRight: 7 }}
              data-test={dataTest}
              aria-label="add"
              component={WrapperLink}
              to={url(versionsOrdered[0].nom)}
              target="_blank"
            >
            <OpenInNewOutlinedIcon />
            </Fab>
      )
      }
      {!oneVersion && fab && (
        <>
          <Tooltip title={title} disabled={disabled}>
            <Fab
            size="small"
            aria-label="add"
            style={{ marginRight: 7 }}
            onClick={handleClick}
            data-test={dataTest}>
              <OpenInNewOutlinedIcon />
            </Fab>
          </Tooltip>
        </>
      )}
      {!oneVersion && !fab && (
        <Tooltip title={title}  disabled={disabled}>
          <IconButton
            size="medium"
            className={classes.button}
            onClick={handleClick}
            target="_blank"
            data-test={dataTest}
          >
            <OpenInNewOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

BOButton.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.func.isRequired,
  dataTest: PropTypes.string.isRequired,
  fab: PropTypes.bool,
  placement: PropTypes.string,
  versions: PropTypes.array,
}

export default BOButton

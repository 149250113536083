import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DataCellButton } from '../tableur/tab/table/row/cell/data-cell-button'
import FormControl from '@material-ui/core/FormControl'
import { getCCWorkflows, getInProgressWf } from '../../store/actions/workflow-action'
import getPayeService from '../../api/envService'
import { WORKFLOW_ENDPOINT } from '../../api/endpoints'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  container: { maxHeight: '100%', overflowX: 'hidden' },
  selector: { margin: 0, height: 31 },
}))

export const patchInstance = ( fromCC, body) => dispatch => {
  return Promise.resolve()
    .then( () => getPayeService().patch(WORKFLOW_ENDPOINT, null, null, parseParamTye(body)))
    .then(() => dispatch(fromCC ? getCCWorkflows(body.idcc) : getInProgressWf()))
    .catch(e => console.log(e))
}

const parseParamTye = body => ({
   ...body,
  parameters: body.parameters.map(p => ({ ...p, type: p.type.name }))
  })


const doNothing = () => {}

export function TextButton({ wfField, wf , fromCC}) {
  const [text, setText] = useState('')
  const [isButton, setIsButton] = useState(true)
  const classes = useStyles()
  const dispatch = useDispatch()

  const onSave = () => {
     dispatch(patchInstance(fromCC, {idcc: wf.idcc, wfName: wf.nom, source: wfField == 'source' ? text : wf.source, refAccord: wfField === 'referenceAccord' ? text: wf.referenceAccord, parameters: wf.parameters}))
     setIsButton(true)
  }

  useEffect(() => {

    setText(wf[wfField] || '')
  }, [wf, wfField])

  const handleClick = e => {
    if(isButton) setIsButton(!isButton)
    e.stopPropagation()
  }
  return isButton
    ? <DataCellButton onClick={handleClick}>
        {text}
    </DataCellButton >
    : <FormControl className={classes.selector}  onClick={handleClick}>
        <input
          type="text"
          value={text}
          onChange={e => setText(e.target.value)}
          open
          onClick={handleClick}
          onKeyDown={e => e.key === 'Enter' ? onSave() : doNothing() }
        />
  </FormControl>
}

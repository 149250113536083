import React from 'react'
import { TabCellAndTooltip } from './table-cell-and-tooltip'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getDataIdFromrState, TableurAction } from './action-row'
import { clearTableurAction, deleteDataLine } from '../../../../../store/actions/tableur-action'
import { useParams } from 'react-router'
import { showMessage } from './data-row-helper'

function getOnDelete(action, dataId) {
  return action.action === TableurAction.ON_DELETE && action.dataId === dataId
}


export function DataRow({ activeComponents, line, prefix, isInput }) {
  const { idcc, theme } = useParams()

  const isOnDelete = useSelector(state => {
    const dId = getDataIdFromrState(state, line)
    const action = state.tableurModule.action
    return action && action.dataId === dId && getOnDelete(action, dId)
  })

  const dispatch = useDispatch()

  const handleCancel = () => {
    dispatch(clearTableurAction())
  }

  const handleDelete = () => {
    dispatch(deleteDataLine(idcc, theme, line))
  }

//  console.log('data-row ===> ' + prefix + ' ' + line)
  return <>
    {isOnDelete
      ? showMessage(isInput, activeComponents, line, handleDelete, handleCancel)
      : activeComponents.map((c) => {
          return <TabCellAndTooltip
            key={line + c.getComponentPath() + c.exclude}
            prefix={prefix}
            component={c}
            activeComponents={activeComponents}
            line={line}/>
        },
      )}
  </>
}

DataRow.propTypes = {
  prefix: PropTypes.any,
  data: PropTypes.any,
  component: PropTypes.any,
  activeComponents: PropTypes.any,
  idx: PropTypes.any,
}

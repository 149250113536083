import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const NavHeader = () => {
  const user = useSelector(state => state.user.user)
  return <>
    <div style={{ height: 50}}>
      <Avatar
        style={{
          float: 'left',
          width: 48,
          height: 48,
          marginRight: 15
        }}/>
        <div style={{width: 110,height: 67,float: 'right'}}>
          <Typography color={'textPrimary'} noWrap style={{margin: 0}}>
            {user.username}
          </Typography>
          <Typography color={'textPrimary'} noWrap style={{margin: 0}}>
            {user.role && user.role.toLowerCase()}
          </Typography>
        </div>
    </div>
    <Divider/>
  </>
}

NavHeader.propTypes = {
  collapsed: PropTypes.bool,
}
NavHeader.defaultProps = {
  collapsed: false,
}

export default NavHeader

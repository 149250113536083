import React,{useState} from 'react'
import * as PropTypes from 'prop-types'
import {TableCell} from '@material-ui/core'
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputText from '../common/input-text'
import InputNumeric from '../common/input-numeric'
import {Type} from '../../store/constants/type'
import CellActions from '../common/table/editable/renderer/cell-actions'
import {Component} from '../../store/constants/component'

function GroupValuesItem({component,dataTestPrefix,dataTestKey,componentValue,childrenReferentiel,onChange,onEditReferentiel}){
    const [referenceCode,setReferenceCode] = useState(component.type === Type.REFERENCE ? (componentValue || "") : "")
    const [inputNumeric,setInputNumeric] = useState(component.type === Type.NUMERIC ? (componentValue || "0") : "0")
    const [inputText,setInputText] = useState(component.type === Type.TEXT ? (componentValue || "") : "")

    const handleSelectChange = (event) => {
        const value=event.target.value
        if(value === "modifierReferentiel"){
            setReferenceCode("");
            onEditReferentiel(component)
        }else{
            setReferenceCode(value);
            onChange(value)
        }
    };

    const handleInputNumeric = (e,value) => {
        setInputNumeric(value)
        onChange(value)
    }

    const handleInputText = (e,value) =>{
        setInputText(value)
        onChange(value)
    }

    const dataTest=dataTestPrefix+"_edit_"+component.nom+(component.identifier ? "_"+component.identifier : "")

    switch(component.type) {
        case Type.NUMERIC:
            return (
                <InputNumeric 
                    placeholder={component.nom}
                    initialValue={parseFloat(inputNumeric)} 
                    dataTest={dataTest}
                    onChange={handleInputNumeric}
                    dataKey={dataTestKey}/>)
        case Type.TEXT:
            return (
                <InputText
                    onChange={handleInputText}
                    placeholder={component.nom}
                    initialValue={inputText}
                    dataTest={dataTest}
                    dataKey={dataTestKey}
                />
            )
        case Type.REFERENCE:
            return (<FormControl>
                <InputLabel id={"component-select-label-"+component.nom}>{component.nom}</InputLabel>
                <Select 
                    id={dataTest+"_"+dataTestKey} 
                    value={referenceCode}
                    labelId={"component-select-label-"+dataTest+"_"+dataTestKey}
                    onChange={handleSelectChange}>
                    <MenuItem value="">
                        <em>Aucun</em>
                    </MenuItem>
                    {childrenReferentiel[component.nom].values.map((option)=>(
                        <MenuItem value={option.code} key={option.code}>{option.code} - {option.libelle}</MenuItem>
                    ))}
                    <MenuItem value="modifierReferentiel">
                        <em>Modifier le référentiel</em>
                    </MenuItem>
                </Select>
            </FormControl>)
        default:
            throw new Error("Type "+component.type.name+" not supported")
    }

}
GroupValuesItem.propTypes = {
    dataTestPrefix:PropTypes.string.isRequired,
    component:PropTypes.any.isRequired,
    dataTestKey:PropTypes.string.isRequired,
    childrenComponent:PropTypes.object.isRequired,
    childrenReferentiel:PropTypes.object.isRequired,
    onChange:PropTypes.func
}

export const getChildValue = (child,rowValue)=>{
    let stack=[]
    while(child){
      stack.push(child)
      child=child.parent
    }
    let result = rowValue
    while(result && stack.length > 0){
      result=result[stack.pop().nom]
    }
    return result || ""
  }

  
  export const computeTree = (component) =>{
    const result= computeTreeRecurs(null,component,{count:{}})
    result.enfants.forEach(enfant=>enfant.parent=null)
    return result
  }

  const computeTreeRecurs = (parent,child,options)=>{
    const incr = (key)=>{
        options.count[key]=options.count[key] ? options.count[key]+1 : 1
        return options.count[key]
    }

    let result= {...child}
    result.identifier=incr(child.nom)
    result.parent=parent
    result.enfants=[]
    if(child.type===Type.GROUP){
        let description=new Component("description","description",null,'',null,null,"description")
        description.identifier=incr("description")
        description.parent=result
        result.enfants.push(description)
    }
    child.enfants.forEach(enfant=>{
        result.enfants.push(computeTreeRecurs(result,enfant,options))
    })
    return result
}

export const flatChildren = (component) =>{
    return flatChildrenRecurs(computeTree(component),[])
}

const flatChildrenRecurs = (tree,result=[])=>{
    tree.enfants.forEach(child=>{
        if(child.type===Type.GROUP){
            flatChildrenRecurs(child,result)
        }else{
            result.push(child)
        }
    })
    return result
}

export default function AddGroupValues({component,rowValue,onChange,onSave,onCancel,dataTestKey,dataTestPrefix,childrenComponent,childrenReferentiel,onEditReferentiel}){
    const children = flatChildren(component)
    const [rowData,setRowData]=useState(rowValue)

    const handleGroupValuesChange = (childComponent,value)=>{
        let data = {...rowData}
        let child=childComponent
        let stack=[]
        while(child){
            stack.push(child)
            child=child.parent
        }
        if(stack.length > 1){
            let val=data
            while(stack.length > 0){
                let parent=stack.pop()
                if(stack.length > 0){
                    if(!val[parent.nom]){
                        val=val[parent.nom]={}
                    }else{
                        val=val[parent.nom]
                    }
                }else{
                    val[parent.nom]=value
                }
            }
        }else{
            data[childComponent.nom]=value
        }

        setRowData(data)
        if(onChange){
            onChange(data)
        }
    }

    const handleRowSave = ()=>{
        if(onSave){
            onSave(rowData)
        }
    }

    const handleRowCancel = ()=>{
        if(onCancel){
            onCancel()
        }
    }

    return (
        <>
            {children.map((child,index)=>(
                        <TableCell key={index+1}>
                            <GroupValuesItem 
                                onChange={(value)=>handleGroupValuesChange(child,value)}
                                component={child} 
                                componentValue={getChildValue(child,rowValue)}
                                dataTestPrefix={dataTestPrefix} 
                                dataTestKey={dataTestKey}
                                childrenComponent={childrenComponent}
                                onEditReferentiel={onEditReferentiel}
                                childrenReferentiel={childrenReferentiel}/>
                        </TableCell>
                    ))}
            
            <CellActions
                        dataTestKey="1"
                        dataTestPrefix="edit_row"
                        cancel={true}
                        edit= {false}
                        remove={false}
                        save={true}
                        onSave={handleRowSave}
                        onCancel={handleRowCancel}
            />
        </>
    )
}

AddGroupValues.propTypes = {
    dataTestKey:PropTypes.string.isRequired,
    dataTestPrefix:PropTypes.string.isRequired,
    component:PropTypes.any.isRequired,
    childrenComponent:PropTypes.object.isRequired,
    childrenReferentiel:PropTypes.object.isRequired,
    onChange:PropTypes.func,
    onSave:PropTypes.func,
    rowValue:PropTypes.object.isRequired
}
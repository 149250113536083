import React from 'react'
import * as PropTypes from 'prop-types'
import { Panel } from '../../common/panel'
import { CCHistoriqueList } from './cc-historique-list'
import { useTheme } from '@material-ui/core'

export function CcHistoriqueTab() {
  const theme = useTheme()
  return (
    <Panel value={0} index={0} dir={theme.direction}>
      <CCHistoriqueList />
    </Panel>
  )
}

CcHistoriqueTab.propTypes = {
}

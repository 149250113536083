import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import { HeaderTable } from './header-table'
import { DataTableRow } from './data-table-row'
import { SelectButton } from './select-button'
import { getComputedData } from '../../../../store/selectors/tableur-selectors'
import { amber, blue } from '@material-ui/core/colors/'
import useWindowDimensions from '../../tableur'
import { AddDataLineButton } from '../add-data-line-button'
import { Statut } from '../../../../store/constants/historique'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    flexWrap: 'nowrap',
    maxHeight: 'calc(100% - 300px)',
  },
  container: { maxHeight: '100%' },
  bleu_line: { backgroundColor: blue[100] },
  white_line: { backgroundColor: 'white' },
  bgcolor_component_not_published: { backgroundColor: blue[100] },
  red_line: { backgroundColor: '#e97070' },
  orange_line: { backgroundColor: amber[100] },
  table: { minWidth: 650 },
  row: { height: '36.5px' },
  empty: {
    backgroundColor: '#fafafa',
    borderBottom: 'none',
    borderTop: 'none',
    borderLeft: '1px solid #e3e3e3',
    borderRight: '1px solid #e3e3e3',
    padding: 3,
  },
})

export function DataTable() {
  const classes = useStyles()
  const pageSize = useSelector(state => state.tableurModule.pageSize)
  const { ouvert, statut } = useSelector(state => state.conventionModule.conventionCollective.ouvert)
  const hasData = useSelector(state => getComputedData(state).length > 0)
  const numbers = useMemo(() => [...Array(pageSize).keys()], [pageSize])
  const { height } = useWindowDimensions()
  return (
    <>
        <>
          <Grid container spacing={1} className={classes.root} direction="row">

              <>
                { (ouvert || statut !== Statut.PUBLIE) && <SelectButton />  }
                <Grid item style={{ overflow: 'hidden' }}>
                  <TableContainer component={Paper} style={{ maxHeight: height - 430 }}>
                    <Table stickyHeader size="small">
                      <HeaderTable classes={classes} />
                      {hasData && (
                        <TableBody>
                          {numbers.map(idx => (
                            <DataTableRow key={idx} classes={classes} idx={idx} />
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </>

          </Grid>

          <Grid container direction="row" justify="flex-end" style={{ maringTop: 50 }}>
            <Grid item>
              <AddDataLineButton />
            </Grid>
          </Grid>
        </>
    </>
  )
}

DataTable.propTypes = {}

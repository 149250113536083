import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { AddItemButtons } from './add-item-buttons'
import * as PropTypes from 'prop-types'

export const AddItemContainer = ({
  onSave,
  onCancel,
  onClick,
  addDataTest,
  saveDataTest,
  cancelDataTest,
  children,
  isDisabled,
  isSaveDisabled,
}) => {
  const [showList, setShowList] = useState(false)
  const showOrHideList = () => setShowList(!showList)
  return (
    <Grid container justify="flex-end" space={1} direction="row" alignItems="center">
      {showList && children}
      <AddItemButtons
        showList={showList}
        onClick={() => {
          showOrHideList()
          if (onClick) onClick()
        }}
        onSave={() => {
          showOrHideList()
          onSave()
        }}
        onCancel={() => {
          showOrHideList()
          if (onCancel) onCancel()
          else if (onClick) onClick()
        }}
        addDataTest={addDataTest}
        saveDataTest={saveDataTest}
        cancelDataTest={cancelDataTest ? cancelDataTest : 'annuler'}
        isDisabled={isDisabled}
        isSaveDisabled={isSaveDisabled}
      />
    </Grid>
  )
}

AddItemContainer.propTypes = {
  onSave: PropTypes.func.isRequired,
  addDataTest: PropTypes.string.isRequired,
  saveDataTest: PropTypes.string.isRequired,
}

import { openDB } from 'idb'
import { ThemeComponent } from '../store/constants/theme-component'
import { CcComponentValues } from '../store/constants/cc-component-values'
import {
  CC_ENDPOINT,
  CC_HISTORIQUE_ENDPOINT,
  CC_THEME_DATA_ENDPOINT,
  CC_THEME_ENDPOINT,
  CC_THEME_INPUTS_ENDPOINT,
  CC_THEME_INPUTS_VALUES_ENDPOINT,
  CC_THEME_OUTPUTS_ENDPOINT,
  CC_THEME_OUTPUTS_VALUES_ENDPOINT,
  COMPONENTS_ENDPOINT,
  //COMPONENTS_CANBEUPDATED_ENDPOINT,
  DATA_ENDPOINT,
  INPUT_ENDPOINT,
  LOGIN_ENDPOINT,
  OUTPUT_ENDPOINT,
  REFERENTIEL_ENDPOINT,
  REFERENTIEL_VALUES_ENDPOINT,
  STATUT_ENDPOINT,
  THEME_ENDPOINT,
  THEME_ENDPOINT_PUBLISH_VERSION,
  THEME_INPUTS_ENDPOINT,
  THEME_OUTPUTS_ENDPOINT,
  VERSION_ENDPOINT,
  VERSION_NEXT_ENDPOINT,
  VERSION_LAST_ENDPOINT,
  VERSION_THEME_ENDPOINT,
  VERSION_CHANGELOGS_ENDPOINT,
  CHANGELOGS_ENDPOINT,
  HISTORIQUE_ENDPOINT,
  USER_ENDPOINT,
  VERSION_LAST_PUBLISHED_ENDPOINT,
  VERSION_LATEST_ENDPOINT,
  LOGOUT_ENDPOINT,
  WORKFLOW_ENDPOINT,
  WORKFLOW_VALIDATION_ENDPOINT,
  WORKFLOW_CANCEL_ENDPOINT,
  TRANSFORMATION_RULE_ENDPOINT
} from './endpoints'
import { CcData } from '../store/constants/cc-data'
import { ReferentielValeur } from '../store/constants/referentiel'
import { Type } from '../store/constants/type'
import { VALUES_ENDPOINT } from '../store/actions/values-action'
import { HistoriqueInfo } from '../store/constants/historique-info'
import { CcTheme } from '../store/constants/cc-theme'
import { Statut, ChangeLog, getChangeLogType } from '../store/constants/historique'
import { deleteComponentByName } from '../store/actions/component-action'
import { getCCWithComponent } from '../store/actions/convention-collective-action'
import _ from 'lodash'
import Doublon, { TypeDoublon } from '../store/constants/Doublons'
import { Version } from '../store/constants/version'
import store from '../store/store'
import { logOut } from '../store/actions/login-state-action'
import { WorkflowStatut } from '../store/constants/ModelInstanceWorkFlow'

export const CC_THEME_DATAWF_ENDPOINT = {
  endpoint: 'conventions-collectives_themes_datawf',
  sexe: 'F',
  singulier: 'données',
  pluriel: 'données',
}

export const DATAWF_ENDPOINT = {
  endpoint: 'datawf',
  sexe: 'F',
  singulier: 'donnée',
  pluriel: 'données',
}

function buildIfNeeded(oldVersion, version, db, name, keyPath, autoIncrement) {
  if (oldVersion < version) {
    db.createObjectStore(name, {
      // The 'id' property of the object will be the key.
      keyPath: keyPath,
      // If it isn't explicitly set, create a value by auto incrementing.
      autoIncrement,
    })
  }
}

const getDB = async () => {
  return openDB('payeDB', 18, {
    upgrade(db, oldVersion) {
      // Create a store of objects
      buildIfNeeded(oldVersion, 1, db, 'inputs', 'nom')
      buildIfNeeded(oldVersion, 2, db, 'referentiels', 'nom')
      buildIfNeeded(oldVersion, 3, db, 'outputs', 'nom')
      buildIfNeeded(oldVersion, 4, db, 'conventions-collectives', 'idcc')
      buildIfNeeded(oldVersion, 5, db, 'themes', 'nom')
      buildIfNeeded(oldVersion, 6, db, 'conventions-collectives_themes', 'key')
      buildIfNeeded(oldVersion, 7, db, 'versions', 'nom')
      buildIfNeeded(oldVersion, 8, db, 'components', 'nom')
      buildIfNeeded(oldVersion, 9, db, 'themes_inputs', 'key')
      buildIfNeeded(oldVersion, 9, db, 'themes_outputs', 'key')
      buildIfNeeded(oldVersion, 10, db, 'conventions-collectives_themes_inputs', 'key')
      buildIfNeeded(oldVersion, 10, db, 'conventions-collectives_themes_outputs', 'key')
      buildIfNeeded(oldVersion, 11, db, 'conventions-collectives_themes_inputs_values', 'key')
      buildIfNeeded(oldVersion, 11, db, 'conventions-collectives_themes_outputs_values', 'key')
      buildIfNeeded(oldVersion, 12, db, 'conventions-collectives_themes_data', 'key')
      buildIfNeeded(oldVersion, 12, db, 'conventions-collectives_themes_datawf', 'key')
      buildIfNeeded(oldVersion, 12, db, 'referentials', 'id')
      buildIfNeeded(oldVersion, 12, db, 'referentials_values', 'key')
      buildIfNeeded(oldVersion, 13, db, 'conventions-collectives_data', 'idcc')
      buildIfNeeded(oldVersion, 13, db, 'user', 'username')
      buildIfNeeded(oldVersion, 14, db, 'conventions-collectives_historiques', 'key')
      buildIfNeeded(oldVersion, 15, db, 'historiques', 'key')
      buildIfNeeded(oldVersion, 16, db, 'modele-workflow', 'nom')
      buildIfNeeded(oldVersion, 16, db, 'workflow', 'nom')
      buildIfNeeded(oldVersion, 16, db, 'versions_themes', 'key')
      buildIfNeeded(oldVersion, 16, db, 'versions_changelogs', 'key')
      buildIfNeeded(oldVersion, 17, db, 'access', 'client')
      buildIfNeeded(oldVersion, 18, db, 'transformation-rules', 'id')
    },
  })
}

const getApi = async () => {}

function setConnectedUser(user) {
  if (user) localStorage.setItem('Authentication', JSON.stringify(user))
  else localStorage.removeItem('Authentication')
}

function getConnectedUser() {
  const userStr = localStorage.getItem('Authentication')
  if (userStr) return JSON.parse(userStr)
  else return null
}

function getByIdJSONFormat(res, endpoint, db) {
  switch (endpoint) {
    case COMPONENTS_ENDPOINT.endpoint:
      return getComponentExpectedJSON(res)
    case CC_THEME_INPUTS_ENDPOINT.endpoint:
      return getComponentExpectedJSON(res)
    case CC_THEME_OUTPUTS_ENDPOINT.endpoint:
      return getComponentExpectedJSON(res)
    case CC_ENDPOINT.endpoint:
      res.alreadyPublished = false
      return getChildren(CC_ENDPOINT.endpoint, res.idcc, HISTORIQUE_ENDPOINT.endpoint).then(response => {
        for (let h of response.data) {
          if (h.statut.name === Statut.PUBLIE.name) {
            res.alreadyPublished = true
            break
          }
        }
        return res
      })
    case 'conventions-collectives_historiques':
      return db.getAll(CC_THEME_DATA_ENDPOINT.endpoint).then(data => {
        const datalines = data.filter(d => d.key.startsWith(res.idcc) && d.historique === res.nom)
        res.creations = datalines.filter(d => d.action === 'creation').length
        res.modifications = datalines.filter(d => d.action === 'modification').length
        res.suppressions = datalines.filter(d => d.action === 'suppression').length
        res.themes = Array.from(new Set(datalines.map(d => d.key.split('_')[1])))
        return res
      })
    default:
      return res
  }
}

function getThemeJSON(r) {
  return { ...r, name: r.nom }
}

function getJSONFormat(res, endpoint) {
  switch (endpoint) {
    case COMPONENTS_ENDPOINT.endpoint:
      return res.map(r => getComponentExpectedJSON(r))
    case THEME_ENDPOINT.endpoint:
      return res.map(r => getThemeJSON(r))
    default:
      return res
  }
}

const get = async (apiEndpoint, headers) => {
  if (
    apiEndpoint === VERSION_NEXT_ENDPOINT.endpoint ||
    apiEndpoint === VERSION_LAST_ENDPOINT.endpoint ||
    apiEndpoint == VERSION_LATEST_ENDPOINT.endpoint ||
    apiEndpoint === VERSION_LAST_PUBLISHED_ENDPOINT.endpoint
  ) {
    return get(VERSION_ENDPOINT.endpoint).then(async data => {
      const today = new Date()
      let newVersions = data.data
        .filter(version => /^v?[0-9]+\.[0-9]+$/.test(version.nom))
        .map(version => {
          return { version: version, numValue: parseFloat(version.nom), statut: version.statut }
        })
        .sort((a, b) => {
          if (a.statut.name === b.statut.name) {
            if (a.numValue < b.numValue) {
              return 1
            } else if (a.numValue > b.numValue) {
              return -1
            } else {
              return 0
            }
          } else if (a.statut.name === 'ENCOURS_MODIFICATION') {
            return -1
          } else {
            return 1
          }
        })
      if (apiEndpoint === VERSION_LAST_PUBLISHED_ENDPOINT.endpoint) return { status: 200, data: newVersions[1].version }
      if (apiEndpoint === VERSION_LATEST_ENDPOINT.endpoint) return { status: 200, data: newVersions[0].version}
      if (newVersions.length > 0) {
        if (newVersions[0].statut.name === 'ENCOURS_MODIFICATION') {
          return { status: 200, data: newVersions[0].version }
        } else {
          let version = new Version('' + (newVersions[0].numValue + 0.1), '', Statut.MODIFICATION_EN_COURS, '')
          return { status: 200, data: version }
        }
      } else {
        let version = new Version('2.1', '', Statut.MODIFICATION_EN_COURS, '')
        return { status: 200, data: version }
      }
    })
  } else if (apiEndpoint === USER_ENDPOINT.endpoint) {
    const user = getConnectedUser()
    if (user) {
      return { status: 200, data: user }
    } else {
      store.dispatch(logOut())
      return Promise.reject({})
    }
  } else if (apiEndpoint === LOGOUT_ENDPOINT.endpoint) {
    setConnectedUser()
    return { status: 200, data: '' }
  }
  const db = await getDB()
  return await db
    .getAll(apiEndpoint)
    .then(response => response || [])
    .then(res => getJSONFormat(res, apiEndpoint))
    .then(data => {
      return { status: data && data.length > 0 ? 200 : 204, data: data }
    })
    .catch(err => {
      console.error("Error for endpoint",apiEndpoint,err)
      throw new Error('500')
    })
}

const getFather = async (childEndpoint, id, apiEndpoint) => {
  const db = await getDB()
  const storeName = getStoreName(apiEndpoint, childEndpoint)
  return await db
    .getAll(storeName)
    .then(response => response || [])
    .then(data => filterFather(storeName, data, id))
    .then(data => _.uniq(data.map(d => d.key.split('_')[0])))
    .then(data => Promise.all(data.map(d => getById(apiEndpoint, d))))
    .then(data => data.map(d => d.data))
    .then(data => ({ status: 200, data: data }))
    .catch(err => {
      console.error(err)
      throw new Error('500')
    })
}

const getGrandFather = async (grandChildrenEndpoint, id, childEndpoint, childId, apiEndpoint) => {
  const db = await getDB()
  const storeName = getStoreName(apiEndpoint, childEndpoint, grandChildrenEndpoint)
  return await db
    .getAll(storeName)
    .then(response => response || [])
    .then(data => filterFather(storeName, data, id, childId))
    .then(data => _.uniq(data.map(d => d.key.split('_')[0])))
    .then(data => Promise.all(data.map(d => getById(apiEndpoint, d))))
    .then(data => data.map(d => d.data))
    .then(data => ({ status: 200, data: data }))
    .catch(err => {
      console.error(err)
      throw new Error('500')
    })
}

const getChildren = async (apiEndpoint, id, childrenEndpoint) => {
  const db = await getDB()
  const storeName = getStoreName(apiEndpoint, childrenEndpoint)
  return await db
    .getAll(storeName)
    .then(response => response || [])
    .then(data => filterChildren(storeName, data, id))
    .then(data => ({ status: 200, data: data }))
    .catch(err => {
      console.error(err)
      throw new Error('500')
    })
}

const getGrandchild = async (apiEndpoint, id, childEndpoint, childId, grandChildrenEndpoint, grandchildId) => {
  const storeName = getStoreName(apiEndpoint, childEndpoint, grandChildrenEndpoint)
  const dataId = `${id}_${childId}${grandchildId ? '_' + grandchildId : ''}`
  return await getById(storeName, dataId)
}

const getGrandchildren = async (
  apiEndpoint,
  id,
  childEndpoint,
  childId,
  grandChildrenEndpoint,
  grandChildId,
  grandGrandChildrenEndpoint
) => {
  if (grandChildrenEndpoint === DATA_ENDPOINT.endpoint) {
    const workflows = (await get(WORKFLOW_ENDPOINT.endpoint)).data
    if (workflows) {
      const workflow = workflows.find(
        workflow =>
          workflow.statut &&
          workflow.statut[childId.toUpperCase()] &&
          workflow.statut[childId.toUpperCase()].name === WorkflowStatut.EN_COURS.name &&
          workflow.idcc === id
      )
      if (workflow) {
        return getGrandchildren(
          apiEndpoint,
          id,
          childEndpoint,
          childId,
          DATAWF_ENDPOINT.endpoint,
          grandChildId,
          grandGrandChildrenEndpoint
        )
      }
    }
  }

  const db = await getDB()
  const storeName = getStoreName(apiEndpoint, childEndpoint, grandChildrenEndpoint, grandGrandChildrenEndpoint)
  const idToFind = `${id}_${childId}${grandChildId ? '_' + grandChildId : ''}`
  return await db
    .getAll(storeName)
    .then(response => response || [])
    .then(data => filterChildren(storeName, data, idToFind))
    .then(data => {
      return { status: 200, data: data }
    })
    .catch(err => {
      console.error(err)
      throw new Error('500')
    })
}

function addChild(db, storeName, childPayload) {
  return db
    .put(storeName, childPayload)
    .then(() => {
      return { status: 200, data: childPayload }
    })
    .catch(err => {
      if (err) {
        console.log(err)
        throw new Error('500')
      }
    })
}

const migrateData = async function(cc, theme, oldComponent, newComponent) {
  if (oldComponent.type === Type.TEXT.name && newComponent.type.name !== oldComponent.type) {
    migrateDataText(cc, theme, oldComponent, newComponent)
  } else if (oldComponent.type === Type.LIST.name && newComponent.type.name !== oldComponent.type) {
    migrateDataList(cc, theme, oldComponent, newComponent)
  }
}
const migrateDataList = async function(cc, theme, oldComponent, newComponent) {
  if (oldComponent.type === Type.LIST.name && newComponent.type.name !== oldComponent.type) {
    let dataLines = (
      await getGrandchildren(CC_ENDPOINT.endpoint, cc.idcc, THEME_ENDPOINT.endpoint, theme.nom, DATA_ENDPOINT.endpoint)
    ).data
    for (let i = 0; i < dataLines.length; i++) {
      let dataLine = dataLines[i]
      if (dataLine.inputs[oldComponent.nom]) {
        if (newComponent.type.name === Type.LIST_EXCLUSION.name) {
          dataLine.inputs[oldComponent.nom] = { inclus: dataLine.inputs[oldComponent.nom], exclus: [] }
        }
      }
      if (dataLine.outputs[oldComponent.nom]) {
        if (newComponent.type.name === Type.LIST_EXCLUSION.name) {
          dataLine.outputs[oldComponent.nom] = { inclus: dataLine.outputs[oldComponent.nom], exclus: [] }
        }
      }
    }
    await patchChild(
      dataLines,
      CC_ENDPOINT.endpoint,
      cc.idcc,
      THEME_ENDPOINT.endpoint,
      theme.nom,
      DATA_ENDPOINT.endpoint
    )
  }
}

const migrateDataText = async function(cc, theme, oldComponent, newComponent) {
  if (oldComponent.type === Type.TEXT.name && newComponent.type.name !== oldComponent.type) {
    let dataLines = (
      await getGrandchildren(CC_ENDPOINT.endpoint, cc.idcc, THEME_ENDPOINT.endpoint, theme.nom, DATA_ENDPOINT.endpoint)
    ).data
    var unitType
    if (newComponent.type.name === Type.LIST.name || newComponent.type.name === Type.LIST_EXCLUSION.name) {
      unitType = (await getById(COMPONENTS_ENDPOINT.endpoint, newComponent.unit)).data.type
    }
    for (let i = 0; i < dataLines.length; i++) {
      let dataLine = dataLines[i]
      if (dataLine.inputs[oldComponent.nom]) {
        if (newComponent.type.name === Type.LIST.name) {
          if (unitType === Type.TEXT.name) dataLine.inputs[oldComponent.nom] = [dataLine.inputs[oldComponent.nom]]
          else dataLine.inputs[oldComponent.nom] = []
        } else if (newComponent.type.name === Type.LIST_EXCLUSION.name) {
          if (unitType === Type.TEXT.name)
            dataLine.inputs[oldComponent.nom] = { inclus: [dataLine.inputs[oldComponent.nom]], exclus: [] }
          else dataLine.inputs[oldComponent.nom] = { inclus: [], exclus: [] }
        }
      }
      if (dataLine.outputs[oldComponent.nom]) {
        if (newComponent.type.name === Type.LIST.name) {
          if (unitType === Type.TEXT.name) dataLine.outputs[oldComponent.nom] = [dataLine.outputs[oldComponent.nom]]
          else dataLine.outputs[oldComponent.nom] = []
        } else if (newComponent.type.name === Type.LIST_EXCLUSION.name) {
          if (unitType === Type.TEXT.name)
            dataLine.outputs[oldComponent.nom] = { inclus: [dataLine.outputs[oldComponent.nom]], exclus: [] }
          else dataLine.outputs[oldComponent.nom] = { inclus: [], exclus: [] }
        }
      }
    }
    await patchChild(
      dataLines,
      CC_ENDPOINT.endpoint,
      cc.idcc,
      THEME_ENDPOINT.endpoint,
      theme.nom,
      DATA_ENDPOINT.endpoint
    )
  }
}
const migrateParentData = async (inoutEndpoint, parentComponent, oldChildComponent, newChildComponent) => {
  if (oldChildComponent.type === Type.TEXT.name && newChildComponent.type.name !== oldChildComponent.type) {
    migrateParentDataText(inoutEndpoint, parentComponent, oldChildComponent, newChildComponent)
  } else if (oldChildComponent.type === Type.LIST.name && newChildComponent.type.name !== oldChildComponent.type) {
    migrateParentDataList(inoutEndpoint, parentComponent, oldChildComponent, newChildComponent)
  }
}

const migrateParentDataList = async (inoutEndpoint, parentComponent, oldChildComponent, newChildComponent) => {
  if (oldChildComponent.type === Type.LIST.name && newChildComponent.type.name !== oldChildComponent.type) {
    const themes = (await getFather(inoutEndpoint.endpoint, parentComponent.nom, THEME_ENDPOINT.endpoint)).data
    const ccs = (
      await getGrandFather(
        inoutEndpoint.endpoint,
        parentComponent.nom,
        THEME_ENDPOINT.endpoint,
        null,
        CC_ENDPOINT.endpoint
      )
    ).data

    for (let c = 0; c < ccs.length; c++) {
      for (let t = 0; t < themes.length; t++) {
        let dataLines = (
          await getGrandchildren(
            CC_ENDPOINT.endpoint,
            ccs[c].idcc,
            THEME_ENDPOINT.endpoint,
            themes[t].nom,
            DATA_ENDPOINT.endpoint
          )
        ).data
        for (let i = 0; i < dataLines.length; i++) {
          let dataLine = dataLines[i]
          if (dataLine.inputs[parentComponent.nom] && dataLine.inputs[parentComponent.nom][oldChildComponent.nom]) {
            if (newChildComponent.type.name === Type.LIST_EXCLUSION.name) {
              dataLine.inputs[parentComponent.nom][oldChildComponent.nom] = {
                inclus: dataLine.inputs[parentComponent.nom][oldChildComponent.nom],
                exclus: [],
              }
            }
          }

          if (dataLine.outputs[parentComponent.nom] && dataLine.outputs[parentComponent.nom][oldChildComponent.nom]) {
            if (newChildComponent.type.name === Type.LIST_EXCLUSION.name) {
              dataLine.outputs[parentComponent.nom][oldChildComponent.nom] = {
                inclus: dataLine.outputs[parentComponent.nom][oldChildComponent.nom],
                exclus: [],
              }
            }
          }
        }
        await patchChild(
          dataLines,
          CC_ENDPOINT.endpoint,
          ccs[c].idcc,
          THEME_ENDPOINT.endpoint,
          themes[t].nom,
          DATA_ENDPOINT.endpoint
        )
      }
    }
  }
}

const migrateParentDataText = async (inoutEndpoint, parentComponent, oldChildComponent, newChildComponent) => {
  if (oldChildComponent.type === Type.TEXT.name && newChildComponent.type.name !== oldChildComponent.type) {
    const themes = (await getFather(inoutEndpoint.endpoint, parentComponent.nom, THEME_ENDPOINT.endpoint)).data
    const ccs = (
      await getGrandFather(
        inoutEndpoint.endpoint,
        parentComponent.nom,
        THEME_ENDPOINT.endpoint,
        null,
        CC_ENDPOINT.endpoint
      )
    ).data
    var unitType
    if (newChildComponent.type.name === Type.LIST.name || newChildComponent.type.name === Type.LIST_EXCLUSION.name) {
      unitType = (await getById(COMPONENTS_ENDPOINT.endpoint, newChildComponent.unit)).data.type
    }
    for (let c = 0; c < ccs.length; c++) {
      for (let t = 0; t < themes.length; t++) {
        let dataLines = (
          await getGrandchildren(
            CC_ENDPOINT.endpoint,
            ccs[c].idcc,
            THEME_ENDPOINT.endpoint,
            themes[t].nom,
            DATA_ENDPOINT.endpoint
          )
        ).data
        for (let i = 0; i < dataLines.length; i++) {
          let dataLine = dataLines[i]
          if (dataLine.inputs[parentComponent.nom] && dataLine.inputs[parentComponent.nom][oldChildComponent.nom]) {
            if (newChildComponent.type.name === Type.LIST.name) {
              if (unitType === Type.TEXT.name)
                dataLine.inputs[parentComponent.nom][oldChildComponent.nom] = [
                  dataLine.inputs[parentComponent.nom][oldChildComponent.nom],
                ]
              else dataLine.inputs[parentComponent.nom][oldChildComponent.nom] = []
            } else if (newChildComponent.type.name === Type.LIST_EXCLUSION.name) {
              if (unitType === Type.TEXT.name)
                dataLine.inputs[parentComponent.nom][oldChildComponent.nom] = {
                  inclus: [dataLine.inputs[parentComponent.nom][oldChildComponent.nom]],
                  exclus: [],
                }
              else dataLine.inputs[parentComponent.nom][oldChildComponent.nom] = { inclus: [], exclus: [] }
            }
          }
          if (dataLine.outputs[oldChildComponent.nom]) {
            if (newChildComponent.type.name === Type.LIST.name) {
              if (unitType === Type.TEXT.name)
                dataLine.outputs[parentComponent.nom][oldChildComponent.nom] = [
                  dataLine.outputs[parentComponent.nom][oldChildComponent.nom],
                ]
              else dataLine.outputs[parentComponent.nom][oldChildComponent.nom] = []
            } else if (newChildComponent.type.name === Type.LIST_EXCLUSION.name) {
              if (unitType === Type.TEXT.name)
                dataLine.outputs[parentComponent.nom][oldChildComponent.nom] = {
                  inclus: [dataLine.outputs[parentComponent.nom][oldChildComponent.nom]],
                  exclus: [],
                }
              else dataLine.outputs[parentComponent.nom][oldChildComponent.nom] = { inclus: [], exclus: [] }
            }
          }
        }
        await patchChild(
          dataLines,
          CC_ENDPOINT.endpoint,
          ccs[c].idcc,
          THEME_ENDPOINT.endpoint,
          themes[t].nom,
          DATA_ENDPOINT.endpoint
        )
      }
    }
  }
}

const patch = async (apiEndpoint, id, childrenEndpoint, payload) => {
  const db = await getDB()
  if (apiEndpoint === COMPONENTS_ENDPOINT.endpoint) {
    if (payload.version) {
      let today = new Date()
      let day = today.getDate()
      if (day < 10) day = '0' + day
      else day = '' + day
      let month = today.getMonth() + 1
      if (month < 10) month = '0' + month
      else month = '' + month
      const component = payload.component
      const state = store.getState()
      const user = state.user.user
      const versionNom = payload.version
      const update = ChangeLog.updateComponent(
        versionNom,
        component.nom,
        `${day}/${month}/${today.getFullYear()}`,
        user.username
      )

      let version = (await getById(VERSION_ENDPOINT.endpoint, versionNom)).data
      if (version.nom !== versionNom) {
        version = new Version(versionNom, '', Statut.MODIFICATION_EN_COURS, null)
        await post(VERSION_ENDPOINT.endpoint, version)
      }
      await postChildren([update], VERSION_ENDPOINT.endpoint, versionNom, CHANGELOGS_ENDPOINT.endpoint)
    }
    if (payload.component) payload = payload.component
    let oldComponent = (await getById(COMPONENTS_ENDPOINT.endpoint, id)).data
    var updateInputOutput = async function(inoutEndpoint) {
      const themes = (await getFather(inoutEndpoint.endpoint, id, THEME_ENDPOINT.endpoint)).data
      for (let i = 0; i < themes.length; i++) {
        await patch(THEME_ENDPOINT.endpoint, themes[i].nom, inoutEndpoint.endpoint, [payload]).catch(e =>
          console.log(e)
        )
      }
      const ccs = (
        await getGrandFather(inoutEndpoint.endpoint, id, THEME_ENDPOINT.endpoint, null, CC_ENDPOINT.endpoint)
      ).data
      for (let i = 0; i < ccs.length; i++) {
        for (let t = 0; t < themes.length; t++) {
          await patchChild(
            [payload],
            CC_ENDPOINT.endpoint,
            ccs[i].idcc,
            THEME_ENDPOINT.endpoint,
            themes[t].nom,
            inoutEndpoint.endpoint
          ).catch(e => console.log(e))
          await migrateData(ccs[i], themes[i], oldComponent, payload)
        }
      }
    }

    let components = await db.getAll(COMPONENTS_ENDPOINT.endpoint)
    let parents = components.filter(comp => comp.enfants && comp.enfants.find(c => c.nom === oldComponent.nom))
    for (let p = 0; p < parents.length; p++) {
      let parent = parents[p]
      for (let i = 0; i < parent.enfants.length; i++) {
        if (parent.enfants[i].nom === payload.nom) {
          parent.enfants[i] = payload
        }
      }
      await patch(COMPONENTS_ENDPOINT.endpoint, parent.nom, null, parent)
      await migrateParentData(INPUT_ENDPOINT, parent, oldComponent, payload)
      await migrateParentData(OUTPUT_ENDPOINT, parent, oldComponent, payload)
    }
    await updateInputOutput(INPUT_ENDPOINT)
    await updateInputOutput(OUTPUT_ENDPOINT)
  } else if (apiEndpoint === THEME_ENDPOINT.endpoint && !childrenEndpoint && payload.theme) {
    if (payload.version) {
      const date = new Date()
      const theme = payload.theme
      const state = store.getState()
      const user = state.user.user
      const versionNom = payload.version
      const update = ChangeLog.updateTheme(
        versionNom,
        theme,
        `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
        user.login
      )

      let version = (await getById(VERSION_ENDPOINT.endpoint, versionNom)).data
      if (version.nom !== versionNom) {
        version = new Version(versionNom, '', Statut.MODIFICATION_EN_COURS, null)
        await post(VERSION_ENDPOINT.endpoint, version)
      }
      await postChildren([update], VERSION_ENDPOINT.endpoint, versionNom, CHANGELOGS_ENDPOINT.endpoint)
    }
    payload = payload.theme
  } else if (
    apiEndpoint === THEME_ENDPOINT.endpoint &&
    (childrenEndpoint === INPUT_ENDPOINT.endpoint || childrenEndpoint === OUTPUT_ENDPOINT.endpoint)
  ) {
    if (payload.length > 0 && payload[0].version) {
      if ([...new Set(payload.map(comp => comp.version))].length !== 1) {
        throw new Error('Ca not update theme components with several version')
      }
      const date = new Date()
      const state = store.getState()
      const user = state.user.user
      const versionNom = payload[0].version
      const components = payload
      const update = ChangeLog.addComposantTheme(
        versionNom,
        id,
        components.map(comp => comp.nom),
        `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
        user.login
      )

      let version = (await getById(VERSION_ENDPOINT.endpoint, versionNom)).data
      if (version.nom !== versionNom) {
        version = new Version(versionNom, '', Statut.MODIFICATION_EN_COURS, null)
        await post(VERSION_ENDPOINT.endpoint, version)
      }
      await postChildren([update], VERSION_ENDPOINT.endpoint, versionNom, CHANGELOGS_ENDPOINT.endpoint)
    }
  } else if (apiEndpoint == THEME_ENDPOINT_PUBLISH_VERSION.endpoint) {
    let today = new Date()
    let day = today.getDate()
    if (day < 10) day = '0' + day
    else day = '' + day
    let month = today.getMonth() + 1
    if (month < 10) month = '0' + month
    else month = '' + month
    const versionNom = id
    const themesNoms = payload
    let versionStore = null
    try {
      versionStore = await getById(VERSION_ENDPOINT.endpoint, versionNom)
    } catch (err) {}

    if (versionStore && versionStore.data && Object.keys(versionStore.data).length !== 0) {
      versionStore = versionStore.data
      await patch(VERSION_ENDPOINT.endpoint, versionNom, null, {
        nom: versionNom,
        datePublication: versionStore.datePublication,
        statut: versionStore.statut,
      })
    } else {
      await post(VERSION_ENDPOINT.endpoint, {
        nom: versionNom,
        datePublication: day + '/' + month + '/' + today.getFullYear(),
        statut: Statut.PUBLIE,
      })
    }

    for (let themeName of themesNoms) {
      let theme = (await getById(THEME_ENDPOINT.endpoint, themeName)).data
      theme.statut = Statut.PUBLISHABLE
      theme.createdVersion = versionNom
      await patch(THEME_ENDPOINT.endpoint, theme.nom, null, theme)

      await patchChild(theme, VERSION_ENDPOINT.endpoint, versionNom, THEME_ENDPOINT.endpoint, theme.nom)
    }
    const state = store.getState()
    const user = state.user.user
    const date = new Date()
    const update = ChangeLog.publishTheme(
      versionNom,
      themesNoms,
      `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
      user.login
    )
    await postChildren([update], VERSION_ENDPOINT.endpoint, versionNom, CHANGELOGS_ENDPOINT.endpoint)

    return { status: 200, data: {} }
  }
  const storeName = childrenEndpoint === VALUES_ENDPOINT ? apiEndpoint : getStoreName(apiEndpoint, childrenEndpoint)
  const childrenPayload = await getChildrenPayload(
    storeName,
    id,
    payload,
    childrenEndpoint === VALUES_ENDPOINT ? await getById(REFERENTIEL_ENDPOINT.endpoint, id) : null,
    db
  )
  return storeName === STATUT_ENDPOINT.endpoint
    ? { status: 200 }
    : {
        status: 200,
        data: Array.isArray(childrenPayload)
          ? childrenPayload.forEach(cp => addChild(db, storeName, cp))
          : addChild(db, storeName, childrenPayload),
      }
}

function getGrandChildId(id, childId, grandchildId) {
  return `${id}${childId ? '_' + childId : ''}${grandchildId ? '_' + grandchildId : ''}`
}

const patchChild = async (
  payload,
  apiEndpoint,
  id,
  childEndpoint,
  childId,
  grandchildrenEndpoint,
  grandchildId,
  grandGrandchildrenEndpoint
) => {
  const db = await getDB()
  let storeName = getStoreName(apiEndpoint, childEndpoint, grandchildrenEndpoint, grandGrandchildrenEndpoint)
  if (storeName === CC_THEME_DATA_ENDPOINT.endpoint) {
    const workflows = (await get(WORKFLOW_ENDPOINT.endpoint)).data
    if (workflows) {
      const workflow = workflows.find(
        workflow =>
          workflow.statut &&
          workflow.statut[childId.toUpperCase()] &&
          workflow.statut[childId.toUpperCase()].name === WorkflowStatut.EN_COURS.name &&
          workflow.idcc === id
      )
      if (workflow) {
        storeName = CC_THEME_DATAWF_ENDPOINT.endpoint
      }
    }
  }else if(storeName === versionStatut){
    const version=(await getById(VERSION_ENDPOINT.endpoint,id)).data
    version.statut = Statut.PUBLIE
    let today = new Date()
    let day=today.getDate()
    if(day<10)
        day="0"+day
    else
        day=""+day
    let month=today.getMonth()+1
        if(month<10)
            month="0"+month
        else
            month=""+month
    version.datePublication=`${day}/${month}/${today.getFullYear()}`
    return await put(VERSION_ENDPOINT.endpoint,version)
  }

  const childrenPayload = await getChildrenPayload(
    storeName,
    getGrandChildId(id, childId, grandchildId),
    payload,
    null,
    db
  )
  if (childrenPayload.status === 'DOUBLONS') return Promise.reject(childrenPayload)
  if(storeName === conventionsCollectivesThemesStatut || storeName === conventionsCollectivesHistoriquesStatut)
    {
      const historiqueEncours=await db.getAll(CC_HISTORIQUE_ENDPOINT.endpoint).then(data=>data.filter(d=>d.key.startsWith(id)).filter(d=>d.statut.name === Statut.MODIFICATION_EN_COURS.name))
      for(let h of historiqueEncours){
        h.statut = Statut.VALIDE
        await db.put(CC_HISTORIQUE_ENDPOINT.endpoint, h)
      }
      return { status: 200, data: payload }
    }
    else if(Array.isArray(childrenPayload))
      childrenPayload.forEach(cp => addChild(db, storeName, cp))
    else addChild(db, storeName, childrenPayload)
}

const getById = async (apiEndpoint, id) => {
  const db = await getDB()
  if(TRANSFORMATION_RULE_ENDPOINT.endpoint == apiEndpoint){
    return db.getAll(TRANSFORMATION_RULE_ENDPOINT.endpoint)
      .then(data=>data.filter(r=>r.component === id))
      .then(data=> {return {status : 200,data:data}})


  }
  return db
    .get(apiEndpoint, id)
    .then(res => getByIdJSONFormat(res, apiEndpoint, db))
    .then(response => response || {})
    .then(data => {
      return { status: 200, data: data }
    })
    .catch(err => {
      console.log(apiEndpoint)
      console.log(id)
      console.error(err)
      throw new Error('500')
    })
}

async function updateParents(apiEndpoint, payload) {
  try {
    if (apiEndpoint === CC_ENDPOINT.endpoint) {
      const allCC = await get(apiEndpoint)
      if (payload.idccNational) {
        const cc = await getById(apiEndpoint, payload.idccNational)
        const regional = cc.data.ccRegionales ? cc.data.ccRegionales : []
        regional.filter(c => c.idcc === payload.idcc).length ||
          (await put(apiEndpoint, { ...cc.data, ccRegionales: [...regional, payload] }, true))
      } else {
        allCC.data.forEach(cc => {
          if (cc.ccRegionales && cc.ccRegionales.filter(c => c.idcc === payload.idcc))
            put(apiEndpoint, { ...cc, ccRegionales: cc.ccRegionales.filter(c => c.idcc !== payload.idcc) }, true)
        })
      }
      if (payload.idccRattachement) {
        const cc = await getById(apiEndpoint, payload.idccRattachement)
        const rattaches = cc.data.ccRattaches ? cc.data.ccRattaches : []
        rattaches.filter(r => r.idcc === payload.idccRattachement).length ||
          (await put(
            apiEndpoint,
            {
              ...cc.data,
              ccRattaches: [...rattaches, payload],
            },
            true
          ))
      } else {
        allCC.data.forEach(cc => {
          if (cc.ccRattaches && cc.ccRattaches.filter(c => c.idcc === payload.idcc))
            put(apiEndpoint, { ...cc, ccRattaches: cc.ccRattaches.filter(c => c.idcc !== payload.idcc) }, true)
        })
      }
    }
  } catch (e) {
    console.log(e)
    console.error(e)
  }
}

const post = async (apiEndpoint, payload) => {
  if (apiEndpoint === LOGIN_ENDPOINT.endpoint) {
    return getById('user', payload.get('username')).then(response => {
      if (response.data.password === payload.get('password')) {
        setConnectedUser(response.data)
        return Promise.resolve()
      } else {
        return Promise.reject({ status: 401, data: 'loginFailed' })
      }
    })
  } else if (apiEndpoint == USER_ENDPOINT.endpoint && (!payload || !payload.password)) {
    setConnectedUser()
    return { status: 200, data: '' }
  } else if (apiEndpoint == VERSION_ENDPOINT.endpoint) {
    let ids = await get(VERSION_ENDPOINT.endpoint)
    ids = ids.data.map(v => v.id).sort()
    if (ids.length > 0) {
      payload.id = ids[ids.length - 1] + 1
    } else {
      payload.id = 1
    }
  } else if (apiEndpoint == WORKFLOW_VALIDATION_ENDPOINT.endpoint) {
    const workflow = (await getById(WORKFLOW_ENDPOINT.endpoint, payload.workflow)).data
    const data = (
      await getGrandchildren(
        CC_ENDPOINT.endpoint,
        workflow.idcc,
        THEME_ENDPOINT.endpoint,
        payload.theme,
        DATAWF_ENDPOINT.endpoint
      )
    ).data
    const newWorkflow = { ...workflow }
    newWorkflow.statut[payload.theme.toUpperCase()] = WorkflowStatut.VALIDE
    await patch(WORKFLOW_ENDPOINT.endpoint, newWorkflow.nom, null, [newWorkflow])
    const dataList = []
    for (let row of data) {
      let newRow = { ...row }
      delete newRow.key
      newRow.editable = true
      dataList.push(newRow)
      await deleteById(CC_THEME_DATAWF_ENDPOINT.endpoint, row.key)
    }
    await patchChild(
      dataList,
      CC_ENDPOINT.endpoint,
      workflow.idcc,
      THEME_ENDPOINT.endpoint,
      payload.theme,
      DATA_ENDPOINT.endpoint
    )
    return
  } else if (apiEndpoint == WORKFLOW_CANCEL_ENDPOINT.endpoint) {
    const workflow = (await getById(WORKFLOW_ENDPOINT.endpoint, payload.workflow)).data
    const data = (
      await getGrandchildren(
        CC_ENDPOINT.endpoint,
        workflow.idcc,
        THEME_ENDPOINT.endpoint,
        payload.theme,
        DATAWF_ENDPOINT.endpoint
      )
    ).data
    for (let row of data) {
      await deleteById(CC_THEME_DATAWF_ENDPOINT.endpoint, row.key)
    }
    const newWorkflow = { ...workflow }
    newWorkflow.statut[payload.theme.toUpperCase()] = WorkflowStatut.CANCEL
    await patch(WORKFLOW_ENDPOINT.endpoint, newWorkflow.nom, null, [newWorkflow])
    return
  }
  apiEndpoint = mapStoreName(apiEndpoint)
  const db = await getDB()
  return await db
    .add(apiEndpoint, payload)
    //.then(() => console.log(`${apiEndpoint} sauvegardé`))
    .then(async () => await updateParents(apiEndpoint, payload))
    .then(() => {
      return { status: 201, data: null }
    })
    .catch(err => {
      console.log(payload)
      if (err) {
        console.log(err)
        throw new Error('500')
      }
    })
}

const postChildren = async (payload, apiEndpoint, idcc, childEndpoint, theme, grandChildEnpoint) => {
  let storeName = getStoreName(apiEndpoint, childEndpoint, grandChildEnpoint)
  try {
    const db = await getDB()
    const result = []
    for (let i = 0; i < payload.length; i++) {
      const all = await db.getAll(storeName)
      const allIds = all.length ? all.map(a => a.id) : [0]
      const id = Math.max(...allIds) + 1
      let payload1 = { ...payload[i] }
      if (!payload1.id) {
        delete payload1.id
        payload1 = { id, ...payload1 }
      }
      if (storeName !== CC_THEME_DATA_ENDPOINT.endpoint) {
        const value = await getChildrenPayload(storeName, getGrandChildId(idcc, theme, id), payload1, null, db)
        await db.add(storeName, value)
      } else {
        const workflows = (await get(WORKFLOW_ENDPOINT.endpoint)).data
        if (workflows) {
          const workflow = workflows.find(
            workflow =>
              workflow.statut &&
              workflow.statut[theme.toUpperCase()] &&
              workflow.statut[theme.toUpperCase()].name === WorkflowStatut.EN_COURS.name &&
              workflow.idcc === id
          )
          if (workflow) {
            storeName = CC_THEME_DATAWF_ENDPOINT.endpoint
          }
        }
        const value = await getChildrenPayload(storeName, getGrandChildId(idcc, theme, ''), [payload1], null, db)
        console.log(value)
        await db.add(storeName, value[0])
      }
      console.log(id)
      result.push(id)
    }
    return { status: 201, data: result }
  } catch (e) {
    console.error(e)
    throw new Error('500')
  }
}

const put = async (apiEndpoint, payload, shouldSkipUpdateParent) => {
  const db = await getDB()
  return await db
    .put(apiEndpoint, payload)
    //.then(() => {      console.log(`${apiEndpoint} numéro ${id} sauvegardé`)    })
    .then(async () => shouldSkipUpdateParent || (await updateParents(apiEndpoint, payload)))
    .then(() => {
      return { status: 200, data: null }
    })
    .catch(err => {
      console.error(err)
      throw new Error('500')
    })
}

const deleteById = async (apiEndpoint, id) => {
  const db = await getDB()
  if (apiEndpoint === COMPONENTS_ENDPOINT.endpoint) {
    await Promise.resolve()
      .then(() => getCCWithComponent(id))
      .then(ccs => {
        return deleteComponentByName(ccs, id, false)
      })
  }
  return (
    db
      .delete(apiEndpoint, id)
      //.then(() => {      console.log(`${apiEndpoint} numéro ${id} supprimé`)    })
      .then(() => {
        return { status: 204, data: null }
      })
      .catch(err => {
        console.error(err)
        throw new Error('500')
      })
  )
}

const deleteChild = async (apiEndpoint, id, childEndpoint, childId, grandChildEnpoint, grandChildId) => {
  if (
    apiEndpoint === THEME_ENDPOINT.endpoint &&
    (childEndpoint === INPUT_ENDPOINT.endpoint || childEndpoint === OUTPUT_ENDPOINT.endpoint) &&
    grandChildEnpoint === VERSION_ENDPOINT.endpoint
  ) {
    const state = store.getState()
    const user = state.user.user
    const versionNom = grandChildId
    const date = new Date()
    const update = ChangeLog.deleteComposantTheme(
      versionNom,
      id,
      [childId],
      `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
      user.login
    )

    let version = (await getById(VERSION_ENDPOINT.endpoint, versionNom)).data
    if (version.nom !== versionNom) {
      version = new Version(versionNom, '', Statut.MODIFICATION_EN_COURS, null)
      await post(VERSION_ENDPOINT.endpoint, version)
    }
    await postChildren([update], VERSION_ENDPOINT.endpoint, versionNom, CHANGELOGS_ENDPOINT.endpoint)
    grandChildEnpoint = null
    grandChildId = null
  }
  const storeName = getStoreName(apiEndpoint, childEndpoint, grandChildEnpoint)
  const idToDetete = getGrandChildId(id, childId, grandChildId)
  const db = await getDB()
  return (
    db
      .delete(storeName, idToDetete)
      //.then(() => {      console.log(`${apiEndpoint} numéro ${id} supprimé`)    })
      .then(() => {
        return { status: 204, data: null }
      })
      .catch(err => {
        console.error(err)
        throw new Error('500')
      })
  )
}

const clear = async apiEndpoint => {
  apiEndpoint = mapStoreName(apiEndpoint)
  const db = await getDB()
  return (
    db
      .clear(apiEndpoint)
      //.then(() => {      console.log(`${apiEndpoint} a été vidée`)    })
      .then(() => {
        return { status: 204, data: null }
      })
      .catch(err => {
        console.log(apiEndpoint)
        console.log(err)
        throw new Error('500')
      })
  )
}

function newCCData(p, id, historiques) {
  return new CcData(id, p, historiques)
}

const conventionsCollectivesThemesStatut = 'conventions-collectives_themes_status'
const conventionsCollectivesHistoriquesStatut = 'conventions-collectives_status'
const versionStatut = 'versions_status'

function checkDoublon(data, db) {
  const doublons = []
  for (let i = 0; i < data.length - 1; i++)
    for (let j = i + 1; j < data.length; j++)
      if (data[i] && _.isEqual(data[i].inputs, data[j].inputs)) {
        doublons.push(new Doublon(TypeDoublon.INPUTS, data[i].key.split('_')[0], data[i].id, data[j].id))
        data[i].statut = Statut.EN_DOUBLON
        data[j].statut = Statut.EN_DOUBLON
        db.put(CC_THEME_DATA_ENDPOINT.endpoint, data[i])
        db.put(CC_THEME_DATA_ENDPOINT.endpoint, data[j])
      }
  return doublons
}

async function getChildrenPayload(storeName, id, payload, referentiel, db) {
  let result
  switch (storeName) {
    case VERSION_ENDPOINT.endpoint:
      result = { ...payload }
      break
    case VERSION_CHANGELOGS_ENDPOINT.endpoint:
      result = {
        version: payload.version,
        type: payload.type.name,
        dateOperation: payload.dateOperation,
        auteur: payload.auteur,
        parameters: { ...payload.parameters },
        description: payload.description,
        key: id,
        id: payload.id,
        ccs: payload.ccs ? payload.ccs : []
      }
      break
    case THEME_ENDPOINT.endpoint:
      result = { ...payload }
      break
    case CC_THEME_ENDPOINT.endpoint:
      const result1 = Array.isArray(payload) ? payload : [payload]
      result = result1.map(
        ({ idTheme, nom, description, themeParent, themeParentId, statut, historique, inputType }) =>
          new CcTheme(idTheme, nom, description, themeParent, themeParentId, id, statut, historique, inputType)
      )
      break
    case CC_HISTORIQUE_ENDPOINT.endpoint:
      result = new HistoriqueInfo(payload)
      break
    case STATUT_ENDPOINT.endpoint:
      const targetedCC = payload.map(p => p.idcc)
      const all = await db.getAll(CC_ENDPOINT.endpoint)
      const cc = all
        .filter(c => targetedCC.includes(c.idcc))
        .map(c => {
          c.statut = Statut.PUBLIE
          return c
        })
      cc.forEach(c => {
        db.put(CC_ENDPOINT.endpoint, c)
      })
      break
    case CC_THEME_DATA_ENDPOINT.endpoint:
      const historiques = await db.getAll(CC_HISTORIQUE_ENDPOINT.endpoint)
      result = payload.map(p => newCCData(p, id + '_' + p.id, historiques))
      break
    case CC_THEME_DATAWF_ENDPOINT.endpoint:
      const historiques2 = await db.getAll(CC_HISTORIQUE_ENDPOINT.endpoint)
      result = payload.map(p => newCCData(p, id + '_' + p.id, historiques2))
      break
    case VERSION_THEME_ENDPOINT.endpoint:
      result = { ...payload }
      result.key = payload.createdVersion + '_' + payload.nom
      break
    case conventionsCollectivesThemesStatut:
      const versions = await db.getAll(VERSION_ENDPOINT.endpoint)
      versions.sort((v1,v2)=>{
        if(v2.id==v1.id) return 0
        if(v1.id < v2.id) return 1
        if(v1.id > v2.id) return -1
      })
      const lastVersion=versions.length>0 ? versions[0] : null
      const ccThemes = await db.getAll(CC_THEME_ENDPOINT.endpoint)
      const histos = await db.getAll(CC_HISTORIQUE_ENDPOINT.endpoint)
      const t = ccThemes.find(cct => cct.key === id)
      const alldata = await db.getAll(CC_THEME_DATA_ENDPOINT.endpoint)
      const data = alldata.filter(d => d.key.startsWith(id)).filter(p=>p.action !== "suppression")
      result = histos.find(cct => cct.nom === t.historique)
      if (!result) result = histos.find(cct => cct.key.startsWith(id.split('_')[0]))
      result.statut = payload.status
      t.statut = payload.status
      const doublons = checkDoublon(data, db)
      if (doublons.length) {
        return { status: 'DOUBLONS', doublons }
      }
      if(!lastVersion || lastVersion.statut.name !== Statut.MODIFICATION_EN_COURS.name) db.put(CC_HISTORIQUE_ENDPOINT.endpoint, result)
      db.put(CC_THEME_ENDPOINT.endpoint, t)
      break
    case conventionsCollectivesHistoriquesStatut:
      const cchistos = await db.getAll(CC_HISTORIQUE_ENDPOINT.endpoint)
      result = cchistos.filter(cct => cct.idcc === id.split('_')[0]).slice(-1)[0]
      result.statut = payload.status
      db.put(CC_HISTORIQUE_ENDPOINT.endpoint, result)
      break
    case THEME_INPUTS_ENDPOINT.endpoint:
      result = payload.map(p => newThemeComponent(p, id))
      break
    case CC_THEME_INPUTS_ENDPOINT.endpoint:
      result = payload.map(p => newThemeComponent(p, id))
      break
    case THEME_OUTPUTS_ENDPOINT.endpoint:
      result = payload.map(p => newThemeComponent(p, id))
      break
    case CC_THEME_OUTPUTS_ENDPOINT.endpoint:
      result = payload.map(p => newThemeComponent(p, id))
      break
    case CC_THEME_INPUTS_VALUES_ENDPOINT.endpoint:
      result = new CcComponentValues(payload, id)
      break
    case CC_THEME_OUTPUTS_VALUES_ENDPOINT.endpoint:
      result = new CcComponentValues(payload, id)
      break
    case REFERENTIEL_ENDPOINT.endpoint:
      referentiel.data.valeurs = payload.map(val => new ReferentielValeur(val.code, val.libelle, val.text, val.defaultValue))
      result = referentiel.data
      break
    case COMPONENTS_ENDPOINT.endpoint:
      return payload
    case WORKFLOW_ENDPOINT.endpoint:
      result = payload.map(p => {
        return { ...p }
      })
      break
    default:
      throw new Error('la row "' + storeName + '" n\'existe pas')
  }
  return result
}

const getComponentExpectedJSON = d => {
  return {
    ...d,
    type: d.type.name,
    role: d.role.name,
    children: d.children ? d.children.map(val => getComponentExpectedJSON(val)) : undefined,
    enfants: d.enfants ? d.enfants.map(val => getComponentExpectedJSON(val)) : undefined,
  }
}

const getFilteredComponentExpecedtJSON = (data, id) =>
  data.filter(d => d.key.startsWith(id)).map(d => getComponentExpectedJSON(d))

function getAllData(data) {
  return Promise.all(
    data.map(async d => {
      const idcc = d.key.split('_')[0]
      var cc = await getById(CC_ENDPOINT.endpoint, idcc)
      cc = cc.data
      return {
        ...d,
        idCC: cc.idcc,
        inputs: { ...d.inputs, titre: cc.titre, idCC: cc.idcc, idccRattachement: cc.idccRattachement },
      }
    })
  )
}

async function filterFather(endpoint, data, id, childId) {
  const prefix = childId ? `${childId}_${id}` : id
  switch (endpoint) {
    case THEME_INPUTS_ENDPOINT.endpoint:
      return data.filter(d => d.key.endsWith(prefix))
    case THEME_OUTPUTS_ENDPOINT.endpoint:
      return data.filter(d => d.key.endsWith(prefix))
    case CC_THEME_INPUTS_ENDPOINT.endpoint:
      return data.filter(d => d.key.endsWith(prefix))
    case CC_THEME_OUTPUTS_ENDPOINT.endpoint:
      return data.filter(d => d.key.endsWith(prefix))
    default:
      throw new Error('la row ' + endpoint + " n'existe pas")
  }
}

async function filterChildren(endpoint, data, id) {
  switch (endpoint) {
    case CC_HISTORIQUE_ENDPOINT.endpoint:
      return data.filter(d => d.key.startsWith(id))
    case CC_THEME_ENDPOINT.endpoint:
      return Object.values(
        data
          .filter(d => {
            if (id.startsWith('all')) {
              return true
            } else {
              return d.key.startsWith(id)
            }
          })
          .map(d => getThemeJSON(d))
          .reduce((obj, theme) => {
            ;(obj[theme.nom] = obj[theme.nom] || []).push(theme)
            return obj
          }, {})
      )
        .map(list => {
          let theme = list.find(t => t.statut.name === Statut.MODIFICATION_EN_COURS.name)
          if (theme) {
            return theme
          } else if (list.length > 0) {
            return list[0]
          } else {
            return null
          }
        })
        .filter(theme => theme)

    case CC_THEME_DATA_ENDPOINT.endpoint:
      if (id.startsWith('all')) {
        const all = await getAllData(data)
        return all.filter(p=>p.action !== "suppression").sort((a, b) => a.idCC.localeCompare(b.idCC))
      } else {
        const filter = data.filter(d => d.key.startsWith(id)).filter(p=>p.action !== "suppression")
        return filter
      }
    case CC_THEME_DATAWF_ENDPOINT.endpoint:
      if (id.startsWith('all')) {
        const all = await getAllData(data)
        return all.sort((a, b) => a.idCC.localeCompare(b.idCC))
      } else {
        const filter = data.filter(d => d.key.startsWith(id))
        return filter
      }
    case CC_THEME_DATAWF_ENDPOINT.endpoint:
      if (id.startsWith('all')) {
        const all = await getAllData(data)
        return all.sort((a, b) => a.idCC.localeCompare(b.idCC))
      } else {
        const filter = data.filter(d => d.key.startsWith(id))
        return filter
      }
    case CC_THEME_INPUTS_ENDPOINT.endpoint:
      return getFilteredComponentExpecedtJSON(data, id)
    case CC_THEME_OUTPUTS_ENDPOINT.endpoint:
      return getFilteredComponentExpecedtJSON(data, id)
    case THEME_INPUTS_ENDPOINT.endpoint:
      return getFilteredComponentExpecedtJSON(data, id)
    case THEME_OUTPUTS_ENDPOINT.endpoint:
      return getFilteredComponentExpecedtJSON(data, id)
    case CC_THEME_INPUTS_VALUES_ENDPOINT.endpoint:
      return data.filter(d => d.key.startsWith(id)).map(v => v.values)[0]
    case CC_THEME_OUTPUTS_VALUES_ENDPOINT.endpoint:
      return data.filter(d => d.key.startsWith(id)).map(v => v.values)[0]
    case REFERENTIEL_VALUES_ENDPOINT.endpoint:
      return data.filter(d => d.key.startsWith(id))
    case VERSION_CHANGELOGS_ENDPOINT.endpoint:
      return data
        .filter(d => d.key.startsWith(id))
        .map(d => new ChangeLog(d.version, getChangeLogType(d.type), d.dateOperation, d.auteur, d.parameters, d.id,d.ccs))
    default:
      throw new Error('la row ' + endpoint + " n'existe pas")
  }
}

const RANK_THEME_COMPONENT = new Map()

function getRankFor(id) {
  const keyRank = id
  const newRank = !RANK_THEME_COMPONENT.get(keyRank) ? 1 : RANK_THEME_COMPONENT.get(keyRank) + 1
  RANK_THEME_COMPONENT.set(keyRank, newRank)
  return newRank
}

function newThemeComponent(p, id) {
  return new ThemeComponent(p, id, getRankFor(id), p.version)
}

export const mapStoreName = apiEndpoint => {
  return apiEndpoint
}

export const getStoreName = (apiEndpoint, childEndpoint, grandChildEndpoint, grandGrandChildrenEndpoint) => {
  if (!childEndpoint) {
    return apiEndpoint
  }
  return `${apiEndpoint}_${childEndpoint}${grandChildEndpoint ? '_' + grandChildEndpoint : ''}${
    grandGrandChildrenEndpoint ? '_' + grandGrandChildrenEndpoint : ''
  }`
}

export const payeService = {
  get,
  getById,
  getChildren,
  post,
  postChildren,
  deleteChild,
  put,
  deleteById,
  clear,
  getApi,
  patch,
  patchChild,
  getGrandchildren,
  getGrandchild,
  getGrandFather,
}

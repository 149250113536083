export const useBreadcrumb = labelsAndUrls => {
  const result = []
  for (let i = 0; i < labelsAndUrls.length; i += 2) {
    const r = {}
    r.label = labelsAndUrls[i]
    if (labelsAndUrls[i + 1]) r.goTo = labelsAndUrls[i + 1]
    result.push(r)
  }
  return [result]
}

import { Theme } from '../constants/theme'
import getPayeService, { setMethodStatus } from '../../api/envService'
import { setLoader, setTableurLoader } from './loader-action'
import { buildComponentFromJson } from './component-action'
import { buildVersionfromJson } from './version-action'
import { SET_THEME, SET_THEME_INPUTS, SET_THEME_OUTPUTS, SET_THEMES } from '../reducers/themes-reducer'
import {
  deleteComponentFromCCTheme,
  getCCTheme,
  getCCThemeComponents, getCCThemes,
  getCCWithComponent,
  getStatutFromData,
} from './convention-collective-action'
import {
  CC_ENDPOINT,
  INPUT_ENDPOINT,
  OUTPUT_ENDPOINT,
  STATUT_ENDPOINT,
  THEME_ENDPOINT,
  THEME_ENDPOINT_PUBLISH_VERSION,
  VERSION_ENDPOINT,
} from '../../api/endpoints'
import { showAlert } from './alert-action'
import { VALIDE_PAYLOAD } from './status-action'
import { isAll, loadData, resolveAndThen } from './tableur-action'
import { Historique, Statut, ChangeLog } from '../constants/historique'
import { ThemeComponent } from '../constants/theme-component'
import { validateTheme } from '../../api/paye-service-api'

const setThemes = themes => ({ type: SET_THEMES, value: themes })
export const setTheme = theme => ({ type: SET_THEME, value: theme })
export const setThemesInputs = inputs => ({ type: SET_THEME_INPUTS, value: inputs })
export const setThemesOutputs = outputs => ({ type: SET_THEME_OUTPUTS, value: outputs })

export const publishTheme = (version, themesNames, doAfter) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().patch(THEME_ENDPOINT_PUBLISH_VERSION, version.nom, null, themesNames))
    .then(() => doAfter && doAfter())
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const initTableurFromWorkflow = (idcc, themeName, history) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then( () => dispatch(getTheme(themeName)))
    .then( () => dispatch(getCCThemes(idcc)))
    .then( () => dispatch(getCCTheme(idcc, themeName)))
    .then( () => history.push('/tableur/' + idcc + '/theme/' + themeName))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const getTheme = (themeName) => dispatch => {
    return Promise.resolve()
      .then(() => dispatch(setLoader(true)))
      .then(() => getPayeService().getById(THEME_ENDPOINT, themeName))
      .then(data => buildThemeFromJson(data))
      .then(theme => dispatch(setTheme(theme)))
      .catch(error => console.error(error))
      .finally(() => dispatch(setLoader(false)))
}

export const createTheme = theme => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().post(THEME_ENDPOINT, theme))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const updateTheme = (theme, version) => dispatch => {
  let payload = { ...theme }
  payload.statut = Statut.MODIFICATION_EN_COURS

  return Promise.resolve()
    .then(() =>
      getPayeService().patch(THEME_ENDPOINT, theme.nom, null, { theme: payload, version: version ? version.nom : '' })
    )
    .catch(error => console.error(error))
}
export const updateThemeCompRank = (payload, theme, compName, role) => dispatch => {
  return Promise.resolve()
    .then(() =>
      getPayeService().patch(THEME_ENDPOINT, theme, (role === 'INPUT' ? 'inputs/' : 'outputs/') + compName, payload)
    )
    .catch(error => console.error(error))
}

export const loadThemesWithComponent = () => {
  return Promise.resolve()
    .then(() => getPayeService().getById(THEME_ENDPOINT, "components"))
    .then(data => {
      return data.map(d => {
          d.theme.inputs = buildThemeComponentsFromJson(d.inputs, true)
          d.theme.outputs = buildThemeComponentsFromJson(d.outputs, true)
        const theme = buildThemeFromJson(d.theme)
        console.log("theme ==> " ,theme)
        return theme
        })
    })
}

export const getThemes = (loadComponents, setInit) => dispatch => {
  if (loadComponents) {
    return Promise.resolve()
      .then(() => loadThemesWithComponent())
      .then(themes => dispatch(setThemes(themes)))
      .then(() => !!setInit && setInit(true))
      .catch(error => console.error(error))
  } else {
    return Promise.resolve()
      .then(() => dispatch(setLoader(true)))
      .then(() => getPayeService().get(THEME_ENDPOINT))
      .then(data => data.map(d => buildThemeFromJson(d)))
      .then(themes => dispatch(setThemes(themes)))
      .then(() => !!setInit && setInit(true))
      .catch(error => console.error(error))
      .finally(() => dispatch(setLoader(false)))
  }
}

export const patchCCThemesComp = (components, isInputs) => (dispatch, getState) => {

  const {
    conventionCollective: { idcc },
    theme: { nom: themeName },
  } = getState().conventionModule

  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() =>
      getPayeService().patchChild(
        components,
        CC_ENDPOINT,
        idcc,
        THEME_ENDPOINT,
        themeName,
        isInputs ? INPUT_ENDPOINT : OUTPUT_ENDPOINT
      )
    )
    .then(() => dispatch(getCCThemeComponents(idcc, themeName, isInputs)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const patchThemesComp = (theme, components, isInputs, version) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().getChildren(THEME_ENDPOINT, theme.nom, isInputs ? INPUT_ENDPOINT : OUTPUT_ENDPOINT))
    .then(currentComponents => {
      let rank = Math.max(...currentComponents.map(comp => comp.rank))
      if (!rank) {
        rank = 1
      }

      const mapper = component => {
        return {
          ...component,
          enfants:
            component.children || component.enfants
              ? component.children
                ? component.children.map(child => mapper(child))
                : component.enfants.map(child => mapper(child))
              : [],
          role: component.role.name,
          unit: component.unit && component.unit.nom ? component.unit.nom : component.unit,
          version: version ? version.nom : '',
          rank: rank++,
        }
      }

      return components.map(comp => {
        return buildThemeComponentFromJson(mapper(comp), false)
      })
    })
    .then(themeComponents =>
      getPayeService().patch(THEME_ENDPOINT, theme.nom, isInputs ? INPUT_ENDPOINT : OUTPUT_ENDPOINT, themeComponents)
    )
    .then(() => dispatch(isInputs ? getThemeInputs(theme.nom) : getThemeOutputs(theme.nom)))
    .then(() => dispatch(getTheme(theme.nom)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

const deleteComponentFromThemeNotDeep = (themes, componentName, version) => {
  const INPUT_ENDPOINT_WITHOUT_ALERT = { ...INPUT_ENDPOINT }
  setMethodStatus(INPUT_ENDPOINT_WITHOUT_ALERT, 'deleteChild', {
    '200': {},
    '202': {},
    '204': {},
  })
  const OUTPUT_ENDPOINT_WITHOUT_ALERT = { ...OUTPUT_ENDPOINT }
  setMethodStatus(OUTPUT_ENDPOINT_WITHOUT_ALERT, 'deleteChild', {
    '200': {},
    '202': {},
    '204': {},
  })

  return Promise.all(
    themes.map(theme => {
      if (theme.inputs && theme.inputs.find(comp => comp.nom === componentName)) {
        if (version) {
          return getPayeService().deleteChild(
            THEME_ENDPOINT,
            theme.nom,
            INPUT_ENDPOINT_WITHOUT_ALERT,
            componentName,
            VERSION_ENDPOINT,
            version.nom
          )
        } else {
          return getPayeService().deleteChild(THEME_ENDPOINT, theme.nom, INPUT_ENDPOINT_WITHOUT_ALERT, componentName)
        }
      } else if (theme.outputs && theme.outputs.find(comp => comp.nom === componentName)) {
        if (version) {
          return getPayeService().deleteChild(
            THEME_ENDPOINT,
            theme.nom,
            OUTPUT_ENDPOINT_WITHOUT_ALERT,
            componentName,
            VERSION_ENDPOINT,
            version.nom
          )
        } else {
          return getPayeService().deleteChild(THEME_ENDPOINT, theme.nom, OUTPUT_ENDPOINT_WITHOUT_ALERT, componentName)
        }
      } else {
        return Promise.resolve()
      }
    })
  ).then(() => {
    return themes
  })
}

export const deleteComponentFromTheme = (ccs, componentName, themes, doNotDeleteData, version) => {
  return Promise.resolve()
    .then(() => {
      if (themes) {
        return Promise.resolve(themes)
      } else {
        return loadThemesWithComponent()
      }
    })
    .then(themesLoaded => {
      return deleteComponentFromCCTheme(ccs, themesLoaded, componentName, doNotDeleteData)
    })
    .then(themesLoaded => {
      return deleteComponentFromThemeNotDeep(themesLoaded, componentName, version)
    })
}

export const deleteThemeComp = (theme, component, isInputs, version) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => initComponentLoading(theme.nom, isInputs ? INPUT_ENDPOINT : OUTPUT_ENDPOINT))
    .then(async components => {
      let c = components.find(comp => comp.nom === component.nom)
      if (c.version) {
        return getPayeService()
          .getById(VERSION_ENDPOINT, c.version)
          .then(version => {
            const v = buildVersionfromJson(version)
            if (v.statut.name === Statut.PUBLIE.name) {
              return Promise.reject({ errorType: 'COMPONENT_USED_IN_VERSION' })
            }
          })
      }
    })
    .then(() => getCCWithComponent(component.nom, theme))
    .then(ccs => deleteComponentFromTheme(ccs, component.nom, [theme], false, version))
    .then(() => dispatch(isInputs ? getThemeInputs(theme.nom) : getThemeOutputs(theme.nom)))
    .catch(error => {
      if (error.errorType === 'COMPONENT_USED_IN_VERSION') {
        dispatch(
          showAlert('error', 'Ce composant est déjà publié sur ce thème, il ne peut donc pas être dissocié du thème')
        )
      } else {
        console.log(error)
      }
    })
    .finally(() => dispatch(setLoader(false)))
}

export const getThemeInputs = themeName => dispatch =>
  Promise.resolve()
    .then(() => initComponentLoading(themeName, INPUT_ENDPOINT))
    .then(async components => {
      await dispatch(setThemesInputs(components))
      return components
    })
    .catch(error => console.error(error))

export const getThemeOutputs = themeName => dispatch =>
  Promise.resolve()
    .then(() => initComponentLoading(themeName, OUTPUT_ENDPOINT))
    .then(components => {
      dispatch(setThemesOutputs(components))
      return components
    })
    .catch(error => console.error(error))

const initComponentLoading = (themeName, endpoint) => {
  return getPayeService()
    .getChildren(THEME_ENDPOINT, themeName, endpoint)
    .then(data => buildThemeComponentsFromJson(data, true))
}

export const validateThemeCC = () => (dispatch, state) => {
  const idcc = state().conventionModule.conventionCollective.idcc
  const theme = state().conventionModule.theme.nom
  return Promise.resolve()
    .then(() => {
      if (!isAll(idcc))
        return Promise.resolve()
          .then( () => validateTheme(VALIDE_PAYLOAD, CC_ENDPOINT, idcc, THEME_ENDPOINT, theme, STATUT_ENDPOINT, 'patch'))
          .then( response => response && response.headers && validateTheme(VALIDE_PAYLOAD, CC_ENDPOINT, idcc, THEME_ENDPOINT, theme, STATUT_ENDPOINT, 'patch', response.headers["servercachename"]))
      else {
        const idccList = new Set(state().tableurModule.data.map(d => d.idCC))
        const promises = []
        idccList.forEach(idCC =>
          promises.push(() =>
            Promise.resolve()
              .then( () => validateTheme(VALIDE_PAYLOAD, CC_ENDPOINT, idCC, THEME_ENDPOINT, theme, STATUT_ENDPOINT, 'patch'))
              .then( response => response && response.headers && validateTheme(VALIDE_PAYLOAD, CC_ENDPOINT, idCC, THEME_ENDPOINT, theme, STATUT_ENDPOINT, 'patch', response.headers["servercachename"]))
          )
        )
        return Promise.resolve().then(() => resolveAndThen(promises, dispatch))
      }
    })
}

export const buildThemesFromJson = data => data.map(data => buildThemeFromJson(data))

export const buildThemeFromJson = (data, fromLabel = false) => {
  return new Theme(
    data.idTheme,
    data.themeName || data.nom || data.name,
    data.description,
    data.themeParent,
    data.themeParentId,
    data.label,
    data.inputLabel,
    data.outputLabel,
    data.withInputs,
    data.withOutputs,
    data.inputs,
    data.outputs,
    getStatutFromData(data, fromLabel),
    data.inputType,
    data.customText
  )
}

export const buildThemeComponentFromJson = (comp, needUnitText = false, mapper) => {
  const data = mapper ? mapper(comp) : comp
  return new ThemeComponent(buildComponentFromJson(comp, needUnitText, mapper), data.themeNom, data.rank, data.version)
}

export const buildThemeComponentsFromJson = (data, needUnitText = false) => {
  return data.map(d => buildThemeComponentFromJson(d, needUnitText))
}

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid, Button, Select, MenuItem, Tooltip, Box } from '@material-ui/core'
import { Panel } from '../../common/panel'
import { ValidationButton } from '../../common/validation-button'
import { CancelButton } from '../../common/cancel-button'
import CCWorkflowList from './cc-workflow-list'
import CCWorkflowForm from './cc-workflow-form'
import { useTheme, makeStyles } from '@material-ui/core'
import { ModelInstanceWorkflow } from '../../../store/constants/ModelInstanceWorkFlow'
import { createWF } from '../../../store/actions/workflow-action'
import { Statut } from '../../../store/constants/historique'

const useStyles = makeStyles(atheme => ({
  contentRoot: {
    ...atheme.mixins.gutters(),
    paddingTop: atheme.spacing(2),
    paddingBottom: atheme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: atheme.palette.background.default,
    padding: atheme.spacing(3),
  },
}))

const WORKFLOW_STATE = {
  LIST: 'LIST',
  SELECT: 'SELECT',
  FORM: 'FORM',
}

const NOT_AUTH_STATUS =  [Statut.MODIFICATION_EN_COURS, Statut.CREATION_EN_COURS, Statut.VALIDE]


export function CcWorkflowTab() {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const [workflowState, setWorkflowState] = useState(WORKFLOW_STATE.LIST)
  const [model, setModel] = useState('')
  const [instanceWF, setInstanceWF] = useState(ModelInstanceWorkflow.NULL)
  const [saveDisabled, setSaveDisabled] = useState(false)
  const { statut, idcc, ouvert } = useSelector(state => state.conventionModule.conventionCollective)
  const dispatch = useDispatch()

  const handleSave = () => {
    setSaveDisabled(true)
    dispatch(
      createWF(
        idcc,
        instanceWF,
        () => history.push(`/tableur/${idcc}/theme/`+instanceWF.themes[0]),
        () => setSaveDisabled(false)
      )
    )
  }

  const models = useSelector(state => state.workflowModule.models.sort((a, b) => a.nom.localeCompare(b.nom)))

  return (
    <Panel value={0} index={0} dir={theme.direction}>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
        <Grid item>
          <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
            {workflowState === WORKFLOW_STATE.LIST && (
              <Grid item>
                <Tooltip
                  title="Impossible de lancer un workflow car la convention n'est pas publiée. Validez vos modifications avant de lancer un workflow"
                  disableFocusListener={!NOT_AUTH_STATUS.includes(statut)}
                  disableHoverListener={!NOT_AUTH_STATUS. includes(statut)}
                  disableTouchListener={!NOT_AUTH_STATUS.includes(statut)}
                >
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => setWorkflowState(WORKFLOW_STATE.SELECT)}
                      data-test="Lancer"
                      disabled={NOT_AUTH_STATUS.includes(statut) || !ouvert}
                    >
                      Lancer
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
            )}
            {workflowState === WORKFLOW_STATE.SELECT && (
              <>
                <Grid item>
                  <Select value={model} onChange={event => setModel(event.target.value)} data-test="select_workflow">
                    {models &&
                      models.map(m => (
                        <MenuItem key={m.nom} value={m} data-test={'item_' + m.description}>
                          {m.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item>
                  <ValidationButton
                    data-test="Commencer le workflow"
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.margin}
                    onClick={() => setWorkflowState(WORKFLOW_STATE.FORM)}
                    disabled={!model.nom}
                  >
                    Commencer le workflow
                  </ValidationButton>
                </Grid>
                <Grid item>
                  <CancelButton
                    data-test="Annuler"
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => setWorkflowState(WORKFLOW_STATE.LIST)}
                    className={classes.margin}
                  >
                    Annuler
                  </CancelButton>
                </Grid>
              </>
            )}
            {workflowState === WORKFLOW_STATE.FORM && (
              <>
                <Grid item>
                  <ValidationButton
                    data-test="Sauvegarder le workflow"
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.margin}
                    onClick={handleSave}
                    disabled={!instanceWF.description || saveDisabled}
                  >
                    Sauvegarder le workflow
                  </ValidationButton>
                </Grid>
                <Grid item>
                  <CancelButton
                    data-test="Annuler"
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => setWorkflowState(WORKFLOW_STATE.LIST)}
                    className={classes.margin}
                  >
                    Annuler
                  </CancelButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {workflowState === WORKFLOW_STATE.LIST && <CCWorkflowList fromCC />}
          {workflowState === WORKFLOW_STATE.FORM && (
            <CCWorkflowForm idcc={idcc} model={model} instanceWF={instanceWF} onChange={wf => setInstanceWF(wf)} />
          )}
        </Grid>
      </Grid>
    </Panel>
  )
}

CcWorkflowTab.propTypes = {}

import { useDispatch, useSelector } from 'react-redux'
import { setActiveCCField } from '../../../store/actions/convention-collective-action'
import ConventionCollective from '../../../store/constants/convention-collective'
import shallowEqual from 'react-redux/lib/utils/shallowEqual'

export const useCCForm = (ccField, isLabel) => {
  const isActive = useSelector(state => state.conventionModule.activeCCField === ccField)
  const dispatch = useDispatch()
  return [
    () => {
      if (!isActive) dispatch(setActiveCCField(ccField))
    },
    isActive,
    isLabel && !isActive,
  ]
}

export function useCCList(idccListInput) {
  const currentIdcc = useSelector(state => state.conventionModule.conventionCollective.idcc)
  return [ConventionCollective.NULL, ...idccListInput.filter(c => c.idcc !== currentIdcc)]
}

export function useChildrenTheme(excludesNames) {
  const allChildren = useSelector(({ themeModule: { themes } }) => {
    const parents = new Set(themes.filter(t => t.themeParent).map(t => t.themeParent))
    return parents ? themes.filter(t => !parents.has(t.nom)) : themes
  }, shallowEqual)
  return excludesNames ? allChildren.filter(t => !excludesNames.includes(t.nom)) : allChildren
}

export function useChildrenCCTheme(excludesNames) {
  const allChildren = useSelector(({ conventionModule: { themes } }) => {
    const parents = new Set(themes.filter(t => t.themeParent).map(t => t.themeParent))
    return parents ? themes.filter(t => !parents.has(t.nom)) : themes
  }, shallowEqual)
  return excludesNames ? allChildren.filter(t => !excludesNames.includes(t.nom)) : allChildren
}

import React from 'react'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, makeStyles, Chip, FormControl, TextField, Checkbox } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(atheme => ({
  contentRoot: {
    ...atheme.mixins.gutters(),
    paddingTop: atheme.spacing(2),
    paddingBottom: atheme.spacing(2),
  },
  content: {
    padding: atheme.spacing(3),
  },
  formControl: {
    margin: atheme.spacing(1),
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      width: '80%',
      marginRight: atheme.spacing(1),
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: atheme.spacing(3),
  },
}))

function CcWorkflowFormList({
  dataTest,
  nom,
  referentielName,
  referenceValues = [],
  onValueChange,
  disabled = false,
  ignore,
  changeIgnore,
  required = false,
}) {
  const classes = useStyles()
  const allReferentiels = useSelector(state => state.referentiels.referentiels)

  const referentiel = allReferentiels.find(ref => ref.nom === referentielName)

  const referentielValues = referentiel.valeurs

  function handleValuesChange(values) {
    onValueChange && onValueChange(values.map(v => v.code))
  }

  return (
    <Box>
      <FormControl component="fieldset" className={classes.formControl}>
        <Grid container direction="row" style={{ width: '100%' }} alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <Checkbox
              disabled={required}
              style={{ margin: 0, padding: 0 }}
              checked={!ignore}
              onClick={() => changeIgnore(!ignore)}
              size="small"
              color="primary"
            />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              data-test={`${dataTest}_list`}
              multiple
              renderOption={option => (
                <span role="option" key={option.code} data-test={`${dataTest}_reference_li`}>
                  {option.code} - {option.libelle}
                </span>
              )}
              options={referentielValues}
              getOptionLabel={val => val.code + ' - ' + val.libelle}
              onChange={(e, value) => handleValuesChange(value)}
              renderInput={params => <TextField {...params} label={nom} />}
              value={referentielValues.filter(val => referenceValues.indexOf(val.code) >= 0)}
              disabled={disabled || ignore}
            />
          </Grid>
        </Grid>
      </FormControl>
    </Box>
  )
}

CcWorkflowFormList.propTypes = {
  dataTest: PropTypes.string.isRequired,
  nom: PropTypes.string.isRequired,
  referentielName: PropTypes.string.isRequired,
  referenceValue: PropTypes.string,
  onValueChange: PropTypes.func,
}

export default CcWorkflowFormList

import React, { useEffect } from 'react'

import { Grid, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCCById } from '../../store/actions/convention-collective-action'
import Paper from '@material-ui/core/Paper'
import { ContainerTitle } from '../common/container-title'
import { getCCThemeComponentValues } from '../../store/actions/component-action'
import { Breadcrumb } from '../common/breadcrumb'
import { ComponentSimpleValues } from './component-simple-values'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useBreadcrumb } from '../common/hooks/breadcrumbs-hooks'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

export const ComponentValueDetail = ({popinAnchorEl}) => {
  const classes = useStyles()
  const [
    {
      role: { name: roleName },
      type,
    },
    breadcrumbValues,
    loader,
  ] = useLoadPage()
  return (
    <>
      {loader ? (
        <LinearProgress />
      ) : (
        <Paper className={classes.contentRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContainerTitle
                classes={classes}
                title={`Valeurs d'un ${roleName.toLowerCase()} de type ${type.name.toLowerCase()}`}
              />
              <Breadcrumb values={breadcrumbValues} />
              <Grid container justify="space-around">
                <ComponentSimpleValues type={type} popinAnchorEl={popinAnchorEl}/>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  )
}

function useLoadPage() {
  const  { idcc, themeName, role, componentName } =  useParams()
  const isInput = role === 'input'
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCCById(idcc))
    dispatch(getCCThemeComponentValues(idcc, themeName, isInput, componentName))
  }, [dispatch, idcc, themeName, componentName, isInput])
  const { input, output } = useSelector(state => state.conventionModule)
  const [breadcrumbValues] = useBreadcrumb([
    idcc,
    `/detail-convention/${idcc}`,
    themeName,
    `/detail-convention/${idcc}/theme/${themeName}`,
    componentName,
  ])
  const loader = useSelector(state => state.loader)
  return [isInput ? input : output, breadcrumbValues, loader]
}

import { Version } from '../constants/version'
import {
  CHANGELOGS_ENDPOINT,
  VERSION_ENDPOINT,
  VERSION_LAST_ENDPOINT,
  VERSION_LAST_PUBLISHED_ENDPOINT,
  VERSION_NEXT_ENDPOINT,
  STATUT_ENDPOINT
} from '../../api/endpoints'
import { SET_VERSION, SET_VERSION_CHANGE_LOGS, SET_VERSIONS } from '../reducers/versions-reducer'
import { setLoader } from '../actions/loader-action'
import getPayeService from '../../api/envService'
import { getChangeLogType, getStatutFromString, ChangeLog, Statut } from '../constants/historique'
import { PUBLISH_PAYLOAD } from './status-action'

export const buildVersionfromJson = ({ id, nom, name, datePublication, publicationDate, statut, status }) => {
  let etat = statut ? statut : status
  if (!etat.name) {
    etat = getStatutFromString(etat)
  }else{
    etat = getStatutFromString(etat.name)
  }
  return new Version(
    nom ? nom : name,
    datePublication ? datePublication : publicationDate ? publicationDate : '',
    etat,
    id
  )
}
export const buildChangeLogsfromJson = ({ version, type, dateOperation, auteur, parameters, id,ccs }) => {
  return new ChangeLog(version, getChangeLogType(type), dateOperation, auteur, parameters, id,ccs)
}

export function buildVersionsFormJson(data) {
  return data.map(d => buildVersionfromJson(d))
}

export const setVersions = versions => ({ type: SET_VERSIONS, value: versions })

export const setVersion = version => ({ type: SET_VERSION, value: version })

export const setVersionChangeLogs = changeLogs => ({
  type: SET_VERSION_CHANGE_LOGS,
  value: changeLogs,
})

export const getVersions = setInit => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().get(VERSION_ENDPOINT))
    .then(data => data.map(d => buildVersionfromJson(d)))
    .then(versions => {
      return Promise.all(
        versions.map(v =>
          getPayeService()
            .getChildren(VERSION_ENDPOINT, v.nom, CHANGELOGS_ENDPOINT)
            .then(historiques => {
              v.description = historiques.map(h => h.description).join(', ')
              return v
            })
        )
      )
    })
    .then(versions => dispatch(setVersions(versions)))
    .then(() => !!setInit && setInit(true))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

  export const deleteVersion = (versionName, doAfter) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().deleteById(VERSION_ENDPOINT, versionName))
    .then(() => doAfter())
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const getVersion = versionName => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().getById(VERSION_ENDPOINT, versionName))
    .then(data => buildVersionfromJson(data))
    .then(v =>
      getPayeService()
        .getChildren(VERSION_ENDPOINT, v.nom, CHANGELOGS_ENDPOINT)
        .then(historiques => {
          v.description = historiques.map(h => h.description).join(', ')
          return v
        })
    )
    .then(version => dispatch(setVersion(version)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const getVersionChangeLogs = versionName => (dispatch, getState) => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().getChildren(VERSION_ENDPOINT.endpoint, versionName ? versionName : getState().versionModule.version.nom, CHANGELOGS_ENDPOINT.endpoint))
    .then(data => data.map(changeLog => buildChangeLogsfromJson(changeLog)))
    .then(changeLogs => dispatch(setVersionChangeLogs(changeLogs)))
    .finally(() => dispatch(setLoader(false)))
}

export const getVersionDescription = async v => {
  return getPayeService()
    .getChildren(VERSION_ENDPOINT.endpoint, v, CHANGELOGS_ENDPOINT.endpoint)
    .then(data => data.map(h => h.description).join(', '))
}


export const patchVersion = (nom, newNom) => dispatch => {
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then( () => getPayeService().patch(VERSION_ENDPOINT.endpoint, nom, null, {oldVersionName: nom, newVersionName : newNom } ))
    .then( () => dispatch(getVersion(newNom)))
    .catch(error => console.error(error))
    .finally(() => dispatch(setLoader(false)))
}

export const getNextVersion = update => {
  return getPayeService()
    .get(VERSION_NEXT_ENDPOINT)
    .then(v => {
      const description = update.description
      const newVersion = buildVersionfromJson(v)
      if (newVersion) {
        if (newVersion.statut.name === 'ENCOURS_MODIFICATION') {
          return getVersionDescription(newVersion.nom).then(versionDescription => {
            return {
              version: newVersion,
              description: versionDescription ? versionDescription + ', ' + description : description,
            }
          })
        } else {
          return { version: newVersion, description: description }
        }
      } else {
        let version = new Version('2.1', '', Statut.MODIFICATION_EN_COURS, '')
        return { version: version, description: description }
      }
    })
}
export const getLastVersion = update => {
  return getPayeService()
    .get(VERSION_LAST_ENDPOINT)
    .then(v => {
      const description = update.description
      const lastVersion = buildVersionfromJson(v)
      return getVersionDescription(lastVersion.nom).then(versionDescription => {
        return {
          version: lastVersion,
          description: versionDescription ? versionDescription + ', ' + description : description,
        }
      })
    })
}
export const getLastPublishedVersion = () => {
  return getPayeService()
    .get(VERSION_LAST_PUBLISHED_ENDPOINT)
    .then(v => {
      return buildVersionfromJson(v)
    })
}

export const publishVersion = (versionName) => dispatch =>{
  return Promise.resolve()
    .then(() => dispatch(setLoader(true)))
    .then(() => getPayeService().patchChild(PUBLISH_PAYLOAD, VERSION_ENDPOINT, versionName, STATUT_ENDPOINT))
    .then(()=>dispatch(getVersion(versionName)))
    .catch((e)=>console.log(e))
    .finally(() => dispatch(setLoader(false)))
}

export const EMPTY = ''
export const SIMPLE_SEPARATOR = ';'
export const TROIS_POINTS_REGEXP = '\\.\\.\\.'
export const ZERO = '0'
export const SPECIAL_TROIS_POINTS = '…'
export const TROIS_POINTS = '...'

export function getListCodesfromFormule(formule, isCode) {
  if (!formule || !isFormule(formule)) return formule
  else return getCodesFromInterval(formule, isCode)
}

function isFormule(formule) {
  return formule.includes(TROIS_POINTS) || formule.includes(SPECIAL_TROIS_POINTS)
}

function getCodesFromInterval(formule, isCode) {
  const split = formule.split(new RegExp(TROIS_POINTS_REGEXP + '|' + SIMPLE_SEPARATOR + '|' + SPECIAL_TROIS_POINTS))
  return calculateSimpleFrom(formule, split, 0, isCode)
}

function calculateSimpleFrom(formule, split, level, isCode) {
  const length = split.length - 1
  if (level >= length) return (level === length) ? SIMPLE_SEPARATOR + split[level] : EMPTY
  return calculateWithNext(formule, split, level, isCode)
}

function calculateWithNext(formule, split, level, isCode) {
  const from = split[level]
  const to = split[level + 1]
  const start = getStart(level)
  if (!isInterval(formule, from, to)) return start + from + calculateSimpleFrom(formule, split, level + 1, isCode)
  const fromAsNumber = Number.parseInt(from)
  const toAsNumber = Number.parseInt(to)
  if(Number.isNaN(fromAsNumber) || Number.isNaN(toAsNumber)) return  start + from + TROIS_POINTS + to
  return start + getSimpleFromRange(fromAsNumber, toAsNumber, isCode) + calculateSimpleFrom(formule, split, level + 2, isCode)

}

function getStart(level) {
  return (level === 0) ? EMPTY : SIMPLE_SEPARATOR
}

function isInterval(formule, from, to) {
  return formule.includes(from + TROIS_POINTS + to) || formule.includes(from + SPECIAL_TROIS_POINTS + to)
}

function getSimpleFromRange(from, to, isCode) {
  return [...Array(to - from + 1).keys()]
    .map(i => i + from)
    .map(v => (v < 10 && isCode ? ZERO : EMPTY) + v)
    .join(';')
}

import { ModelReplacement } from './ModelReplacement'

export class ModelNewValue {
  type
  component
  newValue

  constructor(type = '', component = '', newValue = ModelReplacement.NULL) {
    this.type = type
    this.component = component
    this.newValue = newValue
  }

  static NULL = new ModelNewValue()
}
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import * as PropTypes from 'prop-types'
import React from 'react'

export const capitalizeStr = str => str.charAt(0).toUpperCase() + str.slice(1)

export function PayeLabel({ disabled, onChange, value, dataName, otherLabel }) {
  const label = otherLabel ? otherLabel : capitalizeStr(dataName)
  return (
    <Grid item xs={10}>
      <TextField
        id={dataName}
        label={label}
        data-test={disabled ? `${dataName}_label` : dataName}
        className={'textField'}
        value={value}
        onChange={onChange}
        margin="normal"
        fullWidth
        disabled={disabled}
      />
    </Grid>
  )
}

PayeLabel.propTypes = {
  disabled: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.any,
  dataName: PropTypes.string.isRequired,
}

import React from "react"
import * as PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'

export default function Header ({children,align="left",rowSpan=1,colSpan=1}) {
    return (
        <TableCell align={align} rowSpan={rowSpan} colSpan={colSpan} style={{backgroundColor: 'white'}}>{children}</TableCell>
    )
}

Header.propTypes = {
    align:PropTypes.string
}
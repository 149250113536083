import { Component } from './component'

export class ThemeComponent extends Component {
  key
  rank
  version
  constructor(component, themeNom, rank, version) {
    super(
      component.nom,
      component.label,
      component.role,
      component.referentiel,
      component.defaultValue,
      component.defaultValues,
      component.description,
      component.type,
      component.enfants,
      component.unit,
      component.autonome,
      component.lower,
      component.upper,
      component.bornes,
      component.ferme,
      component.ouvertAGauche,
      component.ouvertADroite,
      component.ouvert,
      component.consistency,
      component.textMap,
      component.customText,
      component.parentPath,
      component.parentType,
      component.exclude,
      component.hasTmpData
    )
    this.key = `${themeNom}_${component.nom}`
    this.rank = rank
    this.version = version
  }
}

import React, { useState } from 'react'
import { TextCard } from './textCard'
import { ChildrenGroupCard } from './children-group-card'
import * as PropTypes from 'prop-types'
import { CardsMenu } from './cards-menu'

export function GroupCard({ idx, childIdx, grandChildIdx, setList, blocks, openMenu, block, handleTextPopoverOpen, parent }) {
  const [anchorElMenu, setAnchorElMenu] = useState()

  function handleOpenMenu(e) {
    setAnchorElMenu(e.target)
  }

  function innerHandleTextPopoverOpen(event, idx, prt, childIdx, grandChildId, condition) {
    setAnchorElMenu(null)
    const p = (parent ? parent : '')

    handleTextPopoverOpen(event, idx, p && prt ? p+'.'+prt : p+prt, childIdx, grandChildId, condition)
  }

  return <>
    <TextCard
      idx={idx}
      grandChildIdx={grandChildIdx}
      childIdx={childIdx}
      isGroup
      edit={e => handleOpenMenu(e)}
      setList={setList}
      list={blocks}>
      {
        block.fragments.map((fragment, k) => <ChildrenGroupCard key={k}
                                                                idx={idx}
                                                                childIdx={childIdx}
                                                                grandChildIdx={grandChildIdx}
                                                                fragment={fragment}
                                                                handleTextPopoverOpen={innerHandleTextPopoverOpen}
                                                                openMenu={openMenu}
                                                                setBlocks={setList}
                                                                blocks={blocks}
                                                                parent={block.fragments[0].component}
                                                                block={block} />)
      }
    </TextCard>

    {
      anchorElMenu
      && <CardsMenu anchorEl={anchorElMenu}
                    idx={idx}
                    childIdx={childIdx}
                    grandChildIdx={grandChildIdx}
                    onClose={() => setAnchorElMenu(null)}
                    handleTextPopoverOpen={innerHandleTextPopoverOpen}
                    block={block}
                    parent={block.fragments[0].component} />
    }
  </>
}

GroupCard.propTypes = {
  idx: PropTypes.number,
  childIdx: PropTypes.number,
  grandChildIdx: PropTypes.number,
  parent: PropTypes.string,
  setList: PropTypes.func,
  block: PropTypes.any,
  blocks: PropTypes.array,
  openMenu: PropTypes.func,
  setBlocks: PropTypes.func,
  handleTextPopoverOpen: PropTypes.func,
}
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setColumnMode, setDoublonMode } from '../../store/actions/tableur-action'
import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'
import BOButton from '../common/bo-button'
import MenuIcon from '@material-ui/icons/Menu'
import * as PropTypes from 'prop-types'
import { getThemes } from '../../store/actions/theme-action'
import { ExportPopOver } from './export-pop-over'


export const TableurMenu = ({ idcc }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElExportForm, setAnchorElExportForm] = useState(null)
  const testmode = useSelector(state => state.user.testmode)
  const versions = useSelector(state => state.versionModule.versions)
  const doublonMode = useSelector(state => state.tableurModule.doublonMode)
  const hideColumns = useSelector(state => state.tableurModule.hideColumns)
  const dispatch = useDispatch()

  const open = Boolean(anchorEl)
  const openExportForm = Boolean(anchorElExportForm)

  useEffect( () => {
    dispatch(getThemes())
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDoublonMode = e => {
    e.stopPropagation()
    dispatch(setDoublonMode(!doublonMode))
  }


  const handleHideColumn = e => {
    e.stopPropagation()
    return dispatch(setColumnMode(!hideColumns))
  }


  const handleOpenExportData = e => {
    setAnchorEl(null)
    setAnchorElExportForm(e.currentTarget)
  }


  const tableurMenu = <Menu
    anchorEl={anchorEl}
    id='account-menu'
    open={open}
    onClose={handleClose}
    onClick={handleClose}>
    <MenuItem onClick={e => e.stopPropagation()}>
      <div style={{ width: 170 }}>Afficher doublons</div>
      <Switch checked={doublonMode} onChange={handleDoublonMode} name='mode_doublons' />
    </MenuItem>
    <MenuItem onClick={e => e.stopPropagation()}>
      <div style={{ width: 170 }}>Masquer vides</div>
      <Switch checked={hideColumns} onChange={handleHideColumn} name='mode_colonnes' color='primary' />
    </MenuItem>
    <Divider />
    <MenuItem disabled={!idcc}>
      <div style={{ width: 170 }}>Visualiser JSON</div>
      <BOButton
        title={`Prévisualiser le Json de ${idcc === 'all' ? 'de toutes les CCs' : `la CC ${idcc}`}`}
        url={version =>
          `/${testmode ? 'testmode' : 'api'}/bo/${version}/conventions-collectives/${
            idcc !== 'all' ? idcc : ''
          }`
        }
        dataTest='PREVISUALISER CC JSON'
        placement='bottom'
        versions={versions}/>
    </MenuItem>
    <MenuItem onClick={handleOpenExportData}>
      <div style={{ width: 170 }}>Exporter données</div>
    </MenuItem>
  </Menu>

  return <Grid item>
     <>
      <IconButton onClick={onClick}>
        <MenuIcon />
      </IconButton>
      {tableurMenu}
      { openExportForm && <ExportPopOver setAnchorElExportForm={setAnchorElExportForm}
                                         anchorElExportForm={anchorElExportForm}
                                         openExportForm={openExportForm}/> }
    </>
  </Grid>
}

TableurMenu.propTypes = {
  idcc: PropTypes.any,
}
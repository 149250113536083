import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { WrapperLink } from '../../../lib/helpers'
import * as PropTypes from 'prop-types'
import React from 'react'
import { ValidationButton } from '../../common/validation-button'

export function CreationButtons({ className, onSave, cc }) {
  return (
    <Grid container spacing={2} alignItems="center" justify="flex-end" direction="row">
      <Grid item>
        <ValidationButton
          variant="contained"
          color="primary"
          className={className}
          onClick={onSave}
          data-test="saveCC"
          disabled={cc.idcc === '' || cc.titre === ''} >
          Enregistrer
        </ValidationButton>
      </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={className}
              component={WrapperLink}
              to="/conventions-collectives"
              data-test="cancel"
            >
              Annuler
            </Button>
          </Grid>
        </Grid>
  )
}

CreationButtons.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  cc: PropTypes.shape({
    titre: PropTypes.string,
    idcc: PropTypes.string,
  }),
}
